import React, { useCallback, useMemo } from "react";
import { x } from "@xstyled/styled-components";
import { Button, IconButton, Switch } from "@alphasights/alphadesign-components";
import { Download } from "@alphasights/alphadesign-icons";
import { useStyles } from "./SynthesisSidebarHeader.styles";
import { SynthesisReprocessButton } from "../SynthesisModuleHeader/SynthesisReprocessButton";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import _ from "lodash";
import { HitAction } from "@alphasights/portal-api-client";

export const SynthesisSidebarHeader = ({
  showSuggested = true,
  setShowSuggested,
}: {
  showSuggested: boolean;
  setShowSuggested: (showSuggested: boolean) => void;
}) => {
  const styles = useStyles();

  const user = useCurrentUser();
  const {
    projectSynthesis,
    setMode,
    onPromoteAllModules,
    modulesToPromote,
    downloadSynthesis,
    synthesisLogHit,
    nothingToShow,
  } = useProjectSynthesisContext();

  const hasDownloadableModules = useMemo(() => {
    const downloadReadyModules = projectSynthesis
      .flatMap((angleSynthesis) => angleSynthesis.modules)
      .filter(
        (module) => module.visibility === "ADDED" && _.maxBy(module.revisions, "revision")?.status === "COMPLETED"
      );
    return downloadReadyModules.length > 0;
  }, [projectSynthesis]);

  const hiddenKpcModules = useMemo(() => {
    return projectSynthesis.flatMap((angleSynthesis) =>
      angleSynthesis.modules.filter((module) => module.contentType === "KPC" && module.visibility === "HIDDEN")
    );
  }, [projectSynthesis]);

  const onNewModuleClick = () => {
    setMode(Mode.NEW);
    synthesisLogHit({ action: HitAction.projectSynthesisCreateModuleClicked });
  };

  const handleSuggestedToggle = () => {
    synthesisLogHit({ action: HitAction.projectSynthesisShowSuggested, details: { showSuggested: !showSuggested } });
    setShowSuggested(!showSuggested);
  };

  const handleDownload = () => {
    synthesisLogHit({ action: HitAction.projectSynthesisDownload });
    downloadSynthesis();
  };

  const handleAddAllModules = useCallback(() => {
    const ids = modulesToPromote.map((ps) => ps.id);
    onPromoteAllModules(ids).then(() => {
      synthesisLogHit({
        action: HitAction.projectSynthesisModulePromoted,
        references: { moduleIds: ids },
        details: { cta: "add-all" },
      });
    });
  }, [modulesToPromote, onPromoteAllModules, synthesisLogHit]);

  const handleAddKpcs = useCallback(() => {
    const hiddenKpcModulesIds = hiddenKpcModules.map((m) => m.id);
    onPromoteAllModules(hiddenKpcModulesIds).then(() => {
      synthesisLogHit({
        action: HitAction.projectSynthesisAddKpcs,
        references: { moduleIds: hiddenKpcModulesIds },
      });
    });
  }, [hiddenKpcModules, onPromoteAllModules, synthesisLogHit]);

  const newModuleOptions = _.compact([
    {
      label: "Create new module",
      onClick: onNewModuleClick,
    },
    modulesToPromote.length > 0 && {
      label: "Add all suggested modules",
      onClick: handleAddAllModules,
    },
    hiddenKpcModules.length > 0 && {
      label: "Add KPC",
      onClick: handleAddKpcs,
    },
  ]);

  const currentUser = useCurrentUser();
  const internalUserNoAccess =
    (currentUser?.internalUser && !(currentUser.permissions ?? []).includes("access_transcripts_and_recordings")) ??
    false;

  return (
    <x.div data-testid="synthesis-sidebar-header">
      {nothingToShow ? (
        <EmptySynthesisSidebarHeader />
      ) : (
        <x.div {...styles.headerWrapper} data-testid="syntesis-header-wrapper">
          <x.div {...styles.leftGroup}>
            {user?.enableAiInteractivity ? (
              <Button
                variant="primary"
                splitOptions={newModuleOptions.length > 1 ? newModuleOptions : undefined}
                size="small"
                onClick={onNewModuleClick}
                dataAttributes={{ "data-testid": "new-module-btn" }}
                disabled={internalUserNoAccess}
              >
                New module
              </Button>
            ) : newModuleOptions.length > 1 ? (
              <Button variant="primary" size="small" onClick={handleAddAllModules} disabled={internalUserNoAccess}>
                Add all suggested modules
              </Button>
            ) : (
              <></>
            )}
            <SynthesisReprocessButton />
            <IconButton
              onClick={handleDownload}
              disabled={internalUserNoAccess || !hasDownloadableModules}
              variant="outline"
              size="small"
              testId="download-synthesis"
            >
              <Download />
            </IconButton>
          </x.div>

          <x.div {...styles.rightGroup}>
            <Switch
              leftText="Suggested"
              size="small"
              isSelected={showSuggested}
              onClick={handleSuggestedToggle}
              dataAttributes={{ "data-testid": "toggle-show-extracted" }}
            />
          </x.div>
        </x.div>
      )}
      <x.div {...styles.separator} />
    </x.div>
  );
};

export const EmptySynthesisSidebarHeader = () => {
  const styles = useStyles();

  return (
    <x.div {...styles.headerWrapper} data-testid="empty-sidebar-header">
      <x.div {...styles.leftGroup}>
        <Button variant="primary" size="small" disabled dataAttributes={{ "data-testid": "new-module-btn" }}>
          Create New Module
        </Button>
        <SynthesisReprocessButton />
      </x.div>

      <x.div {...styles.rightGroup}></x.div>
    </x.div>
  );
};
