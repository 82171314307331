import React from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { labelToMultiline } from "pages/AlphaNowPage/primers/utils/charts";

type XAxisProps = {
  legendsNames: string[];
};

const XAxis = ({ legendsNames }: XAxisProps) => {
  const { color, spacing, typography } = useThemeTokens();

  const getLegendAlignment = (index: number) => {
    switch (index) {
      case 0:
        return "start";
      case legendsNames.length - 1:
        return "end";
      default:
        return "center";
    }
  };

  return (
    <x.div display="flex" flexDirection="column" rowGap={spacing.inner.base05}>
      <x.div
        flex="1 1 auto"
        display="grid"
        alignItems="start"
        gridTemplateColumns="15% repeat(3,1fr) 15%"
        columnGap="10px"
        marginLeft="130px"
      >
        {legendsNames.map((name, labelIndex) => (
          <x.div
            key={`${name}`}
            w="100%"
            alignItems={getLegendAlignment(labelIndex)}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            columnGap={spacing.inner.base02}
            {...typography.body.small}
            color={color.text.strong._}
            rowGap={spacing.inner.base04}
          >
            <div>
              {labelToMultiline(name, 15).map((line, index) => (
                <x.div key={`${name}-${index}`} textAlign="center">
                  {line}
                </x.div>
              ))}
            </div>
          </x.div>
        ))}
      </x.div>
      <x.div marginLeft="108px" textAlign="center" {...typography.body.small} color={color.text.strong._}>
        3yr Market Spend Change
      </x.div>
    </x.div>
  );
};

export default XAxis;
