import React, { useRef } from "react";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { FilterBar } from "components/SurveysPage/FilterBar";
import styled, { x } from "@xstyled/styled-components";
import { Divider, useThemeTokens } from "@alphasights/alphadesign-components";
import { WorkstreamToggler } from "components/InteractionsPage/WorkstreamToggler";
import { HeaderPortal } from "components/InteractionsPage/HeaderPortal";
import { CollapsibleSearch } from "components/CollapsibleSearch/CollapsibleSearch.component";
import { useObserveOverflow } from "hooks/useObserveOverflow";

type SurveyTableHeaderProps = {
  surveyType: SurveyType;
  onFiltersSidebarToggle: () => void;
  onSubmitSearch: React.FormEventHandler<HTMLFormElement>;
  onClearSearch: () => void;
  queryTextRef?: React.Ref<HTMLInputElement>;
};

export const SurveyTableHeader = ({
  surveyType,
  onFiltersSidebarToggle,
  onSubmitSearch,
  onClearSearch,
  queryTextRef,
}: SurveyTableHeaderProps) => {
  const {
    state: { searchQuery },
  } = useProjectInteractionsContext();
  const workstreamTogglerRef = useRef(null);
  const { elementRef, isOverflow } = useObserveOverflow();

  const renderSearchComponent = () => (
    <CollapsibleSearch
      id="advisor-page-search-input"
      data-testid="survey-search"
      onSubmit={onSubmitSearch}
      ref={queryTextRef}
      name="query"
      value={searchQuery}
      autoComplete="off"
      onClear={onClearSearch}
    />
  );

  return (
    <HeaderPortal>
      <SurveyTableHeaderWrapper ref={elementRef}>
        <SearchWrapper>
          <WorkstreamToggler ref={workstreamTogglerRef} />
          {workstreamTogglerRef.current && <Divider vertical h="30px" mx="10px" />}
          {renderSearchComponent()}
        </SearchWrapper>
        <x.div>
          <FilterBar
            toggleFilterSidebar={onFiltersSidebarToggle}
            surveyType={surveyType}
            isCompact={isOverflow}
            searchQuery={searchQuery}
          />
        </x.div>
      </SurveyTableHeaderWrapper>
    </HeaderPortal>
  );
};

const SurveyTableHeaderWrapper = styled(x.div)(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return `
      display: flex;
      align-items: center;
      gap: ${inner.base02};
      padding: ${inner.base04} ${inner.base06};
    `;
});

const SearchWrapper = styled(x.div)(() => {
  return `
    display: flex;
    align-items: center;
    column-gap: 0;
    z-index: 5;
  `;
});
