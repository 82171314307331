import React, { useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "query-utils";
import { isEqual, mergeWith, pick } from "lodash";
import { Popover, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

import { Badge } from "models/Badge";
import { useUserBadgeContext } from "providers/BadgeProvider";
import FilterButton from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/components/FilterButton";
import FilterForm from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/FilterForm";
import { FILTERS } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/constants";
import { FilterFormState } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/Filters/types";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { Filters as TFilters } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";
import useOnClickOutside from "hooks/useOnClickHooks";
import { useAsyncDebounce } from "hooks/useDebounce";
import { eventOnElement } from "utils";
import {
  extractSearchItemsFromQuery,
  getContentFiltersPayload,
} from "pages/AlphaNowPage/components/AlphaNowSearch/utils";
import { contentService } from "services/content";
import {
  BooleanGroup,
  processSearchQuery,
  QueryItem,
} from "pages/AlphaNowPage/components/AlphaNowSearch/boolean-expression-utils";
import { isBooleanSearch } from "components/Search/utils";
import {
  getNumAppliedFilters,
  getContainerMaxHeight,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/Filters/utils";
import {
  INITIAL_FORM_STATE,
  FORM_FILTER_KEYS,
} from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/Filters/constants";

import { MobileFiltersWrapper } from "./Filters.styled";

type FiltersProps = {
  isOpenable: boolean;
};

const Filters = ({ isOpenable }: FiltersProps) => {
  const { color, spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  const { hasUserBadge } = useUserBadgeContext();
  const hasCompanyMetadataBadge = hasUserBadge(Badge.companyMetadata);

  const { query, updateQuery } = useAppSearchContext();
  const { filters: queryFilters } = query;

  const [filterForm, setFilterForm] = useState<FilterFormState>({
    ...INITIAL_FORM_STATE,
    ...pick(queryFilters, FORM_FILTER_KEYS),
  } as FilterFormState);
  const [isOpen, setIsOpen] = useState(false);
  const [isResetDisabled, setIsResetDisabled] = useState(isEqual(filterForm, INITIAL_FORM_STATE));
  const [isShowResultsDisabled, setIsShowResultsDisabled] = useState(true);
  const [numResultsPreview, setNumResultsPreview] = useState<string>();
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  const filtersButtonRef = useRef<HTMLButtonElement>(null);
  const filtersPopoverRef = useRef<HTMLDivElement>(null);

  const isFullScreenView = isMobile && isOpen;
  const numAppliedFilters = useMemo(
    () => getNumAppliedFilters(INITIAL_FORM_STATE, pick(queryFilters, FORM_FILTER_KEYS)),
    [queryFilters]
  );
  const filtersMaxHeight = getContainerMaxHeight(filtersButtonRef.current);

  useEffect(() => {
    const updatedFilters = pick(queryFilters, FORM_FILTER_KEYS);
    setFilterForm(updatedFilters as FilterFormState);
  }, [queryFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isOpenable) {
      handleCloseFilters();
    }
  }, [isOpenable]); // eslint-disable-line react-hooks/exhaustive-deps

  useOnClickOutside(filtersButtonRef, (event) => {
    if (!isMobile && isOpen) {
      if (filtersPopoverRef.current && eventOnElement(event, filtersPopoverRef.current)) {
        return;
      }
      handleCloseFilters();
    }
  });

  const searchSummary = useMutation(
    (filters: FilterFormState) => {
      const allFilters = { ...queryFilters, ...filters };
      const filtersPayload = getContentFiltersPayload(allFilters as TFilters, hasCompanyMetadataBadge);

      const searchQuery = query.searchQuery as QueryItem[];
      const isBooleanQuery = isBooleanSearch(searchQuery);
      let booleanSearchConditions: BooleanGroup[] = [];
      if (isBooleanQuery) {
        const booleanExpressionTree = processSearchQuery(searchQuery);
        booleanSearchConditions.push(booleanExpressionTree as BooleanGroup);
      }
      const searchItemsFromQuery = extractSearchItemsFromQuery(searchQuery);
      const payload = {
        ...searchItemsFromQuery,
        booleanSearchConditions,
        filters: filtersPayload,
      };
      // @ts-ignore - ignoring due to content.js not being typed
      return contentService.fetchSearchSummary(payload);
    },
    {
      onSuccess: (res: { count: string }) => setNumResultsPreview(res?.count),
      onError: () => resetNumResultsPreview(),
      onSettled: () => setIsPreviewLoading(false),
    }
  );

  const fetchSearchSummary = useAsyncDebounce({
    promise: async (filters: FilterFormState) => searchSummary.mutate(filters),
    delay: 1000,
  });

  const updateFilterForm = (newFilters: FilterFormState) => {
    setFilterForm(newFilters);
    setIsResetDisabled(isEqual(newFilters, INITIAL_FORM_STATE));
    const hasFilterChanges = !isEqual(newFilters, pick(queryFilters, FORM_FILTER_KEYS));
    setIsShowResultsDisabled(!hasFilterChanges);
    if (hasFilterChanges) {
      resetNumResultsPreview();
      setIsPreviewLoading(true);
      fetchSearchSummary(newFilters);
    } else {
      setIsPreviewLoading(false);
      resetNumResultsPreview();
    }
  };

  const handleChangeFilters = (value: Partial<FilterFormState>) => {
    const newFilters = mergeWith({}, filterForm, value, (objValue, srcValue) => {
      if (Array.isArray(objValue)) {
        return srcValue;
      }
    });
    updateFilterForm(newFilters);
  };

  const handleApplyFilters = () => {
    updateQuery({ filters: { ...queryFilters, ...filterForm } as TFilters });
    handleCloseFilters();
  };

  const handleResetFilters = () => {
    updateFilterForm(INITIAL_FORM_STATE);
    setIsResetDisabled(true);
  };

  const handleOpenFilters = () => {
    setIsOpen(true);
    setIsResetDisabled(isEqual(filterForm, INITIAL_FORM_STATE));
  };

  const handleCloseFilters = () => {
    const appliedFilters = { ...INITIAL_FORM_STATE, ...pick(queryFilters, FORM_FILTER_KEYS) } as FilterFormState;
    updateFilterForm(appliedFilters);
    setIsOpen(false);
    setIsPreviewLoading(false);
    resetNumResultsPreview();
    setIsShowResultsDisabled(true);
  };

  const handleFiltersClick = () => {
    if (isOpen) {
      handleCloseFilters();
    } else {
      handleOpenFilters();
    }
  };

  const resetNumResultsPreview = () => setNumResultsPreview(undefined);

  const commonFilterFormProps = {
    value: filterForm,
    onChange: handleChangeFilters,
    onConfirm: handleApplyFilters,
    numResults: numResultsPreview,
    isPreviewLoading,
  };

  return (
    <>
      <FilterButton ref={filtersButtonRef} onClick={handleFiltersClick} selected={isOpen}>
        {FILTERS}
        {!!numAppliedFilters && (
          <Typography component="span" pl={spacing.inner.base} color={color.text.info}>
            {numAppliedFilters}
          </Typography>
        )}
      </FilterButton>
      {!isMobile && (
        <Popover
          id="filters-popover"
          ref={filtersPopoverRef}
          anchorEl={filtersButtonRef.current!}
          placement="bottom-start"
          open={isOpen}
          m={0}
        >
          <FilterForm
            isCancelDisabled={isResetDisabled}
            isConfirmDisabled={isShowResultsDisabled}
            maxHeight={filtersMaxHeight}
            onClear={handleResetFilters}
            {...commonFilterFormProps}
          />
        </Popover>
      )}
      {isFullScreenView && (
        <MobileFiltersWrapper>
          <FilterForm onClose={handleCloseFilters} {...commonFilterFormProps} />
        </MobileFiltersWrapper>
      )}
    </>
  );
};

export default Filters;
