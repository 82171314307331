import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ExpertCompareQuestion, QuestionType } from "models/ExpertCompare";
import { UpdateQuestionsRequest, UpdateQuestionsResponse, expertCompareService } from "services/expertCompareService";
import { ENABLE_COMPARISON_VIEW, useProjectBadgeContext } from "./BadgeProvider";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import { Cookie } from "../Cookie";
import { HitOrigin } from "@alphasights/portal-api-client";
import { isMobile } from "browser";

export type ExpertCompareContextState = {
  isViewAccessible: boolean;
  questions: ExpertCompareQuestion[];
  loading: boolean;
  fetchQuestions: (showLoadingIndicator?: boolean) => void;
  updateQuestions: (questions: UpdateQuestionsRequest) => Promise<UpdateQuestionsResponse>;
  isViewBeingGenerated: boolean;
  setReferrerOrigin: (origin: HitOrigin | string | undefined) => void;
  referrerOrigin?: HitOrigin | string;
  enableComparisonView: boolean;
};

export interface ExpertCompareContextProps {
  projectToken: string;
  project?: Project;
  service?: typeof expertCompareService;
  children: ReactNode;
}

export const ExpertCompareContext = React.createContext<undefined | ExpertCompareContextState>(undefined);

const getPoolingFrequency = (hasQuestions: boolean, pendingCommandsCount: number) =>
  !hasQuestions && pendingCommandsCount === 0 ? 60000 : 3000;

const REFRESH_INTERVAL = 3000;

const hasAcceptedLimitationsOfUse = () => {
  return !!Cookie.findByName("limitations_of_use");
};
export const ExpertCompareProvider = ({
  service = expertCompareService,
  project,
  projectToken,
  children,
}: ExpertCompareContextProps) => {
  const [referrerOrigin, setReferrerOrigin] = useState<HitOrigin | string>();
  const [loading, setLoading] = useState(true);
  const [pendingCommandsCount, setPendingCommandsCount] = useState(0);
  const [hasQuestions, setHasQuestions] = useState(false);
  const [questions, setQuestions] = useState<ExpertCompareQuestion[]>([]);
  const { hasProjectBadge } = useProjectBadgeContext();
  const enableComparisonView =
    hasProjectBadge(ENABLE_COMPARISON_VIEW) &&
    !isMobile() &&
    (project?.workstreams?.filter((w) => w.workstreamType === "survey")?.length ?? 0) === 0;
  const isLoggedIn = useIsAuthenticated();
  const isViewAccessible = useMemo(() => enableComparisonView && hasQuestions, [enableComparisonView, hasQuestions]);
  const isViewBeingGenerated = useMemo(() => enableComparisonView && !hasQuestions && pendingCommandsCount > 0, [
    enableComparisonView,
    hasQuestions,
    pendingCommandsCount,
  ]);

  const countPendingCommands = useCallback(
    () => service.countPendingCommands(projectToken).then((commandsCount) => setPendingCommandsCount(commandsCount)),
    [projectToken, service]
  );

  const countQuestions = useCallback(() => {
    const canCountQuestions = hasAcceptedLimitationsOfUse() || isLoggedIn;
    if (canCountQuestions && enableComparisonView) {
      service
        .countQuestions(projectToken)
        .then((questionsCount) => setHasQuestions(questionsCount > 0))
        .finally(() => countPendingCommands());
    }
  }, [countPendingCommands, enableComparisonView, projectToken, service, isLoggedIn]);

  const fetchQuestions = useCallback(
    (showLoadingIndicator = true) => {
      if (isLoggedIn && enableComparisonView) {
        showLoadingIndicator && setLoading(true);
        service
          .findQuestions(projectToken)
          .then((questions) => setQuestions(questions))
          .finally(() => showLoadingIndicator && setLoading(false));
      }
    },
    [enableComparisonView, isLoggedIn, projectToken, service]
  );

  const updateQuestions = useCallback(
    (questions: UpdateQuestionsRequest) => service.updateQuestions(projectToken, questions),
    [projectToken, service]
  );

  useEffect(() => fetchQuestions(), [fetchQuestions, hasQuestions]);

  useEffect(() => countQuestions(), [countQuestions]);

  useEffect(
    function fetchNewResponses() {
      const isAnyQuestionLoading = questions.some(
        (question) => question.responses.length === 0 && question.type === QuestionType.Client
      );
      isAnyQuestionLoading && setTimeout(() => fetchQuestions(false), REFRESH_INTERVAL);
    },
    [fetchQuestions, questions]
  );

  useEffect(
    function updateComparisonViewVisibility() {
      let interval: NodeJS.Timer | undefined;
      if (enableComparisonView && !isViewAccessible) {
        interval = setInterval(() => countQuestions(), getPoolingFrequency(hasQuestions, pendingCommandsCount));
      }
      return () => {
        clearInterval(interval);
      };
    },
    [countQuestions, enableComparisonView, hasQuestions, isViewAccessible, pendingCommandsCount]
  );

  const context = {
    questions,
    loading,
    isViewAccessible,
    isViewBeingGenerated,
    fetchQuestions,
    updateQuestions,
    setReferrerOrigin,
    referrerOrigin,
    enableComparisonView,
  };

  return <ExpertCompareContext.Provider value={context} children={children} />;
};

export const useExpertCompareContext = () => {
  const context = useContext(ExpertCompareContext);

  if (!context) throw new Error("ExpertCompareContext should only be used within ExpertCompareProvider");

  return context;
};
