import React, { useEffect, useState } from "react";
import { ComparisonTable } from "./ComparisonTable/ComparisonTable";
import { Skeleton, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useLocation, useNavigate } from "router-utils";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useExpertCompareContext } from "providers/ExpertCompareProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { getInitialAngleId, useComparisonViewParams } from "./helpers";

type ComparisonViewProps = {
  interactions: Interaction[];
  loading: boolean;
  selectedCardId?: string;
  onCloseFlyout: () => void;
};

export const ComparisonView = ({
  interactions,
  loading: interactionsLoading,
  selectedCardId: selectedInteractionId,
  onCloseFlyout,
}: ComparisonViewProps) => {
  const {
    allInteractionsForProject,
    onFiltersChange,
    state: { appliedFilters },
  } = useProjectInteractionsContext();
  const {
    loading: loadingQuestions,
    referrerOrigin,
    setReferrerOrigin,
    enableComparisonView,
  } = useExpertCompareContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { logHit } = useTrackUserAction();
  const [initialAngleId, setInitialAngleId] = useState<string>();
  const { project } = useCurrentProjectContext();
  const { questions, selectedAngle } = useComparisonViewParams();
  const origin = new URLSearchParams(location.search).get("origin");

  useEffect(
    function redirectToTableView() {
      const noQuestions = questions.length === 0 && !loadingQuestions;
      if (!enableComparisonView || noQuestions) {
        navigate(`/${project!.token}/experts/table-view`, { replace: true });
      }
    },
    [navigate, loadingQuestions, project, questions.length, enableComparisonView]
  );

  useEffect(
    function readInitialAngleIdFromUrl() {
      const params = new URLSearchParams(location.search);
      const selectedAngleId = params.get("selectedAngleId");
      if (selectedAngleId) {
        setInitialAngleId(selectedAngleId);
        params.delete("selectedAngleId");
        navigate(
          {
            search: params.toString(),
          },
          { replace: true }
        );
      }
    },
    [location, navigate]
  );

  useEffect(
    function applyInitialAngleFilter() {
      if (loadingQuestions) return;

      const appliedAngleFilter = appliedFilters.groups ?? [];
      const angleId = getInitialAngleId(allInteractionsForProject, questions, initialAngleId);
      if (angleId && appliedAngleFilter.length === 0) {
        onFiltersChange(
          {
            ...appliedFilters,
            groups: [angleId],
          },
          undefined,
          { keepFlyoutOpen: true, replace: true }
        );
      }
    },
    [allInteractionsForProject, onFiltersChange, initialAngleId, appliedFilters, loadingQuestions, questions]
  );

  useEffect(
    function logHitVisit() {
      logHit({
        action: HitAction.viewComparisonView,
        projectToken: project?.token,
        origin: referrerOrigin ?? origin ?? HitOrigin.comparisonView,
      });
      setReferrerOrigin(undefined);
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <x.div flexGrow="1" display="flex" flexDirection="column" minH="0">
      {loadingQuestions || interactionsLoading || !selectedAngle ? (
        <ComparisonViewSkeleton />
      ) : (
        <ComparisonTable
          selectedAngle={selectedAngle}
          questions={questions}
          interactions={interactions}
          selectedInteractionId={selectedInteractionId}
          onCloseFlyout={onCloseFlyout}
        />
      )}
    </x.div>
  );
};

const ComparisonViewSkeleton = () => {
  const {
    spacing: { layout },
  } = useThemeTokens();

  return (
    <x.div>
      <Skeleton height={layout.base08} count={6} />
    </x.div>
  );
};
