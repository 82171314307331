import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Accordion, Loading, Typography, useAlphaToast, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

import { InteractionHeader } from "./sections/InteractionHeader/InteractionHeader";
import { Messages } from "./sections/Messages/Messages";
import { getThreadStateColorFlyout } from "./utils";

import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useLockedExpert } from "hooks/useLockedExpert";
import { useUserCidEnabled } from "hooks/useUserCidEnabled";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { messageThreadService } from "services/messageThread";
import { ThreadResponse, MessageThreadHeaderResponse, MessageType, Expert } from "types";

import { FlyoutMode, NewMessageOrigin } from "./enums";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { selectExperts, setNewMessageType } from "components/InteractionsPage/reducer";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { setExpertsNamesThread } from "helpers/messageHelpers";
import { interactionsToExperts } from "components/MessengerPage/helper";
import { useIsInternalUser } from "@alphasights/portal-auth-react";
import { ENABLE_LEGACY_BCG_CLARIFICATIONS, useProjectBadgeContext } from "providers/BadgeProvider";
import { NewMessagePanel } from "components/NewMessagePanel/NewMessagePanel";

export const MessagesFlyout = ({
  isProjectCidEnabled = false,
  sendMessageButtonRef,
  maximumTaskDuration,
  setMaximumTaskDuration,
  setSelectedExperts,
}: {
  isProjectCidEnabled: boolean;
  sendMessageButtonRef: React.MutableRefObject<any>;
  maximumTaskDuration: number | undefined;
  setMaximumTaskDuration: (duration: number) => void;
  setSelectedExperts: Function;
}) => {
  const {
    flyoutMode,
    projectToken,
  }: {
    flyoutMode: FlyoutMode;
    projectToken: string;
  } = useFlyoutContext();
  return flyoutMode === FlyoutMode.Messages ? (
    <ViewMessagesFlyout projectToken={projectToken} isProjectCidEnabled={isProjectCidEnabled} />
  ) : (
    <NewMessageFlyout
      sendMessageButtonRef={sendMessageButtonRef}
      setMaximumTaskDuration={setMaximumTaskDuration}
      maximumTaskDuration={maximumTaskDuration}
      setSelectedExperts={setSelectedExperts}
    />
  );
};

const NewMessageFlyout = ({
  sendMessageButtonRef,
  maximumTaskDuration,
  setMaximumTaskDuration,
  setSelectedExperts: setSelectedExpertsInput,
}: {
  sendMessageButtonRef: React.MutableRefObject<any>;
  maximumTaskDuration: number | undefined;
  setMaximumTaskDuration: (duration: number) => void;
  setSelectedExperts: Function;
}) => {
  const {
    interaction,
    newMessageType,
    newMessageOrigin,
    allInteractions,
    onSubmitMessage,
    projectToken,
    selectedExpertsIds,
  }: {
    interaction: any;
    newMessageType: MessageType;
    newMessageOrigin: NewMessageOrigin;
    allInteractions: any[];
    onSubmitMessage: (...args: any) => Promise<void>;
    projectToken: string;
    selectedExpertsIds: string[];
  } = useFlyoutContext();

  const dispatch = useContext(DispatchContext);
  const { bulkLogHit } = useTrackUserAction();
  const internalUser = useIsInternalUser();
  const { toast } = useAlphaToast();

  const [isLoading, setIsLoading] = useState(false);
  const [messageText, setMessageText] = useState("");

  const { hasProjectBadge } = useProjectBadgeContext();
  const enableLegacyBCGClarifications = hasProjectBadge(ENABLE_LEGACY_BCG_CLARIFICATIONS);

  const portalMessagesEnabled = !interaction?.isSurvey;

  const allExperts = useMemo(() => interactionsToExperts(allInteractions, portalMessagesEnabled), [
    allInteractions,
    portalMessagesEnabled,
  ]);

  const validSelectedExperts = useMemo(
    () => allExperts.filter((expert: any) => selectedExpertsIds.includes(expert.id)),
    [allExperts, selectedExpertsIds]
  );

  const [selectedExperts, setSelectedExperts] = useState<Expert[]>(validSelectedExperts);

  const isBlinded = useMemo(() => selectedExperts.some((expert) => expert.hostedInteraction === true), [
    selectedExperts,
  ]);

  useEffect(() => {
    setSelectedExpertsInput(selectedExperts);
  }, [selectedExperts, setSelectedExpertsInput]);

  useEffect(() => {
    setSelectedExperts(validSelectedExperts);
  }, [validSelectedExperts]);

  const onSelectExperts = (selectedExperts: Expert[]) => {
    setSelectedExperts(selectedExperts);
    if (newMessageOrigin === NewMessageOrigin.TableView) {
      dispatch(selectExperts(selectedExperts.map((expert) => expert.id)));
    }
  };

  const onChangeMessageType = useCallback((value: MessageType) => dispatch(setNewMessageType(value)), [dispatch]);

  const handleSubmit = useCallback(
    (params: any, attachment = null) => {
      setIsLoading(true);
      return onSubmitMessage(params, attachment)
        .then(() => {
          toast.success({ message: `${newMessageType} is being sent.` });
          bulkLogHit(
            selectedExperts.map((expert) => ({
              origin: HitOrigin.flyout,
              action:
                newMessageType === MessageType.Clarification
                  ? HitAction.submitClarification
                  : newMessageType === MessageType.CallGuide
                  ? HitAction.submitCallGuide
                  : HitAction.submitWorkRequest,
              advisorshipId: expert.interaction.id,
              projectToken: projectToken,
              details: {
                ...(internalUser && {
                  internalUser,
                }),
              },
            }))
          );
        })
        .finally(() => setIsLoading(false));
    },
    [newMessageType, onSubmitMessage, projectToken, selectedExperts, toast, internalUser, bulkLogHit]
  );

  return (
    <NewMessagePanel
      enableLegacyBCGClarifications={enableLegacyBCGClarifications}
      onChangeMessageType={onChangeMessageType}
      switchToWorkRequest={() => onChangeMessageType(MessageType.WorkRequest)}
      newMessageType={newMessageType}
      experts={allExperts}
      selectedExperts={selectedExperts}
      setSelectedExperts={onSelectExperts}
      handleSubmit={handleSubmit}
      messageText={messageText}
      setMessageText={setMessageText}
      isLoading={isLoading}
      blinded={isBlinded}
      sendMessageButtonRef={sendMessageButtonRef}
      setMaximumTaskDuration={setMaximumTaskDuration}
      showInfoBanner={false}
      showNonElegibleExperts={false}
      maximumTaskDuration={maximumTaskDuration}
    />
  );
};

const ViewMessagesFlyout = ({
  projectToken,
  isProjectCidEnabled = false,
}: {
  projectToken: string;
  isProjectCidEnabled: boolean;
}) => {
  const {
    selectedMessageThread,
    interaction,
    experts,
    onSettingsChanged,
  }: {
    selectedMessageThread: MessageThreadHeaderResponse | undefined;
    interaction: any;
    experts: Expert[];
    onSettingsChanged: () => void;
  } = useFlyoutContext();
  const [threads, setThreads] = useState<ThreadResponse[]>([]);
  const [loading, setLoading] = useState<boolean>();
  const { locked } = useLockedExpert(interaction);
  const userCidEnabled = useUserCidEnabled();
  const { logHit } = useTrackUserAction();

  useEffect(() => {
    if (selectedMessageThread) {
      setLoading(true);

      messageThreadService
        .fetchThread(projectToken, selectedMessageThread.id)
        .then((response) => {
          const threads = response.map((thread) => setExpertsNamesThread(thread, experts));
          setThreads(threads);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedMessageThread, projectToken, interaction.id, experts]);

  useEffect(
    function computeLogHit() {
      if (selectedMessageThread) {
        const currentThread = threads.find((thread) => thread.id === selectedMessageThread.id);
        const hadUnreadMessages = selectedMessageThread.hasUnreadMessages;
        if (hadUnreadMessages && currentThread) {
          logHit({
            projectToken: projectToken,
            origin: HitOrigin.flyout,
            action: selectedMessageThread.workRequest
              ? HitAction.readWorkRequestResponse
              : HitAction.readClarificationResponse,
            references: {
              threadId: currentThread.id,
              expertId: currentThread.advisor.id,
            },
            details: {
              expertName: currentThread.advisor.name,
            },
          });
        }
      }
    },
    [logHit, projectToken, selectedMessageThread, threads]
  );

  if (!selectedMessageThread) return null;

  return (
    <>
      <InteractionHeader
        interaction={interaction}
        isProjectCidEnabled={isProjectCidEnabled}
        userCidEnabled={userCidEnabled}
        locked={locked}
        statusComponent={<ThreadStatus messageThread={selectedMessageThread} />}
      />
      {loading ? (
        <x.div display="flex" justifyContent="center">
          <Loading size="sm" />
        </x.div>
      ) : (
        <Accordion>
          {threads.map((thread: ThreadResponse) => (
            <Messages thread={thread} projectToken={projectToken} onSettingsChanged={onSettingsChanged} />
          ))}
        </Accordion>
      )}
    </>
  );
};

const ThreadStatus = ({ messageThread }: { messageThread: MessageThreadHeaderResponse }) => {
  const { isMobile } = useCheckScreen();
  const { color } = useThemeTokens();

  return (
    <Typography
      variant={isMobile ? "h2" : "h3"}
      color={getThreadStateColorFlyout(messageThread?.state, color)}
      component="span"
    >
      {messageThread?.state.description}
    </Typography>
  );
};
