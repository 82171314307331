import { areIntervalsOverlapping, isAfter, addSeconds, isFuture, min, max } from "date-fns";
import { parseISO } from "../../providers/TimezoneProvider";

export const mergeTimeslots = (slots) => {
  if (slots.length === 0) return [];

  slots.sort(({ start: startX }, { start: startY }) => startX.getTime() - startY.getTime());

  const [first, ...rest] = slots;

  const result = [first];

  rest.forEach((slot) => {
    const last = result[result.length - 1];

    if (areIntervalsOverlapping(last, slot, { inclusive: true })) {
      if (isAfter(slot.end, last.end)) last.end = slot.end;
    } else {
      result.push(slot);
    }
  });

  return result;
};

export const availabilityToEvents = ({ availability = [], postProcess }) => {
  return (availability || [])
    .filter(({ endsAt }) => isFuture(parseISO(endsAt)))
    .map((avail) => ({
      emit: () => true,
      start: parseISO(avail.startsAt),
      end: parseISO(avail.endsAt),
      raw: avail,
    }))
    .map((event) => (postProcess ? postProcess(event) : event));
};

export const interactionToScheduledEvent = ({ interaction, postProcess, startOverride }) => {
  const start = parseISO(startOverride || interaction.scheduledCallTime);
  const event = {
    start,
    end: addSeconds(start, interaction.duration || interaction.expectedDuration || 3600),
    raw: interaction,
  };
  return postProcess ? postProcess(event) : event;
};

const hasPendingSchedule = (interactionId, project) =>
  project.clientRequests?.find((req) => req.interactionId === interactionId && req.type === "SCHEDULE_REQUEST");

const getOverlaps = (event, clientAvailability) => {
  const overlaps = (clientAvailability || []).filter((ca) => areIntervalsOverlapping(ca, event));
  return overlaps.map((o) => ({
    start: max([o.start, event.start]),
    end: min([o.end, event.end]),
  }));
};

export const mapAdvisorAvailability = ({
  projects,
  preselect,
  eventHandler,
  reschedulingId,
  isProvideAvailability,
}) => {
  const source = "expertAvailability";
  const eventsFromInput = projects.flatMap((proj) => {
    const projectInteractions = proj.interactions || [];
    const clientAvailability = (proj.clientAvailability || []).map((ca) => ({
      ...ca,
      start: parseISO(ca.startsAt),
      end: parseISO(ca.endsAt),
    }));
    const clientAvailabilityMap = clientAvailability.reduce(
      (acc, cavail) => {
        const { angleId, advisorshipId } = cavail;
        if (!angleId && !advisorshipId) {
          acc.project = [...acc.project, cavail];
        }
        if (angleId && !advisorshipId) {
          acc[`angle-${angleId}`] = [...(acc[`angle-${angleId}`] || []), cavail];
        }
        if (advisorshipId) {
          acc[`advisorship-${advisorshipId}`] = [...(acc[`advisorship-${advisorshipId}`] || []), cavail];
        }
        return acc;
      },
      { project: [] }
    );
    const getApplicableAvailability = (angleId, advisorshipId) => {
      return [
        ...clientAvailabilityMap.project,
        ...(clientAvailabilityMap[`angle-${angleId}`] || []),
        ...(clientAvailabilityMap[`advisorship-${advisorshipId}`] || []),
      ];
    };
    const projInteractionIds = projectInteractions.map((i) => i.id);
    const ignoreStates = ["scheduled"];
    return projectInteractions
      .filter(
        ({ id, state }) =>
          (!ignoreStates.includes(state) || id === reschedulingId) &&
          !hasPendingSchedule(id, proj) &&
          (!proj.selectedInteractionIds?.length || proj.selectedInteractionIds.includes(id))
      )
      .filter(({ id, followUpId }) => !followUpId || !projInteractionIds.includes(followUpId))
      .flatMap((interaction) => {
        const id = (ev) => `${source}-${interaction.id}-${ev.start.toISOString()}`;
        return availabilityToEvents({
          availability: interaction.advisorAvailability,
          postProcess: (ev) => ({
            ...proj,
            ...ev,
            overlaps: getOverlaps(
              ev,
              getApplicableAvailability(interaction.group?.parent?.id || interaction.group?.id, interaction.id)
            ),
            availability: ev.raw,
            interaction: interaction,
            projectToken: interaction.projectToken,
            source,
            renderPriority: 1,
            emit: eventHandler,
            detailedDescription: false,
            preselected:
              preselect &&
              ((preselect.id && preselect.id === id(ev)) ||
                (preselect.type === ev.source &&
                  preselect.start &&
                  preselect.start.toISOString() === ev.start.toISOString())),
            id: id(ev),
            isProvideAvailability,
          }),
        });
      });
  });

  return eventsFromInput;
};

export const mapUpcomingCalls = ({ upcomingCalls, eventHandler }) => {
  return upcomingCalls.map((call) => {
    const start = parseISO(call.scheduledCallTime);
    const end = addSeconds(start, call.duration || 3600);

    return {
      start,
      end,
      raw: call,
      source: "upcomingCall",
      emit: eventHandler,
    };
  });
};

export const mapScheduledCalls = ({ projects, preselect, eventHandler, reschedulingId, secondary = false }) => {
  const source = "interaction";
  const eventsFromInput = projects.flatMap((proj) =>
    proj.interactions
      .filter(({ id, state }) => ["scheduled", "completed"].includes(state) && id !== reschedulingId)
      .map((interaction) => {
        const id = `${source}-${interaction.id}`;
        return interactionToScheduledEvent({
          interaction: interaction,
          projectToken: interaction.projectToken,
          postProcess: (ev) => ({
            ...proj,
            ...ev,
            highlight:
              !secondary &&
              (!proj.selectedInteractionIds?.length || proj.selectedInteractionIds.includes(interaction.id)),
            requestPending: proj.clientRequests?.find((req) => req.interactionId === interaction.id),
            projectTitle: !proj.hideTitle && interaction.projectTitle,
            interaction: interaction,
            projectToken: interaction.projectToken,
            enableLegacyBCGClarifications: proj.enableMessagesComplianceReview,
            source,
            emit: eventHandler,
            preselected:
              preselect &&
              ((preselect.id && preselect.id === id) ||
                (preselect.type === source &&
                  preselect.start &&
                  preselect.start.toISOString() === ev.start.toISOString())),
            id,
          }),
        });
      })
  );
  return eventsFromInput;
};

export const mapPendingSchedule = ({ projects, preselect, eventHandler }) => {
  const source = "pending";
  const eventsFromInput = projects.flatMap((proj) => {
    return proj.interactions
      .filter(({ id, state }) => {
        const pendingSchedule = hasPendingSchedule(id, proj);

        return ["requested"].includes(state) && pendingSchedule;
      })
      .map((interaction) => {
        const pendingSchedule = hasPendingSchedule(interaction.id, proj);

        const id = `${source}-${interaction.id}`;
        return interactionToScheduledEvent({
          interaction: interaction,
          projectToken: interaction.projectToken,
          startOverride: pendingSchedule.payload.requestedTime || interaction.clientTimeslots[0].startsAt,
          postProcess: (ev) => ({
            ...proj,
            ...ev,
            highlight: true,
            requestPending: proj.clientRequests?.find((req) => req.interactionId === interaction.id),
            projectTitle: !proj.hideTitle && interaction.projectTitle,
            interaction: interaction,
            projectToken: interaction.projectToken,
            source,
            emit: eventHandler,
            preselected:
              preselect &&
              ((preselect.id && preselect.id === ev) ||
                (preselect.type === source && preselect.start.toISOString() === ev.start.toISOString())),
            id,
          }),
        });
      });
  });
  return eventsFromInput;
};

export const createLabel = ({ advisorshipIds = [], angleIds = [], angles = [], advisorships = [], subtype }) => {
  const base = {
    project: "Team Availability | Project",
    angle: "Team Availability",
    expert: "Team Availability",
  }[subtype];
  if (!base) return "(Availability)";

  if (advisorshipIds.length > 0) {
    const names = advisorships
      .filter((a) => advisorshipIds.includes(a.id))
      .map((a) => a?.advisorName)
      .join(" • ");
    return `${base} | ${names}`;
  }

  if (angleIds.length > 0) {
    const angle = angles
      .filter((a) => angleIds.includes(a.id))
      .map((a) => a?.title)
      .join(" • ");
    return `${base} | ${angle}`;
  }

  return base;
};

const convertClientAvailability = (avails, angles, advisorships) => {
  const baseEvents = avails.map((avail) => {
    const subtype = (avail.advisorshipId && "expert") || (avail.angleId && "angle") || "project";
    const advisorshipIds = subtype === "expert" ? [avail.advisorshipId].filter((a) => a) : [];
    const angleIds = subtype === "angle" ? [avail.angleId].filter((a) => a) : [];
    const id = `clientAvailability-${subtype}-${avail.startsAt}-${avail.endsAt}`;
    return {
      ...avail,
      original: avail,
      advisorshipIds,
      angleIds,
      subtype,
      id,
    };
  });
  const groupedBySameTypeStartEnd = baseEvents.reduce((acc, cur) => {
    const key = `${cur.subtype}.${cur.startsAt}.${cur.endsAt}`;
    (acc[key] = acc[key] || []).push(cur);
    return acc;
  }, {});
  const flattened = Object.values(groupedBySameTypeStartEnd).map((grouped) => {
    const advisorshipIds = [...new Set(grouped.flatMap((g) => g.advisorshipIds))];
    const angleIds = [...new Set(grouped.flatMap((g) => g.angleIds))];
    const originals = [...new Set(grouped.flatMap((g) => g.original))];
    const overlaps = grouped.flatMap((g) => g.overlaps);
    const merged = {
      ...grouped[0],
      overlaps,
      advisorshipIds,
      angleIds,
      originals,
      label: createLabel({
        advisorshipIds,
        angleIds,
        angles,
        advisorships,
        subtype: grouped[0].subtype,
      }),
    };
    return merged;
  });
  return flattened;
};

export const mapClientAvailability = ({ projects, preselect, eventHandler }) => {
  const source = "clientAvailability";
  const eventsFromInput = projects.flatMap((proj) => {
    const eventsFromProject = convertClientAvailability(
      proj.clientAvailability || [],
      proj.angles,
      proj.allInteractions
    ).flatMap((avail) => {
      const vals = availabilityToEvents({
        availability: [avail],
        postProcess: (ev) => ({
          ...proj,
          ...ev,
          ...avail,
          project: proj,
          projectToken: proj.token,
          origin: avail,
          source,
          emit: eventHandler,
          preselected:
            preselect &&
            ((preselect.id && preselect.id === avail.id) ||
              (preselect.type === source && preselect.start.toISOString() === ev.start.toISOString())),
        }),
      });
      return vals;
    });
    return eventsFromProject;
  });

  return eventsFromInput;
};

export const mapMissingEventFromPreselect = ({ preselect, editing, eventHandler, project }) => {
  const start = editing?.start || preselect.start;
  const end = editing?.end || preselect.end || addSeconds(start, preselect.duration || 3600);
  return preselect.type === "clientAvailability" || editing
    ? {
        id: "clientAvailability-new",
        start,
        end,
        interaction: preselect.interaction,
        source: "clientAvailability",
        subtype: "pending",
        emit: eventHandler,
        preselected: true,
        renderPriority: 5,
        project,
        label: preselect.label,
        selectedType: preselect.selectedType,
      }
    : null;
};

export const selectedInteractionAngleIds = (project) => {
  const anglesIds = project.allInteractions
    .filter((it) => project.selectedInteractionIds.includes(it.id))
    .flatMap((it) => it.angles)
    .flatMap((angle) => [angle?.parent?.id || angle?.id])
    .filter((a) => a);

  return [...new Set(anglesIds)];
};
