import { Icon, Tooltip } from "@alphasights/alphadesign-components";
import { Project } from "@alphasights/alphadesign-icons";
import { useIsOverflow } from "@alphasights/client-portal-shared";
import React, { useRef } from "react";
import * as S from "./ProjectTitle.styled";
import { ViewInSystem } from "components/DebugSupport";

export const ProjectTitle = ({ projectTitle }: { projectTitle: string }) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const isTitleOverflow = useIsOverflow(titleRef);

  return (
    <S.Wrapper data-testid="project-title-wrapper">
      <Icon color="inverse">
        <Project />
      </Icon>
      <Tooltip disabled={!isTitleOverflow} title={projectTitle}>
        <S.ProjectTitle ref={titleRef}>{projectTitle}</S.ProjectTitle>
      </Tooltip>
      <ViewInSystem />
    </S.Wrapper>
  );
};
