import React, { FC, useRef, useState } from "react";
import { omit } from "lodash";
import { IconButton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { LimitedLinesTypography } from "@alphasights/client-portal-shared";
import { MoreVert } from "@alphasights/alphadesign-icons";
import { useModal } from "@alphasights/ads-community-hooks";

import { useMutation, useQueryClient } from "query-utils";
import { ThirdPartyInteraction } from "types";
import { getTitle } from "views/DeliverablesView/ThirdPartyDocumentCard/utils";
import EditDocumentModal from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal";
import { createFormPropsFromDocument } from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/utils";
import { DocumentAttributes } from "services/thirdPartyDocumentsService";
import { useDeliverableContext } from "providers/DeliverableProvider";
import ConfirmRemoveDocModal from "views/DeliverablesView/ThirdPartyDocUploadModal/ConfirmRemoveDocModal";
import DocumentOptionsPopover from "./DocumentOptionsPopover";
import DetailsRow from "./DetailsRow";

import * as S from "./PageHeader.styled";
import DocumentActionsPanel from "./DocumentActionsPanel";
import { ViewType } from "views/DeliverablesView/ThirdPartyDocumentPage/consts";

type PageHeaderProps = {
  interaction: ThirdPartyInteraction;
  searchTargetEl: any;
  selectedView: ViewType;
  onChangeView: (view: ViewType) => void;
};

const PageHeader: FC<PageHeaderProps> = ({ interaction, searchTargetEl, selectedView, onChangeView }) => {
  const { color } = useThemeTokens();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const popoverAnchor = useRef(null);
  const queryClient = useQueryClient();
  const { updateDocumentAttributes, deleteDocument } = useDeliverableContext();
  const { isVisible: isEditModalVisible, onOpen: onOpenEditModal, onClose: onCloseEditModal } = useModal();
  const {
    isVisible: isRemoveConfirmationModalVisible,
    onOpen: onOpenRemoveConfirmationModal,
    onClose: onCloseRemoveConfirmationModal,
  } = useModal();

  const title = getTitle(interaction);
  const { id: documentId, scheduledCallTime } = interaction;

  const handleClosePopover = () => setIsOptionsOpen(false);

  const refetchDocuments = () => queryClient.invalidateQueries(["fetchThirdPartyDocuments"]);

  const updateDocumentAttributesMutation = useMutation(
    ({ documentId, attributes }: { documentId: string; attributes: Partial<DocumentAttributes> }) => {
      const updatedAttributes = {
        ...interaction.attribute.attributes,
        documentTitle: attributes.documentTitle,
        ...attributes,
        date: scheduledCallTime,
      };
      return updateDocumentAttributes(documentId, updatedAttributes);
    },
    {
      onSuccess: () => {
        refetchDocuments();
      },
    }
  );

  const deleteDocumentMutation = useMutation((documentId: string) => deleteDocument(documentId), {
    onSuccess: () => {
      refetchDocuments();
    },
  });

  const handleSaveEdits = (attributes: DocumentAttributes) => {
    try {
      updateDocumentAttributesMutation.mutateAsync({ documentId, attributes });
    } catch (error) {
      console.error("Unable to save edits", error);
    }
    onCloseEditModal();
  };

  const editDocumentModalFileProps = createFormPropsFromDocument(omit(interaction, ["scheduledCallTime", "origin"]));

  const handleClickEditOption = () => {
    setIsOptionsOpen(false);
    onOpenEditModal();
  };

  const handleClickDeleteOption = () => {
    setIsOptionsOpen(false);
    onOpenRemoveConfirmationModal();
  };

  const handleConfirmRemove = () => {
    onCloseRemoveConfirmationModal();
    deleteDocumentMutation.mutate(documentId);
  };

  return (
    <S.HeaderWrapper>
      <S.ContentsWrapper>
        <S.InfoWrapper>
          <LimitedLinesTypography tooltip={title} component="span">
            <Typography color={color.text.strong._} variant="body-large-em" component="span">
              {title}
            </Typography>
          </LimitedLinesTypography>
          <DetailsRow interaction={interaction} onOpenEditModal={onOpenEditModal} />
        </S.InfoWrapper>
        <IconButton ref={popoverAnchor} onClick={() => setIsOptionsOpen((a) => !a)} variant="ghost" color="secondary">
          <MoreVert />
        </IconButton>
        {isOptionsOpen && (
          <DocumentOptionsPopover
            anchorRef={popoverAnchor}
            onClose={handleClosePopover}
            onClickEdit={handleClickEditOption}
            onClickDelete={handleClickDeleteOption}
          />
        )}
      </S.ContentsWrapper>
      <DocumentActionsPanel searchTargetEl={searchTargetEl} selectedView={selectedView} onChangeView={onChangeView} />
      {isEditModalVisible && (
        <EditDocumentModal
          onClickCancel={onCloseEditModal}
          onClickSave={handleSaveEdits}
          {...editDocumentModalFileProps}
        />
      )}
      {isRemoveConfirmationModalVisible && (
        <ConfirmRemoveDocModal onClickConfirm={handleConfirmRemove} onClickCancel={onCloseRemoveConfirmationModal} />
      )}
    </S.HeaderWrapper>
  );
};

export default PageHeader;
