import React, { useRef } from "react";
import { Tooltip, Typography } from "@alphasights/alphadesign-components";
import { useIsOverflow } from "@alphasights/client-portal-shared";

export const HighlightTextTypography = ({ variant = "body", component, className = "", color, ...props }) => {
  const tooltipTitle = getHighlightedText(props);
  const textRef = useRef(null);
  const isOverflow = useIsOverflow(textRef);

  return (
    <Tooltip data-testid="highlited-tooltip" title={tooltipTitle} disabled={!isOverflow}>
      <Typography
        ref={textRef}
        maxWidth="100%"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        display="inline-block"
        cursor="pointer"
        variant={variant}
        component={component}
        className={className}
        color={color}
        dangerouslySetInnerHTML={{ __html: tooltipTitle }}
      />
    </Tooltip>
  );
};

export const getHighlightedText = ({ highlights = [], fieldNames, text }) => {
  const highlight = highlights.find((highlight) => fieldNames.includes(highlight.fieldName));
  return highlight && highlight.terms.length ? highlight.terms[0] : text;
};
