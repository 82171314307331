import { useAlphaToast } from "@alphasights/alphadesign-components";
import { AngleTypeSynthesis, SynthesisModule } from "@alphasights/portal-api-client";
import { useCallback, useEffect, useRef } from "react";
import { projectSynthesis as API } from "@alphasights/portal-api-client";
import _ from "lodash";

export const useDeleteSynthesisModule = ({
  projectToken,
  projectSynthesis,
  setProjectSynthesis,
  selectModuleInLatestRevision,
}: {
  projectToken: string;
  projectSynthesis: AngleTypeSynthesis[];
  setProjectSynthesis: React.Dispatch<React.SetStateAction<AngleTypeSynthesis[]>>;
  selectModuleInLatestRevision: (module: SynthesisModule) => void;
}) => {
  const modulesToDelete = useRef<SynthesisModule[]>([]);
  const { toast } = useAlphaToast();

  const revertSoftDelete = useCallback(
    (angleType: string, deletedModule: SynthesisModule) => {
      modulesToDelete.current = modulesToDelete.current.filter((m) => m.id !== deletedModule.id);
      setProjectSynthesis((prev) => {
        return prev.map((angle) =>
          angle.angleType === angleType
            ? { ...angle, modules: angle.modules.map((m) => (m.id !== deletedModule.id ? m : { ...m, hidden: false })) }
            : angle
        );
      });
    },
    [setProjectSynthesis]
  );

  const deleteModuleFromBackend = useCallback(
    (deletedModule: SynthesisModule) => {
      modulesToDelete.current = modulesToDelete.current.filter((m) => m.id !== deletedModule.id);
      API.deleteProjectSynthesisModule(projectToken, deletedModule.id);
    },
    [projectToken]
  );

  const softDelete = useCallback(
    (moduleToDelete: SynthesisModule) => {
      let didUndo = false;
      const visibleProjectSynthesis = projectSynthesis
        .map((angleSynthesis) => ({
          ...angleSynthesis,
          modules: _.partition(
            angleSynthesis.modules.filter(
              (module) =>
                !(module as SynthesisModule & { hidden: boolean }).hidden &&
                module.contentType !== "SUGGESTED_INQUIRY" &&
                module.visibility !== "HIDDEN"
            ),
            (module) => module.visibility === "ADDED"
          ).flat(),
        }))
        .filter((angleSynthesis) => angleSynthesis.modules.length > 0);
      const deletedModuleAngleType = visibleProjectSynthesis.find((angle) =>
        angle.modules.some((m) => m.id === moduleToDelete.id)
      )?.angleType!;

      function onUndo() {
        if (didUndo) return;
        revertSoftDelete(deletedModuleAngleType, moduleToDelete);
        didUndo = true;
      }

      function onToastExpire() {
        didUndo || deleteModuleFromBackend(moduleToDelete);
      }

      function onClose() {
        didUndo || deleteModuleFromBackend(moduleToDelete);
      }

      modulesToDelete.current.push(moduleToDelete);

      const relevantAngleSynthesis = visibleProjectSynthesis.find((angle) =>
        angle.modules.some((m) => m.id === moduleToDelete.id)
      );
      const modulesToSearch =
        relevantAngleSynthesis && relevantAngleSynthesis.modules.length > 1
          ? relevantAngleSynthesis.modules
          : visibleProjectSynthesis.flatMap((angle) => angle.modules);
      const deletedModuleIndex = modulesToSearch.map((module) => module.id).indexOf(moduleToDelete.id);
      const moduleToSelect = modulesToSearch[deletedModuleIndex + 1] ?? modulesToSearch[deletedModuleIndex - 1];

      selectModuleInLatestRevision(moduleToSelect);
      setProjectSynthesis((angles) =>
        angles.map((angle) => ({
          ...angle,
          modules: angle.modules.map((m) => (m.id !== moduleToDelete.id ? m : { ...m, hidden: true })),
        }))
      );

      toast.success({ message: "Module deleted.", onUndo, onClose, onToastExpire });
    },
    [
      deleteModuleFromBackend,
      projectSynthesis,
      revertSoftDelete,
      selectModuleInLatestRevision,
      setProjectSynthesis,
      toast,
    ]
  );

  useEffect(function triggerBackendDeleteOnUnloadaAndUnmount() {
    window.addEventListener("beforeunload", () =>
      modulesToDelete.current.forEach((module) => {
        deleteModuleFromBackend(module);
      })
    );

    return () => {
      toast.clean();
      window.removeEventListener("beforeunload", () =>
        modulesToDelete.current.forEach((module) => {
          deleteModuleFromBackend(module);
        })
      );
      modulesToDelete.current.forEach((module) => {
        deleteModuleFromBackend(module);
      });
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    deleteModule: softDelete,
  };
};
