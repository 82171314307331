import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation, useParams } from "router-utils";
import {
  Logo,
  LogoVariation,
  NavBar,
  Typography,
  Alert,
  Loading,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { CallMePanel } from "components/CallMePanel";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { fetch } from "hooks/useApi";
import { x } from "@xstyled/styled-components";
import { useContentWrapper, StyledOuterContainer } from "./CallMePage.styled";

const LoadingData = ({ isMobile }) => {
  const { spacing, color } = useThemeTokens();
  return (
    <x.div
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={isMobile ? "100%" : "auto"}
    >
      <Typography color={color.text.assistive} variant="body" textAlign="center">
        Loading Dial-in Options
      </Typography>
      <x.div mt={spacing.layout.base02} display="flex" justifyContent="center" alignItems="center">
        <Loading size="lg" />
      </x.div>
    </x.div>
  );
};

export const CallMePage = () => {
  const params = useParams();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const { tab } = values;

  const { token, interactionId } = params;
  const { isMobile } = useCheckScreen();
  const [isLoading, setIsLoading] = useState(true);
  const [dialInInfo, setDialInInfo] = useState();
  const { spacing, color, shape } = useThemeTokens();
  const { outerContainer, innerContainer, contentContainer } = useContentWrapper();

  useEffect(() => {
    setIsLoading(true);
    const url = `/api/internal/projects/${token}/calls/${interactionId}/details`;
    fetch({
      url,
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        if (!data) {
          setDialInInfo(null);
        } else {
          const { clientAccessNumber, clientAccessCode, dialInNumbers, id, newspeakProvider, callMeUrl } = data;

          setDialInInfo({
            mainNumber: clientAccessNumber,
            accessCode: clientAccessCode,
            otherNumbers: dialInNumbers,
            projectToken: token,
            interactionId: id,
            newspeakProvider: newspeakProvider,
            isClosed: false,
            callMeUrl: callMeUrl,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [token, interactionId]);

  return (
    <>
      {isMobile ? (
        <x.div
          display="flex"
          justifyContent="center"
          py={spacing.inner.base04}
          boxShadow={shape.shadow.medium}
          borderRadius={shape.border.radius.medium}
        >
          <Logo size="xs" variation={LogoVariation.ColorName} />
        </x.div>
      ) : (
        <NavBar logoProps={{ size: "xs" }} />
      )}
      <>
        {isLoading ? (
          isMobile ? (
            <x.div marginTop={spacing.layout.base10}>
              <LoadingData isMobile={isMobile} />
            </x.div>
          ) : (
            <x.div display="flex" alignItems="center" justifyContent="center" marginTop="25%">
              <x.div display="flex" flexDirection="column">
                <LoadingData isMobile={isMobile} />
              </x.div>
            </x.div>
          )
        ) : (
          <>
            {dialInInfo ? (
              isMobile ? (
                <x.div marginTop={spacing.layout.base04}>
                  <div>
                    <CallMePanel {...dialInInfo} initialTab={tab ? parseInt(tab) || null : null} />
                  </div>
                </x.div>
              ) : (
                <StyledOuterContainer style={outerContainer}>
                  <div style={innerContainer}>
                    <div style={contentContainer}>
                      <CallMePanel {...dialInInfo} initialTab={tab ? parseInt(tab) || null : null} />
                    </div>
                  </div>
                </StyledOuterContainer>
              )
            ) : (
              <x.div m={spacing.layout.base04}>
                <Alert variant="danger">
                  Failed to load dial-in options. Please reach out to your AlphaSights project lead if you continue to
                  get this error.{" "}
                  <Typography
                    style={{ cursor: "pointer" }}
                    component="span"
                    color={color.text.link}
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Refresh Page
                  </Typography>
                </Alert>
              </x.div>
            )}
          </>
        )}
      </>
    </>
  );
};
