import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { SETTLED_STATUS, DataFetchAction } from "./consts";

import Experts from "pages/AlphaNowPage/components/AlphaNowCompanyPage/Experts";
import Insights from "pages/AlphaNowPage/components/AlphaNowCompanyPage/Insights";
import AlphaNowCompanyOverview from "pages/AlphaNowPage/components/AlphaNowCompanyPage/AlphaNowCompanyOverview";
import PrimerSection from "pages/AlphaNowPage/components/AlphaNowCompanyPage/PrimerSection";
import AlphaNowCompanyAngleSection, {
  Variant,
} from "pages/AlphaNowPage/components/AlphaNowCompanyPage/AlphaNowCompanyAngleSection";
import useCompanyPageTracking, {
  Action as TrackingDataAction,
  ActionType as TrackingDataActionType,
  PayloadType as TrackingDataPayloadType,
} from "pages/AlphaNowPage/components/AlphaNowCompanyPage/useCompanyPageTracking";
import { handleScroll, logScrollPercentage, logTimeSpent } from "pages/AlphaNowPage/utils/logHit";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { FetchStatus, initialFetchStatuses } from "./consts";
import useFetchStatus from "hooks/useFetchStatus";
import LoadingSpinner from "pages/AlphaNowPage/components/AlphaNowCompanyPage/components/LoadingSpinner";
import { useCompanyPrimersStore } from "pages/AlphaNowPage/primers/CompanyPrimer/state/store";
import useCompanyOverview from "pages/AlphaNowPage/hooks/useCompanyOverview";
import { PrimerCompanyLogo, RequestSurveyBanner } from "pages/AlphaNowPage/primers/components";
import { RequestSurveyBannerViewModes } from "pages/AlphaNowPage/primers/request-survey/banner/RequestSurveyBanner";
import useGetModalFromUrl from "hooks/useGetModalFromUrl";
import SurveyModal from "pages/AlphaNowPage/primers/request-survey/modal/SurveyModal";
import { SURVEY_URL_PARAM } from "pages/AlphaNowPage/primers/request-survey/constants";
import { useSurveysStore } from "pages/AlphaNowPage/primers/request-survey/state/store";

const DataTestIds = {
  alphaNowCompanyPage: "alphanow-company-page",
};

const AlphaNowCompanyPage = ({ companyId }: { companyId: number }) => {
  const { spacing } = useThemeTokens();

  const [fetchStatus, setFetchStatus] = useState(initialFetchStatuses);
  const { isLoading } = useFetchStatus(fetchStatus);

  const [bubbledData, setBubbledData] = useState({
    competitors: [] as CompanyInfo[],
  });

  const { logHit } = useTrackUserAction();
  const currentUser = useCurrentUser();
  const userId = String(currentUser?.id ?? "");
  const { companyOverviewData, hasCompanyOverview } = useCompanyOverview(companyId);
  const companyName = companyOverviewData?.name;
  const companyLogo = companyOverviewData?.logo;

  const setCompanyPrimerState = useCompanyPrimersStore(({ setCompanyPrimerState }) => setCompanyPrimerState);

  const isSurveyModalOpen = useSurveysStore(({ isModalOpen }) => isModalOpen);
  const setIsModalOpen = useSurveysStore(({ setIsModalOpen }) => setIsModalOpen);
  useGetModalFromUrl(() => setIsModalOpen(true), SURVEY_URL_PARAM, {
    origin: HitOrigin.companyPage,
    action: HitAction.alphaNowRequestSurveyAttemptLink,
  });

  const maxScrollPercentage = useRef(0);
  const scrollAreaRef = useRef<HTMLDivElement | null>(null);

  const { onDataFetch } = useCompanyPageTracking();

  const handleDataFetch = (fetchAction: { trackingAction?: TrackingDataAction; status: FetchStatus }) => (
    data: TrackingDataPayloadType
  ) => {
    const hasDataTracking = !!fetchAction.trackingAction;
    hasDataTracking &&
      onDataFetch({ type: fetchAction.trackingAction, payload: { ...data } } as TrackingDataActionType);

    setFetchStatus((prevState) => ({
      ...prevState,
      [fetchAction.status]: SETTLED_STATUS,
    }));
  };

  const handleDataBubbling = (trackingAction: TrackingDataAction) => (data: CompanyInfo[]) => {
    if (trackingAction === TrackingDataAction.FetchCompetitors) {
      setBubbledData((prevState) => ({ ...prevState, competitors: data }));
    }
  };

  useEffect(() => {
    hasCompanyOverview && handleDataFetch(DataFetchAction.overview)({ companyId });
  }, [hasCompanyOverview]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScrollWrapper = useCallback(() => {
    scrollAreaRef.current &&
      handleScroll(
        (newMaxScrollPercentage) => (maxScrollPercentage.current = newMaxScrollPercentage),
        maxScrollPercentage.current,
        scrollAreaRef.current
      );
  }, [maxScrollPercentage, scrollAreaRef]);

  useEffect(() => {
    const current = scrollAreaRef.current;
    current?.addEventListener("scroll", handleScrollWrapper);

    if (!!current) {
      return () => {
        current?.removeEventListener("scroll", handleScrollWrapper);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollAreaRef.current, handleScrollWrapper]);

  useEffect(() => {
    const startTime = Date.now();
    return () => {
      logTimeSpent(startTime, { companyId, userId }, logHit, HitAction.alphaNowTimeSpentOnCompanyPage);
      logScrollPercentage(
        logHit,
        {
          companyId,
          scrollPercentage: maxScrollPercentage.current,
          userId: userId,
        },
        HitAction.alphaNowCompanyPageScrollPercentage
      );
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (companyName || companyLogo || companyId)
      setCompanyPrimerState({
        companyName,
        companyLogo,
        companyId: companyId?.toString(),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyOverviewData]);

  const onSurveyModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <CompanyPageWrapper data-testid={DataTestIds.alphaNowCompanyPage} ref={scrollAreaRef} isLoading={isLoading}>
      <LoadingSpinner isLoading={isLoading} />
      {hasCompanyOverview && <AlphaNowCompanyOverview data={companyOverviewData} />}
      <PrimerSection
        isLoadingContent={isLoading}
        companyName={companyName}
        companyId={companyId}
        onDataFetch={handleDataFetch(DataFetchAction.primers)}
      />
      <Insights
        companyId={companyId}
        companyName={companyName}
        onDataFetch={handleDataFetch(DataFetchAction.insights)}
      />
      <AlphaNowCompanyAngleSection
        variant={Variant.Competitors}
        companyName={companyName}
        companyId={companyId}
        onDataFetch={handleDataFetch(DataFetchAction.competitors)}
        onDataBubbling={handleDataBubbling(DataFetchAction.competitors.trackingAction)}
      />
      <AlphaNowCompanyAngleSection
        variant={Variant.Customers}
        companyName={companyName}
        companyId={companyId}
        onDataFetch={handleDataFetch(DataFetchAction.customers)}
      />
      <RequestSurveyBanner viewMode={RequestSurveyBannerViewModes.CompanyPage} companyName={companyName} />
      <Experts companyId={companyId} companyName={companyName} onDataFetch={handleDataFetch(DataFetchAction.experts)} />
      {isSurveyModalOpen && !isLoading && (
        <SurveyModal
          origin={HitOrigin.companyPage}
          onClose={onSurveyModalClose}
          targets={[
            {
              companyName: companyName,
              cdsAlphaCompanyId: `${companyId}`,
              StartIcon: () => <PrimerCompanyLogo size={spacing.inner.base06} src={companyLogo} whiteBackground />,
            },
          ]}
          competitors={bubbledData.competitors.map((competitor) => ({
            companyName: competitor.name,
            cdsAlphaCompanyId: `${competitor.cdsAlphaCompanyId}`,
            StartIcon: () => <PrimerCompanyLogo size={spacing.inner.base06} src={competitor.logo} whiteBackground />,
          }))}
        />
      )}
    </CompanyPageWrapper>
  );
};

const CompanyPageWrapper = styled.div(({ isLoading }: { isLoading: boolean }) => {
  const { spacing } = useThemeTokens();
  return {
    display: "flex",
    flexDirection: "column" as "column",
    maxHeight: "-webkit-fill-available",
    gap: spacing.inner.base08,
    paddingBottom: spacing.inner.base06,
    position: "relative" as const,
    height: isLoading ? "100%" : "auto",
    overflow: isLoading ? "hidden" : "visible",
  };
});

export { AlphaNowCompanyPage as default, DataTestIds };
