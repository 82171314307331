import {
  AngleTypeSynthesis,
  projectSynthesis as API,
  User,
  HitAction,
  HitOrigin,
  SynthesisModule,
  SynthesisModuleRevision,
} from "@alphasights/portal-api-client";
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  EditSynthesisModuleOperations,
  useEditSynthesisModule,
} from "views/ProjectSynthesisView/hooks/useEditSynthesisModule";
import { useNewSynthesisModule } from "views/ProjectSynthesisView/hooks/useNewSynthesisModule";
import { Mode, SynthesisPreferences } from "./ProjectSynthesisProvider.types";
import _ from "lodash";
import { usePreference } from "hooks/usePreference";
import { PreferenceType } from "./types";
import { useAlphaToast } from "@alphasights/alphadesign-components";
import { useDeleteSynthesisModule } from "views/ProjectSynthesisView/hooks/useDeleteSynthesisModule";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import useQueryParams from "hooks/useQueryParams";
import { isKpcContent, isQuestionContent, isVendorContent, question } from "views/ProjectSynthesisView";

export const ProjectSynthesisContext = React.createContext<ProjectSynthesisProviderState | undefined>(undefined);

export type ProjectSynthesisProviderProps = PropsWithChildren<{
  project: Project;
  angleTypes: AngleTypeData[];
  user?: User;
  pollingTimeout: number;
}>;

export interface ExpertCountByAngle {
  name: string;
  count: number;
  subAngles?: {
    name: string;
    count: number;
  }[];
}

export interface AngleTypeData {
  angleType: string;
  transcriptIds: string[];
  expertCountByAngle: ExpertCountByAngle[];
}

export interface ProjectSynthesisProviderState {
  projectSynthesis: AngleTypeSynthesis[];
  selectedModule: SynthesisModule | undefined;
  selectModule: (module: SynthesisModule) => void;
  mode: Mode;
  setMode: (mode: Mode) => void;
  editOperations: EditSynthesisModuleOperations;
  selectedRevisionIdx: number;
  revision: SynthesisModuleRevision | undefined;
  reprocess: (args: { angleTypeName?: string; moduleId?: string }) => void;
  selectedProjectSynthesis?: AngleTypeSynthesis;
  promoteModule: (module: SynthesisModule) => void;
  reorderModule: (angleType: string, order: string[]) => void;
  nextRevision: () => void;
  prevRevision: () => void;
  saveModuleChanges: (
    moduleToSave: SynthesisModule,
    logDetails?: any,
    toastSuccess?: boolean,
    message?: string
  ) => Promise<SynthesisModule>;
  cancelModuleChanges: () => void;
  undo: () => void;
  redo: () => void;
  saveInProgress: boolean;
  newSynthesisModule: ReturnType<typeof useNewSynthesisModule>;
  isQuestionEdited: boolean;
  deleteModule: (module: SynthesisModule) => void;
  onPromoteAllModules: (idsToPromote: string[]) => Promise<void>;
  modulesToPromote: SynthesisModule[];
  synthesisPreferences: SynthesisPreferences;
  updateSynthesisPreferences: (updatedPreferences: Partial<SynthesisPreferences>) => void;
  downloadSynthesis: () => void;
  loading: boolean;
  synthesisLogHit: (args: {
    action: HitAction;
    references?: Record<string, any>;
    details?: Record<string, any>;
  }) => void;
  angleTypes: AngleTypeData[];
  nothingToShow: boolean;
  moduleTitle: string;
}

const cleanupSynthesis = (projectSynthesis: AngleTypeSynthesis[], angleTypes: AngleTypeData[], user?: User) => {
  const stubbedBEResponse =
    user?.internalUser && !(user?.permissions ?? []).includes("access_transcripts_and_recordings");
  if (stubbedBEResponse) return projectSynthesis;

  const angleTypesMap = _.keyBy(projectSynthesis, "angleType");
  const cleanedModules = projectSynthesis.map((angle) => {
    const knownTranscriptIds = angleTypes.find((data) => data.angleType === angle.angleType)?.transcriptIds ?? [];
    return {
      ...angle,
      modules: angle.modules.filter((m) => _.intersection(m.transcriptsAvailable, knownTranscriptIds).length),
    };
  });
  const cleanedAngleTypes = cleanedModules.filter(
    (angle) => angle.modules.length > 0 || angleTypesMap[angle.angleType].modules.length === 0
  );
  return cleanedAngleTypes;
};

export const ProjectSynthesisProvider = ({
  project,
  angleTypes,
  user,
  pollingTimeout,
  children,
}: ProjectSynthesisProviderProps) => {
  const [projectSynthesisRaw, setProjectSynthesis] = useState<AngleTypeSynthesis[]>([]);
  const projectSynthesis = useMemo(() => cleanupSynthesis(projectSynthesisRaw, angleTypes, user), [
    projectSynthesisRaw,
    angleTypes,
    user,
  ]);
  const [module, setModule] = useState<SynthesisModule | undefined>(undefined);
  const [selectedRevisionIdx, setSelectedRevisionIdx] = useState(-1);
  const [mode, setMode] = useState<Mode>(Mode.VIEW);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [loading, setLoading] = useState(true);
  const nothingToShow = useMemo(
    () => projectSynthesis.flatMap((ps) => ps.modules.flatMap((m) => m.revisions)).length === 0,
    [projectSynthesis]
  );

  const queryParams = useQueryParams();

  const { logHit } = useTrackUserAction();

  const { toast } = useAlphaToast();

  const { editedModule, editOperations, cancelChanges, undo, redo, clearUndoRedo } = useEditSynthesisModule(module);

  const { preference, updatePreference } = usePreference(PreferenceType.PROJECT_SYNTHESIS);

  const moduleId = useMemo(() => module?.id, [module]);

  const hasAnyPendingProcessingQuestion = useMemo(
    () =>
      projectSynthesis.some((ps) =>
        ps.modules.some((module) => module.revisions.some((qr) => ["PENDING", "PROCESSING"].includes(qr.status)))
      ),
    [projectSynthesis]
  );

  const synthesisPreferences: SynthesisPreferences = useMemo(() => {
    const defaults = {
      showSavePrompt: true,
      showSaveAndRegeneratePrompt: true,
      disableAutoRegeneration: true,
    };
    return {
      ...defaults,
      ...preference?.attributes,
    };
  }, [preference]);

  const selectedModule = useMemo(() => {
    return mode === Mode.EDIT ? editedModule : module;
  }, [mode, editedModule, module]);

  const selectedProjectSynthesis = useMemo(() => {
    if (!module) return undefined;

    return projectSynthesis.find((ps) => ps.modules.includes(module))!!;
  }, [projectSynthesis, module]);

  const revision = useMemo(() => {
    return selectedRevisionIdx >= 0 ? selectedModule?.revisions[selectedRevisionIdx] : undefined;
  }, [selectedModule, selectedRevisionIdx]);

  const moduleTitle = useMemo(() => {
    if (!selectedModule) return "";
    if (isQuestionContent(revision?.contents)) return revision?.contents.topic ?? "";
    if (isKpcContent(revision?.contents)) return "Key Purchasing Criteria";
    if (isVendorContent(revision?.contents)) return "Vendor List";
    return "";
  }, [selectedModule, revision]);

  const isQuestionEdited = useMemo(() => {
    if (!editedModule || !module) return false;
    return (
      question(editedModule?.revisions[selectedRevisionIdx]?.contents)?.question !==
      question(module?.revisions[selectedRevisionIdx]?.contents)?.question
    );
  }, [editedModule, module, selectedRevisionIdx]);

  const selectModule = useCallback((moduleToSelect: SynthesisModule | undefined, revisionNumToSelect?: number) => {
    const typesWithAllowedRevisionNav = ["QUESTION"];
    const specificRevisionIndex =
      moduleToSelect?.revisions.findIndex((rev) => rev.revision === revisionNumToSelect) ?? -1;
    const defaultRevisionIndex = (moduleToSelect?.revisions.length ?? 0) - 1;
    const revisionIdx =
      specificRevisionIndex >= 0 && typesWithAllowedRevisionNav.includes(moduleToSelect?.contentType || "")
        ? specificRevisionIndex
        : defaultRevisionIndex;

    setMode(Mode.VIEW);
    setModule((currentModule) => {
      // only changes revision if current module changed
      moduleToSelect?.id !== currentModule?.id && setSelectedRevisionIdx(revisionIdx);
      return moduleToSelect;
    });
  }, []);

  const { deleteModule } = useDeleteSynthesisModule({
    projectToken: project.token,
    projectSynthesis,
    setProjectSynthesis,
    selectModuleInLatestRevision: selectModule,
  });

  const nextRevision = useCallback(() => {
    setMode(Mode.VIEW);
    setSelectedRevisionIdx((prevIdx) => Math.min(prevIdx + 1, (selectedModule?.revisions.length ?? 0) - 1));
  }, [selectedModule]);

  const prevRevision = useCallback(() => {
    setMode(Mode.VIEW);
    setSelectedRevisionIdx((prevIdx) => Math.max(prevIdx - 1, 0));
  }, []);

  const updateSynthesis = useCallback((updatedModule: SynthesisModule) => {
    setProjectSynthesis((angles) => {
      const updatedAngles = angles.map((angle) => {
        const moduleFound = angle.modules.some((m) => {
          return m.id === updatedModule.id;
        });

        if (moduleFound) {
          return {
            ...angle,
            modules: _.sortBy(
              angle.modules.map((m) => (m.id === updatedModule.id ? updatedModule : m)),
              "rank"
            ),
          };
        }

        return angle;
      });

      return updatedAngles;
    });
  }, []);

  const updateSynthesisWithReorder = useCallback(
    (updatedAngle: AngleTypeSynthesis) => {
      setProjectSynthesis((angles) => {
        const updatedAngles = angles.map((angle) => {
          if (angle.angleType === updatedAngle.angleType) {
            return {
              ...angle,
              modules: updatedAngle.modules,
            };
          }
          return angle;
        });
        return updatedAngles;
      });
    },
    [setProjectSynthesis]
  );

  const preemptiveReorderUpdate = useCallback(
    (angletype: string, newOrder: string[]) => {
      setProjectSynthesis((angles) => {
        const updatedAngles = angles.map((angle) => {
          if (angle.angleType === angletype) {
            const previousOrder = angle.modules.map((m) => m.id);
            const reorderedModules = _.sortBy(angle.modules, [
              (m) => newOrder.indexOf(m.id),
              (m) => previousOrder.indexOf(m.id),
            ]);
            return {
              ...angle,
              modules: reorderedModules,
            };
          }
          return angle;
        });
        return updatedAngles;
      });
    },
    [setProjectSynthesis]
  );

  const fetchQuestions = useCallback(
    ({ bg }: { bg: boolean } = { bg: false }) => {
      !bg && setLoading(true);
      return API.getProjectSynthesis(project.token)
        .then((resp) => {
          setProjectSynthesis(resp);
          return resp;
        })
        .finally(() => {
          !bg && setLoading(false);
        });
    },
    [project.token]
  );

  const reprocess = useCallback(
    ({ angleTypeName, moduleId }: { angleTypeName?: string; moduleId?: string }) => {
      setSaveInProgress(true);
      API.reprocessAngleType(project.token, angleTypeName ?? "", moduleId ?? "")
        .then((updatedSynthesis) => {
          setProjectSynthesis(
            projectSynthesis.map((ps) => updatedSynthesis.find((updated) => ps.angleType === updated.angleType) ?? ps)
          );
          if (moduleId) {
            const module = updatedSynthesis
              .flatMap((us) => us.modules)
              .find((m) => m.id === moduleId) as SynthesisModule;
            setModule(module);
            module && setSelectedRevisionIdx(module.revisions.length - 1);
          }
        })
        .finally(() => setSaveInProgress(false));
    },
    [projectSynthesis, project.token]
  );

  const synthesisLogHit = useCallback(
    ({
      action,
      references,
      details,
    }: {
      action: HitAction;
      references?: Record<string, any>;
      details?: Record<string, any>;
    }) => {
      logHit({
        origin: HitOrigin.synthesisView,
        projectToken: project.token,
        action,
        details,
        references,
      });
    },
    [logHit, project.token]
  );

  const promoteModule = useCallback(
    (moduleToPromote: SynthesisModule) => {
      return API.promoteProjectSynthesisModule(project.token, moduleToPromote.id).then((resp) => {
        const updated = resp as SynthesisModule;
        setModule(updated);
        updateSynthesis(updated);
        return resp;
      });
    },
    [project.token, updateSynthesis]
  );

  const reorderModule = useCallback(
    (angleType: string, order: string[]) => {
      preemptiveReorderUpdate(angleType, order);
      return API.reorderProjectSynthesisAngleType(project.token, angleType, order).then((resp) => {
        const updated = resp as AngleTypeSynthesis;
        updateSynthesisWithReorder(updated);
        return resp;
      });
    },
    [project.token, preemptiveReorderUpdate, updateSynthesisWithReorder]
  );

  const saveModuleChanges = useCallback(
    (moduleToSave: SynthesisModule, logDetails = undefined, toastSuccess = true, message = "Edits saved.") => {
      setSaveInProgress(true);
      return API.updateProjectSynthesisModule(project.token, moduleToSave.id, moduleToSave)
        .then((updatedModule) => {
          logDetails &&
            synthesisLogHit({
              action: HitAction.projectSynthesisModuleEdited,
              references: { moduleId: moduleToSave.id },
              details: logDetails,
            });
          toastSuccess && toast.success({ message });
          clearUndoRedo();
          setModule(updatedModule);
          updateSynthesis(updatedModule);
          if (updatedModule.revisions.length !== moduleToSave.revisions.length) {
            setSelectedRevisionIdx(updatedModule.revisions.length - 1);
          }
          setMode(Mode.VIEW);
          return updatedModule;
        })
        .catch((error) => {
          toast.error({ message: "Unable to save changes to module." });
          throw error;
        })
        .finally(() => {
          setSaveInProgress(false);
        });
    },
    [clearUndoRedo, project.token, synthesisLogHit, toast, updateSynthesis]
  );

  const onSubmitQuestion = useCallback(
    (question: string, angleTypeName: string, suggestionId?: string) => {
      return API.createProjectSynthesisModule(project.token, { angleTypeName, question })
        .then((newModule) => {
          synthesisLogHit({
            action: HitAction.projectSynthesisModuleAdded,
            details: { angleType: angleTypeName, question },
          });
          toast.success({ message: "Generating new module." });
          suggestionId && API.deleteProjectSynthesisModule(project.token, suggestionId);
          setProjectSynthesis((previous) =>
            previous.map((ps) => {
              if (ps.angleType === newModule.angleType) {
                return {
                  ...ps,
                  modules: [...ps.modules, ...newModule.modules].filter((m) => m.id !== suggestionId),
                };
              }
              return ps;
            })
          );
          setMode(Mode.VIEW);
          selectModule(newModule.modules[0]);
          return newModule;
        })
        .catch((error) => {
          toast.error({ message: "Unable to generate insights." });
          synthesisLogHit({
            action: "PROJECT_SYNTHESIS_CREATE_MODULE_FAILED" as HitAction,
            details: { angleType: angleTypeName, question },
          });
          throw error;
        });
    },
    [project.token, selectModule, synthesisLogHit, toast]
  );

  const downloadSynthesis = useCallback(() => window.open(`/api/projects/${project.token}/synthesis/xlsx`, "_blank"), [
    project.token,
  ]);

  const updateSynthesisPreferences = useCallback(
    (updatedPreferences: Partial<SynthesisPreferences>) => {
      updatePreference(updatedPreferences);
    },
    [updatePreference]
  );

  const newSynthesisModule = useNewSynthesisModule({
    projectSynthesis,
    onSubmitQuestion,
  });

  useEffect(
    function markModuleAsRead() {
      if (!selectedModule) return;
      API.markAsViewedProjectSynthesisModule(project.token, selectedModule.id);
    },
    [project.token, selectedModule?.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function polling() {
      const signal = { active: true };
      const waitingTime = pollingTimeout / (hasAnyPendingProcessingQuestion ? 3 : 1);
      const pollingInterval =
        mode === Mode.VIEW &&
        setInterval(() => {
          fetchQuestions({ bg: true }).then((resp: AngleTypeSynthesis[]) => {
            const fetchedModule = resp.flatMap((r) => r.modules).find((m) => m.id === moduleId);
            fetchedModule && signal.active && selectModule(fetchedModule);
          });
        }, waitingTime);
      return () => {
        signal.active = false;
        pollingInterval && clearInterval(pollingInterval);
      };
    },
    [fetchQuestions, moduleId, pollingTimeout, hasAnyPendingProcessingQuestion, selectModule, mode]
  );

  useEffect(function loadOnLand() {
    fetchQuestions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    function autoSelect() {
      if (!module) {
        const urlModuleId = queryParams.get("selectedQuestion");
        const urlRevision = parseInt(queryParams.get("selectedRevision") ?? "");
        const modules = projectSynthesis.flatMap((ps) => ps.modules);
        const toSelect = modules.find((m) => m.id === urlModuleId) ?? modules[0];

        toSelect && selectModule(toSelect, urlRevision);
      }
    },
    [module, projectSynthesis, selectModule, queryParams]
  );

  const modulesToPromote = useMemo(() => {
    return _.flattenDeep(
      projectSynthesis.map((ps) =>
        ps.modules.filter((m) => m.visibility === "SUGGESTED" && m.contentType !== "SUGGESTED_INQUIRY")
      )
    );
  }, [projectSynthesis]);

  const onPromoteAllModules = useCallback(
    (idsToPromote: string[]) => {
      return API.promoteAllModules(project.token, { ids: idsToPromote }).then((list) => {
        list
          .map((ps) => ps.modules)
          .flat()
          .map((m) => updateSynthesis(m));
      });
    },
    [project.token, updateSynthesis]
  );

  const context: ProjectSynthesisProviderState = useMemo(
    () => ({
      projectSynthesis,
      selectedProjectSynthesis,
      selectedModule,
      selectModule,
      mode,
      setMode,
      editOperations,
      selectedRevisionIdx,
      revision,
      promoteModule,
      reorderModule,
      reprocess,
      nextRevision,
      prevRevision,
      saveModuleChanges,
      cancelModuleChanges: cancelChanges,
      undo,
      redo,
      saveInProgress,
      newSynthesisModule,
      isQuestionEdited,
      deleteModule,
      onPromoteAllModules,
      modulesToPromote,
      updateSynthesisPreferences,
      synthesisPreferences,
      downloadSynthesis,
      loading,
      synthesisLogHit,
      angleTypes,
      nothingToShow,
      moduleTitle,
    }),
    [
      projectSynthesis,
      selectedModule,
      mode,
      selectModule,
      editOperations,
      selectedRevisionIdx,
      revision,
      promoteModule,
      reorderModule,
      nextRevision,
      prevRevision,
      saveModuleChanges,
      cancelChanges,
      undo,
      redo,
      saveInProgress,
      reprocess,
      selectedProjectSynthesis,
      newSynthesisModule,
      isQuestionEdited,
      deleteModule,
      onPromoteAllModules,
      modulesToPromote,
      updateSynthesisPreferences,
      synthesisPreferences,
      downloadSynthesis,
      loading,
      synthesisLogHit,
      angleTypes,
      nothingToShow,
      moduleTitle,
    ]
  );

  return <ProjectSynthesisContext.Provider value={context} children={children} />;
};

export const useProjectSynthesisContext = () => {
  const context = useContext(ProjectSynthesisContext);

  if (!context) throw new Error("ProjectSynthesisContext should only be used within the ProjectSynthesisProvider");

  return context;
};
