enum SummaryType {
  overview = "overview",
  theme = "theme",
  quote = "quote",
  point = "point",
}

const DEFAULT_SUMMARY = { parts: [] };

const SUMMARY_LOADING_TITLE = "Summary view is loading";
const SUMMARY_LOADING_MESSAGE = "Loading data...";

const SUMMARY_FAILED_MESSAGE = "Generation was unsuccessful. Please try again.";

const NO_SUMMARY_MESSAGE = "The document is too small to generate summary view.";

const OVERVIEW_TITLE = "Overview:";

export {
  SummaryType,
  SUMMARY_LOADING_TITLE,
  SUMMARY_LOADING_MESSAGE,
  SUMMARY_FAILED_MESSAGE,
  OVERVIEW_TITLE,
  DEFAULT_SUMMARY,
  NO_SUMMARY_MESSAGE,
};
