import React from "react";
import { TextArea } from "@alphasights/alphadesign-components";
import * as S from "./ProjectBriefEditor.styled";

export const ProjectBriefEditor = ({ loading }: { loading: boolean }) => {
  return (
    <S.Wrapper>
      <TextArea disabled={loading} value="Write your brief here..." label="Project Brief" width="100%" />
    </S.Wrapper>
  );
};
