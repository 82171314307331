import React from "react";
import { Pill } from "@alphasights/alphadesign-components";
import { SurveyStateConfig } from "./pillStateConfig";

interface SurveyStatusPillProps {
  interactionId: string;
  stateConfig: SurveyStateConfig;
}

export const SurveyStatusPill: React.FC<SurveyStatusPillProps> = ({ interactionId, stateConfig }) => {
  return (
    <Pill
      leftAccessories={stateConfig.icon}
      size="small"
      isInteractive={false}
      variant={stateConfig.variant}
      data-testid={`survey-status-column-${interactionId}`}
    >
      {stateConfig.description}
    </Pill>
  );
};
