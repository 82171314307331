import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import { CardSkeleton, DeliverablesCards, PROJECT_SUMMARY_ID } from "views/DeliverablesView/Cards";
import {
  deliverablesInteractions,
  getAvailableRecordings,
  getAvailableTranscripts,
} from "views/DeliverablesView/helpers";
import {
  Button,
  IconButton,
  Portal,
  ToggleButton,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { PageInfo, Search } from "@alphasights/alphadesign-icons";
import { useMobileDeliverablesStyles } from "./MobileDeliverablesView.styles";
import { DeliverablesFilterMobile } from "./MobileDeliverablesFilter.component";
import { InteractionDeliverablesView } from "./InteractionDeliverables.component";
import { useDeliverableContext } from "providers/DeliverableProvider";
import useQueryParams from "hooks/useQueryParams";
import { useFixedWhileInBackground } from "utils/mobileHacks";
import { PARAM_CONTENT_SELECTED } from "views/DeliverablesView/DeliverablesView";
import { ContentList, MobileContentView } from "./MobileContent.component";
import { PortalMobileTopBar } from "components/PortalMobileTopBar";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { ProjectSummariesProvider } from "providers/ProjectSummariesProvider";
import { MobileProjectSummary } from "./MobileProjectSummary.component";
import { AlphaNowContentResponse, ContentSuggestion } from "views/DeliverablesView/types";
import { InteractionDeliverablesProvider } from "providers/InteractionDeliverableProvider";

const PARAM_INTERACTION_SELECTED = "selectedInteraction";

export const MobileDeliverablesView = ({
  project,
  interactions,
  loading,
  currentSelection,
  onTextSearch: onDeliverablesTextSearch,
  filterOptions: options,
  appliedFilters,
  onSubmitFilters,
  onResetAllFilters,
  ...props
}: {
  project: Project;
  interactions: Interaction[];
  currentSelection?: Interaction;
  loading: boolean;
  onTextSearch: (words: string[]) => void;
  filterOptions: any;
  appliedFilters: any;
  onSubmitFilters: (fil: any) => void;
  onResetAllFilters: () => void;
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [currentUtilityCard, setCurrentUtilityCard] = useState<string>();
  const [selectedInteraction, setSelectedInteraction] = useState<Partial<Interaction>>();
  const [selectedContent, setSelectedContent] = useState<ContentSuggestion>();
  const [selectedTab, setSelectedTab] = useState("deliverables");
  const [keywords, setKeywords] = useState<string[]>([]);
  const [contentList, setContentList] = useState<ContentSuggestion[]>([]);
  const [privateContent, setPrivateContent] = useState<ContentSuggestion[]>([]);
  const [pitchedContent, setPitchedContent] = useState<ContentSuggestion[]>([]);
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [freeSamples, setFreeSamples] = useState<any>({});

  const sortCriteria = "";
  const { logHit } = useTrackUserAction();
  const queryParams = useQueryParams();
  const fromUrl = queryParams.get(PARAM_INTERACTION_SELECTED);

  const bulkUpdateContentStates = (res: AlphaNowContentResponse) => {
    setContentList(res.suggestedContent || []);
    setPrivateContent(res.privateContent || []);
    setFreeSamples(res.freeSamples || {});
    setPitchedContent(res.pitchedContent || {});
  };

  useEffect(() => {
    fromUrl && setSelectedInteraction(interactions.find((i) => i.id === fromUrl));
  }, [interactions]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectInteraction = (interaction?: Interaction) => {
    if (interaction?.id === PROJECT_SUMMARY_ID) {
      setCurrentUtilityCard(interaction.id);
      setSelectedContent(undefined);
      setSelectedInteraction(undefined);
    } else {
      setSelectedContent(undefined);
      setSelectedInteraction(interaction);
      interaction
        ? queryParams.set(PARAM_INTERACTION_SELECTED, interaction.id)
        : queryParams.delete(PARAM_INTERACTION_SELECTED);
    }
  };

  const deliverableTypesAvailable = new Set(
    (interactions ?? []).flatMap(getAvailableRecordings).flatMap((rec) => {
      const transcriptTypes = getAvailableTranscripts(rec).map((tr: TranscriptRequest) => tr.transcriptType);
      return rec.visibleToClient ? ["recording", ...transcriptTypes] : transcriptTypes;
    })
  );

  const { bookmarkedInteractionIds, fetchAlphaNowContent, fetchAlphaNowContentById } = useDeliverableContext();

  const filteredContentList = useMemo(() => {
    const bookmarkedKey = "bookmarked";
    const unrelatedFiltersApplied = Object.keys(appliedFilters)
      .filter((key) => key !== bookmarkedKey)
      .find((key) => (appliedFilters[key] || []).length);
    if (unrelatedFiltersApplied) return [];
    const bookmarkedSelected = (appliedFilters[bookmarkedKey] || []).includes("true");
    if (bookmarkedSelected) return contentList.filter((c) => c.bookmarked);
    return contentList;
  }, [appliedFilters, contentList]);

  const updateContent = useCallback(
    (id: string, updates: any) => {
      const defaultUpdate = { isAccessible: true };
      if (pitchedContent.find((con) => con.id === id)) {
        setPitchedContent((vals) =>
          vals.map((val) => (val.id === id ? { ...val, ...updates, ...defaultUpdate } : val))
        );
      } else {
        setContentList((vals) => vals.map((val) => (val.id === id ? { ...val, ...updates, ...defaultUpdate } : val)));
      }
      setSelectedContent((val) => (val?.id === id ? { ...val, ...updates, ...defaultUpdate } : val));
    },
    [pitchedContent]
  );

  const filteredInteractions = useMemo(() => {
    return deliverablesInteractions(interactions, appliedFilters, sortCriteria, bookmarkedInteractionIds);
  }, [interactions, sortCriteria, bookmarkedInteractionIds, appliedFilters]);

  const selectContent = (content?: ContentSuggestion) => {
    setSelectedContent(content);
    setSelectedInteraction(undefined);
    content ? queryParams.set(PARAM_CONTENT_SELECTED, content.id) : queryParams.delete(PARAM_CONTENT_SELECTED);
  };

  useEffect(() => {
    setIsContentLoading(true);
    fetchAlphaNowContent(undefined).then((res: any) => {
      bulkUpdateContentStates(res);
      const contentId = queryParams.get(PARAM_CONTENT_SELECTED);
      if (contentId) {
        setSelectedTab("recommended");
        const contentSelected = res.suggestedContent.find((c: ContentSuggestion) => c.id === contentId);
        if (contentSelected) {
          selectContent(contentSelected);
        } else {
          fetchAlphaNowContentById({ id: contentId }).then((resById: any) => {
            selectContent(resById);
          });
        }
      }
      setIsContentLoading(false);
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const wrapperRef = useRef<HTMLDivElement>(null);
  useFixedWhileInBackground({ isInBackground: !!(showFilters || selectedInteraction), refToFix: wrapperRef });

  const onContentTextSearch = useCallback(
    (words: string[]) => {
      setIsContentLoading(true);
      fetchAlphaNowContent(words.join(",")).then((res: any) => {
        bulkUpdateContentStates(res);
        setIsContentLoading(false);
      });
    },
    [fetchAlphaNowContent]
  );

  const onTextSearchWrapped = useCallback(
    (words: string[]) => {
      onContentTextSearch(words);
      onDeliverablesTextSearch(words);
      setKeywords(words);
    },
    [onDeliverablesTextSearch, onContentTextSearch]
  );

  const criteriaApplied = useMemo(
    () => !!Object.values(appliedFilters).find((arr: any) => arr.length) || keywords.length !== 0,
    [appliedFilters, keywords]
  );

  const clearCriteria = () => {
    onContentTextSearch([]);
    onResetAllFilters();
    setKeywords([]);
  };

  useEffect(() => {
    if (!selectedContent && !selectedInteraction) return;

    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.interactionSelected,
      projectToken: project.token,
      details: {
        suggestionId: selectedContent?.id,
      },
      references: {
        advisorshipId: selectedInteraction?.id,
      },
    });
  }, [selectedInteraction?.id, selectedContent?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ProjectSummariesProvider
      projectToken={project.token}
      // @ts-ignore
      interactions={props.allInteractions}
      // @ts-ignore
      filterOptions={props.filterOptions}
    >
      <x.div data-testid="mobile-deliverables-page" ref={wrapperRef}>
        <DeliverablesTopBar
          setSelectedTab={setSelectedTab}
          title={project.title}
          hasSuggestions={contentList.length > 0}
          onSearch={onTextSearchWrapped}
          onToggleFilter={() => setShowFilters((v) => !v)}
          selectedTab={selectedTab}
          keywords={keywords}
          onSearchClose={() => onTextSearchWrapped([])}
        />
        {selectedTab === "deliverables" && (
          <>
            {loading ? (
              <>
                <CardSkeleton width="100%" height="105px" />
                <CardSkeleton width="100%" height="105px" />
                <CardSkeleton width="100%" height="105px" />
              </>
            ) : filteredInteractions.length > 0 ? (
              <>
                <DeliverablesCards
                  appliedFilters={appliedFilters}
                  // bypasses ts lint error because DeliverablesCards is in JS and it can't resolve the types
                  appliedKeywords={keywords as any}
                  order={["projectSummary", "deliverables"] as any}
                  currentSelection={currentSelection as any}
                  interactions={filteredInteractions}
                  onSelect={selectInteraction}
                />
              </>
            ) : (
              <EmptyDeliverables
                criteriaApplied={criteriaApplied}
                clearCriteria={clearCriteria}
                resultLabel="deliverables"
              />
            )}
          </>
        )}
        {selectedTab === "recommended" && (
          <>
            {isContentLoading ? (
              <>
                <CardSkeleton width="100%" height="105px" />
                <CardSkeleton width="100%" height="105px" />
                <CardSkeleton width="100%" height="105px" />
              </>
            ) : (
              <>
                {filteredContentList.length > 0 ? (
                  <ContentList
                    contentList={filteredContentList}
                    privateContent={privateContent}
                    pitchedContent={pitchedContent}
                    onSelect={selectContent}
                  />
                ) : (
                  <EmptyDeliverables
                    criteriaApplied={criteriaApplied}
                    clearCriteria={clearCriteria}
                    resultLabel="content"
                  />
                )}
              </>
            )}
          </>
        )}
        {selectedInteraction && (
          <Portal>
            <InteractionDeliverablesProvider keywords={keywords} interaction={selectedInteraction}>
              <InteractionDeliverablesView onClose={() => selectInteraction(undefined)} />
            </InteractionDeliverablesProvider>
          </Portal>
        )}
        {selectedContent && (
          <Portal>
            <MobileContentView
              content={selectedContent}
              freeSamples={freeSamples}
              onClose={() => selectContent(undefined)}
              updateContent={updateContent}
              project={project}
            />
          </Portal>
        )}
        {currentUtilityCard && (
          <Portal>
            <MobileProjectSummary onClose={() => setCurrentUtilityCard(undefined)} />
          </Portal>
        )}
        {showFilters && (
          <Portal>
            <DeliverablesFilterMobile
              onClose={() => setShowFilters(false)}
              transcriptTypesSet={deliverableTypesAvailable}
              appliedFilters={appliedFilters}
              options={options}
              onSubmitFilters={onSubmitFilters}
            />
          </Portal>
        )}
      </x.div>
    </ProjectSummariesProvider>
  );
};

export const EmptyDeliverables = ({
  criteriaApplied,
  clearCriteria,
  resultLabel = "deliverables",
}: {
  criteriaApplied: boolean;
  clearCriteria: () => void;
  resultLabel?: string;
}) => {
  const styles = useMobileDeliverablesStyles();
  return (
    <x.div {...styles.noDeliverablesWrapper}>
      {!criteriaApplied && (
        <Typography variant="body-em" color="#717C80">
          No {resultLabel} added yet.
        </Typography>
      )}
      {criteriaApplied && (
        <>
          <Typography variant="body-em" color="#717C80">
            No {resultLabel} found for the selected criteria.
          </Typography>
          <Button size="small" variant="outline" onClick={clearCriteria} data-testid="no-results-clear-filters-btn">
            Clear Criteria
          </Button>
        </>
      )}
    </x.div>
  );
};

const DeliverablesTopBar = ({
  title,
  setSelectedTab,
  hasSuggestions,
  onSearch,
  onToggleFilter,
  selectedTab,
  keywords,
  onSearchClose,
}: {
  title: string;
  setSelectedTab: (tab: string) => void;
  hasSuggestions: boolean;
  onSearch: (words: string[]) => void;
  onToggleFilter: () => void;
  selectedTab: string;
  keywords: string[];
  onSearchClose: () => void;
}) => {
  const [topBarVariant, setTopBarVariant] = useState("regular");
  const {
    color: { background },
  } = useThemeTokens();

  const handleSearchClose = useCallback(() => {
    setTopBarVariant("regular");
    onSearch([]);
    onSearchClose();
  }, [onSearch, onSearchClose]);

  useEffect(() => {
    if (keywords.length === 0) {
      setTopBarVariant("regular");
    }
  }, [keywords]);

  return (
    <x.div bg={background.inverse} position="sticky" top="0">
      <PortalMobileTopBar
        onKeywordsMatch={onSearch}
        keywords={keywords}
        autoFocusSearch
        title={title}
        // @ts-ignore
        variant={topBarVariant}
        onSearchClose={handleSearchClose}
        defaultNavValue={selectedTab}
        searchButton={
          <IconButton size="large" variant="ghost" onClick={onToggleFilter} testId="mobile-top-bar-filters-open-btn">
            <PageInfo />
          </IconButton>
        }
        regularButtons={
          <>
            <IconButton
              size="large"
              variant="ghost"
              onClick={() => setTopBarVariant("search")}
              testId="mobile-top-bar-search-open-btn"
            >
              <Search />
            </IconButton>
            <IconButton size="large" variant="ghost" onClick={onToggleFilter} testId="mobile-top-bar-filters-open-btn">
              <PageInfo />
            </IconButton>
          </>
        }
      >
        {/* @ts-ignore */}
        {hasSuggestions && (
          <>
            <ToggleButton value="deliverables" sizeVariant="small" onClick={() => setSelectedTab("deliverables")}>
              Deliverables
            </ToggleButton>
            <ToggleButton value="recommended" sizeVariant="small" onClick={() => setSelectedTab("recommended")}>
              Recommended
            </ToggleButton>
          </>
        )}
      </PortalMobileTopBar>
    </x.div>
  );
};
