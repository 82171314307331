import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useProjectLaunchPageStyles = () => {
  const {
    spacing,
    color: { background },
  } = useThemeTokens();

  return {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      pb: spacing.layout.base03,
      overflowX: "auto",
      width: "100%",
    },
    header: {
      position: "sticky",
      top: 0,
      pt: spacing.layout.base03,
      zIndex: 1,
      background: background.surface.recessed,
    },
    mainContent: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: spacing.inner.base,
      margin: "0 auto",
    },
    projectDetails: {
      gap: spacing.inner.base04,
    },
    detailsContent: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      gap: spacing.inner.base,
      w: "650px",
      marginRight: 1,
    },
    searchContent: {
      flex: 1,
      h: "100%",
      minWidth: "350px",
    },
    sideBySideContainer: {
      display: "flex",
      alignItems: "stretch",
    },
    sideBySidePanel: {
      flex: 1,
      flexGrow: 1,
    },
  };
};
