import React from "react";
import { ProjectBriefEditor } from "./ProjectBriefEditot";
import { AngleCards } from "./AngleCards";
import * as S from "./Sidebar.styled";

export const Sidebar = ({ loading }: { loading: boolean }) => (
  <S.Wrapper>
    <AngleCards loading={loading} />
    <ProjectBriefEditor loading={loading} />
  </S.Wrapper>
);
