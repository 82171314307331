import React, { useState } from "react";
import { x } from "@xstyled/styled-components";
import { withAccessControl } from "@alphasights/client-portal-shared";
import { Alert, Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { ResponsiveModal } from "components/ResponsiveModal";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { MaximumChargeLink } from "pages/InteractionPage/sections/Topbar/WorkRequestMaximumCharge";
import { ExpertChargesList } from "components/MessengerPage/ExpertChargesList";
import { formatTimeInHoursAndMinutes } from "helpers/interactionHelpers";
import { calculateTotalCredit, convertCreditsToString } from "components/MessengerPage/helper";
import {
  ENABLE_COMPLIANCE_REVIEW_CALL_GUIDES,
  ENABLE_COMPLIANCE_REVIEW_CLARIFICATIONS,
  ENABLE_COMPLIANCE_REVIEW_WORK_REQUESTS,
  ENABLE_LEGACY_BCG_CLARIFICATIONS,
  ENABLE_SIX_YEARS_MESSAGE_RETENTION_POLICY,
  useProjectBadgeContext,
} from "providers/BadgeProvider";
import { useImpersonatingMessenger } from "hooks/messenger/useImpersonatingMessenger";
import { Expert } from "types/common-types";
import { DisclaimerSection } from "components/SendMessageModals/DisclaimerSection";

const ButtonWithAccess = withAccessControl(Button);

const SendMessageModal = ({
  children,
  isOpen,
  onClose,
  title,
  onSend,
  sendButtonText,
  onCancel,
  isLoading,
  blinded,
  complianceReviewEnabled,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  onSend: () => void;
  sendButtonText: string;
  onCancel: () => void;
  isLoading: boolean;
  blinded: boolean;
  complianceReviewEnabled: boolean;
}) => {
  const {
    spacing,
    color: { text },
  } = useThemeTokens();

  const { isMobile } = useCheckScreen();
  const { canSendImpersonatingMessage } = useImpersonatingMessenger();
  const [showExpandedDisclaimer, setShowExpandedDisclaimer] = useState(false);

  // Need to check if legacy badge is enabled to skip permissions check. This is used to send BCG Clarification while impersonating
  const { hasProjectBadge } = useProjectBadgeContext();
  const enableLegacyBCGClarifications = hasProjectBadge(ENABLE_LEGACY_BCG_CLARIFICATIONS);
  const enableSixYearsMessageRetentionPolicy = hasProjectBadge(ENABLE_SIX_YEARS_MESSAGE_RETENTION_POLICY);

  const skipPermissionsCheck = canSendImpersonatingMessage || enableLegacyBCGClarifications;

  const modalStyles = {
    h: isMobile ? "100%" : undefined,
    display: "flex",
    flexDirection: "column",
  };

  return (
    <ResponsiveModal
      variant="fullscreen"
      title={<x.div color={text.strong._}>{title}</x.div>}
      open={isOpen}
      onClose={() => {
        if (!isLoading) onClose();
      }}
      primaryButton={
        <ButtonWithAccess
          data-testid="messages-flyout-v2-modal-send-button"
          variant="secondary"
          accessControl={skipPermissionsCheck ? undefined : { allowedPermissions: ["follow_up_messages"] }}
          onClick={() => {
            if (!isLoading) {
              onSend();
            }
          }}
          loading={isLoading}
          cursor={isLoading ? "not-allowed" : "pointer"}
        >
          {sendButtonText}
        </ButtonWithAccess>
      }
      secondaryButton={
        <Button
          variant="ghost"
          mr={spacing.inner.base04}
          onClick={() => {
            if (!isLoading) {
              onCancel();
            }
          }}
          data-testid="cancel-button"
        >
          Cancel
        </Button>
      }
      testId="send-message-modal"
      {...modalStyles}
    >
      <Body isMobile={isMobile}>
        {children}

        <DisclaimerAlert
          complianceReviewEnabled={complianceReviewEnabled}
          enableSixYearsMessageRetentionPolicy={enableSixYearsMessageRetentionPolicy}
          isInternalUser={canSendImpersonatingMessage}
        />

        <Typography component="div" overflow="auto" maxHeight={isMobile ? "30vh" : "175px"} minHeight="175px">
          <DisclaimerSection
            setShowExpandedDisclaimer={setShowExpandedDisclaimer}
            showExpandedDisclaimer={showExpandedDisclaimer}
            blinded={blinded}
            complianceReviewEnabled={complianceReviewEnabled}
            enableSixYearsMessageRetentionPolicy={enableSixYearsMessageRetentionPolicy}
          />
        </Typography>
      </Body>
    </ResponsiveModal>
  );
};

const DisclaimerAlert = ({
  complianceReviewEnabled,
  enableSixYearsMessageRetentionPolicy,
  isInternalUser,
}: {
  complianceReviewEnabled: boolean;
  enableSixYearsMessageRetentionPolicy: boolean;
  isInternalUser: boolean;
}) => {
  const { spacing } = useThemeTokens();

  if (isInternalUser)
    return (
      <Alert my={spacing.layout.base03} variant="warning">
        You must receive written approval from the client that you are impersonating before sending a Work Request to an
        expert.
      </Alert>
    );

  if (complianceReviewEnabled && !enableSixYearsMessageRetentionPolicy)
    return (
      <Alert my={spacing.layout.base03} variant="warning">
        You must not use AlphaSights Messenger to solicit confidential, proprietary or material non-public information.
      </Alert>
    );

  return (
    <Alert my={spacing.layout.base03} variant="warning">
      You must not use AlphaSights Messenger to solicit confidential, proprietary or material non-public information.
      Your AlphaSights project lead has no visibility of expert responses.
    </Alert>
  );
};

const Body = ({ children, isMobile }: { children: React.ReactNode; isMobile: boolean }) => {
  return (
    <x.div maxWidth="640px" minWidth={!isMobile ? "640px" : undefined}>
      {children}
    </x.div>
  );
};

export const SendClarificationModal = ({
  isOpen,
  onCancel,
  onClose,
  onSend,
  experts,
  message,
  isLoading,
  blinded,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSend: () => void;
  experts: number;
  message: string;
  isLoading: boolean;
  blinded: boolean;
}) => {
  const {
    spacing,
    color: { text },
  } = useThemeTokens();

  const { hasProjectBadge } = useProjectBadgeContext();
  const clarificationsComplianceReviewEnabled = hasProjectBadge(ENABLE_COMPLIANCE_REVIEW_CLARIFICATIONS);

  return (
    <SendMessageModal
      title={"Send Clarification"}
      isOpen={isOpen}
      onClose={onClose}
      onSend={onSend}
      sendButtonText={"Send Clarification"}
      onCancel={onCancel}
      isLoading={isLoading}
      blinded={blinded}
      complianceReviewEnabled={clarificationsComplianceReviewEnabled}
    >
      <x.div>
        <Typography overflow="auto" color={text.strong._}>
          Message: {message}
        </Typography>
        <Typography mt={spacing.layout.base02} color={text.strong._}>
          Experts: {experts}
        </Typography>
        <Typography mt={spacing.layout.base02} color={text.strong._}>
          Maximum charge: Free
        </Typography>
      </x.div>
    </SendMessageModal>
  );
};

export const SendCallGuideModal = ({
  isOpen,
  onCancel,
  onClose,
  onSend,
  experts,
  isLoading,
  blinded,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSend: () => void;
  experts: number;
  isLoading: boolean;
  blinded: boolean;
}) => {
  const {
    spacing,
    color: { text },
  } = useThemeTokens();

  const { hasProjectBadge } = useProjectBadgeContext();
  const callGuidesComplianceReviewEnabled = hasProjectBadge(ENABLE_COMPLIANCE_REVIEW_CALL_GUIDES);

  return (
    <SendMessageModal
      title={"Send Call Guide"}
      isOpen={isOpen}
      onClose={onClose}
      onSend={onSend}
      sendButtonText={"Send Call Guide"}
      onCancel={onCancel}
      isLoading={isLoading}
      blinded={blinded}
      complianceReviewEnabled={callGuidesComplianceReviewEnabled}
    >
      <x.div>
        <Typography color={text.strong._}>Experts: {experts}</Typography>
        <Typography mt={spacing.layout.base02} color={text.strong._}>
          Maximum charge: Free
        </Typography>
      </x.div>
    </SendMessageModal>
  );
};

export const SendWorkRequestModal = ({
  isOpen,
  onCancel,
  onClose,
  onSend,
  experts,
  attachments,
  maximumTaskDuration,
  deadline,
  charge,
  isLoading,
  blinded,
}: {
  isOpen: boolean;
  onCancel: () => void;
  onClose: () => void;
  onSend: () => void;
  experts: Expert[];
  attachments: number;
  maximumTaskDuration: number;
  charge: string;
  deadline: string;
  message: boolean;
  isLoading: boolean;
  blinded: boolean;
}) => {
  const {
    spacing,
    color: { text },
  } = useThemeTokens();

  const { hasProjectBadge } = useProjectBadgeContext();
  const workRequestComplianceReviewEnabled = hasProjectBadge(ENABLE_COMPLIANCE_REVIEW_WORK_REQUESTS);

  const expertCharges = experts.map((selectedExpert) => ({
    credits: convertCreditsToString(calculateTotalCredit([selectedExpert], maximumTaskDuration)),
    name: selectedExpert.name,
    alphaCircleMultiplier: selectedExpert.alphaCircleMultiplier,
  }));

  const [isExpertsListOpen, setIsExpertsListOpen] = useState(false);

  return (
    <SendMessageModal
      title={"Send Work Request"}
      isOpen={isOpen}
      onClose={onClose}
      onSend={onSend}
      sendButtonText={"Send Work Request"}
      onCancel={onCancel}
      isLoading={isLoading}
      blinded={blinded}
      complianceReviewEnabled={workRequestComplianceReviewEnabled}
    >
      <x.div display="flex">
        <x.div flex="100%" display="inline-grid" gap={spacing.layout.base02}>
          <x.div display="flex">
            <Typography flex="50%" color={text.strong._}>
              Experts: {experts.length}
            </Typography>
            <Typography flex="50%" color={text.strong._}>
              Maximum task duration: {formatTimeInHoursAndMinutes(maximumTaskDuration)}
            </Typography>
          </x.div>
          <x.div display="flex">
            <Typography flex="50%" color={text.strong._}>
              Attachments: {attachments}
            </Typography>
            <Typography flex="50%" color={text.strong._}>
              Deadline: {deadline}
            </Typography>
          </x.div>
          <x.div>
            <Typography color={text.strong._}>
              Maximum charge:{" "}
              <MaximumChargeLink
                expertCount={expertCharges.length}
                totalCharge={charge}
                opened={isExpertsListOpen}
                setOpened={setIsExpertsListOpen}
                fontColor={text.strong._}
              />
            </Typography>
            {isExpertsListOpen && (
              <x.div pt={spacing.inner.base02}>
                <ExpertChargesList experts={expertCharges} />
              </x.div>
            )}
          </x.div>
        </x.div>
      </x.div>
    </SendMessageModal>
  );
};
