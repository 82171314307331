import React from "react";
import {
  Skeleton,
  Button,
  Divider,
  EllipsisText,
  IconButton,
  Typography,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { Delete } from "@alphasights/alphadesign-icons";
import MultiplePrimerCompanyLogo from "pages/AlphaNowPage/primers/components/primer-icon/MultiplePrimerCompanyLogo";
import CommissionModalPrimerFormat from "./CommissionModalPrimerFormat";
import PrimerTypeButtonGroup from "./PrimerTypeButtonGroup";
import { COMMISSION_MODAL_FIT, MODERATED_TRANSCRIPT } from "../../constants";
import CommissionModalRush from "./CommissionModalRush";
import { PrimerTypes } from "models/primers/constants";

type PropTypes = {
  format: string;
  companyName: string;
  logoLinks: string[];
  deleteRow: () => void;
  onChangePrimerRush: (value: any, index: number) => void;
  onChangePrimerType: (value: any, index: number, hasAvailableContent: boolean) => void;
  index: number;
  primerType: string;
  credits: number;
  isRecommended?: boolean;
  rush: boolean;
  onAddRecommendedPrimer?: (value: boolean, index: number) => void;
  dataTestId: string;
  availableLink: string | null;
  isLoadingAvailable: boolean;
};

export const testIds = {
  addModalRowButton: "add-modal-row-button",
  addModalRowButtonSearchRow: "add-modal-row-button-search-row",
  creditValue: "product-credit-value",
  deleteRowButton: "delete-row-button",
};

export const AddModalRowButton = ({
  onClick,
  variant,
  index,
}: {
  onClick: () => void;
  variant: string;
  index?: number;
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <Button
      variant={variant}
      onClick={onClick}
      size="small"
      data-testid={index ? `${testIds.addModalRowButton}-${index}` : testIds.addModalRowButtonSearchRow}
      w={inner.base12}
    >
      <Typography variant="body-small-em">Add</Typography>
    </Button>
  );
};

const CommissionModalContent = ({
  dataTestId,
  format,
  companyName,
  logoLinks,
  deleteRow,
  onChangePrimerType,
  onChangePrimerRush,
  credits,
  index,
  rush,
  isRecommended = false,
  onAddRecommendedPrimer = () => {},
  primerType,
  availableLink,
  isLoadingAvailable,
}: PropTypes) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();
  const isModeratedTranscript = format === MODERATED_TRANSCRIPT;
  const availableContentRow = availableLink !== null && primerType === PrimerTypes.ALPHA_NOW;

  const onHandleChangePrimerType = (e: any) => {
    onChangePrimerType(e, index, !!availableLink);
  };

  return (
    <>
      <x.div
        data-testid={dataTestId}
        py={inner.base02}
        ml={isLoadingAvailable ? 0 : inner.base06}
        alignItems="center"
        display="grid"
        columnGap="10px"
        gridTemplateColumns={COMMISSION_MODAL_FIT}
        w="100%"
      >
        {isLoadingAvailable ? (
          <div style={{ margin: 0, width: "740px" }}>
            <Skeleton />
          </div>
        ) : (
          <>
            <x.div display="flex" gap="2" alignItems="center">
              {logoLinks.length > 1 ? (
                <MultiplePrimerCompanyLogo isGrayscale={availableContentRow} companyLogos={logoLinks} />
              ) : (
                <PrimerCompanyLogo isGrayscale={availableContentRow} size={inner.base08} src={logoLinks[0]} />
              )}
              <EllipsisText
                typographyProps={{
                  variant: "body-small-em",
                  component: "p",
                  color: availableContentRow ? text.disabled : text.strong._,
                  maxWidth: "100px",
                }}
              >
                {companyName ?? "-"}
              </EllipsisText>
            </x.div>
            <CommissionModalPrimerFormat
              format={format}
              isRush={rush}
              availableLink={availableLink}
              disabled={availableContentRow}
            />
            {isModeratedTranscript ? (
              <Typography variant="body-small-em" color={text.strong._} pl={inner.base03}>
                {PrimerTypes.ALPHA_NOW}
              </Typography>
            ) : (
              <PrimerTypeButtonGroup value={primerType} onChange={onHandleChangePrimerType} />
            )}
            {isModeratedTranscript ? (
              <x.div />
            ) : (
              <CommissionModalRush
                onChange={(value) => onChangePrimerRush(value, index)}
                rush={rush}
                dataTestId={`commission-rush-${index}`}
                disabled={availableContentRow}
              />
            )}
            <Typography
              color={isRecommended || availableContentRow ? text.disabled : text.strong._}
              variant="body-small"
              textAlign="center"
              data-testid={testIds.creditValue}
            >
              {availableContentRow ? "—" : credits}
            </Typography>
            {isRecommended ? (
              <AddModalRowButton
                variant="outline"
                onClick={() => {
                  onAddRecommendedPrimer(false, index);
                }}
                index={index}
              />
            ) : (
              <x.div textAlign="center" data-testid={testIds.deleteRowButton}>
                {!availableContentRow && (
                  <IconButton onClick={deleteRow} size="small" variant="outline" key="rounded-button">
                    <Delete />
                  </IconButton>
                )}
              </x.div>
            )}
          </>
        )}
      </x.div>
      <Divider />
    </>
  );
};

export default CommissionModalContent;
