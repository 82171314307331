import { FileStatus } from "./constants";
import { SelectedThirdPartyFileToUpload } from "./types";

const isSuccessStatus = (status: FileStatus) => [FileStatus.uploaded, FileStatus.informationRequired].includes(status);

const getEditDocumentProps = (file: SelectedThirdPartyFileToUpload) => {
  const {
    name,
    date,
    expertCompanyName,
    expertCompanyCdsAlphaCompanyId,
    expertPosition,
    angle,
    isTranscript,
    documentTitle,
  } = file;
  return {
    date: date.toISOString(),
    expertCompanyName,
    expertCompanyCdsAlphaCompanyId,
    expertPosition,
    angle,
    isTranscript,
    fileName: name,
    documentTitle,
  };
};

export { isSuccessStatus, getEditDocumentProps };
