import { Typography, Icon } from "@alphasights/alphadesign-components";
import { Close } from "@alphasights/alphadesign-icons";
import React, { useEffect } from "react";
import ReactModal from "react-modal";
import ReactTooltip from "react-tooltip";

if (process.env.NODE_ENV !== "test") {
  ReactModal.setAppElement("#root");
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  contentLabel,
  className = "",
  showCloseIcon = false,
  overlayColor = "rgba(0,0,0,.80)",
  style = null,
  headerClassName = "aui-bg-highlight-1-alt aui-border-grey-2 aui-text-grey-5",
  testId,
  zIndex = 50,
}) => {
  useEffect(() => {
    if (isOpen) {
      // There's a poor interaction between react-tooltip and react-modal libs, this is a workaround
      setTimeout(ReactTooltip.rebuild, 500);
      const onEscape = (event) => {
        if (event.keyCode === 27) {
          onClose && onClose();
        }
      };

      document.addEventListener("keydown", onEscape);

      return function cleanup() {
        document.removeEventListener("keydown", onEscape);
      };
    }
  }, [isOpen, onClose]);

  return (
    <ReactModal
      shouldFocusAfterRender={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={contentLabel || contentLabel}
      className={`aui-box-border aui-bg-white aui-absolute aui-inset-0 md:aui-static aui-overflow-auto md:aui-max-h-screen ${className}`}
      bodyOpenClassName="aui-overflow-hidden"
      overlayClassName="aui-fixed aui-inset-0 aui-flex aui-justify-center aui-items-start md:aui-items-center"
      style={{
        content: style,
        overlay: { backgroundColor: overlayColor, zIndex: zIndex },
      }}
      testId={testId}
    >
      {(title || showCloseIcon) && (
        <header
          className={`aui-flex aui-justify-between aui-items-center aui-px-6 aui-py-2 aui-relative ${headerClassName}`}
        >
          {title && <Typography variant="h3">{title}</Typography>}
          {showCloseIcon && (
            <div className="aui-flex aui-cursor-pointer" onClick={onClose}>
              <Icon color="secondary">
                <Close />
              </Icon>
            </div>
          )}
        </header>
      )}
      {children}
    </ReactModal>
  );
};
