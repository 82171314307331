import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { hasOnlySurveyWorkstreams } from "components/InteractionsPage/helpers/Workstreams";
import { AngleQuestionTheme } from "models/AngleQuestions";
import React, { ReactNode, useCallback, useContext, useMemo } from "react";
import { angleQuestionsService } from "services/angleQuestionsService";

interface AngleQuestionsContextState {
  enableAngleQuestions: boolean;
  getAngleQuestionThemes: (angleId: string) => Promise<AngleQuestionTheme[]>;
}

export interface AngleQuestionsContextProps {
  service?: typeof angleQuestionsService;
  project?: Project;
  children: ReactNode;
}

const AngleQuestionsContext = React.createContext<undefined | AngleQuestionsContextState>(undefined);

export const AngleQuestionsProvider = ({
  service = angleQuestionsService,
  project,
  children,
}: AngleQuestionsContextProps) => {
  const { isMobile } = useCheckScreen();
  const currentUser = useCurrentUser() as (User & { questionsViewEnabled: boolean }) | undefined;
  const isSurveyOnlyProject = useMemo(() => project && hasOnlySurveyWorkstreams(project), [project]);
  const projectToken = project?.token;
  const enableAngleQuestions = !!(currentUser?.questionsViewEnabled && !isSurveyOnlyProject && !isMobile);

  const getAngleQuestionThemes = useCallback(
    (angleId: string) => {
      return projectToken ? service.findAngleQuestionThemes(projectToken, angleId) : Promise.resolve([]);
    },
    [projectToken, service]
  );

  const context = {
    enableAngleQuestions,
    getAngleQuestionThemes,
  };

  return <AngleQuestionsContext.Provider value={context} children={children} />;
};

export const useAngleQuestionsContext = () => {
  const context = useContext(AngleQuestionsContext);

  if (!context) throw new Error("AngleQuestionsContext should only be used within AngleQuestionsProvider");

  return context;
};
