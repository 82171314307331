import React from "react";
import { StatusError, StatusFull, StatusHalf, StatusPending, StatusPriorityLow } from "@alphasights/alphadesign-icons";
import { useThemeTokens } from "@alphasights/alphadesign-components";

interface StatusPillConfig {
  icon: JSX.Element | undefined;
  styles: { [key: string]: React.CSSProperties };
}

export type StatusPillVariant =
  | "creating"
  | "replied"
  | "awaiting"
  | "declined"
  | "expired"
  | "nolongeravailable"
  | "cancelled"
  | "sent"
  | "more";

export const variantStyleMap = {
  creating: {
    icon: <StatusPending />,
    styles: {
      textColor: "strong",
      color: "background-neutral-default",
      iconColor: "secondary",
      borderColor: "background-neutral-subtle !important",
    },
  },
  replied: {
    icon: <StatusFull />,
    styles: {
      textColor: "success",
      color: "background-success",
      iconColor: "success",
      borderColor: "background-success !important",
    },
  },
  awaiting: {
    icon: <StatusHalf />,
    styles: {
      textColor: "processing",
      color: "background-processing-default",
      iconColor: "processing",
      borderColor: "background-processing-default !important",
    },
  },
  declined: {
    icon: <StatusError />,
    styles: {
      textColor: "danger",
      color: "background-danger-default",
      iconColor: "danger",
      borderColor: "background-danger-default !important",
    },
  },
  expired: {
    icon: <StatusPending />,
    styles: {
      textColor: "warning",
      color: "background-warning",
      iconColor: "warning",
      borderColor: "border-warning !important",
    },
  },
  nolongeravailable: {
    icon: <StatusError />,
    styles: {
      textColor: "danger",
      color: "background-danger-default",
      iconColor: "danger",
      borderColor: "background-danger-default !important",
    },
  },
  cancelled: {
    icon: <StatusError />,
    styles: {
      textColor: "danger",
      color: "background-danger-default",
      iconColor: "danger",
      borderColor: "background-danger-default !important",
    },
  },
  sent: {
    icon: <StatusPriorityLow />,
    styles: {
      textColor: "success",
      color: "background-success",
      iconColor: "success",
      borderColor: "background-success !important",
    },
  },
  more: {
    icon: undefined,
    styles: {
      textColor: "strong",
      color: "background-neutral-default",
      iconColor: "secondary",
      borderColor: "background-neutral-subtle !important",
    },
  },
} as Record<StatusPillVariant, StatusPillConfig>;

export const useMessengerStatusPillStyles = ({ variant }: { variant: StatusPillVariant }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const variantStyle = variantStyleMap[variant] ?? variantStyleMap.more;

  const stausPopoverItem = {
    display: "flex",
    gap: inner.base,
  };

  const statusPopoverText = {
    padding: inner.base02,
  };

  return {
    variantStyle,
    stausPopoverItem,
    statusPopoverText,
  };
};
