import React, { useRef } from "react";
import { x } from "@xstyled/styled-components";
import { Icon, useThemeTokens } from "@alphasights/alphadesign-components";
import { Company } from "@alphasights/alphadesign-icons";
import Card from "components/Card";
import { Companies, ContentCardSubtitle, ContentCardTitle } from "@alphasights/client-portal-shared";

type PropTypes = {
  onClick: () => void;
  companies: {
    name: string;
    id: number;
  }[];
  isRequestPrimer: boolean;
};

const DataTestIds = {
  requestPrimerCard: "request-primer-card",
  placeholderIcon: "placeholder-icon",
};

const RequestPrimerCard = ({ onClick, companies, isRequestPrimer }: PropTypes) => {
  const {
    color: { text, border: borderColor },
    spacing: { inner },
    shape: { border },
  } = useThemeTokens();

  const ref = useRef(null);

  const getSelectedCompaniesNames = () => companies.map((company) => company.name);

  return (
    <Card
      data-testid={DataTestIds.requestPrimerCard}
      mt={inner.base03}
      borderWidth={border.width.small}
      borderColor={isRequestPrimer ? borderColor.info : { _: borderColor.neutral.default, hover: borderColor.info }}
      p={inner.base04}
      color={text.secondary}
      cursor="pointer"
      {...{ onClick: onClick }}
    >
      <x.div display="flex">
        <x.div
          w="72px"
          minWidth="72px"
          h="72px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          border="1px solid"
          borderRadius={border.radius.small}
          borderColor={borderColor.neutral.default}
        >
          <div data-testid={DataTestIds.placeholderIcon}>
            <Icon size="large" color={text.secondary}>
              <Company />
            </Icon>
          </div>
        </x.div>
        <x.div flexGrow={1} ml={inner.base02}>
          <>
            <x.div flex="1 0 auto" row flexWrap="nowrap" pb={inner.base02} spaceX={inner.base} ref={ref}>
              <Companies
                companies={getSelectedCompaniesNames()}
                searchCompanies={getSelectedCompaniesNames()}
                containerRef={ref}
              />
            </x.div>
            <x.div position="relative" ml={border.radius.none}>
              <ContentCardTitle isRequestPrimer title="Commission Research" />
              <x.div row alignItems="center">
                <ContentCardSubtitle subtitleString="Expert-led company overviews" />
              </x.div>
            </x.div>
          </>
        </x.div>
      </x.div>
    </Card>
  );
};

export { RequestPrimerCard as default, DataTestIds };
