import React from "react";
import * as S from "./Topbar.styled";

export const Topbar = ({ loading }: { loading: boolean }) => {
  return (
    <S.Wrapper>
      {loading ? (
        <>
          <S.TopbarTitleLoading />
          <S.TopbarActionsLoading />
        </>
      ) : (
        <h1>TODO: Topbar</h1>
      )}
    </S.Wrapper>
  );
};
