import React, { useCallback } from "react";
import { Typography } from "@alphasights/alphadesign-components";
import { useInteractioUpgradesStyles } from "./InteractionUpgrades.styles";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { x } from "@xstyled/styled-components";
import useWindowDimensions from "hooks/useWindowDimensions";

export const EnhancementsInformation = ({ upgrades }) => {
  const { tooltipContentStyle } = useInteractioUpgradesStyles({});
  const { project } = useCurrentProjectContext();

  const offersUpgrade = useCallback(
    (...types) => types.reduce((acc, type) => acc || !!upgrades.find((u) => u === type), false),
    [upgrades]
  );

  const [width] = useWindowDimensions();

  return (
    <x.div maxWidth={`${width - 30}px`}>
      <ul>
        <Typography {...tooltipContentStyle}>
          {offersUpgrade("video_and_screen_share") && (
            <li>
              <Typography variant="body-em" component="span">
                Video Call:{" "}
              </Typography>
              {project?.enableNewVideoCallAndScreenSharing
                ? "Turn on video during the call."
                : "Share your screen with call participants."}
            </li>
          )}
          {offersUpgrade("screen_sharing") && (
            <li>
              <Typography variant="body-em" component="span">
                Screen Sharing:{" "}
              </Typography>
              Share your screen with call participants.
            </li>
          )}
          {offersUpgrade("hosted") && (
            <li>
              <Typography variant="body-em" component="span">
                Blinded:{" "}
              </Typography>
              Conduct your call without disclosing your identity, or your company’s identity, to the expert.
            </li>
          )}
          {offersUpgrade("interpreted") && (
            <li>
              <Typography variant="body-em" component="span">
                Interpreted:{" "}
              </Typography>
              Engage an experienced, AlphaSights-vetted interpreter to overcome language barriers.
            </li>
          )}
        </Typography>
      </ul>
    </x.div>
  );
};

export const DeliverablesInformation = ({ upgrades }) => {
  const { tooltipContentStyle } = useInteractioUpgradesStyles({});

  const offersUpgrade = useCallback(
    (...types) => types.reduce((acc, type) => acc || !!upgrades.find((u) => u === type), false),
    [upgrades]
  );

  const [width] = useWindowDimensions();

  return (
    <x.div maxWidth={`${width - 30}px`}>
      <ul>
        <Typography {...tooltipContentStyle}>
          {offersUpgrade("transcript_and_recorded", "recorded") && (
            <li>
              <Typography variant="body-em" component="span">
                Audio Recording:{" "}
              </Typography>
              Play back a recording of your call, available up to 21 days after the call’s completion.
            </li>
          )}
          {offersUpgrade("transcript_and_recorded", "transcript_ai") && (
            <li>
              <Typography variant="body-em" component="span">
                AI Transcript:{" "}
              </Typography>
              Access a transcript of your call generated by speech recognition technology. Only available for English
              calls.
            </li>
          )}
          {offersUpgrade("transcript") && (
            <li>
              <Typography variant="body-em" component="span">
                Human Transcript:{" "}
              </Typography>
              Receive a high quality, human-authored transcript with 99%+ accuracy.
            </li>
          )}
          {offersUpgrade("translated") && (
            <li>
              <Typography variant="body-em" component="span">
                Translated Transcript:{" "}
              </Typography>
              Access the content of your transcript in a language of your choice.
            </li>
          )}
        </Typography>
      </ul>
    </x.div>
  );
};
