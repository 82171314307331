import React, { FC, SetStateAction, useState, Dispatch, useEffect, useRef } from "react";
import { Document, Page, pdfjs, PDFPageProxy } from "react-pdf";
import { Loading } from "@alphasights/alphadesign-components";

import { LANDSCAPE_SCALING_FACTOR, LOAD_ERROR_MESSAGE, PORTRAIT_SCALING_FACTOR } from "./constants";

import * as S from "./DocumentViewer.styled";

pdfjs.GlobalWorkerOptions.workerSrc = "/scripts/pdf.worker.min.js";

type DocumentViewerProps = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  data?: Blob;
};

const DocumentViewer: FC<DocumentViewerProps> = ({ data, isLoading, setIsLoading }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [hasWhiteBackground, setHasWhiteBackground] = useState<boolean>(true);
  const [pageScales, setPageScales] = useState<number[]>([]);

  const pagesContainerRef = useRef<HTMLDivElement>(null);

  const handleDocumentLoadSuccess = ({ numPages: numberOfPages }: { numPages: number }) => {
    setHasWhiteBackground(false);
    setNumPages(numberOfPages);
    setPageScales(new Array(numPages).fill(1));
  };

  const handleDocumentLoadError = (error: Error) => {
    setIsLoading(false);
    setHasWhiteBackground(true);
    console.error("Error loading document:", error);
  };

  const updatePageScale = (page: PDFPageProxy) => {
    const { width: pageWidth, height: pageHeight } = page;
    const isLandscape = pageWidth > pageHeight;
    const newScales = [...pageScales];
    newScales[page.pageNumber - 1] = Math.min(
      (pagesContainerRef.current?.getBoundingClientRect().width ?? pageWidth) / pageWidth,
      isLandscape ? LANDSCAPE_SCALING_FACTOR : PORTRAIT_SCALING_FACTOR
    );
    setPageScales(newScales);
  };

  useEffect(() => {
    isLoading && setHasWhiteBackground(true);
  }, [isLoading]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <S.DocumentWrapper hasWhiteBackground={hasWhiteBackground}>
      <Document
        file={data}
        onLoadSuccess={handleDocumentLoadSuccess}
        onLoadError={handleDocumentLoadError}
        loading={<LoadingSpinner />}
        error={<ErrorMessage />}
      >
        <S.PagesContainer ref={pagesContainerRef}>
          {Array.from({ length: numPages }, (_, index) => (
            <S.PageWrapper key={`page_${index + 1}`}>
              <Page
                pageNumber={index + 1}
                renderAnnotationLayer={false}
                renderTextLayer
                scale={pageScales[index]}
                onLoadSuccess={updatePageScale}
              />
            </S.PageWrapper>
          ))}
        </S.PagesContainer>
      </Document>
    </S.DocumentWrapper>
  );
};

const LoadingSpinner = () => (
  <S.SpinnerContainer>
    <Loading />
  </S.SpinnerContainer>
);

const ErrorMessage = () => <S.ErrorContainer>{LOAD_ERROR_MESSAGE}</S.ErrorContainer>;

export default DocumentViewer;
