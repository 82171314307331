export enum SummaryType {
  Comprehensive = "COMPREHENSIVE",
}

export enum ViewType {
  Summary = "Summary",
  Questions = "Questions",
  Mentions = "Mentions",
  Transcript = "Transcript",
}

export enum SummaryStatus {
  NotRequested = "NOT_REQUESTED",
  Generating = "GENERATING",
  Generated = "GENERATED",
  FailedToGenerate = "FAILED_TO_GENERATE",
}

export interface TranscriptContent {
  fullText: string;
  numHighlights: number;
}

export interface Sentence {
  speaker: string;
  value: string;
  position: number;
  hasHighlight: boolean;
  start?: string;
  end?: string;
  startSeconds?: number;
  endSeconds?: number;
}

export interface Cue {
  id: number;
  text: string;
}

export interface AccumulatedCue {
  id: number;
  body: string;
  cues: Cue[];
  speaker: string;
  timestamp: number;
}

export interface AiSummaryReference {
  transcriptIndex: number;
  contents: string;
}

export interface AiSummaryPart {
  type: string;
  references: AiSummaryReference[] | null;
  text: string;
  keyword: string | null;
}

export interface HighlightAiSummaryPart extends AiSummaryPart {
  hasHighlight: boolean;
  highlightCounts: number;
}

export interface AiSummaryContent {
  generatedAt: string;
  type: SummaryType;
  parts: AiSummaryPart[];
}

export interface Summary {
  type: SummaryType;
  status: SummaryStatus;
  content: AiSummaryContent | null;
  feedbackType: string;
}
