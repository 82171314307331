import React, { useEffect, useState } from "react";
import { fetch } from "../../hooks/useApi";
import { BackgroundPage, ModalForm } from "./BackgroundPage";
import { Spinner } from "../../components/Spinner";
import { FormBody, FormContainer, FormFooter, FormHeader } from "./Form";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { AccountDeactivatedSignUp, Help, MagicLinkRequested, SignInFooter } from "./index";
import { useParams, Navigate } from "router-utils";
import { useLoginStyles } from "./index.styles";
import { Button, Link, Typography } from "@alphasights/alphadesign-components";
import { useEnv } from "@alphasights/client-portal-shared";

export const MagicLinkLogin = () => {
  const { token } = useParams();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [step, setStepInternal] = useState("loading");
  const [previousStep, setPreviousStep] = useState();
  const env = useEnv();

  const setStep = (newstep) => {
    setPreviousStep(step);
    setStepInternal(newstep);
  };

  const redirectToHelp = () => {
    setStep("help");
  };

  const redirectToSignIn = () => {
    setStep("sign-in");
  };

  const onClickReturn = () => setStep(previousStep);

  const addCustomMessages = (error) => {
    const { message, request } = error;
    const messages = {
      USED: {
        title: "This link has been already used",
        description: `This sign in link has already been used. Please request a new sign in link which will be valid for ${env.userActionRequestsValidityInHours} hours.`,
      },
      CANCELED: {
        title: "This link is no longer valid",
        description: `The sign in link you are trying to use is no longer valid. Please request a new sign in link which will be valid for ${env.userActionRequestsValidityInHours} hours.`,
      },
      EXPIRED: {
        title: "This link has expired",
        description: `The sign in link you are trying to use has expired. Please request a new sign in link which will be valid for ${env.userActionRequestsValidityInHours} hours.`,
      },
    };
    return request
      ? { ...error, ...messages[request.state] }
      : { ...error, title: "Invalid magic link", description: message };
  };

  useEffect(() => {
    fetch({
      url: `/api/auth/magic-links/${token}/authenticate`,
      method: "POST",
      skipAlert: true,
    })
      .then(() => {
        setError(null);
        window.location.href = `/my-projects`;
      })
      .catch((err) => {
        err
          .json()
          .then(addCustomMessages)
          .then((error) => {
            setError(error);
            if (error.type === "used") {
              setStep("link");
            } else if (error.request) {
              setEmail(error.request.email);
              setStep("request-magic-link");
            } else if (error.message === "User is disabled") {
              setStep("deactivated");
            } else {
              setStep("sign-in");
            }
          });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onRequestMagicLink = async () => {
    setLoading(true);
    try {
      await fetch({
        url: `/api/auth/magic-links`,
        method: "POST",
        body: JSON.stringify({ email }),
      });

      setStep("magic-link-requested");
      setLoading(false);
    } catch (err) {
      const { message } = await err.json();
      setError(message);
      setLoading(false);
    }
  };

  return (
    <SwitchTransition>
      <CSSTransition
        key={step}
        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
        classNames="LoginTransition"
      >
        <>
          {step === "loading" && (
            <BackgroundPage>
              <div className="aui-flex aui-flex-col aui-justify-around aui-items-center aui-h-8/10">
                <Spinner />
              </div>
            </BackgroundPage>
          )}
          {step === "request-magic-link" && (
            <MagicLinkInvalidState
              isLoading={isLoading}
              title={error.title}
              description={error.description}
              onResendMagicLink={onRequestMagicLink}
              onClickHelp={redirectToHelp}
              onClickReturnSignIn={redirectToSignIn}
            />
          )}
          {step === "magic-link-requested" && (
            <ModalForm>
              <MagicLinkRequested
                email={email}
                onReturnToSignIn={() => setStep("sign-in")}
                onClickHelp={redirectToHelp}
              />
            </ModalForm>
          )}
          {step === "deactivated" && (
            <ModalForm>
              <AccountDeactivatedSignUp
                email={email}
                onClickEmail={() => setStep("sign-in")}
                onClickHelp={redirectToHelp}
              />
            </ModalForm>
          )}
          {step === "deactivated_inactivity" && (
            <Navigate
              to={{
                pathname: "/deactivated-account",
              }}
              state={{ email, step: "deactivated_inactivity" }}
            />
          )}
          {step === "link" && <Navigate to="/sign-in?link" />}
          {step === "sign-in" && <Navigate to="/sign-in" />}
          {step === "help" && (
            <ModalForm>
              <Help onClickReturn={onClickReturn} />
            </ModalForm>
          )}
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};

const MagicLinkInvalidState = ({
  title,
  description,
  onResendMagicLink,
  isLoading,
  onClickHelp,
  onClickReturnSignIn,
}) => {
  const { modalTitle, modalDescription, backButton } = useLoginStyles();

  return (
    <ModalForm>
      <FormContainer isLoading={isLoading}>
        <FormHeader
          title={
            <Typography variant="h3" {...modalTitle}>
              The link is no longer valid
            </Typography>
          }
          description={
            <Typography {...modalDescription}>
              The sign in link you are trying to use is no longer valid. To resend a new link&nbsp;
              <Link component="button" onClick={onResendMagicLink}>
                <Typography variant="body-em">click here.</Typography>
              </Link>
            </Typography>
          }
        />
        <FormBody>
          <Button variant="outline" onClick={onClickReturnSignIn} {...backButton}>
            <Typography variant="body-em">Return to Sign In</Typography>
          </Button>
        </FormBody>
        <FormFooter>
          <SignInFooter onClickHelp={onClickHelp} showSignUp={false} />
        </FormFooter>
      </FormContainer>
    </ModalForm>
  );
};
