import { ThirdPartyDocumentType, ThirdPartyInteraction } from "types";
import { OPEN_EDIT_MODAL_PROMPT } from "./constants";

const getOpenEditModalPrompt = (interaction: ThirdPartyInteraction) => {
  const {
    documentType,
    attribute: {
      title,
      attributes: { angle, expertCompanyName, expertPosition },
    },
  } = interaction;
  const isTranscript = documentType === ThirdPartyDocumentType.transcript;

  if (isTranscript) {
    if (!angle) {
      return OPEN_EDIT_MODAL_PROMPT.addAngle;
    }
    if (!expertCompanyName || !expertPosition) {
      return OPEN_EDIT_MODAL_PROMPT.addExpertInfo;
    }
  } else if (!title) {
    return OPEN_EDIT_MODAL_PROMPT.addDocumentInfo;
  }

  return "";
};

export { getOpenEditModalPrompt };
