import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { x } from "@xstyled/styled-components";
import { Icon, IconButton, Loading, Pill, Popover, Typography } from "@alphasights/alphadesign-components";
import { ChevronDown, ChevronUp, Close, Search } from "@alphasights/alphadesign-icons";
import { useTranscriptSearchBarStyles } from "./TranscriptSearchBar.styles";

type SearchBarProps = {
  placeholder: string;
  onKeywordsUpdated: (keywords: string[]) => void;
  onNav: (index: number) => void;
  totalResults: number;
  queryKeywords: string[];
  loading: boolean;

  forceOpen?: boolean;
  variant?: "inline" | "popover";
  isDisabled?: boolean;
};

export const TranscriptSearchBar = (props: SearchBarProps) => {
  const { variant } = props;

  const Wrapper = variant === "popover" ? PopoverWrapper : React.Fragment;

  return (
    <Wrapper {...props}>
      <SearchBar {...props} />
    </Wrapper>
  );
};

const PopoverWrapper = ({
  onKeywordsUpdated,
  queryKeywords,
  children,
  forceOpen = false,
  isDisabled = false,
}: {
  onKeywordsUpdated: (keywords: string[]) => void;
  queryKeywords: string[];
  children: React.ReactNode;
  forceOpen?: boolean;
  isDisabled?: boolean;
}) => {
  const styles = useTranscriptSearchBarStyles();

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const [isOpen, setIsOpen] = React.useState(forceOpen || queryKeywords.length > 0);

  const handleClick = () => {
    setIsOpen((prev) => {
      if (prev) onKeywordsUpdated([]);
      return !prev;
    });
  };

  useEffect(() => {
    setIsOpen(forceOpen || queryKeywords.length > 0);
  }, [forceOpen, queryKeywords]);

  return (
    <>
      <IconButton
        testId="transcript-searchbar-popover-btn"
        ref={setAnchorEl}
        onClick={handleClick}
        size="medium"
        variant="outline"
        isSelectable={true}
        isSelected={isOpen}
        disabled={isDisabled}
      >
        <Search />
      </IconButton>
      <Popover anchorEl={anchorEl ?? undefined} open={isOpen && !isDisabled} closeOnClickOutside={false}>
        <x.div {...styles.popoverVariantWrapper} data-testid="transcript-searchbar-popover">
          {children}
          <IconButton
            testId="transcript-searchbar-popover-close"
            variant="ghost"
            onClick={() => {
              onKeywordsUpdated([]);
              setIsOpen(false);
            }}
            size="small"
          >
            <Close />
          </IconButton>
        </x.div>
      </Popover>
    </>
  );
};

const SearchBar = ({
  onKeywordsUpdated,
  placeholder,
  onNav,
  totalResults,
  loading,
  queryKeywords,
  variant,
}: SearchBarProps) => {
  const styles = useTranscriptSearchBarStyles();

  return (
    <x.div display="flex" w="100%">
      <SearchInput
        onKeywordsUpdated={onKeywordsUpdated}
        placeholder={placeholder}
        loading={loading}
        queryKeywords={queryKeywords}
        variant={variant}
      />
      {queryKeywords.length > 0 && (
        <x.div {...styles.resultsNavigatorWrapper} data-testid="transcript-searchbar-results-navigator">
          <ResultsNavigator onNav={onNav} totalResults={totalResults} loading={loading} />
        </x.div>
      )}
    </x.div>
  );
};

const ResultsNavigator = ({
  onNav,
  totalResults,
  loading,
}: {
  onNav: (index: number) => void;
  totalResults: number;
  loading: boolean;
}) => {
  const styles = useTranscriptSearchBarStyles();
  const [selectedResultIndex, setSelectedResultIndex] = useState(0);
  useEffect(() => setSelectedResultIndex(0), [totalResults, loading]);

  const onPrevious = useCallback(() => {
    setSelectedResultIndex((prev) => {
      const nextIndex = prev - 1 < 0 ? totalResults - 1 : prev - 1;
      onNav(nextIndex);
      return nextIndex;
    });
  }, [totalResults, onNav]);

  const onNext = useCallback(() => {
    setSelectedResultIndex((prev) => {
      const nextIndex = (prev + 1) % totalResults;
      onNav(nextIndex);
      return nextIndex;
    });
  }, [totalResults, onNav]);

  useEffect(() => {
    onNav(0);
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <x.div>
        <Icon size="small">
          <Loading />
        </Icon>
      </x.div>
    );
  }

  return (
    <>
      {totalResults > 0 ? (
        <>
          <x.div {...styles.resultsNavigatorActions}>
            <Icon data-testid="previous-match" size="small" onClick={onPrevious}>
              <ChevronUp />
            </Icon>
            <Icon data-testid="next-match" size="small" onClick={onNext}>
              <ChevronDown />
            </Icon>
          </x.div>
          <x.div
            {...styles.resultsNavigatorMentions}
            minWidth={`${totalResults} / ${totalResults} `.length * 6 + "px"}
            data-testid="total-results"
          >
            <Typography variant="body-small" color="#FD3001">
              {selectedResultIndex + 1}
            </Typography>
            &nbsp;
            <Typography variant="body-small" color="#717C80">
              / {totalResults}
            </Typography>
          </x.div>
        </>
      ) : (
        <Typography variant="body-small-em">No Results</Typography>
      )}
    </>
  );
};

const SearchInput = ({
  onKeywordsUpdated,
  queryKeywords,
  loading,
  placeholder = "",
  variant,
}: {
  onKeywordsUpdated: (keywords: string[]) => void;
  queryKeywords: string[];
  loading: boolean;
  placeholder?: string;
  variant?: "inline" | "popover";
}) => {
  const [searchBarInputText, setSearchBarInputText] = useState("");
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const inputSearchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    variant === "popover" && inputSearchRef.current && inputSearchRef.current.focus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const resetInputSearch = () => {
    if (inputSearchRef.current) {
      setSearchBarInputText("");
      inputSearchRef.current.value = "";
      inputSearchRef.current.style.width = "1px";
    }
  };

  useEffect(() => {
    if (!loading && inputSearchRef.current) inputSearchRef.current?.scrollIntoView();
  }, [loading, inputSearchRef]);

  const addSearchKeyword = (event: React.KeyboardEvent | React.FocusEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (searchBarInputText && searchBarInputText !== "") {
      const keywords = queryKeywords.includes(searchBarInputText)
        ? queryKeywords
        : [...queryKeywords, searchBarInputText];

      onKeywordsUpdated(keywords);
      resetInputSearch();
    }
  };

  const removeSearchKeyword = useCallback(
    (searchKeyword: string) => {
      return (event: React.MouseEvent | React.KeyboardEvent) => {
        event.preventDefault();
        const remainingKeywords = queryKeywords.filter((queryKeyword) => queryKeyword !== searchKeyword);
        onKeywordsUpdated(remainingKeywords);
      };
    },
    [queryKeywords, onKeywordsUpdated]
  );

  const placeholderMessage = () => {
    return queryKeywords.length > 0 ? "" : placeholder;
  };

  const handleFocusClick = (event: React.MouseEvent) => {
    event && event.preventDefault();
    inputSearchRef.current && inputSearchRef.current.focus();
  };

  const removeLastKeyword = (event: React.MouseEvent | React.KeyboardEvent) => {
    if (queryKeywords.length === 0) return;
    if (inputSearchRef.current && inputSearchRef.current.value.length !== 0) return;

    removeSearchKeyword(queryKeywords[queryKeywords.length - 1])(event);
  };

  const handleInputKeyDown = (event: React.KeyboardEvent) => {
    if (loading) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (inputSearchRef.current) {
      inputSearchRef.current.style.width = (inputSearchRef.current.value.length + 1) * 8 + "px";
    }

    if (event.key === "Enter") {
      addSearchKeyword(event);
    }
    if (event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      resetInputSearch();
    }

    if (event.key === "Backspace") {
      removeLastKeyword(event);
    }
  };

  const styles = useTranscriptSearchBarStyles({
    isSearchFocused,
    loading,
    variant,
  });

  return (
    <x.div {...styles.searchInputWrapper}>
      <x.div {...styles.searchInputDiv} data-testid="transcript-searchbar-div" onClick={handleFocusClick}>
        <NoScrollDiv {...styles.searchInputPillsContainer}>
          {queryKeywords.map((value) => {
            return (
              <Pill
                key={value}
                data-testid={`transcript-searchbar-chip-${value}`}
                variant="blue"
                whiteSpace="nowrap"
                onClose={removeSearchKeyword(value)}
                isInteractive={false}
                size={"small"}
              >
                {value}
              </Pill>
            );
          })}
          <x.input
            {...styles.searchInput}
            data-testid="transcript-searchbar-input"
            ref={inputSearchRef}
            placeholder={placeholderMessage()}
            onKeyDown={handleInputKeyDown}
            onChange={(e) => {
              setSearchBarInputText(e.target.value.trim());
            }}
            onBlur={(event) => {
              addSearchKeyword(event);
              setIsSearchFocused(false);
            }}
            onFocus={() => {
              setIsSearchFocused(true);
            }}
          />
        </NoScrollDiv>
      </x.div>
    </x.div>
  );
};

const NoScrollDiv = styled(x.div)`
  &::-webkit-scrollbar {
    display: none;
  }
`;
