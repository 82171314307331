import { IconButton, ListOption, Popover, Typography } from "@alphasights/alphadesign-components";
import { Call, Copy, Mail, MobilePhone } from "@alphasights/alphadesign-icons";
import { Lead } from "@alphasights/portal-api-client";
import ExpiringComponent from "components/ExpiringComponent";
import { compact } from "lodash";
import React, { useState } from "react";
import * as S from "./ContactInfoDropdown.styled";

export const ContactInfoDropdown = ({ lead }: { lead: Lead }) => {
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const [tooltipAnchorEl, setTooltipAnchorEl] = useState<Element | undefined>(undefined);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const copyToClipboard = (text: string, event: React.MouseEvent) => {
    navigator.clipboard.writeText(text);
    setTooltipAnchorEl(event.currentTarget);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 2000);
  };

  const handlePopoverOpen = ({ currentTarget }: { currentTarget: Element }) => {
    setAnchorEl(currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(undefined);
  };

  const capitalizeWords = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };

  const open = Boolean(anchorEl);
  const phoneNumberCount = compact([lead.desktopNumber, lead.mobileNumber]).length;

  return (
    <>
      <div data-testid="cst-dropdown" onMouseEnter={handlePopoverOpen}>
        <S.CustomProjectLeadAvatarTop lead={{ ...lead, name: capitalizeWords(lead.name) }} />
      </div>
      <Popover
        closeOnMouseLeave={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        leaveDelay={100}
        zIndex={100}
        placement="bottom-end"
      >
        <S.PopoverContainer>
          <ListOption type="title" label="Project Lead" />
          <div className="aui-ml-2 aui-flex aui-flex-row aui-gap-2 aui-py-2">
            <S.CustomProjectLeadAvatar lead={{ ...lead, name: capitalizeWords(lead.name) }} />
            <Typography>{capitalizeWords(lead.name)}</Typography>
          </div>

          {lead.desktopNumber && (
            <S.ContactWrapper>
              <S.Link href={`tel:${lead.desktopNumber}`}>
                <ListOption type="text" label={lead.desktopNumber} leftIcon={<Call />} />
              </S.Link>
              <IconButton
                variant="ghost"
                color="secondary"
                size="medium"
                onClick={(e) => copyToClipboard(lead.desktopNumber, e)}
              >
                <Copy />
              </IconButton>
            </S.ContactWrapper>
          )}

          {lead.mobileNumber && (
            <S.ContactWrapper>
              <S.Link href={`tel:${lead.mobileNumber}`}>
                <ListOption
                  type="text"
                  label={lead.mobileNumber}
                  leftIcon={phoneNumberCount > 1 ? <MobilePhone /> : <Call />}
                />
              </S.Link>
              <IconButton
                variant="ghost"
                color="secondary"
                size="medium"
                onClick={(e) => copyToClipboard(lead.mobileNumber, e)}
              >
                <Copy />
              </IconButton>
            </S.ContactWrapper>
          )}

          {lead.email && (
            <S.ContactWrapper>
              <S.Link href={`mailto:${lead.email}`}>
                <ListOption type="text" label={lead.email} leftIcon={<Mail />} />
              </S.Link>
              <IconButton
                variant="ghost"
                color="secondary"
                size="medium"
                onClick={(e) => copyToClipboard(lead.email, e)}
              >
                <Copy />
              </IconButton>
            </S.ContactWrapper>
          )}
        </S.PopoverContainer>
      </Popover>

      {tooltipOpen && (
        <ExpiringComponent delay={2000} onExpire={() => setTooltipOpen(false)}>
          <S.CopiedPopover
            anchorEl={tooltipAnchorEl}
            open={tooltipOpen}
            placement="bottom"
            onClose={() => setTooltipOpen(false)}
          >
            <Typography variant="body-small">Copied!</Typography>
          </S.CopiedPopover>
        </ExpiringComponent>
      )}
    </>
  );
};
