import React, { FC } from "react";
import { compact } from "lodash";
import { Pill, Typography } from "@alphasights/alphadesign-components";

import { ThirdPartyInteraction } from "types";
import { Spacer } from "pages/AlphaNowPage/components";
import { UPLOADED } from "views/DeliverablesView/ThirdPartyDocumentCard";
import { formatDate } from "views/DeliverablesView/ThirdPartyDocUploadModal/EditDocumentModal/utils";
import { getOpenEditModalPrompt } from "./utils";

import * as S from "./DetailsRow.styled";

type DetailsRow = {
  interaction: ThirdPartyInteraction;
  onOpenEditModal: () => void;
};

const DetailsRow: FC<DetailsRow> = ({ interaction, onOpenEditModal }) => {
  const {
    attribute: {
      attributes: { angle },
    },
    scheduledCallTime,
    fileExtension,
  } = interaction;
  const formattedDate = formatDate(new Date(scheduledCallTime));
  const items = compact([angle && angle, formattedDate, fileExtension]);
  const openEditModalPrompt = getOpenEditModalPrompt(interaction);

  return (
    <S.DetailsWrapper>
      <Pill size="small" variant="outline">
        {UPLOADED}
      </Pill>
      <Spacer />
      <Typography component="span">
        {items.map((item, index) => {
          const showSpacer = index !== items.length - 1;
          return (
            <span key={index}>
              {item}
              {showSpacer && <Spacer />}
            </span>
          );
        })}
      </Typography>
      {openEditModalPrompt && (
        <>
          <Spacer />
          <S.OpenEditModalLink component="span" onClick={onOpenEditModal}>
            {openEditModalPrompt}
          </S.OpenEditModalLink>
        </>
      )}
    </S.DetailsWrapper>
  );
};

export default DetailsRow;
