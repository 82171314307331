import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  AccordionItem,
  Avatar,
  Button,
  Icon,
  IconButton,
  Link,
  Pill,
  Tooltip,
  Typography,
  useAlphaToast,
} from "@alphasights/alphadesign-components";
import { FormattedDateTime } from "providers/TimezoneProvider";
import {
  AttachmentResponse,
  MessageResponse,
  ParticipantRole,
  ResponseStatusType,
  ThreadResponse,
  WorkRequestResponse,
} from "types";
import { messageAttachmentService } from "services/messageAttachment";
import { x } from "@xstyled/styled-components";
import { Attachment as AttachmentIcon, Download, Expert, Reply } from "@alphasights/alphadesign-icons";
import { useNotifications } from "@alphasights/client-portal-shared";
import { useMessagesStyles } from "./Messages.styles";
import { WorkRequestMaxDuration } from "components/WorkRequestMaxDuration";
import { WorkRequestDeadline } from "components/WorkRequestDeadline/WorkRequestDeadline";
import { formatTimeInHoursAndMinutes } from "helpers/interactionHelpers";
import { format, isEqual } from "date-fns";
import { messageThreadService } from "services/messageThread";
import { getRequestTypeName, sanitizeMessage } from "pages/MessengerPage/utils";
import { withAccessControl } from "components/AccessControl/AccessControl";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { MessengerReplyField } from "components/MessengerReplyField/MessengerReplyField";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { EllipsisText } from "components/EllipsisText";

export const Messages: React.FC<{
  thread: ThreadResponse;
  projectToken: string;
  onSettingsChanged: () => void;
}> = ({ thread, projectToken, onSettingsChanged }) => {
  const { wrapper } = useMessagesStyles();

  return (
    <>
      <AccordionItem title={getRequestTypeName(thread.requestType)} open>
        <x.div {...wrapper}>
          {thread.messages.map((message: MessageResponse, index) => (
            <Message
              key={message.id}
              message={message}
              projectToken={projectToken}
              lastMessage={index === thread.messages.length - 1}
              threadId={thread.id}
            />
          ))}
        </x.div>
      </AccordionItem>
      {thread.workRequest && (
        <MessageSettings
          projectToken={projectToken}
          threadToken={thread.token}
          workRequest={thread.workRequest}
          onSettingsChanged={onSettingsChanged}
        />
      )}
    </>
  );
};

const Message: React.FC<{
  message: MessageResponse;
  projectToken: string;
  lastMessage: boolean;
  threadId: string;
}> = ({ message, projectToken, lastMessage, threadId }) => {
  const {
    messageContainer,
    messageContent,
    messageHeader,
    messageInfo,
    messageSender,
    messageDate,
    messageExtraInfo,
    messageReplyContainer,
    messageAttachmentsContainer,
    messageAttachmentIcon,
    messageAttachmentCount,
  } = useMessagesStyles();

  const { isMobile } = useCheckScreen();

  const [isExpanded, setExpanded] = useState(false);

  const replyRef = useRef<HTMLTextAreaElement>(null);
  const [showReply, setShowReply] = useState(false);

  const { sanitizedContent, content, isContentSanitized } = useMemo(() => sanitizeMessage(message), [message]);
  const messageText = isExpanded ? content : sanitizedContent;

  const { onSubmitReply, onReplyCreated } = useFlyoutContext();

  const isAdvisor = message.sender.role === ParticipantRole.Advisor;

  const onToggleExpandedMode = () => setExpanded(!isExpanded);
  const expertNameWithheld = useMemo(() => {
    return message.sender.name?.toLowerCase().includes("expert name withheld");
  }, [message.sender.name]);

  const focusReply = useCallback(() => {
    if (!replyRef.current) return;
    replyRef.current.focus();
  }, []);

  useEffect(() => focusReply(), [focusReply, showReply]);

  return (
    <x.div {...messageContainer} data-testid={`message-container-${message.id}`}>
      <x.div {...messageHeader}>
        <x.div {...messageInfo}>
          {expertNameWithheld ? (
            <Avatar color="base02">
              <Expert />
            </Avatar>
          ) : (
            <Avatar text={message.sender.name} color="base02" />
          )}
          <div>
            <Typography variant="body-em" {...messageSender}>
              {message.sender.name}
            </Typography>
            <Typography variant="body-small" {...messageDate}>
              <FormattedDateTime date={message.sentAt} format="d LLL yyyy, hh:mm" />
            </Typography>
          </div>
        </x.div>
        <x.div {...messageExtraInfo}>
          {!message.obfuscated && lastMessage && isAdvisor && (
            <x.div {...messageReplyContainer}>
              <Button
                marginRight="auto"
                variant="ghost"
                size="small"
                onClick={() => {
                  setShowReply(true);
                  focusReply();
                }}
                startIcon={<Reply />}
                data-testid="reply-message-button"
              >
                Reply
              </Button>
            </x.div>
          )}
          {message.attachments.length > 0 && (
            <x.div {...messageAttachmentsContainer} data-testid="message-attachments-count">
              <Icon {...messageAttachmentIcon}>
                <AttachmentIcon />
              </Icon>
              <Typography {...messageAttachmentCount}>{message.attachments.length}</Typography>
            </x.div>
          )}
        </x.div>
      </x.div>
      <Typography
        {...messageContent}
        style={{
          filter: message.obfuscated ? "blur(2.5px)" : "none",
          userSelect: message.obfuscated ? "none" : "auto",
        }}
        dangerouslySetInnerHTML={{ __html: messageText }}
      />
      {message.attachments.length > 0 && <Attachments attachments={message.attachments} projectToken={projectToken} />}

      {isContentSanitized && (
        <Link onClick={() => onToggleExpandedMode()}>
          <Typography variant={isMobile ? "body-large-em" : "body-em"}>See {isExpanded ? "Less" : "More"}</Typography>
        </Link>
      )}

      {showReply && (
        <MessengerReplyField
          ref={replyRef}
          threadId={threadId}
          closeReply={() => setShowReply(false)}
          onSubmitReply={onSubmitReply}
          onReplyCreated={onReplyCreated}
        />
      )}
    </x.div>
  );
};

const Attachments: React.FC<{
  attachments: AttachmentResponse[];
  projectToken: string;
}> = ({ attachments, projectToken }) => {
  const { attachmentsSection, attachmentsCount } = useMessagesStyles();

  return (
    <x.div {...attachmentsSection}>
      <Typography variant="body-small" {...attachmentsCount}>
        {`${attachments.length} attachment${attachments.length > 1 ? "s" : ""}`}
      </Typography>
      {attachments.map((attachment: AttachmentResponse) => (
        <Attachment attachment={attachment} projectToken={projectToken} key={`attachment-${attachment.id}`} />
      ))}
    </x.div>
  );
};

const Attachment: React.FC<{
  projectToken: string;
  attachment: AttachmentResponse;
}> = ({ projectToken, attachment }) => {
  const { attachmentContainer, attachmentFile, attachmentFileInfo } = useMessagesStyles();
  const { showSuccessBanner, showErrorBanner } = useNotifications();
  const [showTooltip, setShowTooltip] = useState(false);

  const onClickDownload = useCallback(() => {
    messageAttachmentService
      .downloadMessageAttachment(projectToken, attachment)
      .then(() => {
        showSuccessBanner("Attachment download successful");
      })
      .catch(() => {
        showErrorBanner("The attachment could not be downloaded. Please try again or contact your project lead");
      });
  }, [attachment, projectToken, showSuccessBanner, showErrorBanner]);

  return (
    <Tooltip title={attachment.filename} disabled={!showTooltip}>
      <x.div {...attachmentContainer} data-testid="attachment-card">
        <x.div {...attachmentFile}>
          <Avatar color="base02" minWidth={8} minHeight={8}>
            <AttachmentIcon />
          </Avatar>
          <x.div overflow="hidden">
            <EllipsisText text={attachment.filename} toggleExternalTooltip={setShowTooltip} />

            <Typography variant="body-small" {...attachmentFileInfo}>
              {attachment.type} • {attachment.fileSize}
            </Typography>
          </x.div>
          <x.div marginLeft="auto">
            <IconButtonWithAccess
              accessControl={{ allowedPermissions: ["follow-up-messages"] }}
              variant="basic"
              onClick={onClickDownload}
              color="secondary"
            >
              <Download />
            </IconButtonWithAccess>
          </x.div>
        </x.div>
      </x.div>
    </Tooltip>
  );
};

const IconButtonWithAccess = withAccessControl(IconButton);

export const MessageSettings: React.FC<{
  projectToken: string;
  threadToken: string;
  workRequest: WorkRequestResponse;
  onSettingsChanged: () => void;
}> = ({ projectToken, threadToken, workRequest, onSettingsChanged }) => {
  const { headerSlot, messageSettingsFields, messageSettingsButton, titleContent } = useMessagesStyles();

  const { toast } = useAlphaToast();

  const [deadline, setDeadline] = useState(new Date(workRequest.deadline));
  const [maximumTimeAllowed, setMaximumTimeAllowed] = useState(workRequest.maximumTimeAllowed);
  const [selectedDeadline, setSelectedDeadline] = useState(new Date(workRequest.deadline));
  const [selectedMaximumTimeAllowed, setSelectedMaximumTimeAllowed] = useState(workRequest.maximumTimeAllowed);
  const [updateWorkRequestLoading, setUpdateWorkRequestLoading] = useState(false);

  const onUpdateWorkRequest = () => {
    setUpdateWorkRequestLoading(true);

    const content: any = {};
    const isDeadlineChanged = !isEqual(selectedDeadline, deadline);
    const isMaximumTimeAllowedChanged = selectedMaximumTimeAllowed !== maximumTimeAllowed;

    if (isDeadlineChanged) {
      content["deadline"] = selectedDeadline;
    }

    if (isMaximumTimeAllowedChanged) {
      content["maximumTimeAllowed"] = selectedMaximumTimeAllowed;
    }

    messageThreadService
      .updateWorkRequest(projectToken, threadToken, content)
      .then(() => {
        const messages: string[] = [];
        isDeadlineChanged && messages.push(`Deadine extended to ${format(selectedDeadline, "E d MMM yyyy, h:mmaaa")}`);
        isMaximumTimeAllowedChanged &&
          messages.push(`\nDuration extended to ${formatTimeInHoursAndMinutes(selectedMaximumTimeAllowed)}`);

        setDeadline(selectedDeadline);
        setMaximumTimeAllowed(selectedMaximumTimeAllowed);

        toast.success({ message: messages.join("\n") });

        onSettingsChanged();
      })
      .finally(() => {
        setUpdateWorkRequestLoading(false);
      });
  };

  const isMessageReplied = useMemo(() => {
    return workRequest?.threadState?.description === ResponseStatusType.Replied;
  }, [workRequest]);

  return (
    <AccordionItem
      data-testid="message-settings"
      disabled={isMessageReplied}
      titleContent={
        <x.div {...titleContent}>
          <Typography variant="body-large-em">Message Settings</Typography>
          <x.div {...headerSlot} data-testid="header-slot">
            <Pill isInteractive={false} size="small">
              <FormattedDateTime date={deadline} format="d LLL yyyy, hh:mm" />
            </Pill>
            <Pill isInteractive={false} size="small">
              {formatTimeInHoursAndMinutes(maximumTimeAllowed)}
            </Pill>
          </x.div>
        </x.div>
      }
    >
      <x.div {...messageSettingsFields}>
        <div>
          <WorkRequestMaxDuration
            labelWithRequired
            allowOnlyIncrease
            showCallGuideOption={false}
            value={maximumTimeAllowed}
            onChange={setSelectedMaximumTimeAllowed}
          />
        </div>
        <div>
          <WorkRequestDeadline
            labelWithRequired
            value={deadline}
            disabled={isMessageReplied}
            onChange={setSelectedDeadline}
          />
        </div>
      </x.div>
      <Button
        data-testid="message-confirm-changes-button"
        onClick={onUpdateWorkRequest}
        loading={updateWorkRequestLoading}
        {...messageSettingsButton}
      >
        Confirm Changes
      </Button>
    </AccordionItem>
  );
};
