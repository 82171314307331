import React from "react";
import { RoundedButtonProps, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import * as S from "./FilterChip.styled";

export type FilterChipProps = {
  title: string;
  onClick: (event: React.MouseEvent) => void;
  appliedFilters: string[];
  getAppliedFiltersCountFormatter?: (props: { appliedFilters: string[] }) => string;
  testId?: string;
  open?: boolean;
} & RoundedButtonProps;

export const FilterChip = React.forwardRef<HTMLLIElement, FilterChipProps>(
  (
    {
      title,
      onClick,
      appliedFilters,
      getAppliedFiltersCountFormatter: getAppliedFiltersCountFormatterInput,
      testId,
      open = false,
      ...props
    },
    ref
  ) => {
    const {
      color: { text },
    } = useThemeTokens();
    const getAppliedFiltersCountFormatter =
      getAppliedFiltersCountFormatterInput ?? defaultGetAppliedFiltersCountFormatter;
    const filterCount = React.useMemo(() => getAppliedFiltersCountFormatter({ appliedFilters }), [
      appliedFilters,
      getAppliedFiltersCountFormatter,
    ]);

    return (
      <x.li ref={ref} data-testid={testId} listStyleType="none">
        <S.RoundedButton onClick={onClick} open={open} {...props}>
          <Typography whiteSpace="nowrap" variant="body-small" color="secondary">
            {title}
            {filterCount.length > 0 && <x.span color={text.info}>{` ${filterCount}`}</x.span>}
          </Typography>
        </S.RoundedButton>
      </x.li>
    );
  }
);

const defaultGetAppliedFiltersCountFormatter = ({ appliedFilters }: { appliedFilters: string[] }) => {
  if (appliedFilters.length > 0) {
    return `${appliedFilters.length}`;
  }

  return "";
};
