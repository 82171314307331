import React from "react";
import { IconButton } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useCustomerKnowledgeContext } from "providers/CustomerKnowledgeProvider";
import useCkFiltersStyles from "./CustomerKnowledgeFilters.style";
import { VendorFilter } from "./VendorFilter";
import { Close } from "@alphasights/alphadesign-icons";
import { FilterItem } from "components/InteractionsPage/FilterItem";
import { CustomerKnowledgeFilters as CustomerKnowledgeFiltersInterface } from "./ckFilterService";

interface FilterWithCustomComponent {
  name: string;
  component: JSX.Element;
}

interface CustomerKnowledgeFiltersProps {
  interactionsFilterOptions: {
    groups?: FilterOption[];
    date_added?: FilterOption[];
  };
}

const ClearAll = ({ resetFilters }: { resetFilters: () => void }) => (
  <IconButton variant="ghost" color="strong" onClick={resetFilters} size="small" testId="clear-all-filters">
    <Close />
  </IconButton>
);

export const CustomerKnowledgeFilters = ({ interactionsFilterOptions = {} }: CustomerKnowledgeFiltersProps) => {
  const { clearFilters, hasAppliedFilters, appliedFilters } = useCustomerKnowledgeContext();
  const styles = useCkFiltersStyles();

  const filterDefinitions = customerKnowledgeFilterDefinitions({
    interactionsFilterOptions,
    appliedFilters,
  });

  const { applyFilters }: { applyFilters: (filter: any) => void } = useCustomerKnowledgeContext();

  return (
    <x.div {...styles.filtersList}>
      {filterDefinitions.map((filter) => {
        return (
          <React.Fragment key={filter.name}>
            {(filter as FilterWithCustomComponent).component ? (
              (filter as FilterWithCustomComponent).component
            ) : (
              <FilterItem
                filterDefinition={filter as FilterDefinition}
                onApplyFilter={(values) =>
                  applyFilters({
                    [filter.name]: values,
                  })
                }
                testId={`filter-pill-${(filter as FilterDefinition).name}`}
              />
            )}
          </React.Fragment>
        );
      })}
      {hasAppliedFilters && <ClearAll resetFilters={clearFilters} />}
    </x.div>
  );
};

const customerKnowledgeFilterDefinitions = ({
  interactionsFilterOptions = {},
  appliedFilters,
}: {
  interactionsFilterOptions: {
    groups?: FilterOption[];
    date_added?: FilterOption[];
  };
  appliedFilters: CustomerKnowledgeFiltersInterface;
}): (FilterDefinition | FilterWithCustomComponent)[] => {
  const filterOptions = [];

  filterOptions.push({ name: "vendor", component: <VendorFilter /> });

  const roleOptions = {
    title: "Role",
    name: "role",
    options: [
      { value: "kdm", label: "KDM" },
      { value: "evaluation_team", label: "Evaluation Team" },
      { value: "user", label: "User" },
    ],
    value: appliedFilters.role ?? [],
  };
  filterOptions.push(roleOptions);

  const statusOptions = {
    title: "Status",
    name: "status",
    options: [
      { value: "proposed", label: "Available" },
      { value: "requested", label: "Requested" },
      { value: "scheduled", label: "Scheduled" },
      { value: "completed", label: "Completed" },
    ],
    value: appliedFilters.status ?? [],
  };
  filterOptions.push(statusOptions);

  const groupOption = {
    title: "Angle",
    name: "groups",
    options: interactionsFilterOptions.groups ?? [],
    value: appliedFilters.groups ?? [],
  };
  filterOptions.push(groupOption);

  const dateAddedOptions = {
    title: "Date Added",
    name: "date_added",
    options: interactionsFilterOptions.date_added ?? [],
    value: appliedFilters.date_added ?? [],
  };
  filterOptions.push(dateAddedOptions);

  return filterOptions;
};
