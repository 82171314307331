import React, { FC } from "react";
import { IconButton } from "@alphasights/alphadesign-components";
import { Delete } from "@alphasights/alphadesign-icons";

import { Cell } from "views/DeliverablesView/ThirdPartyDocUploadModal/FileTable/types";

type DeleteCellProps = Cell & {
  onClick: (id: number) => void;
};

const DeleteCell: FC<DeleteCellProps> = ({ row: { original: selectedFile }, onClick }) => {
  const handleClick = () => {
    onClick(selectedFile.id);
  };
  return (
    <IconButton variant="outline" size="small" onClick={handleClick}>
      <Delete />
    </IconButton>
  );
};

export default DeleteCell;
export type { DeleteCellProps };
