import React, { useMemo, useRef } from "react";
import { Avatar, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Expert } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { toMinutesAndSeconds } from "helpers/displayHelpers";
import { tagHighlight } from "./support";
import { EmphasisTypography } from "./EmphasisTypography";

const TranscriptCue = ({
  cue,
  onCueClick,
  currentTime = null,
  setActiveCues,
  highlightAiMatches,
  showPlayer = true,
}) => {
  const { cueActiveStyles, cueClickStyles } = useTranscriptSpeakerCueStyles();

  const { id, text, startTime = null, endTime = null, hasHighlight, aiMatch, start, end } = cue;

  const isActive = useMemo(() => {
    const anyNull = [startTime, currentTime, endTime].some((val) => val === null || val === false);

    if (anyNull) return false;

    return showPlayer && startTime <= currentTime && currentTime < endTime;
  }, [currentTime, showPlayer]); // eslint-disable-line react-hooks/exhaustive-deps

  const debugProps = useMemo(() => {
    if (document.location.search.includes("debug-transcript") || highlightAiMatches) {
      return {
        border: aiMatch ? "solid 1px red" : null,
        title: `${start}-${end}`,
      };
    }

    return {};
  }, [aiMatch, start, end, highlightAiMatches]);

  return (
    <EmphasisTypography
      key={id}
      component="span"
      {...(onCueClick && cueClickStyles)}
      {...(isActive && cueActiveStyles)}
      {...(isActive && { "data-transcript-active": true })}
      onClick={onCueClick ? () => onCueClick(cue) : null}
      data-transcript-id={id}
      {...debugProps}
    >
      {hasHighlight ? tagHighlight(text) : text}
    </EmphasisTypography>
  );
};

const SpeakerAndTimestamp = ({ speakerName, seconds }) => {
  const { speakerStyles } = useTranscriptSpeakerCueStyles();
  return (
    <x.div {...speakerStyles}>
      <Typography variant="body-large-em">{speakerName}</Typography>
      {typeof seconds === "number" && (
        <Typography color="secondary" data-testid="cue-seconds">
          {toMinutesAndSeconds(seconds)}
        </Typography>
      )}
    </x.div>
  );
};

export const TranscriptSpeakerCue = ({
  speakerName,
  seconds,
  cues,
  avatarColors,
  currentTime,
  onCueClick,
  setActiveCues,
  highlightAiMatches,
  isMobile,
  showPlayer,
}) => {
  const { wrapperStyles, avatarStyles, contentStyles } = useTranscriptSpeakerCueStyles({ isMobile });

  const cuesRef = useRef(null);

  return (
    <>
      <x.div {...wrapperStyles} className={"transcript-speaker-cue"}>
        <x.div display={"flex"}>
          <Avatar
            size="small"
            color={avatarColors.find(({ speaker }) => speaker === speakerName).color}
            {...avatarStyles}
          >
            <Expert />
          </Avatar>
          {isMobile && <SpeakerAndTimestamp speakerName={speakerName} seconds={seconds} />}
        </x.div>
        <x.div ref={cuesRef} {...contentStyles}>
          {!isMobile && <SpeakerAndTimestamp speakerName={speakerName} seconds={seconds} />}
          {cues.map((cue) => {
            return (
              <TranscriptCue
                key={cue.id}
                cue={cue}
                currentTime={currentTime}
                onCueClick={onCueClick}
                setActiveCues={setActiveCues}
                highlightAiMatches={highlightAiMatches}
                showPlayer={showPlayer}
              />
            );
          })}
        </x.div>
      </x.div>
    </>
  );
};

const useTranscriptSpeakerCueStyles = ({ isMobile } = {}) => {
  const tokens = useThemeTokens();

  return {
    wrapperStyles: {
      display: "flex",
      flexDirection: isMobile ? "column" : "row",
      gap: isMobile ? tokens.spacing.inner.base02 : undefined,
    },
    avatarStyles: {
      mr: isMobile ? tokens.spacing.inner.base02 : tokens.spacing.inner.base06,
      flexShrink: 0,
    },
    contentStyles: {
      flex: 1,
    },
    speakerStyles: {
      gap: tokens.spacing.inner.base,
      display: "flex",
      alignItems: "baseline",
    },
    cueActiveStyles: {
      backgroundColor: "rgba(242, 208, 36, 0.2)",
    },
    cueClickStyles: {
      cursor: "pointer",
      backgroundColor: {
        hover: tokens.color.background.neutral.default,
      },
    },
  };
};
