import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import {
  addDays,
  addHours,
  addSeconds,
  differenceInMinutes,
  eachMinuteOfInterval,
  getHours,
  getMinutes,
  isBefore,
  max,
  min,
  startOfDay,
  startOfHour,
} from "date-fns";
import { createPortal } from "react-dom";
import { usePopper } from "react-popper";
import { useTimezone } from "providers/TimezoneProvider";
import { Button, TextField, Portal, useThemeTokens, useAlphaToast } from "@alphasights/alphadesign-components";
import { OneHourMinimumAlert } from "components/OneHourMinimumAlert";
import { x, createGlobalStyle } from "@xstyled/styled-components";
import { calculateOptionsEnd, interactionMinDuration } from "./AvailabilityPopup";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { requestChangeInteraction, loadAdvisorInteractions } from "components/InteractionsPage/reducer";
import {
  CalendarPopup,
  CalendarPopupFooter,
  ExpertData,
  PopupDataAreaLeftPad,
  PopupMenus,
  ScheduledCallTime,
} from "./PopupRenderers/common";
import { Clock } from "@alphasights/alphadesign-icons";
import { SelectDateTime } from "./PopupRenderers/SelectDateTime";
import { useReschedulingGhostEventStyles } from "./ReschedulingGhostEvent.styles";
import { dateIntervalContains, onGracePeriod } from "pages/InteractionPage/utils";

export const ReschedulePopup = ({
  interaction,
  onClose,
  requestedTime,
  setRequestedTime,
  duration,
  setDuration,
  onReschedulingDone,
  onCancel,
  advisorAvailability = [],
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const tz = useTimezone();

  const start = max([addHours(startOfHour(new Date()), 1), tz.zonedMidnightToUtc(requestedTime)]);
  const optionsStart = eachMinuteOfInterval(
    {
      start,
      end: tz.zonedMidnightToUtc(addDays(requestedTime, 1)),
    },
    { step: 15 }
  ).map((date) => ({ value: date, label: tz.format(date, "h:mmaaa") }));

  const optionsEnd = calculateOptionsEnd({
    selectedStart: requestedTime,
    minDuration: interactionMinDuration(interaction),
    tz,
    currentDuration: interaction.expectedDuration,
  });

  const dispatch = useContext(DispatchContext);
  const { toast } = useAlphaToast();
  const onSubmitReschedule = useCallback(
    (e) => {
      e.preventDefault();

      const reason = new FormData(e.target).get("reason");

      dispatch(
        requestChangeInteraction({
          id: interaction.id,
          reason: reason,
          type: "RESCHEDULE_REQUEST",
          payload: {
            requestedTime: requestedTime.toISOString(),
            duration: duration,
            timezone: tz.currentTimezone,
          },
        })
      ).then((reschedulingRequest) => {
        if (!reschedulingRequest) {
          dispatch(
            loadAdvisorInteractions({
              interactionId: interaction.id,
              advisorId: interaction.advisorId,
              projectToken: interaction.projectToken,
            })
          );
          toast.success({
            message: `You have rescheduled this interaction for ${tz.format(requestedTime, "EEEE d LLL")}.`,
          });
        }
        onReschedulingDone(reschedulingRequest);
      });
    },
    [
      dispatch,
      interaction.id,
      requestedTime,
      duration,
      onReschedulingDone,
      tz,
      interaction.advisorId,
      interaction.projectToken,
      toast,
    ]
  );

  const isOnGracePeriod = onGracePeriod(interaction);
  const instantRescheduleEnabled =
    isOnGracePeriod &&
    advisorAvailability.find((avail) => dateIntervalContains(avail.startsAt, avail.endsAt, requestedTime));

  return (
    <CalendarPopup id={`reschedule-popup-${interaction.id}`}>
      <PopupMenus advisorName={interaction.advisorName} onClose={onClose} />
      <ExpertData interaction={interaction} />
      <ScheduledCallTime
        label="Scheduled interaction time"
        addMarginTop={true}
        date={interaction.scheduledCallTime}
        duration={interaction.expectedDuration || 3600}
      />

      <SelectDateTime
        icon={<Clock />}
        addMarginTop={true}
        optionsStart={optionsStart}
        optionsEnd={optionsEnd}
        selectedStart={requestedTime}
        setSelectedStart={setRequestedTime}
        duration={duration}
        setDuration={setDuration}
        label="Proposed interaction time"
        selectableDate={true}
      />

      <form onSubmit={onSubmitReschedule}>
        <PopupDataAreaLeftPad mt={inner.base02} paddingLeft={inner.base06} paddingRight={inner.base04}>
          <TextField
            name="reason"
            size="small"
            placeholder="Tell us why you want to reschedule..."
            data-testid="reschedule-reason-input"
          />
          {duration < 3600 && interaction.oneHourMinimum && <OneHourMinimumAlert />}
        </PopupDataAreaLeftPad>

        <CalendarPopupFooter>
          <Button data-testid="request-reschedule-button" variant="secondary" size="small">
            {instantRescheduleEnabled ? "Reschedule" : "Request to Reschedule"}
          </Button>
          <Button variant="ghost" type="button" onClick={onCancel} size="small">
            Cancel
          </Button>
        </CalendarPopupFooter>
      </form>
    </CalendarPopup>
  );
};

const SetPointerCursor = createGlobalStyle`
  * {
    cursor: pointer !important;
  }
`;

const HoveringGhostEvent = ({ onSelectedDate, duration }) => {
  const [hoveringDate, setHoveringDate] = useState();
  const [pointerEvents, setPointerEvents] = useState("initial");

  const tz = useTimezone();

  useEffect(() => {
    let lastTimeslot;

    const mouseMoveListener = (e) => {
      const els = document.elementsFromPoint(e.clientX, e.clientY);
      if (!els.some((el) => el.classList.contains("rbc-day-slot"))) {
        setHoveringDate(null);
        return;
      }

      const overElements = els.find((el) => (el.id || "").startsWith("reschedule-popup") || el.role === "option");

      const slotClasses = ["rbc-time-slot", "rbc-timeslot-group"];
      const mapClasses = slotClasses.reduce(
        (acc, val) => ({
          ...acc,
          [val]: els.find((el) => el.classList.contains(val)),
        }),
        {}
      );
      const timeslot = mapClasses[slotClasses[0]] || mapClasses[slotClasses[1]].lastChild;

      if (!timeslot || overElements || !timeslot.closest(".rbc-day-slot")) {
        setHoveringDate(null);
        return;
      }

      if (lastTimeslot !== timeslot) {
        setHoveringDate(new Date(parseInt(timeslot.dataset.date)));
        lastTimeslot = timeslot;
      }
    };

    document.addEventListener("mousemove", mouseMoveListener);

    const mouseDownListener = () => {
      setPointerEvents("initial");
    };

    document.addEventListener("mousedown", mouseDownListener);

    return () => {
      document.removeEventListener("mousemove", mouseMoveListener);
      document.removeEventListener("mousedown", mouseDownListener);
    };
  }, []);

  const selectedEventContainer = useMemo(() => {
    if (!hoveringDate) return null;

    const eventsContainer = document
      .querySelector(`.rbc-day-slot [data-date='${hoveringDate.getTime()}']`)
      .parentElement.parentElement.querySelector(".rbc-events-container");

    return eventsContainer;
  }, [hoveringDate]);

  const [hoverBounds, roundedBorderBottom] = useMemo(() => {
    if (!selectedEventContainer) return [null];
    if (isBefore(tz.zonedTimeToUtc(hoveringDate), addHours(new Date(), 1))) return [null];

    const pixelsPerMinute = selectedEventContainer.getBoundingClientRect().height / (24 * 60);

    const minutes = getHours(hoveringDate) * 60 + getMinutes(hoveringDate);

    const midnight = addHours(startOfDay(hoveringDate), 24);

    const end = addSeconds(hoveringDate, duration);

    const preventOverflowEnd = min([midnight, end]);

    return [
      {
        top: minutes * pixelsPerMinute + "px",
        height: differenceInMinutes(preventOverflowEnd, hoveringDate) * pixelsPerMinute + "px",
        zIndex: 40,
        width: "100%",
        borderRadius: "5px",
      },
      end.getTime() <= midnight.getTime(),
    ];
  }, [selectedEventContainer, hoveringDate, duration, tz]);

  return (
    hoverBounds && (
      <>
        <SetPointerCursor />
        <Portal containerEl={selectedEventContainer}>
          <x.div
            className="aui-absolute aui-cursor-pointer"
            style={hoverBounds}
            onWheel={() => setPointerEvents("none")}
            onMouseUp={() => onSelectedDate(hoveringDate)}
            data-testid="rescheduling-ghost-event"
            pointerEvents={pointerEvents}
          >
            <x.div
              className="aui-bg-grey-2"
              h="100%"
              borderRadius={"5px 5px " + (roundedBorderBottom ? "5px 5px" : "0px 0px")}
              pointerEvents={pointerEvents}
            />
          </x.div>
        </Portal>
      </>
    )
  );
};

export const ReschedulingGhostEvent = ({
  interaction,
  onReschedulingDone,
  onCancel: onCancelInput,
  currentDate,
  advisorAvailability,
  onChangeDate,
}) => {
  const { popupDiv } = useReschedulingGhostEventStyles();
  const [referenceElement, setReferenceElement] = useState();
  const [popperElement, setPopperElement] = useState();
  const [isShowingPopup, setIsShowingPopup] = useState(false);
  const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
    placement: "left-start",
  });
  const tz = useTimezone();

  const [requestedTime, setRequestedTime] = useState();
  const [duration, setDuration] = useState(interaction.expectedDuration || 3600);

  useEffect(() => {
    update && update();
    setTimeout(() => {
      referenceElement &&
        referenceElement.scrollIntoView({
          block: "end",
          inline: "nearest",
          behavior: "smooth",
        });
    }, 50);
  }, [currentDate, referenceElement, update]);

  const selectedEventContainer =
    requestedTime &&
    document
      .querySelector(`.rbc-day-slot [data-date='${tz.parseDateZoned(requestedTime).getTime()}']`)
      ?.parentElement.parentElement.querySelector(".rbc-events-container");

  const [selectedBounds, roundedBorderBottom] = useMemo(() => {
    if (!selectedEventContainer) return [null];

    const pixelsPerMinute = selectedEventContainer.getBoundingClientRect().height / (24 * 60);

    const requestedTimeZoned = tz.parseDateZoned(requestedTime);

    const minutes = getHours(requestedTimeZoned) * 60 + getMinutes(requestedTimeZoned);

    const midnight = addHours(startOfDay(requestedTimeZoned), 24);

    const end = addSeconds(requestedTimeZoned, duration);

    const preventOverflowEnd = min([midnight, end]);

    return [
      {
        top: minutes * pixelsPerMinute + "px",
        height: differenceInMinutes(preventOverflowEnd, requestedTimeZoned) * pixelsPerMinute + "px",
        zIndex: 40,
        width: "100%",
      },
      end.getTime() <= midnight.getTime(),
    ];
  }, [selectedEventContainer, requestedTime, duration, tz]);

  const onSelectDate = (date) => {
    setIsShowingPopup(true);
    setRequestedTime(tz.zonedTimeToUtc(date));
    update && update();
  };

  const onPopupClose = () => {
    setIsShowingPopup(false);
    setRequestedTime(null);
  };

  const onCancel = () => {
    onPopupClose();
    onCancelInput();
  };

  return createPortal(
    <>
      <HoveringGhostEvent onSelectedDate={onSelectDate} duration={duration} />
      {isShowingPopup && (
        <x.div {...popupDiv}>
          <div ref={setPopperElement} style={{ ...styles.popper }} {...attributes.popper}>
            <ReschedulePopup
              interaction={interaction}
              setRequestedTime={(value) => {
                setRequestedTime(value);
                onChangeDate(value);
              }}
              requestedTime={requestedTime}
              setDuration={setDuration}
              duration={duration}
              onClose={onPopupClose}
              onReschedulingDone={onReschedulingDone}
              onCancel={onCancel}
              advisorAvailability={advisorAvailability}
            />
          </div>
        </x.div>
      )}
      {requestedTime &&
        selectedEventContainer &&
        createPortal(
          <x.div
            ref={setReferenceElement}
            className="aui-absolute aui-bg-grey-2 aui-cursor-pointer"
            style={selectedBounds}
            data-testid="rescheduling-event"
            borderRadius={"5px 5px " + (roundedBorderBottom ? "5px 5px" : "0px 0px")}
          />,
          selectedEventContainer
        )}
    </>,
    document.querySelector("body")
  );
};
