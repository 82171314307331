import React from "react";
import { x } from "@xstyled/styled-components";
import { Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import useNewsletterPreferences from "pages/AlphaNowPage/hooks/useNewsletterPreferences";
import { useParams } from "router-utils";

const DataTestIds = {
  UnsubscribeButton: "unsubscribe-button",
  ResubscribeButton: "resubscribe-button",
};

const NewsletterPreferencesPage = () => {
  const { trackingId } = useParams();
  const updateSubscription = true;

  const { color, spacing } = useThemeTokens();
  const { isSubscribed, error, updateNewsletterPreferences } = useNewsletterPreferences(trackingId, updateSubscription);

  const shouldRenderContents = isSubscribed !== undefined && !error;

  if (shouldRenderContents) {
    return (
      <x.div mt={spacing.inner.base10} ml={spacing.inner.base08} spaceY={spacing.inner.base04}>
        <x.div spaceY={spacing.inner.base02}>
          <Typography variant="h3" textTransform="" color={color.text.strong._} mb={spacing.inner.base02}>
            {`You have been successfully ${isSubscribed ? "subscribed to" : "unsubscribed from"} AlphaNow Newsletters.`}
          </Typography>
          {!isSubscribed && (
            <Typography variant="body-large" color={color.text.secondary}>
              Click below to receive highlights from newly published content on AlphaNow.
            </Typography>
          )}
        </x.div>
        <Button
          data-testid={isSubscribed ? DataTestIds.UnsubscribeButton : DataTestIds.ResubscribeButton}
          variant={isSubscribed ? "outline" : "secondary"}
          onClick={() => updateNewsletterPreferences(!isSubscribed)}
        >
          {isSubscribed ? "Unsubscribe" : "Resubscribe"}
        </Button>
      </x.div>
    );
  }

  return <></>;
};

export { NewsletterPreferencesPage as default, DataTestIds };
