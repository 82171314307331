import React, { FC, useCallback, useEffect, useState } from "react";
import { Divider } from "@alphasights/alphadesign-components";

import { useQuery } from "query-utils";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { ThirdPartyInteraction } from "types";
import { DEFAULT_SUMMARY } from "./PageHeader/DocumentSummaryTab/consts";
import DocumentSummaryTab from "./PageHeader/DocumentSummaryTab";
import DocumentQuestionTab from "./PageHeader/DocumentQuestionsTab";
import { DocumentSummary } from "./PageHeader/DocumentSummaryTab/types";
import { DocumentQuestion } from "./PageHeader/DocumentQuestionsTab/types";
import PageHeader from "./PageHeader";
import DocumentViewer from "./DocumentViewer";
import { DOCUMENT_QUESTIONS_KEY, DOCUMENT_SUMMARY_KEY, ViewType } from "./consts";
import { QuestionProcessionStatus } from "./PageHeader/DocumentQuestionsTab/consts";

import * as S from "./ThirdPartyDocumentPage.styled";

type ThirdPartyDocumentPageProps = {
  interaction: ThirdPartyInteraction;
};

const DataTestIds = {
  tabContent: "tab-content-wrapper",
};

const ThirdPartyDocumentPage: FC<ThirdPartyDocumentPageProps> = ({ interaction }) => {
  const [selectedView, setSelectedView] = useState(ViewType.Transcript);
  const [searchTargetEl, setSearchTargetEl] = useState<HTMLElement | null>(null);
  const [file, setFile] = useState<Blob>();
  const [isDocumentLoading, setIsDocumentLoading] = useState<boolean>(false);
  const [pollingInterval, setPollingInterval] = useState<number>();

  const { fetchDocumentSummary, fetchDocumentQuestions, fetchFileAsPdf } = useDeliverableContext();

  const documentId = interaction.id;

  const { data: documentQuestions = [], status: documentQuestionsStatus } = useQuery<DocumentQuestion[]>(
    [DOCUMENT_QUESTIONS_KEY, documentId],
    () => fetchDocumentQuestions(documentId),
    {
      refetchInterval: pollingInterval,
    }
  );

  const { data: documentSummary = DEFAULT_SUMMARY, status: documentSummaryStatus } = useQuery<DocumentSummary>(
    [DOCUMENT_SUMMARY_KEY, documentId],
    () => fetchDocumentSummary(documentId)
  );

  const readDocument = useCallback(async () => {
    try {
      const response = await fetchFileAsPdf(interaction.id);

      const fileStream = response.body;
      const reader = fileStream.getReader();
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
      }

      const blob = new Blob(chunks, { type: response.headers.get("Content-Type") });
      setFile(blob);
    } catch (error) {
      console.error("Error fetching document", error);
    } finally {
      setIsDocumentLoading(false);
    }
  }, [fetchFileAsPdf, setFile, setIsDocumentLoading, interaction.id]);

  useEffect(() => {
    setIsDocumentLoading(true);
    readDocument();
  }, [interaction, readDocument, setIsDocumentLoading]);

  useEffect(() => {
    const hasPendingQuestions = documentQuestions.some((q) => q.status === QuestionProcessionStatus.pending);
    if (hasPendingQuestions) {
      setPollingInterval(10000);
    } else {
      setPollingInterval(undefined);
    }
  }, [documentQuestions]);

  return (
    <S.Wrapper>
      <PageHeader
        interaction={interaction}
        searchTargetEl={searchTargetEl}
        selectedView={selectedView}
        onChangeView={setSelectedView}
      />
      <Divider />
      <S.MainContentContainer data-testid={DataTestIds.tabContent}>
        {selectedView === ViewType.Summary && (
          <S.TabContainer data-summary-scrollable-area>
            <DocumentSummaryTab
              summary={documentSummary}
              setSearchTargetEl={setSearchTargetEl}
              interaction={interaction}
              documentSummaryStatus={documentSummaryStatus}
            />
          </S.TabContainer>
        )}
        {selectedView === ViewType.Questions && (
          <S.TabContainer data-questions-scrollable-area>
            <DocumentQuestionTab
              questions={documentQuestions}
              setSearchTargetEl={setSearchTargetEl}
              interaction={interaction}
              documentQuestionsStatus={documentQuestionsStatus}
            />
          </S.TabContainer>
        )}
        {selectedView === ViewType.Transcript && (
          <S.TabContainer data-transcript-scrollable-area>
            <DocumentViewer data={file} isLoading={isDocumentLoading} setIsLoading={setIsDocumentLoading} />
          </S.TabContainer>
        )}
      </S.MainContentContainer>
    </S.Wrapper>
  );
};

export default ThirdPartyDocumentPage;
