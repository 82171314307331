import React, { useCallback, useState } from "react";
import { x } from "@xstyled/styled-components";
import { TabItem, Tabs, useThemeTokens } from "@alphasights/alphadesign-components";
import { Expert, Mail, Transcript } from "@alphasights/alphadesign-icons";
import { useLocation, useNavigate } from "router-utils";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";

export const MobileNavBar = ({ project, isMessagesViewEnabled, isDeliverablesViewEnabled }) => {
  const { color } = useThemeTokens();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const { openLoginModal } = useClientPortalOperations();
  const routeIndexes = [
    {
      basePath: "/experts/list-view",
      label: "Experts",
      icon: <Expert />,
      protected: false,
    },
    isMessagesViewEnabled && {
      basePath: "/experts/messages-view",
      label: "Messages",
      icon: <Mail />,
      protected: true,
    },
    isDeliverablesViewEnabled && {
      basePath: "/experts/deliverables-view",
      label: "Deliverables",
      icon: <Transcript />,
      protected: true,
    },
  ].filter((a) => a);

  const [index, setIndex] = useState(routeIndexes.findIndex((p) => location.pathname.includes(p.basePath)));

  const onClickRoute = useCallback(
    (ix) => {
      const option = routeIndexes[ix];
      if (!user && option?.protected) openLoginModal({ allowAnonymousContinue: true });
      else {
        setIndex(ix);
        const expectedRoute = option?.basePath;
        const pathname = location.pathname;
        if (expectedRoute && !pathname.includes(expectedRoute)) {
          navigate(`/${project.token}${expectedRoute}`);
        }
      }
    },
    [routeIndexes, openLoginModal, location, navigate, user, project.token]
  );

  if (routeIndexes.length < 2) {
    return null;
  }

  return (
    <x.div data-testid="mobile-navbar" bg={color.background.surface.page.default} position="sticky" bottom={0}>
      <x.div id="navbar-companion" />
      <Tabs variant="tabBar" onChange={onClickRoute} index={index}>
        {routeIndexes.map((route) => (
          <TabItem
            key={route.label}
            topIcon={route.icon}
            label={route.label}
            dataAttributes={{ "data-testid": `mobile-navtab-${route.label}` }}
          />
        ))}
      </Tabs>
    </x.div>
  );
};
