import { Popover } from "@alphasights/alphadesign-components";
import { ProjectLeadAvatar } from "@alphasights/client-portal-shared";
import styled, { th } from "@xstyled/styled-components";

export const Link = styled.a`
  flex-grow: 1;
  color: inherit;
  text-decoration: none;
   &:hover {
      background-color: transparent !important;
  
`;

export const PopoverContainer = styled.div`
  div[type="title"] {
    padding-bottom: ${th.space("inner-base")};
  }
`;

export const CopiedPopover = styled(Popover)`
  background-color: ${th.color("background-dark")};
  color: ${th.color("text-inverse")};
  padding: ${th.space("inner-base")};
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${th.space("inner-base")};
  align-items: center;
  transition: background-color 0.3s ease;
  border-radius: ${th.space("inner-base02")};

  &:hover {
    background-color: ${th.color("background-neutral-hover")};
  }

  .aui-flex-grow {
    flex-grow: 1;
  }

  button {
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover button {
    opacity: 1;
  }
`;

export const CustomProjectLeadAvatarTop = styled(ProjectLeadAvatar)`
  width: ${th.space("layout-base05")};
  height: ${th.space("layout-base05")};
`;

export const CustomProjectLeadAvatar = styled(ProjectLeadAvatar)`
  display: flex;
  width: ${th.space("layout-base03")};
  height: ${th.space("layout-base03")};
`;
