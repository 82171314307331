import { Button, Icon, Portal, TabItem, Tabs, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { groupBy } from "lodash";
import { useDeliverableContext } from "providers/DeliverableProvider";
import React, { useEffect, useState } from "react";
import { useLocation } from "router-utils";
import { WhatIsAPrimer } from "views/DeliverablesView/PrimerDetails";
import {
  ContentPurchaseCTA,
  RequestExpertsButton,
  TypographyEllipsis,
  TypographyNoWrap,
} from "./MobileContentCommons.component";
import { useMobileDeliverablesStyles } from "./MobileDeliverablesView.styles";

import { Expert } from "@alphasights/alphadesign-icons";
import { HighlightHtmlProvider } from "providers/HighlightHtmlProvider";
import { InsightsNav } from "./MobilePrimer/InsightsNav.component";
import {
  CompanyPrimerContentFlexible,
  PrimerCompetitorsDynamics,
  PrimerCustomerDynamics,
  PrimerOverview,
  PrimerStrategicOutlook,
} from "./MobilePrimer/MobilePrimerSections.component";
import { PrimerContributingExperts } from "./MobilePrimer/PrimerContributingExperts.component";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { ContentSuggestion } from "views/DeliverablesView/types";
import PitchedDetails from "components/PitchedDetails";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import useContentVisit from "hooks/useContentVisit";

export const PrimerRenderer = ({
  content,
  freeSamples,
  updateContent,
  topBarButtonAreaRef,
}: {
  content: ContentSuggestion;
  freeSamples: any[];
  updateContent: (id: string, updates: any) => void;
  topBarButtonAreaRef: any;
}) => {
  const { renderedWrapper } = useMobileDeliverablesStyles();
  const isAccessible =
    content.purchaseStatus &&
    content.approvalStatus &&
    isContentAccessible(content.purchaseStatus, content.approvalStatus);
  return (
    <x.div {...renderedWrapper}>
      {!isAccessible && <PrimerPreview primer={content} freeSamples={freeSamples} updateContent={updateContent} />}
      {isAccessible && (
        <HighlightHtmlProvider highlightCriteria={{ dataField: "contributor-ids", divider: ";" }}>
          <PrimerView content={content} topBarButtonAreaRef={topBarButtonAreaRef} />
        </HighlightHtmlProvider>
      )}
    </x.div>
  );
};

const PrimerPreview = ({
  primer,
  freeSamples,
  updateContent,
}: {
  primer: ContentSuggestion;
  freeSamples: any;
  updateContent: (id: string, updates: any) => void;
}) => {
  const angles = groupBy(primer.speakers, (s) => s.angleTypes[0]?.name || "Others");
  const location = useLocation();
  const freeLink =
    freeSamples["COMPANY_PRIMER"] && `${location.pathname}?selectedContent=${freeSamples["COMPANY_PRIMER"]}`;
  const { stickyAtTheBottom, expertGroup, expertGroups, contentWrapper } = useMobileDeliverablesStyles();

  const { color, spacing } = useThemeTokens();
  const { project } = useCurrentProjectContext();

  const { recommendationId, id, pitchedAt, speakers } = primer;

  useContentVisit({ recommendationId, contentId: id });

  return (
    <>
      <x.div {...contentWrapper}>
        {pitchedAt && (
          <x.div alignSelf="flex-end">
            <PitchedDetails
              pitchedAt={pitchedAt}
              pitchedBy={project?.lead}
              hasTooltip
              hasDescription
              stylingProps={{ marginLeft: spacing.inner.base }}
            />
          </x.div>
        )}
        <WhatIsAPrimer mobile={true} />
        {freeLink && (
          <Button
            size="small"
            variant="outline"
            w="100%"
            onClick={() => window.open(freeLink)}
            dataAttributes={{ "data-testid": "free-primer-btn" }}
          >
            View a free Primer
          </Button>
        )}
        <x.div {...expertGroups}>
          <Typography variant="body-small-em" color={color.text.assistive}>
            Featured Experts
          </Typography>
          {Object.keys(angles).map((angle) => (
            <x.div {...expertGroup}>
              <Typography variant="body-small-em" color={color.text.strong._}>
                {angle}
              </Typography>
              {angles[angle].map((speaker) => (
                <x.div display={"flex"} w={"100%"} columnGap={spacing.inner.base02}>
                  <TypographyNoWrap variant="body-small" color={color.text.strong._}>
                    {speaker.company}
                  </TypographyNoWrap>
                  <TypographyEllipsis variant="body-small" color={color.text.secondary}>
                    {speaker.jobTitle}
                  </TypographyEllipsis>
                </x.div>
              ))}
            </x.div>
          ))}
        </x.div>
        <RequestExpertsButton speakers={speakers} />
      </x.div>
      <x.div {...stickyAtTheBottom}>
        <ContentPurchaseCTA content={primer} updateContent={updateContent} />
      </x.div>
    </>
  );
};

const PrimerView = ({ content, topBarButtonAreaRef }: { content: ContentSuggestion; topBarButtonAreaRef: any }) => {
  const [primer, setPrimer] = useState<CompanyPrimerContentFlexible>();
  const [tab, setTab] = useState(0);
  const [showExpertsView, setShowExpertsView] = useState(false);
  const [selectedExpert, setSelectedExpert] = useState<Speaker>();
  const { fetchAlphaNowContentById, project } = useDeliverableContext();
  const { logHit } = useTrackUserAction();

  useEffect(() => {
    fetchAlphaNowContentById({ id: content.id }).then(setPrimer);
  }, [content.id, fetchAlphaNowContentById]);

  const { color, spacing } = useThemeTokens();
  // Brilliantly, "Tabs" works with index only, so we need to get creative to handle an optional mid-tab
  const visibleTabs = [
    "Overview",
    "Competitor Dynamics",
    primer?.customerDynamics && "Customer Dynamics",
    "Strategic Outlook",
  ].filter((a) => a);

  return primer ? (
    <x.div fontSize="smaller" color={color.text.strong._}>
      <x.div overflowX={"scroll"} marginBottom={spacing.inner.base02} data-testid="primer-tabs">
        <Portal containerEl={topBarButtonAreaRef.current}>
          <Button
            variant={"ghost"}
            onClick={selectedExpert ? () => setSelectedExpert(undefined) : () => setShowExpertsView(true)}
            h="auto"
            dataAttributes={{
              "data-testid": "mobile-top-bar-toggle-experts-btn",
              "data-active": `${!!selectedExpert}`,
            }}
          >
            <x.div display="flex">
              <Icon active={!!selectedExpert}>
                <Expert />
              </Icon>
            </x.div>
          </Button>
        </Portal>
        <Tabs
          onChange={setTab}
          index={tab}
          size="small"
          w="fit-content"
          minWidth="100%"
          paddingLeft={spacing.inner.base02}
          paddingRight={spacing.inner.base02}
          justifyContent={"space-between"}
        >
          {visibleTabs.map((label) => (
            <TabItem label={<x.div whiteSpace={"nowrap"}>{label}</x.div>} />
          ))}
        </Tabs>
      </x.div>
      <x.div padding={spacing.inner.base04}>
        <x.div
          data-tab-index={visibleTabs.indexOf("Overview")}
          display={tab === visibleTabs.indexOf("Overview") ? "block" : "none"}
        >
          <PrimerOverview primer={primer!} />
        </x.div>
        <x.div
          data-tab-index={visibleTabs.indexOf("Competitor Dynamics")}
          display={tab === visibleTabs.indexOf("Competitor Dynamics") ? "block" : "none"}
        >
          <PrimerCompetitorsDynamics primer={primer} />
        </x.div>
        <x.div
          data-tab-index={visibleTabs.indexOf("Customer Dynamics")}
          display={tab === visibleTabs.indexOf("Customer Dynamics") ? "block" : "none"}
        >
          <PrimerCustomerDynamics primer={primer} />
        </x.div>
        <x.div
          data-tab-index={visibleTabs.indexOf("Strategic Outlook")}
          display={tab === visibleTabs.indexOf("Strategic Outlook") ? "block" : "none"}
        >
          <PrimerStrategicOutlook primer={primer} />
        </x.div>
      </x.div>
      {showExpertsView && (
        <PrimerContributingExperts
          primer={primer}
          onClose={() => setShowExpertsView(false)}
          onSelectExpert={(speaker) => {
            setSelectedExpert(speaker);
            setShowExpertsView(false);
            logHit({
              origin: HitOrigin.deliverablesView,
              action: HitAction.alphaNowViewCompanyPrimerExpertCitations,
              projectToken: project.token,
              details: {
                expertId: speaker.id,
                contentId: content.id,
              },
            });
          }}
          selectedExpert={selectedExpert}
        />
      )}
      <InsightsNav
        speakers={primer.speakers}
        currentContributor={selectedExpert}
        setCurrentContributor={setSelectedExpert}
        onClose={() => setSelectedExpert(undefined)}
        showTab={setTab}
      />
    </x.div>
  ) : null;
};
