import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

export const Wrapper = styled(x.div)(() => {
  const { color, spacing } = useThemeTokens();

  return `
    padding: ${spacing.layout.base03};
    textarea:disabled {
      background-color: white;
      color: ${color.text.assistive};
    }
  `;
});
