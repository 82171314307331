import React, { useEffect, useMemo, useRef } from "react";
import { x } from "@xstyled/styled-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { useStyles } from "./SynthesisModuleContent.styles";
import { Mode } from "providers/ProjectSynthesisProvider.types";
import { useHideDeliverablesContent } from "views/DeliverablesView/DeliverablesPage/useHideDeliverablesContent";
import { KpcModuleContent } from "./KpcModuleContent";
import { VendorModuleContent } from "./VendorModuleContent";
import { QuestionModuleContent } from "./QuestionModuleContent";
import { NewSynthesisModuleContent } from "./NewSynthesisModuleContent";
import { SynthesisModule } from "@alphasights/portal-api-client";

export const SynthesisModuleContent = () => {
  const { selectedModule, revision, selectedRevisionIdx, mode } = useProjectSynthesisContext();
  const { moduleWrapper } = useStyles({
    isEditing: mode === Mode.EDIT,
  });
  const { contentStyle } = useHideDeliverablesContent();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const selectedModuleId = useMemo(() => selectedModule?.id, [selectedModule]);

  useEffect(() => {
    const position = parseInt(sessionStorage.getItem("synthesisPosition") ?? "0");
    sessionStorage.removeItem("synthesisPosition");

    wrapperRef.current?.scrollTo(0, position);
  }, [selectedModuleId, selectedRevisionIdx]);

  if (!revision || !selectedModule) return <x.div {...moduleWrapper} data-testid="empty-module-content"></x.div>;

  const handleSelectionCopy = (ev: React.ClipboardEvent<HTMLDivElement>) => {
    ev.preventDefault();

    const selectedText = window.getSelection();
    if (ev.clipboardData && selectedText) {
      const formattedFragment = formatHtmlSelection(selectedText);
      const div = document.createElement("div");
      div.appendChild(formattedFragment);

      const cleanContent = div.innerHTML;
      ev.clipboardData.setData("text/html", cleanContent);
    }
  };

  return (
    <x.div
      ref={wrapperRef}
      {...moduleWrapper}
      {...contentStyle}
      data-testid="synthesis-module-content"
      data-synthesis-scrollable-area
      onCopy={handleSelectionCopy}
    >
      <ModuleContentSwitch mode={mode} selectedModule={selectedModule} />
    </x.div>
  );
};

const ModuleContentSwitch = ({ mode, selectedModule }: { mode: Mode; selectedModule: SynthesisModule }) => {
  if (mode === Mode.NEW) return <NewSynthesisModuleContent />;

  if (selectedModule.contentType === "KPC") return <KpcModuleContent />;
  if (selectedModule.contentType === "VENDORS") return <VendorModuleContent />;

  return <QuestionModuleContent />;
};

const formatHtmlSelection = (selectedText: Selection): DocumentFragment => {
  const clonedSelection = selectedText.getRangeAt(0).cloneContents();

  const spaceAfter = (query: string) =>
    clonedSelection.querySelectorAll(query).forEach((el) => el?.after(document.createElement("br")));

  const removeElements = (query: string) => clonedSelection.querySelectorAll(query).forEach((el) => el.remove());

  const replaceElement = (query: string, newElType: string, style: any = {}, callback?: (el: string) => string) =>
    clonedSelection.querySelectorAll(query).forEach((el) => {
      if (el.innerHTML) {
        const newEl = document.createElement(newElType);
        Object.assign(newEl.style, style);
        newEl.innerHTML = el.innerHTML;
        if (callback && newEl.textContent) {
          newEl.textContent = callback(newEl.textContent);
        }
        el.before(newEl);
        el.remove();
      }
    });

  // remove undesired components
  [
    "div[data-testid='revision-toggler']",
    "div[data-testid='company-logo']",
    "span[data-testid='expert-counter']",
    "button[data-testid='icon-button-ghost']",
  ].forEach(removeElements);

  // adds space after elements
  ["div[data-testid='company-name']", "div[data-testid='expert-answer']", "div[id='answer-block']"].forEach(spaceAfter);

  // replace elements
  replaceElement("h3", "p", { fontSize: "18px", fontStyle: "italic" });
  replaceElement("div[data-testid='overview-topic']", "strong", {}, (str) => `${str}: `);
  replaceElement("div[data-testid='overview-list']", "ul", { paddingLeft: "16px", fontSize: "14px" });
  replaceElement("div[data-testid='overview-item']", "li");
  replaceElement("div[data-testid='company-name']", "div", { color: "#666B7A", fontSize: "13px" });
  replaceElement("div[data-testid='expert-answer']", "div", { fontSize: "13px" });
  replaceElement("span[data-testid^='quote-']", "span", { fontSize: "13px" });
  replaceElement("span[data-testid='quoted-text']", "span", { fontStyle: "italic" });

  return clonedSelection;
};
