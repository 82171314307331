import { compact } from "lodash";
import { ThirdPartyDocumentType, ThirdPartyInteraction } from "types";
import { PLACEHOLDER } from "./constants";

const getExpertInfo = (
  interaction: ThirdPartyInteraction,
  format: (position: string, company: string) => string,
  defaultValue: string = ""
) => {
  const {
    documentType,
    attribute: {
      attributes: { expertCompanyName, expertPosition },
    },
  } = interaction;

  const isTranscript = documentType === ThirdPartyDocumentType.transcript;
  const expertCompanyNameDisplay = expertCompanyName || PLACEHOLDER.expertCompanyName;
  const expertPositionDisplay = expertPosition || PLACEHOLDER.expertPosition;

  if (isTranscript) {
    return format(expertPositionDisplay, expertCompanyNameDisplay);
  }

  return defaultValue;
};

const getTitle = (interaction: ThirdPartyInteraction) => {
  const documentTitle = interaction.attribute.title;
  return getExpertInfo(
    interaction,
    (position, company) => `${company} - ${position}`,
    documentTitle ?? PLACEHOLDER.documentTitle
  );
};

const getAuthor = (interaction: ThirdPartyInteraction) => {
  return getExpertInfo(interaction, (position, company) => `${position}, ${company}`);
};

const getSubtitle = (interaction: ThirdPartyInteraction) => {
  const {
    documentType,
    redactedFilename,
    attribute: {
      title,
      attributes: { angle, expertCompanyName, expertPosition },
    },
    fileExtension,
  } = interaction;
  const isTranscript = documentType === ThirdPartyDocumentType.transcript;
  const showFileName = isTranscript ? !(expertCompanyName && expertPosition) : !title;
  const subtitleParts = compact([!!angle && [angle], fileExtension, showFileName && redactedFilename]);
  return subtitleParts.join(" | ");
};

export { getTitle, getSubtitle, getAuthor };
