import { apiClient } from "@alphasights/portal-api-client";

export const InteractionsService = {
  getEstimatedPrice(projectToken: string, interactionId: string) {
    return apiClient.get<EstimatedPrice[]>(
      `/api/projects/${projectToken}/interactions/${interactionId}/estimated-price`
    );
  },
  getAccessPlan(projectToken: string, interactionId: string) {
    return apiClient.get<AccessPlan>(`/api/projects/${projectToken}/interactions/${interactionId}/access-plan`);
  },
};
