import React from "react";
import { CustomerExpertView } from "./CustomerExpertView";
import { useLocation, useNavigate } from "router-utils";
import { CustomerKnowledgeProvider } from "providers/CustomerKnowledgeProvider";
import { hasSufficientCustomerKnowledge } from "./helpers";

export const CustomerExpertPage = (props) => {
  const { loading, experts } = props;
  const location = useLocation();
  const navigate = useNavigate();

  if (!loading && !hasSufficientCustomerKnowledge(experts)) {
    navigate(location.pathname.replace("/customer-view", "/table-view"));
  }

  return (
    <CustomerKnowledgeProvider {...props}>
      <CustomerExpertView {...props} />
    </CustomerKnowledgeProvider>
  );
};
