import React, { useMemo, useState } from "react";
import { AccordionItem, Icon, Link, Typography } from "@alphasights/alphadesign-components";
import { CalendarAvailable } from "@alphasights/alphadesign-icons";

import { DATE_FORMAT, DATE_FORMAT_WITH_ABBREVIATION } from "helpers/interactionHelpers";
import { x } from "@xstyled/styled-components";
import { FormattedDateTime, hasTimeZoneAbbreviation, useTimezone } from "providers/TimezoneProvider";
import { useInteractionDetailsStyles } from "../../AdvisorFlyOut.styles";
import { Badge } from "components/Badge";
import { CallMeButton } from "components/CallMe";
import {
  ENABLE_PORTAL_MESSAGES,
  ENABLE_LEGACY_BCG_CLARIFICATIONS,
  useProjectBadgeContext,
} from "providers/BadgeProvider";
import { CtaButton } from "../Topbar/Topbar";
import { MessageType } from "types";
import { isCallAvailable } from "components/InteractionsPage/helpers/Interaction";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useCallAvailability } from "providers/TwilioProvider";
import { UpcomingCallPill } from "pages/UpcomingCallsPage/UpcomingCallCard";

export const InteractionDetails = ({ projectToken, interaction, onSendNewMessage, showMobileView }) => {
  const { hasProjectBadge } = useProjectBadgeContext();
  const timezone = useTimezone();
  const portalMessagesEnabled = hasProjectBadge(ENABLE_PORTAL_MESSAGES) && !interaction.isSurvey;
  const isCallAboutToStartOrHappening = useMemo(() => isCallAvailable(interaction), [interaction]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    containerStyles,
    interactionScheduledTimeStyles,
    interactionScheduledTimeTextStyles,
  } = useInteractionDetailsStyles();

  const { isMobile } = useCheckScreen();
  const { callAvailability } = useCallAvailability({
    interactionId: interaction.id,
    projectToken: interaction.projectToken,
    scheduledCallTime: interaction.scheduledCallTime,
  });

  const enableLegacyBCGClarifications = hasProjectBadge(ENABLE_LEGACY_BCG_CLARIFICATIONS);

  if (interaction.isSurvey || !["scheduled", "completed"].includes(interaction.state)) {
    return null;
  }

  const title = (
    <Title compliancePending={interaction.profileState === "checklist_incomplete"} state={interaction.state} />
  );

  const durationInMinutes = (interaction?.duration ?? 0) / 60;

  const addToCalendarLink = `/api/projects/${projectToken}/interactions/${interaction.id}/calendar-invitation`;

  return (
    <AccordionItem title={title} open={interaction.state === "scheduled"} data-testid="interaction-details-accordion">
      <x.div {...containerStyles}>
        <Typography variant="body-em" component="div" {...interactionScheduledTimeStyles}>
          <Icon size="medium">
            <CalendarAvailable />
          </Icon>
          <x.div {...interactionScheduledTimeTextStyles}>
            <x.div display={"flex"} flexWrap={"wrap"} gap={"12px"}>
              <Typography>
                <FormattedDateTime
                  date={interaction.scheduledCallTime}
                  format={
                    hasTimeZoneAbbreviation(timezone.currentTimezone) ? DATE_FORMAT_WITH_ABBREVIATION : DATE_FORMAT
                  }
                />
              </Typography>
              {isMobile && <UpcomingCallPill interaction={interaction} callAvailability={callAvailability} />}
            </x.div>
            {interaction.state === "scheduled" && (
              <Link href={addToCalendarLink}>
                <Typography variant="body-em">Add to Calendar</Typography>
              </Link>
            )}
            {interaction.state === "completed" && durationInMinutes > 0 && (
              <Typography>
                Duration: {durationInMinutes} min
                {durationInMinutes > 1 ? "s" : ""}
              </Typography>
            )}
          </x.div>
        </Typography>
        {interaction.state === "scheduled" && !showMobileView && (
          <>
            {portalMessagesEnabled && !isCallAboutToStartOrHappening && !enableLegacyBCGClarifications ? (
              <CtaButton
                variant="outline"
                onClick={() => onSendNewMessage([interaction], MessageType.CallGuide)}
                size="medium"
              >
                Send Call Guide
              </CtaButton>
            ) : (
              <>
                {!isCallAboutToStartOrHappening && !interaction.isNonCallInteraction && (
                  <CallMeButton
                    callMeUrl={interaction.callMeUrl}
                    provider={interaction.newspeakProvider}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    projectToken={projectToken}
                    interaction={interaction}
                  />
                )}
              </>
            )}
          </>
        )}
      </x.div>
    </AccordionItem>
  );
};

const Title = ({ compliancePending, state }) => {
  const { titleStyles } = useInteractionDetailsStyles();

  return (
    <x.span {...titleStyles}>
      <Typography component="span" variant="body-large-em">
        Interaction Details
      </Typography>
      {state === "scheduled" && compliancePending && (
        <Badge value="Compliance Pending" data-testid="compliance-pending-badge" />
      )}
      {state === "completed" && <Badge value="Completed" data-testid="completed-interaction-badge" />}
    </x.span>
  );
};
