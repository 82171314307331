import React, { useCallback, useEffect, useMemo, useState } from "react";
import { x, useSize } from "@xstyled/styled-components";
import { format } from "date-fns";
import { Alert, Link, Typography, useThemeTokens, useAlphaToast } from "@alphasights/alphadesign-components";
import { ExpertLabel } from "components/ExpertLabel";
import { MultipleSelect } from "components/MultipleSelect";
import { WorkRequestMaxDuration } from "components/WorkRequestMaxDuration";
import { WorkRequestDeadline } from "components/WorkRequestDeadline/WorkRequestDeadline";
import { TextBox } from "components/TextBox";
import { useOnClick } from "hooks/useOnClickHooks";
import { SendWorkRequestModal } from "components/SendMessageModals";
import { find } from "lodash";
import {
  SURVEY_WARNING_THRESHOLD,
  calculateTotalCredit,
  convertCreditsToString,
} from "components/MessengerPage/helper";
import { MobileAttachmentsList, MobileTextBox } from "components/MobileTextBox";
import { useProjectBadgeContext, ENABLE_LEGACY_BCG_CLARIFICATIONS } from "providers/BadgeProvider";

export const WorkRequestSection = ({
  allExperts = [],
  selectedExperts = [],
  setSelectedExperts,
  handleSubmit,
  messageText,
  setMessageText,
  maximumTaskDuration,
  setMaximumTaskDuration,
  isLoading,
  blinded,
  sendMessageButtonRef,
  showInfoBanner: showInfoBannerInput = true,
  showNonElegibleExperts = true,
  externalLinkLabel = "",
  onClickExternalLink = () => {},
  mobileVariant = false,
  onMessageSent = () => {},
  attachTextBoxToNavbar = false,
  onMobileTextBoxHeightChange = (newHeight) => {},
}) => {
  const { toast } = useAlphaToast();
  const [expertErrors, setExpertErrors] = useState();
  const [messageErrors, setMessageErrors] = useState();
  const [maximumDurationErrors, setMaximumDurationErrors] = useState();
  const [deadlineErrors, setDeadlineErrors] = useState();

  const [showModal, setShowModal] = useState(false);
  const [showInfoBanner, setShowInfoBanner] = useState(showInfoBannerInput);

  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedDeadline, setSelectedDeadline] = useState();
  const [attachments, setAttachments] = useState([]);

  const { spacing } = useThemeTokens();

  const { hasProjectBadge } = useProjectBadgeContext();
  const enableLegacyBCGClarifications = hasProjectBadge(ENABLE_LEGACY_BCG_CLARIFICATIONS);

  const expertItems = useMemo(
    () =>
      allExperts
        .filter((expert) => {
          const noWrittenWork = expert.noWrittenWork;
          const locked = expert.advisorLockState && expert.advisorLockState.locked;
          const canSendMessage = expert.canSendMessage;
          return showNonElegibleExperts || (canSendMessage && !noWrittenWork && !locked);
        })
        .map((expert) => {
          const noWrittenWork = expert.noWrittenWork;
          const locked = expert.advisorLockState && expert.advisorLockState.locked;

          return {
            key: expert.id,
            label: (
              <ExpertLabel expert={expert} noWrittenWork={noWrittenWork} locked={locked} showSecondaryInformation />
            ),
            disabled: noWrittenWork || locked,
          };
        }),
    [allExperts, showNonElegibleExperts]
  );

  useEffect(() => {
    const expertIds = expertItems.map((expertItem) => expertItem.key);
    const validSelectedExperts = selectedExperts.filter(
      (selectedExpert) =>
        expertIds.includes(selectedExpert.id) &&
        !selectedExpert.noWrittenWork &&
        !(selectedExpert.advisorLockState?.locked ?? false)
    );
    setSelectedItems(validSelectedExperts.map((expert) => expert.id));
  }, [selectedExperts, expertItems]);

  const enteredInputs = useMemo(() => {
    const selectedExpertEntered = selectedExperts?.length > 0;
    const messageEntered = messageText?.length > 0;
    const maxTaskDurationsEndered = !!maximumTaskDuration;
    const selectedDeadlineEntered = !!selectedDeadline;
    return selectedExpertEntered && messageEntered && maxTaskDurationsEndered && selectedDeadlineEntered;
  }, [maximumTaskDuration, messageText, selectedDeadline, selectedExperts]);

  useOnClick(
    ({ didClickInsideRef }) => {
      if (sendMessageButtonRef && didClickInsideRef(sendMessageButtonRef) && (!mobileVariant || enteredInputs)) {
        openModal();
      }
    },
    [selectedExperts, messageText, maximumTaskDuration, selectedDeadline, enteredInputs, mobileVariant, attachments]
  );

  const invalidAttachmentsMessage =
    "Check if your attachment can be opened and if applicable, remove password protection.";

  const onSendWorkRequest = () => {
    if (performValidation()) {
      handleSubmit(
        {
          advisors: selectedExperts.map((expert) => ({
            id: expert.id,
            name: expert.label,
            multiplier: expert.alphaCircleMultiplier,
          })),
          workRequest: {
            maximumTimeAllowed: maximumTaskDuration,
            deadline: selectedDeadline,
          },
          content: messageText,
        },
        attachments
      )
        .then(() => {
          setShowModal(false);
          onMessageSent && onMessageSent();
        })
        .catch((error) => {
          setShowModal(false);
          error.json().then((json) => {
            const type = json.type;

            if (type === "INVALID_ATTACHMENTS") {
              const invalidAttachments = Object.getOwnPropertyNames(json.context);

              const newFiles = attachments.map((attachment) => {
                if (invalidAttachments.includes(attachment.name)) {
                  return { ...attachment, valid: false };
                } else {
                  return attachment;
                }
              });

              setAttachments(newFiles);
              setMessageErrors(invalidAttachmentsMessage);
            } else {
              toast.error({ message: "An error has occurred: please try refreshing" });
            }
          });
        });
    }
  };

  const performValidation = () => {
    let isOk = true;
    isOk = checkExpertError(isOk);
    isOk = checkMessageError(isOk);
    isOk = checkDurationError(isOk);
    isOk = checkDeadlineError(isOk);
    return isOk;
  };

  const checkExpertError = useCallback(
    (isOk) => {
      if (!selectedExperts || selectedExperts.length === 0) {
        setExpertErrors("No experts selected.");
        return false;
      } else {
        setExpertErrors();
      }
      return isOk;
    },
    [selectedExperts]
  );

  const checkMessageError = useCallback(
    (isOk) => {
      if (attachments.some((attachment) => !attachment.valid)) {
        setMessageErrors(invalidAttachmentsMessage);
        return false;
      } else if (!messageText || messageText.length === 0) {
        setMessageErrors("The message is empty.");
        return false;
      } else {
        setMessageErrors();
      }
      return isOk;
    },
    [messageText, attachments]
  );

  const checkDurationError = useCallback(
    (isOk) => {
      if (maximumTaskDuration == null || maximumTaskDuration === undefined) {
        setMaximumDurationErrors("No maximum duration selected.");
        return false;
      } else {
        setMaximumDurationErrors();
      }
      return isOk;
    },
    [maximumTaskDuration]
  );

  const checkDeadlineError = useCallback(
    (isOk) => {
      if (!selectedDeadline) {
        setDeadlineErrors("No deadline set.");
        return false;
      } else if (selectedDeadline.valueOf() < new Date().valueOf()) {
        setDeadlineErrors("Must be later than the current date.");
        return false;
      } else {
        setDeadlineErrors();
      }

      return isOk;
    },
    [selectedDeadline]
  );

  const deadlineText = () => {
    return selectedDeadline ? format(selectedDeadline, "dd MMM yyyy, hh:mmaaaaa'm'") : null;
  };

  const openModal = () => {
    if (performValidation()) {
      document.activeElement && document.activeElement.blur();
      setShowModal(true);
    }
  };

  useEffect(() => {
    if (expertErrors) {
      checkExpertError(false);
    }
    if (messageErrors) {
      checkMessageError(false);
    }
    if (maximumDurationErrors) {
      checkDurationError(false);
    }
    if (deadlineErrors) {
      checkDeadlineError(false);
    }
  }, [
    selectedExperts,
    messageText,
    maximumTaskDuration,
    selectedDeadline,
    expertErrors,
    messageErrors,
    maximumDurationErrors,
    deadlineErrors,
    checkExpertError,
    checkMessageError,
    checkDurationError,
    checkDeadlineError,
  ]);

  const getChargeTooltip = () => {
    return selectedExperts.length > 0 && maximumTaskDuration > 0
      ? convertCreditsToString(calculateTotalCredit([selectedExperts[0]], maximumTaskDuration)) + " per expert"
      : null;
  };

  const onAttachmentChange = (attachments) => {
    setAttachments(attachments);
  };

  const onSelectedExpertsChange = (values) => {
    const experts = allExperts.filter((expert) => values && values.includes(expert.id));
    setSelectedExperts(experts);
  };

  const renderSelected = (selectedExpert) => ({
    ...selectedExpert,
    label: <ExpertLabel expert={find(allExperts, (expert) => expert.id === selectedExpert.value)} />,
  });

  return (
    <>
      <x.div gap={spacing.layout.base02} w={useSize("full")} display="flex" flexDirection="column">
        <x.div>
          {showInfoBanner && (
            <Alert mb={spacing.inner.base04}>
              Select experts to complete a task or answer a follow-up question, then add attachments and set a deadline.{" "}
              <Link onClick={() => setShowInfoBanner(false)} component="button">
                <Typography variant="body-em">Dismiss</Typography>
              </Link>
            </Alert>
          )}

          <MultipleSelect
            error={expertErrors}
            required={true}
            value={selectedItems}
            items={expertItems}
            label="Expert(s)"
            placeholder="Select experts..."
            noResultsMessage="No experts found"
            onChange={onSelectedExpertsChange}
            externalLinkLabel={externalLinkLabel}
            onClickExternalLink={onClickExternalLink}
            renderSelected={renderSelected}
            size={mobileVariant ? "small" : "medium"}
          />
          {selectedExperts && selectedExperts.length > SURVEY_WARNING_THRESHOLD && (
            <Alert variant="warning" mt={spacing.layout.base}>
              Before contacting multiple experts, explore <Typography variant="body-em">AlphaSights Surveys</Typography>
              to receive validated and structured answers faster and with a higher response rate.
            </Alert>
          )}
        </x.div>

        {!mobileVariant ? (
          <TextBox
            name="message-content"
            required
            withAttachments
            attachments={attachments}
            error={messageErrors}
            label="Message"
            inputHeight="120px"
            inputMinHeight="120px"
            placeholder="Begin typing your message here..."
            onChange={setMessageText}
            onAttachmentChange={onAttachmentChange}
            defaultValue={messageText}
            resizable={false}
          />
        ) : (
          <MobileTextBox
            name="message-content"
            withAttachments
            attachments={attachments}
            error={messageErrors}
            placeholder="Begin typing your message here..."
            onChange={setMessageText}
            onAttachmentChange={onAttachmentChange}
            defaultValue={messageText}
            resizable={false}
            sendMessageButtonRef={sendMessageButtonRef}
            disableSend={!enteredInputs}
            attachToNavbar={attachTextBoxToNavbar}
            onHeightChange={onMobileTextBoxHeightChange}
          />
        )}

        <x.div>
          <WorkRequestMaxDuration
            value={maximumTaskDuration}
            labelWithRequired
            onChange={setMaximumTaskDuration}
            errorMessage={maximumDurationErrors}
            showCallGuideOption={false}
            size={mobileVariant ? "small" : "medium"}
            input={!mobileVariant}
            placeholder={mobileVariant ? <Typography>Select option</Typography> : undefined}
          />
        </x.div>

        <x.div display="inline-grid">
          <WorkRequestDeadline
            labelWithRequired
            onChange={setSelectedDeadline}
            errorMessage={deadlineErrors}
            mobileVariant={mobileVariant}
          />
        </x.div>

        {mobileVariant && <MobileAttachmentsList attachments={attachments} onAttachmentChange={onAttachmentChange} />}
        {enableLegacyBCGClarifications && (
          <Alert variant="info">
            <Typography variant="body-small">
              Your enterprise requires messages to be approved by AlphaSights. It will therefore take longer for experts
              to receive messages. Please account for this when selecting a deadline.
            </Typography>
          </Alert>
        )}
      </x.div>
      {showModal && (
        <SendWorkRequestModal
          isOpen={true}
          onCancel={() => setShowModal(false)}
          onClose={() => setShowModal(false)}
          onSend={onSendWorkRequest}
          experts={selectedExperts}
          maximumTaskDuration={maximumTaskDuration}
          deadline={deadlineText()}
          charge={convertCreditsToString(calculateTotalCredit(selectedExperts, maximumTaskDuration))}
          chargeTooltip={getChargeTooltip()}
          attachments={attachments.length}
          isLoading={isLoading}
          blinded={blinded}
        />
      )}
    </>
  );
};
