import React, { useMemo } from "react";
import { format } from "date-fns";
import { Checkbox, IconButton, Portal, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { ArrowLeft, ArrowRight } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { DropdownButton } from "components/DropdownButton";
import { compact, sortBy, uniq } from "lodash";
import { Separator } from "components/InteractionsPage/HeaderPortal";

export type CustomToolbarProps = {
  toolbarShowViewOptions: boolean;
  onNavigate: (direction: "PREV" | "NEXT") => void;
} & CalendarDateProps &
  ViewOptionsProps;

/**
 * Using toolbar as `components: {toolbar: CustomToolbar}` on the Calendar component
 * was loosing reactivity from the props, that's why it's being used as an external
 * toolbar for the calendar.
 */
export const CustomToolbar = (props: CustomToolbarProps) => {
  const { isFlyout } = props;
  const containerEl = document.getElementById(
    isFlyout ? "calendar-controls-placeholder" : "calendar-controls-header-placeholder"
  );

  const Wrapper = containerEl ? Portal : React.Fragment;
  return (
    <Wrapper containerEl={containerEl ?? undefined}>
      <CustomToolbarElement {...props} hasContainer={containerEl !== null} />
    </Wrapper>
  );
};

const CustomToolbarElement = ({
  date,
  dateList,
  onNavigate,
  onView,
  view,
  toolbarShowViewOptions,
  toolbarShowViewAvailability,
  showWeekend,
  setShowWeekend,
  sundayWorkday,
  setSundayWorkday,
  showClientAvailability,
  setShowClientAvailability,
  isFlyout = false,
  hasContainer,
}: CustomToolbarProps & { hasContainer: boolean }) => {
  const { wrapper } = useCustomToolbarStyles({ isFlyout, hasContainer });

  const components = [
    { component: <NavigationArrow onNavigate={onNavigate} direction="PREV" />, order: 3 },
    { component: <NavigationArrow onNavigate={onNavigate} direction="NEXT" />, order: 5 },
    { component: <CalendarDate date={date} dateList={dateList} />, order: 4 },
    { component: <Divider />, order: 2 },
    toolbarShowViewOptions
      ? {
          component: (
            <ViewOptions
              onView={onView}
              view={view}
              toolbarShowViewAvailability={toolbarShowViewAvailability}
              showWeekend={showWeekend}
              setShowWeekend={setShowWeekend}
              sundayWorkday={sundayWorkday}
              setSundayWorkday={setSundayWorkday}
              showClientAvailability={showClientAvailability}
              setShowClientAvailability={setShowClientAvailability}
              isFlyout={isFlyout}
            />
          ),
          order: 1,
        }
      : null,
  ];

  return (
    <>
      <x.div data-testid="calendar-toolbar" {...wrapper}>
        {sortBy(compact(components), "order").map(({ component }, index) => (
          <React.Fragment key={index}>{component}</React.Fragment>
        ))}
      </x.div>
      {!hasContainer && <Separator />}
    </>
  );
};

const NavigationArrow = ({
  onNavigate,
  direction,
}: {
  onNavigate: (direction: "PREV" | "NEXT") => void;
  direction: "PREV" | "NEXT";
}) => {
  const onClick = () => onNavigate(direction);

  return (
    <IconButton
      size="small"
      variant="outline"
      onClick={onClick}
      dataAttributes={{
        "data-testid": `calendar-toolbar-${direction === "PREV" ? "previous" : "next"}`,
      }}
    >
      {direction === "PREV" ? <ArrowLeft /> : <ArrowRight />}
    </IconButton>
  );
};

type CalendarDateProps = {
  date: Date;
  dateList: Date[];
};
const CalendarDate = ({ date, dateList }: CalendarDateProps) => {
  const { date: dateStyles } = useCustomToolbarStyles();

  const [firstDate, secondDate] = useMemo(() => {
    let dates = uniq(dateList.map((d) => format(d, "MMM u")));
    const lastDateYear = format(dateList[dateList.length - 1], "u");
    const allDatesFromTheSameYear = dates.every((d) => d.endsWith(lastDateYear));

    // If all dates are from the same year, we can remove the year from every date except last one
    if (allDatesFromTheSameYear) {
      dates = dates.map((d, index) => (index === dates.length - 1 ? d : d.replace(` ${lastDateYear}`, "")));
    }
    return dates.map((d) => <StyledDate date={d} />);
  }, [dateList]);

  return (
    <x.div data-testid="calendar-toolbar-date" {...dateStyles}>
      {firstDate}
      {secondDate && <Typography>&nbsp;-&nbsp;</Typography>}
      {secondDate}
    </x.div>
  );
};

const StyledDate = ({ date = "" }) => {
  const [month, year] = date.split(" ");
  return (
    <>
      {month && <Typography variant="body-em">{month}</Typography>}
      {year && (
        <Typography variant="body" color="secondary">
          &nbsp;{year}
        </Typography>
      )}
    </>
  );
};

type ViewOptionsProps = {
  onView: (view: string) => void;
  view: "day" | "three_days" | "week";
  toolbarShowViewAvailability: boolean;
  showWeekend: boolean;
  setShowWeekend: (showWeekend: boolean) => void;
  sundayWorkday: boolean;
  setSundayWorkday: (sundayWorkday: boolean) => void;
  showClientAvailability: boolean;
  setShowClientAvailability: (showClientAvailability: boolean) => void;
  isFlyout?: boolean;
};
const ViewOptions = ({
  onView,
  view,
  toolbarShowViewAvailability,
  showWeekend,
  setShowWeekend,
  sundayWorkday,
  setSundayWorkday,
  showClientAvailability,
  setShowClientAvailability,
  isFlyout = false,
}: ViewOptionsProps) => {
  const { innerDropdown, innerCheckbox } = useCustomToolbarStyles();

  const labels = new Map<string, string>([
    ["day", "Day"],
    ["three_days", "3 Days"],
    ["week", "Week"],
  ]);

  return (
    <DropdownButton
      popoverPlacement={isFlyout ? "bottom-end" : "bottom-start"}
      variant="outline"
      size="small"
      optionsSize="medium"
      options={Array.from(labels.entries()).map((item) => {
        const [key, value] = item;
        return {
          label: value,
          action: () => onView(key),
        };
      })}
      label={labels.get(view)!}
      data-testid="calendar-view-dropdown"
    >
      <x.div {...innerDropdown}>
        <x.div {...innerCheckbox}>
          <Checkbox
            data-testid="calendar-show-weekends"
            checked={showWeekend}
            onChange={() => setShowWeekend(!showWeekend)}
          >
            <Typography component="span">Show weekends</Typography>
          </Checkbox>
        </x.div>
        <x.div {...innerCheckbox}>
          <Checkbox
            data-testid="calendar-sun-thu-workweek"
            checked={sundayWorkday}
            onChange={() => setSundayWorkday(!sundayWorkday)}
          >
            <Typography component="span">Sun-Thu workweek</Typography>
          </Checkbox>
        </x.div>
        {toolbarShowViewAvailability && (
          <x.div {...innerCheckbox}>
            <Checkbox
              checked={showClientAvailability}
              onChange={() => setShowClientAvailability(!showClientAvailability)}
            >
              <Typography component="span">Show team availability</Typography>
            </Checkbox>
          </x.div>
        )}
      </x.div>
    </DropdownButton>
  );
};

const Divider = () => {
  const { divider } = useCustomToolbarStyles();
  return <x.div data-testid="separator" {...divider} />;
};

const useCustomToolbarStyles = ({ isFlyout = false, hasContainer = false } = {}) => {
  const {
    spacing: { layout, inner },
    shape,
    color: { border },
  } = useThemeTokens();

  return {
    date: {
      display: "flex",
      alignItems: "baseline",
      mx: layout.base,
    },
    divider: {
      borderLeft: `${shape.border.width.sm} solid ${border.divider}`,
      h: "28px",
    },
    innerDropdown: {
      borderTop: shape.border.width.sm,
      borderColor: border.divider,
      borderStyle: "solid",
      padding: inner.base03,
      gap: inner.base,
    },
    innerCheckbox: {
      paddingTop: inner.base02,
      paddingBottom: inner.base02,
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
      gap: inner.base02,
      my: isFlyout ? layout.base : hasContainer ? undefined : layout.base02,
      mx: hasContainer ? undefined : layout.base03,
      justifyContent: "end",
    },
  };
};
