import React from "react";
import { Search } from "@alphasights/alphadesign-icons";
import { useIsTabletNavigationLayout, useRenderSearchBarInsideOverlay } from "components/NavigationContainer/utils";
import { SidebarOption } from "../SidebarOption";
import { Overlay } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";
import SearchBar from "components/NavigationContainer/SearchBar";

export const SearchOption = () => {
  const isTabletNavigationLayout = useIsTabletNavigationLayout();
  const renderSearchBarInsideOverlay = useRenderSearchBarInsideOverlay();
  const [open, setOpen] = React.useState(false);

  const closeOverlay = () => setOpen(false);
  const onClick = () => {
    renderSearchBarInsideOverlay && setOpen(true);
    const searchInputEl = document.querySelector("[data-testid=top-bar-search] #search-input") as HTMLElement;
    setTimeout(() => searchInputEl?.focus(), 100);
  };

  if (!isTabletNavigationLayout) return null;

  return (
    <SearchOptionWrapper>
      <SidebarOption id="search" label="Search" icon={<Search />} onClick={onClick} />
      {renderSearchBarInsideOverlay && (
        <Overlay open={open} zIndex={999} onClick={closeOverlay}>
          <SearchBarWrapper onClick={(e) => e.stopPropagation()}>
            <SearchBar onNavigateToProject={closeOverlay} />
          </SearchBarWrapper>
        </Overlay>
      )}
    </SearchOptionWrapper>
  );
};

const SearchOptionWrapper = styled.div`
  height: 60px;
  overflow: hidden;
`;

const SearchBarWrapper = styled.div`
  margin: 0 auto;
  margin-top: 15dvh;
  max-width: 731px;
`;
