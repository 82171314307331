import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";

import { ThirdPartyDocument, ThirdPartyDocumentType } from "types";
import { fromPx } from "pages/AlphaNowPage/utils";
import { Angle } from "views/DeliverablesView/ThirdPartyDocUploadModal/constants";

const getPopoverOffset = () => ({
  mainAxis: fromPx(tokens.spacing.inner.base02),
  crossAxis: fromPx(tokens.spacing.inner.base04),
});

const createFormPropsFromDocument = (documentResponse: ThirdPartyDocument) => {
  const {
    originalFilename,
    redactedFilename,
    documentType,
    attribute: {
      documentDate,
      title,
      attributes: { angle, expertCompanyName, expertCompanyCdsAlphaCompanyId, expertPosition },
    },
    createdAt,
  } = documentResponse;
  return {
    date: documentDate ?? createdAt,
    documentTitle: title,
    fileName: redactedFilename ?? originalFilename,
    expertCompanyName,
    expertCompanyCdsAlphaCompanyId,
    expertPosition,
    angle: angle as Angle | undefined,
    isTranscript: documentType === ThirdPartyDocumentType.transcript,
  };
};

const formatDate = (date: Date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("en-GB", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month} ${year}`;
};

export { getPopoverOffset, createFormPropsFromDocument, formatDate };
