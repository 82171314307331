import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "router-utils";
import { useMutation } from "query-utils";
import { x } from "@xstyled/styled-components";
import { contentService } from "services/content";
import { Modal, Typography, useAlphaToast, useThemeTokens } from "@alphasights/alphadesign-components";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitOrigin, HitAction } from "@alphasights/portal-api-client";
import {
  COMMISSION_SURVEY_HEIGHT,
  COMMISSION_SURVEY_WIDTH,
  SUCCESS_COMMISSION,
  SURVEY_URL_PARAM,
} from "pages/AlphaNowPage/primers/request-survey/constants";
import { useAppSearchContext } from "providers/AppSearchProvider";
import { unsuccessfulRequest } from "content/AlphaNow";
import CopyUrlButton from "../../components/copy-url-button/CopyUrlButton";
import SurveyModalFooter from "./footer/SurveyModalFooter";
import SurveyCustomers from "./customers/SurveyCustomers";
import SurveyCompanySearch from "./companies/SurveyCompanySearch";

import styles from "./styles/surveyModal.module.css";

type SurveyCompanyWithLogo = {
  cdsAlphaCompanyId: string;
  companyName: string;
  StartIcon: any;
};
type SurveyCompany = {
  cdsAlphaCompanyId: string;
  companyName: string;
};

type SurveyModalForm = {
  targetCompanies: SurveyCompany[];
  competitorSet: SurveyCompany[];
  customers: {
    count: string;
    notes: string;
  };
};

type searchQuery = {
  label: string;
  value: string;
  StartIcon: any;
};

const SurveyModal = ({
  targets,
  competitors,
  onClose,
  origin,
}: {
  onClose: () => void;
  targets?: SurveyCompanyWithLogo[];
  competitors?: SurveyCompanyWithLogo[];
  origin: HitOrigin;
}) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();
  const { logHit } = useTrackUserAction();
  const { toast } = useAlphaToast();
  const navigate = useNavigate();
  const { query, updateQuery } = useAppSearchContext();

  const [requestForm, setRequestForm] = useState<SurveyModalForm>({
    targetCompanies: [],
    competitorSet: [],
    customers: {
      count: "100",
      notes: "",
    },
  });

  const [targetsQuery, setTargetsQuery] = useState<searchQuery[]>([]);
  const [competitorsQuery, setCompetitorsQuery] = useState<searchQuery[]>([]);

  const onHandleCloseModal = () => {
    const currentUrl = new URL(window.location.href);
    const searchParams = currentUrl.searchParams;
    searchParams.set(SURVEY_URL_PARAM, "false");
    navigate(currentUrl.pathname + "?" + searchParams.toString());

    updateQuery({ ...query, commissionSurvey: false });

    onClose();
  };

  const { mutate: sendSurveyRequest, isLoading: isRequestingSurvey } = useMutation(
    () => contentService.postSurveyRequest(requestForm),
    {
      onError: () => toast.error({ message: unsuccessfulRequest }),
      onSuccess: () => {
        toast.success({
          message: SUCCESS_COMMISSION,
        });
        onHandleCloseModal();
      },
    }
  );

  const onConfirm = () => {
    sendSurveyRequest();
    logHit({
      origin: origin,
      action: HitAction.alphaNowRequestSurvey,
      details: { ...requestForm },
    });
  };

  const onHandleChangeCustomer = (property: string, value: string) => {
    setRequestForm({
      ...requestForm,
      customers: {
        ...requestForm.customers,
        [property]: value,
      },
    });
  };

  const onHandleChangeCompanies = (property: string, value: any) => {
    setRequestForm({
      ...requestForm,
      [property]: value,
    });
  };

  const memoizedTargets = useMemo(() => {
    return targets
      ? targets.map((company) => ({
          companyName: company.companyName,
          cdsAlphaCompanyId: company.cdsAlphaCompanyId,
        }))
      : [];
  }, [targets]);

  const memoizedCompetitors = useMemo(() => {
    return competitors
      ? competitors.map((company) => ({
          companyName: company.companyName,
          cdsAlphaCompanyId: company.cdsAlphaCompanyId,
        }))
      : [];
  }, [competitors]);

  const memoizedTargetsQuery = useMemo(() => {
    return targets
      ? targets.map((company) => ({
          label: company.companyName,
          value: company.companyName,
          StartIcon: company.StartIcon,
        }))
      : [];
  }, [targets]);

  const memoizedCompetitorsQuery = useMemo(() => {
    return competitors
      ? competitors.map((company) => ({
          label: company.companyName,
          value: company.companyName,
          StartIcon: company.StartIcon,
        }))
      : [];
  }, [competitors]);

  // uniqueDefaultAddedCompanies removes duplicates from the competitorSet and target companies into one array.
  //  These are used to make sure the cdsAlphaCompanyIds are unique and added to the search query and the default state.
  const uniqueDefaultAddedCompanies = useMemo(() => {
    const seen = new Set();
    return [...memoizedTargets, ...memoizedCompetitors].filter((company) => {
      if (seen.has(company.cdsAlphaCompanyId)) {
        return false;
      }
      seen.add(company.cdsAlphaCompanyId);
      return true;
    });
  }, [memoizedTargets, memoizedCompetitors]);

  useEffect(() => {
    setRequestForm((prevRequestForm) => ({
      ...prevRequestForm,
      targetCompanies: memoizedTargets,
      competitorSet: memoizedCompetitors,
    }));

    setTargetsQuery(memoizedTargetsQuery);
    setCompetitorsQuery(memoizedCompetitorsQuery);
  }, [
    memoizedTargets,
    memoizedCompetitors,
    memoizedTargetsQuery,
    memoizedCompetitorsQuery,
    setRequestForm,
    setTargetsQuery,
    setCompetitorsQuery,
  ]);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(SURVEY_URL_PARAM, "true");
    navigate(currentUrl.pathname + currentUrl.search);

    updateQuery({ ...query, commissionSurvey: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      className={styles.modal}
      title={
        <x.div display="flex" gap={inner.base02} alignItems="center">
          <Typography variant="h3" color={text.strong._} style={{ textTransform: "none" }}>
            Launch a Customer Survey
          </Typography>
          <CopyUrlButton />
        </x.div>
      }
      open
      onClose={onHandleCloseModal}
      slotWidth={COMMISSION_SURVEY_WIDTH}
      slotHeight={COMMISSION_SURVEY_HEIGHT}
      variant="complex"
      zIndex={10}
      paddingTop={inner.base06}
    >
      <x.div display="flex" flexDirection="column" justifyContent="space-between" w="100%">
        <x.div flex="1 1 auto" overflowY="auto" overflowX="hidden">
          <x.div display="flex" paddingTop={inner.base06} flexDirection="column" gap={inner.base04} px={inner.base06}>
            <x.div display="flex" gap={inner.base02} flexDirection="column">
              <Typography variant="body-em" color={text.strong._}>
                Companies of focus
              </Typography>
              <Typography variant="body-small" color={text.secondary}>
                Choose the companies that you want customer perspectives on in the Customer Survey
              </Typography>
            </x.div>
            <x.div display="flex" gap={inner.base04} flexDirection="column">
              <x.div display="flex" gap={inner.base02} flexDirection="column">
                <Typography variant="body-small" color={text.secondary}>
                  Target companies
                </Typography>
                <SurveyCompanySearch
                  dataTestId="target-companies"
                  defaultCompanies={uniqueDefaultAddedCompanies}
                  onChangeProperties={(companies) => onHandleChangeCompanies("targetCompanies", companies)}
                  errorMessage={""}
                  query={targetsQuery}
                  setQuery={setTargetsQuery}
                />
              </x.div>
              <x.div display="flex" gap={inner.base02} flexDirection="column">
                <Typography variant="body-small" color={text.secondary}>
                  Competitor set
                </Typography>
                <SurveyCompanySearch
                  dataTestId="competitor-set"
                  defaultCompanies={uniqueDefaultAddedCompanies}
                  onChangeProperties={(companies) => onHandleChangeCompanies("competitorSet", companies)}
                  errorMessage={""}
                  query={competitorsQuery}
                  setQuery={setCompetitorsQuery}
                />
              </x.div>
            </x.div>
          </x.div>
          <SurveyCustomers
            customerCount={requestForm.customers.count}
            customerNote={requestForm.customers.notes}
            onChange={onHandleChangeCustomer}
          />
        </x.div>
        <x.div flex="0 0 auto">
          <SurveyModalFooter
            onConfirm={onConfirm}
            isEditing={
              (requestForm.targetCompanies.length === 0 && requestForm.competitorSet.length === 0) ||
              parseInt(requestForm.customers.count) <= 0
            }
            onClose={onHandleCloseModal}
            isLoading={isRequestingSurvey}
          />
        </x.div>
      </x.div>
    </Modal>
  );
};

export default SurveyModal;
