import React from "react";
import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { CommissionButton as CommissionPrimerButton } from "pages/AlphaNowPage/primers/CommissionPrimer/CommissionModal";

const CommissionFooter = ({ onOpen }: { onOpen: () => void }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <x.div display="flex" justifyContent="space-between" py={inner.base06} alignItems="center">
      <div>
        <Typography variant="h3" style={{ textTransform: "none" }}>
          Commission Research on this company
        </Typography>
      </div>
      <CommissionPrimerButton onClick={onOpen} />
    </x.div>
  );
};

export default CommissionFooter;
