import React, { FC } from "react";
import { LimitedLinesTypography } from "@alphasights/client-portal-shared";
import { Pill, Typography, useThemeTokens } from "@alphasights/alphadesign-components";

import { CustomHoverContentCard } from "components/CustomHoverContentCard";
import { ThirdPartyDocumentType, ThirdPartyInteraction } from "types";

import { getSubtitle, getTitle } from "./utils";
import { UPLOADED } from "./constants";
import { TextWrapper, CardHeader } from "./ThirdPartyDocumentCard.styled";
import { useTimezone } from "providers/TimezoneProvider";

const DataTestIds = {
  transcriptCard: "transcript-card",
};

type ThirdPartyDocumentCardProps = {
  interaction: ThirdPartyInteraction;
  selected: boolean;
  onSelect: () => void;
};

const Title = ({ interaction }: { interaction: ThirdPartyInteraction }) => {
  const { color } = useThemeTokens();
  const isTranscript = interaction.documentType === ThirdPartyDocumentType.transcript;

  let contents;
  let tooltipText;

  if (isTranscript) {
    tooltipText = getTitle(interaction);
    const [expertCompanyName, expertPosition] = tooltipText.split(" - ");
    contents = (
      <>
        <Typography variant="body-em" component="span">
          {expertCompanyName}
        </Typography>{" "}
        - {expertPosition}
      </>
    );
  } else {
    const title = getTitle(interaction);
    tooltipText = title;
    contents = (
      <Typography variant="body-em" component="span" color={color.text.strong._}>
        {title}
      </Typography>
    );
  }

  return (
    <LimitedLinesTypography tooltip={tooltipText} component="span">
      {contents}
    </LimitedLinesTypography>
  );
};

const Subtitle = ({ interaction }: { interaction: ThirdPartyInteraction }) => {
  const subtitleText = getSubtitle(interaction);
  return (
    <LimitedLinesTypography variant="body-small" color="secondary" component="span" tooltip={subtitleText}>
      {subtitleText}
    </LimitedLinesTypography>
  );
};

const ThirdPartyDocumentCard: FC<ThirdPartyDocumentCardProps> = ({ interaction, selected, onSelect }) => {
  const timezone = useTimezone();
  const formattedDate = timezone.format(interaction.scheduledCallTime, "dd MMM yyyy");
  return (
    <CustomHoverContentCard
      selected={selected}
      onClick={onSelect}
      interaction-testid={`${DataTestIds.transcriptCard}-${interaction.id}`}
    >
      <CardHeader>
        <Pill size="small" variant="outline">
          {UPLOADED}
        </Pill>
        <Typography variant="body-small" color="secondary">
          {formattedDate}
        </Typography>
      </CardHeader>
      <TextWrapper>
        <Title interaction={interaction} />
        <Subtitle interaction={interaction} />
      </TextWrapper>
    </CustomHoverContentCard>
  );
};

export default ThirdPartyDocumentCard;
