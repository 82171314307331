import React from "react";
import * as S from "./ViewToggler.styled";
import { Icon, IconButton, Pill, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { Compare, List, Table, Tick } from "@alphasights/alphadesign-icons";
import { useExpertCompareContext } from "providers/ExpertCompareProvider";
import { useProjectMoreOptionsContext } from "providers/ProjectMoreOptionsProvider";
import { usePreference } from "hooks/usePreference";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useNotifications } from "@alphasights/client-portal-shared";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { PreferenceType } from "providers/types";

export const ViewToggler = () => (
  <S.Container data-testid="view-toggler">
    <MakeViewDefaultButton />
    <Views />
  </S.Container>
);

const MakeViewDefaultButton = () => {
  const { project } = useCurrentProjectContext();
  const { currentView } = useProjectMoreOptionsContext();
  const { showSuccessBanner } = useNotifications();
  const { preference, updatePreference: updateViewPreference, isLoading } = usePreference(
    PreferenceType.DEFAULT_ADVISOR_VIEW,
    project ? { token: project.token } : undefined
  );

  const defaultView = preference?.attributes?.defaultView;
  const makeViewDefault = () => {
    if (isLoading) return;
    updateViewPreference({ defaultView: currentView });
    showSuccessBanner("View set as default.");
  };

  if (isLoading || defaultView === currentView) return null;
  return (
    <Tooltip variant="dark" title="Set view as default">
      <IconButton
        variant="outline"
        size="small"
        onClick={() => makeViewDefault && makeViewDefault()}
        dataAttributes={{ "data-testid": "set-default-view" }}
      >
        <Tick />
      </IconButton>
    </Tooltip>
  );
};

const Views = () => {
  const { onChangeLayout, currentView } = useProjectMoreOptionsContext();
  return (
    <S.ViewsContainer>
      <ViewButton
        icon={<Table />}
        views={["table-view", "customer-view"]}
        onClick={() => onChangeLayout && onChangeLayout("table-view")}
        currentView={currentView}
      />
      <ViewButton
        icon={<List />}
        views={["list-view"]}
        onClick={() => onChangeLayout && onChangeLayout("list-view")}
        currentView={currentView}
      />
      <CompareButton />
    </S.ViewsContainer>
  );
};

type ViewButtonProps = {
  icon: React.ReactNode;
  currentView: string;
  views: string[];
  onClick: () => void;
  isNew?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const ViewButton = ({ icon, currentView, views, onClick, isNew, ...props }: ViewButtonProps) => {
  const selected = views.includes(currentView);
  const firstView = views.at(0);
  return (
    <S.ViewButton
      selected={selected}
      onClick={onClick}
      aria-label={firstView ? firstView : undefined}
      aria-selected={selected}
      {...props}
    >
      {isNew && <S.NewDot data-testid="new-dot" />}
      <Icon size="small" color={selected ? "strong" : "secondary"}>
        {icon}
      </Icon>
    </S.ViewButton>
  );
};

const CompareButton = () => {
  const { onChangeLayout, currentView } = useProjectMoreOptionsContext();
  const [anchorEl, setAnchorEl] = React.useState<Element | undefined>(undefined);
  const { enableComparisonView, isViewAccessible, isViewBeingGenerated, setReferrerOrigin } = useExpertCompareContext();
  const [showTooltip, setShowTooltip] = React.useState(false);
  const isCurrentViewComparisonView = currentView === "comparison-view";

  const handlePopoverOpen = ({ currentTarget }: { currentTarget: Element }) => {
    setAnchorEl(currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(undefined);
    setShowTooltip(false);
  };
  const onClick = () => {
    if (isViewAccessible) {
      setReferrerOrigin(currentView);
      onChangeLayout && onChangeLayout("comparison-view");
    } else {
      setShowTooltip(true);
    }
  };
  const open = Boolean(anchorEl);

  if (!enableComparisonView) return null;

  return (
    <>
      <ViewButtonWithLoginWall
        data-testid="compare-button"
        icon={<Compare />}
        currentView={currentView}
        views={["comparison-view"]}
        onClick={onClick}
        isNew={!isCurrentViewComparisonView}
        onMouseEnter={isCurrentViewComparisonView ? undefined : handlePopoverOpen}
      />
      <S.Popover
        data-testid="compare-button-popover"
        dark={showTooltip}
        closeOnMouseLeave={true}
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        leaveDelay={100}
        zIndex={100}
        placement="bottom-end"
      >
        <div>
          {showTooltip ? (
            <S.NotEnoughExperts>
              <Typography variant="body-small" color="inverse">
                {isViewBeingGenerated
                  ? "Comparison view is being generated"
                  : "Not enough experts to compare at this time"}
              </Typography>
            </S.NotEnoughExperts>
          ) : (
            <ComparisonViewPreview />
          )}
        </div>
      </S.Popover>
    </>
  );
};

const ComparisonViewPreview = () => (
  <S.ComparisonViewPreviewWrapper>
    <S.ComparisonViewPreviewHeader>
      <Pill size="x-small" variant="blue">
        New
      </Pill>
      <Typography variant="body-em">Comparison View</Typography>
    </S.ComparisonViewPreviewHeader>
    <Typography color="secondary">Select experts for calls based on their responses to your questions.</Typography>
    <img src="/images/comparisonView.png" alt="Comparison View" />
  </S.ComparisonViewPreviewWrapper>
);

const ViewButtonWithLoginWall = withLoginWall(ViewButton);
