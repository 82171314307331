import React, { FC } from "react";
import { useNavigate } from "router-utils";
import { isEqual } from "lodash";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { SEARCH_SUGGESTION_TYPES } from "@alphasights/client-portal-shared";

import Search, {
  getDefaultComponents,
  SearchOption,
  SearchSizeVariant,
  SearchVariant,
  OptionProps,
  SearchItemProps,
} from "components/Search";
import { NUMBER_OPTIONS_PER_SECTION, PLACEHOLDER } from "components/NavigationContainer/SearchBar/constants";
import { useAppSearchContext } from "providers/AppSearchProvider";
import useAlphaNowSearch, { AlphaNowSearchError } from "pages/AlphaNowPage/hooks/useAlphaNowSearch";
import { SEARCH_SECTIONS, SEARCH_SUGGESTION_TYPE_ICONS, SEARCH_SUGGESTION_TYPE_NAMES } from "constants/AlphaNow/Search";
import { CompanySuggestion, SearchSuggestion } from "pages/AlphaNowPage/components/AlphaNowSearch/types";
import { AlphaNowSearchQuery, Query } from "pages/AlphaNowPage/hooks/useAlphaNowQuery";
import { cleanUpQuery, getProjectSearchQuery } from "pages/AlphaNowPage/components/AlphaNowSearch/utils";
import { processSearchQuery, QueryItem } from "pages/AlphaNowPage/components/AlphaNowSearch/boolean-expression-utils";
import { isBooleanSearch, BooleanExpressionError } from "components/Search/utils";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";
import {
  invalidBooleanSearchErrorMessage,
  noSearchMatchesErrorMessage,
} from "pages/AlphaNowPage/components/AlphaNowSearch/constants";

import * as S from "./SearchBar.styled";
import { useCurrentUser } from "@alphasights/portal-auth-react";

const DataTestIds = {
  topBarSearch: "top-bar-search",
};

const enhanceOption = (option: SearchSuggestion) => {
  const enhancedOption: SearchOption = {
    ...option,
    secondaryLabel: SEARCH_SUGGESTION_TYPE_NAMES[option.type],
  };
  if ((option as CompanySuggestion).logo) {
    enhancedOption.avatar = { src: (option as CompanySuggestion).logo } as SearchOption["avatar"];
  } else {
    enhancedOption.StartIcon = SEARCH_SUGGESTION_TYPE_ICONS[option.type];
  }
  return enhancedOption;
};

const SearchItem: FC<SearchItemProps> = ({ data, ...props }) => {
  const { SearchItem: BaseSearchItem } = getDefaultComponents();
  const enhancedData = enhanceOption(data as SearchSuggestion);
  return <BaseSearchItem data={enhancedData} {...props} />;
};

const Option: FC<OptionProps> = ({ data, ...props }) => {
  const { Option: BaseOption } = getDefaultComponents();
  const enhancedOption: SearchOption = enhanceOption(data as SearchSuggestion);
  return <BaseOption data={enhancedOption} {...(props as Omit<OptionProps, "data">)} />;
};

const SearchBar = ({ onNavigateToProject }: { onNavigateToProject?: () => void }) => {
  const { isMobile } = useCheckScreen();
  const {
    query: { searchQuery },
    updateQuery,
    searchErrorMessage,
    setSearchErrorMessage,
  } = useAppSearchContext();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const isAlphaNowEnabled = currentUser?.alphaNowEnabled;

  const { loadSearchSuggestions } = useAlphaNowSearch();

  const resetSearchErrorMessage = (errorMessagesToReset: string[] = []) => {
    if (searchErrorMessage && errorMessagesToReset.includes(searchErrorMessage)) {
      setSearchErrorMessage("");
    }
  };

  const loadOptions = async (inputValue: string) => {
    if (searchErrorMessage === noSearchMatchesErrorMessage) {
      setSearchErrorMessage("");
    }
    let options: SearchSuggestion[] = [];
    try {
      options = await loadSearchSuggestions(inputValue);
    } catch (e) {
      if (e instanceof AlphaNowSearchError) {
        setSearchErrorMessage(noSearchMatchesErrorMessage);
      }
    }
    return options;
  };

  const validateQuery = (query: AlphaNowSearchQuery[]) => {
    if (isBooleanSearch(query)) {
      try {
        const booleanExpressionTree = processSearchQuery(query as QueryItem[]);
        resetSearchErrorMessage([invalidBooleanSearchErrorMessage]);
        return booleanExpressionTree;
      } catch (e) {
        if (e instanceof BooleanExpressionError) {
          setSearchErrorMessage(invalidBooleanSearchErrorMessage);
          return;
        } else {
          throw e;
        }
      }
    }
    resetSearchErrorMessage([invalidBooleanSearchErrorMessage]);
    return query;
  };

  const onProjectSearch = (query: SearchOption) => {
    navigate(`/${myAlphaSightsBaseUrl}/projects/${query.token}`);
    onNavigateToProject?.();
  };

  const onSearchChange = (newQuery: SearchOption[] = []) => {
    const cleanQuery = cleanUpQuery(newQuery);

    const projectSearchQuery = getProjectSearchQuery(newQuery);

    if (!!projectSearchQuery) {
      onProjectSearch(projectSearchQuery);
      return;
    }

    if (isEqual(cleanQuery, searchQuery) || !validateQuery(cleanQuery as AlphaNowSearchQuery[])) return;

    updateQuery({
      searchQuery: cleanQuery,
      selectedContentId: undefined,
    } as Query);
  };

  // TODO: implement in RD1-178
  const activateSearchBar = () => {};
  const deactivateSearchBar = () => {};

  const optionSections = [
    { title: SEARCH_SECTIONS.ResearchLibrary, numOptionsPerSection: NUMBER_OPTIONS_PER_SECTION },
    { title: SEARCH_SECTIONS.Project },
  ];

  return (
    <S.SearchBarWrapper data-testid={DataTestIds.topBarSearch}>
      <Search
        variant={SearchVariant.Complex}
        size={isMobile ? SearchSizeVariant.Large : SearchSizeVariant.Medium}
        query={searchQuery}
        placeholder={PLACEHOLDER}
        debounceSearch={true}
        loadOptions={loadOptions}
        optionSections={optionSections}
        onChange={onSearchChange}
        onFocus={activateSearchBar}
        onBlur={deactivateSearchBar}
        components={{
          SearchItem,
          Option,
        }}
        optionProps={{
          inputEmphasis: ({ type }) =>
            [SEARCH_SUGGESTION_TYPES.Company, SEARCH_SUGGESTION_TYPES.Colleague].includes(
              type as SEARCH_SUGGESTION_TYPES
            ),
          anywhereEmphasis: ({ type }) => type === SEARCH_SUGGESTION_TYPES.Colleague,
        }}
        allowSingleCharSearch={false}
        allowBooleanOperators={isAlphaNowEnabled}
        autoHideSearchIcon={isMobile}
        booleanSearchProps={{
          section: SEARCH_SECTIONS.ResearchLibrary,
        }}
      />
    </S.SearchBarWrapper>
  );
};

export { SearchBar as default, DataTestIds };
