import { NotificationEvent, NotificationsWidget, useTrackUserAction } from "@alphasights/client-portal-shared";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "router-utils";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import * as S from "./NotificationsOption.styled";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useUserNotificationsContext } from "./UserNotificationsContextProvider";

type NotificationsOptionProps = {
  isSelected: boolean;
  setIsSelected: (value: boolean) => void;
};

export const NotificationsOption = ({ isSelected, setIsSelected }: NotificationsOptionProps) => {
  const enableNotifications = useEnableNotifications();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const afterNotificationWidgetClick = () => {
    if (isMounted.current) {
      setIsSelected(!isSelected);
    }
  };

  return enableNotifications ? (
    <S.NotificationsWrapper isSelected={isSelected}>
      <PortalNotificationWidget
        afterNotificationWidgetClick={afterNotificationWidgetClick}
        open={isSelected}
        onPopoverClose={() => {
          if (isMounted.current) {
            setIsSelected(false);
          }
        }}
      />
    </S.NotificationsWrapper>
  ) : null;
};

export const PortalNotificationWidget = ({
  afterNotificationWidgetClick,
  afterNotificationClick,
  ...props
}: {
  afterNotificationWidgetClick?: () => void;
  afterNotificationClick?: () => void;
  open?: boolean;
  onPopoverClose?: () => void;
}) => {
  const enableNotifications = useEnableNotifications();
  const { logHit } = useTrackUserAction();
  const navigate = useNavigate();
  const { fetchNotifications, markAsRead, notificationsSummary } = useUserNotificationsContext();

  const onClickNotificationWidget = (currentEvents: NotificationEvent[]) => {
    const summary = notificationsSummary(currentEvents);
    logHit({
      origin: HitOrigin.globalNav,
      action: HitAction.notificationsBellClicked,
      details: {
        ...summary.details,
      },
      references: {
        ...summary.references,
      },
      decodeWithUserId: true,
    });
    afterNotificationWidgetClick && afterNotificationWidgetClick();
  };

  const onNotificationClick = (notification: NotificationEvent) => {
    logHit({
      origin: HitOrigin.globalNav,
      action: HitAction.notificationClicked,
      details: {
        notificationType: notification.type,
        notificationCreatedAt: notification.createdAt,
      },
    });
    markAsRead(notification.ids).finally(() => {
      const url = notification.data?.get("redirectUrl") as string;
      url && navigate(url);
      afterNotificationClick && afterNotificationClick();
    });
  };

  const onMarkAsRead = (ids: (number | string)[]) => {
    logHit({
      origin: HitOrigin.globalNav,
      action: HitAction.notificationMarkAllAsRead,
    });
    markAsRead(ids);
  };

  if (!enableNotifications) return null;

  return (
    <NotificationsWidget
      onClickNotificationWidget={onClickNotificationWidget}
      onMarkAsRead={onMarkAsRead}
      fetchNotifications={fetchNotifications!}
      onNotificationClick={onNotificationClick}
      {...props}
    />
  );
};

const useEnableNotifications = () => {
  const currentUser = useCurrentUser();
  const { fetchNotifications } = useUserNotificationsContext();
  return !!(fetchNotifications && currentUser && !currentUser.accessOnly);
};
