import { IconButton, Tooltip } from "@alphasights/alphadesign-components";
import { ConsultantLed, ExternalLink } from "@alphasights/alphadesign-icons";
import React, { useCallback } from "react";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import useQueryParams from "hooks/useQueryParams";
import { x } from "@xstyled/styled-components";
import { fetch } from "hooks/useApi";
import { useCurrentUser } from "@alphasights/portal-auth-react";

// you can hide these elements by adding hideDebug=true query in the url
export const ViewInSystem = ({
  interactionId,
  projectOverride,
}: {
  interactionId?: string;
  projectOverride?: string;
}) => {
  const queryParams = useQueryParams();
  const debugActions = useCurrentUser()?.permissions?.includes("debug_actions");
  const projectTokenCtx = useCurrentProjectContext()?.project?.token;
  const hideDebug = queryParams.get("hideDebug");
  const viewInSystem = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const url = interactionId
        ? `/api/projects/${projectOverride ?? projectTokenCtx}/interactions/${interactionId}/delivery-manage`
        : `/api/projects/${projectOverride ?? projectTokenCtx}/delivery-manage`;
      e.stopPropagation();
      window.open(url);
    },
    [projectTokenCtx, projectOverride, interactionId]
  );

  const printIds = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const url = `/api/projects/${projectOverride ?? projectTokenCtx}/interactions/${interactionId}/debug`;
      fetch({ url })
        .then((res) => res.json())
        .then((json) => console.log(`Interaction(${interactionId})`, json));
    },
    [projectTokenCtx, projectOverride, interactionId]
  );

  if (!debugActions || hideDebug) return null;

  return (
    <x.div display={"flex"}>
      <Tooltip title={"View in system (This button is visible to internal users only)"} position="right" size="small">
        <IconButton size="small" onClick={viewInSystem} variant="ghost" color="red">
          <ExternalLink />
        </IconButton>
      </Tooltip>
      {interactionId && (
        <Tooltip
          title={"Print decoded interaction to console (This button is visible to internal users only)"}
          position="right"
          size="small"
        >
          <IconButton size="small" onClick={printIds} variant="ghost" color="red">
            <ConsultantLed />
          </IconButton>
        </Tooltip>
      )}
    </x.div>
  );
};
