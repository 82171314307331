import React, { useState } from "react";
import styled, { x } from "@xstyled/styled-components";
import { Close, Expert, InsertLink } from "@alphasights/alphadesign-icons";
import {
  Accordion,
  AccordionItem,
  Button,
  Icon,
  IconButton,
  Popover,
  Typography,
} from "@alphasights/alphadesign-components";
import { useContentFlyoutStyles } from "./ContentFlyout.styles";
import ReactMarkdown from "react-markdown";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { CompanyPill, COMPANY_TYPE } from "@alphasights/client-portal-shared";
import { getCompanyRelationship, suggestionsInfo } from "views/DeliverablesView/helpers";
import { PrimerDescription } from "views/DeliverablesView/PrimerDetails";
import { angleTypeName, groupAndSortExpertsByAngleTypeName } from "components/Experts/CategorizedByAngle";
import { formatAngleTypeName } from "components/Experts/ExpertCard";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { useDeliverableContext } from "providers/DeliverableProvider";
import ExpiringComponent from "components/ExpiringComponent";
import useModal from "hooks/useModal";
import { useLocation } from "router-utils";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";
import { ContentFlyoutFooter } from "../ContentFlyoutFooter/ContentFlyoutFooter";
import { PurchaseInDeliverables } from "views/DeliverablesView/PurchaseInDeliverables";
import { RequestExpertOverlay } from "pages/AlphaNowPage/components";
import useRequestForProject from "views/DeliverablesView/useRequestForProject";
import { DataTestIds } from "pages/AlphaNowPage/components/AlphaNowContent/RequestExpertOverlay";
import useContentVisit from "hooks/useContentVisit";
import { REQUEST_EXPERT_DISCLAIMER } from "views/DeliverablesView/constants";

export const SIDEBAR_WIDTH = 475;

interface FreeSamples {
  COMPANY_PRIMER: string;
  MARKET_PRIMER: string;
  CUSTOMER_PRIMER: string;
  ALPHAVIEW: string;
  PEER_CONTRIBUTED: string;
  ALPHAVIEW_DIAL_IN: string;
}

type handleSubmitProps = (selectedExperts: number[], reason: string) => void;

export interface ContentFlyoutProps {
  onClose: () => void;
  content: ContentResults;
  updateDeliverableSuggestion?: (contentId: string, changes: any) => void;
  freeSamples?: FreeSamples;
  fetchSuggestions?: () => Promise<void>;
  fragmentIds?: string[];
}

export const ContentFlyout = ({
  onClose,
  content,
  updateDeliverableSuggestion = () => {},
  freeSamples,
  fetchSuggestions,
  fragmentIds,
}: ContentFlyoutProps) => {
  const { speakers } = content;
  const [canRequestForProject, setCanRequestForProject] = useState(true);

  const { submit, validSpeakers } = useRequestForProject({ speakers, setCanRequestForProject });

  const { isVisible: isModalVisible, onOpen: onOpenModal, onClose: onCloseModal } = useModal();

  const handleSubmit: handleSubmitProps = async (selectedExperts, reason) => {
    await submit(selectedExperts, reason);
    onCloseModal();
  };

  return (
    <>
      <ContentFlyoutInternal
        onClose={onClose}
        content={content}
        openRequestExpertOverlay={onOpenModal}
        updateDeliverableSuggestion={updateDeliverableSuggestion}
        freeSamples={freeSamples}
        fetchSuggestions={fetchSuggestions}
        canRequestForProject={canRequestForProject}
        fragmentIds={fragmentIds}
      />
      {isModalVisible && (
        <RequestExpertOverlay
          onClose={onCloseModal}
          onCancel={onCloseModal}
          speakers={validSpeakers}
          onSubmit={handleSubmit}
          disclaimer={REQUEST_EXPERT_DISCLAIMER}
        />
      )}
    </>
  );
};

interface ContentFlyoutInternalProps {
  onClose: () => void;
  content: ContentResults;
  openRequestExpertOverlay: () => void;
  updateDeliverableSuggestion: (contentId: string, changes: any) => void;
  freeSamples?: FreeSamples;
  fetchSuggestions?: () => Promise<void>;
  canRequestForProject: boolean;
  fragmentIds?: string[];
}

const ContentFlyoutInternal = ({
  onClose,
  content,
  openRequestExpertOverlay,
  updateDeliverableSuggestion,
  freeSamples,
  fetchSuggestions,
  canRequestForProject,
  fragmentIds,
}: ContentFlyoutInternalProps) => {
  const { project } = useDeliverableContext();
  const { body, header, container, accordion } = useContentFlyoutStyles();
  const {
    location: { protocol, host },
  } = window;

  const { title, recommendationId, id } = suggestionsInfo(content);

  useContentVisit({ recommendationId, contentId: id });

  const isAccessible =
    content.purchaseStatus &&
    content.approvalStatus &&
    isContentAccessible(content.purchaseStatus, content.approvalStatus);

  return (
    <x.div {...container} data-testid="content-flyout">
      <x.div {...header}>
        <CloseButton onClick={onClose} />
        <Topbar
          title={title}
          shareableUrl={`${protocol}//${host}/${project.token}/experts/deliverables-view/?selectedContentId=${content.id}`}
        >
          {content.primer ? (
            <Details>
              <Typography variant="body-small" color="secondary">
                {"Last updated on: "}
                <FormattedDateTime
                  date={content.primer!.lastUpdatedAt?.value ?? content.createdAt}
                  format="d MMM yyyy"
                />
              </Typography>
            </Details>
          ) : (
            <ContentDetails content={content} />
          )}
        </Topbar>
      </x.div>
      <x.div {...body}>
        <Accordion {...accordion}>
          <DescriptionSection content={content} freeSamples={freeSamples} />
          <ExpertsSection
            content={content}
            openRequestExpertOverlay={openRequestExpertOverlay}
            canRequestForProject={canRequestForProject}
          />
        </Accordion>
      </x.div>
      <x.div data-testid="content-flyout-footer">
        {isAccessible ? (
          <ContentFlyoutFooter content={content} fragmentIds={fragmentIds} />
        ) : (
          <PurchaseInDeliverables
            suggestion={content}
            updateDeliverableSuggestion={updateDeliverableSuggestion}
            isContentFlyout
            fetchSuggestions={fetchSuggestions}
          />
        )}
      </x.div>
    </x.div>
  );
};

interface TopbarProps {
  title?: string | React.ReactNode;
  shareableUrl: string;
  children: React.ReactNode;
}

export const Topbar = ({ title, shareableUrl, children }: TopbarProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>();
  const tooltipOpen = Boolean(anchorEl);
  const { topbar, topbarTitle } = useContentFlyoutStyles();

  const onShare = async ({ currentTarget }: { currentTarget: HTMLElement }) => {
    try {
      await navigator.clipboard.writeText(shareableUrl);
      setAnchorEl(currentTarget);
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  return (
    <x.div {...topbar}>
      <x.div {...topbarTitle}>
        <Typography variant="body-large-em">{title}</Typography>
        <IconButton variant="basic" testId="share-link-button" onClick={onShare}>
          <InsertLink />
        </IconButton>
        {tooltipOpen && (
          <ExpiringComponent delay={3000} onExpire={() => setAnchorEl(undefined)}>
            <CopiedPopover
              anchorEl={anchorEl}
              open={tooltipOpen}
              onClose={() => setAnchorEl(undefined)}
              placement="bottom"
            >
              <Typography variant="body-small">Link copied!</Typography>
            </CopiedPopover>
          </ExpiringComponent>
        )}
      </x.div>
      {children}
    </x.div>
  );
};

const CopiedPopover = styled(Popover)`
  background-color: ${tokens.color.background.dark};
  color: ${tokens.color.text.inverse};
  padding: ${tokens.spacing.inner.base};
`;

interface ContentDetailsProps {
  content: ContentResults;
}

const ContentDetails = ({ content }: ContentDetailsProps) => {
  const { contentDetails } = useContentFlyoutStyles();

  let companyRelationship = getCompanyRelationship(content.contentType, content.speakers);

  return (
    <x.div {...contentDetails}>
      <Typography color="secondary">{companyRelationship}</Typography>
      {content.companies
        .filter(({ companyType }: Company) => companyType === COMPANY_TYPE.anchor)
        .map(({ companyName }: Company) => (
          <CompanyPill name={companyName} />
        ))}
      <Typography variant="body-small" color="secondary">
        |
      </Typography>
      <Typography variant="body-small" color="secondary">
        <FormattedDateTime date={content.scheduledTime} format="d MMM yyyy" />
      </Typography>
    </x.div>
  );
};

interface DetailsProps {
  children: React.ReactNode;
}

export const Details = ({ children }: DetailsProps) => {
  const { details } = useContentFlyoutStyles();

  return <x.div {...details}>{children}</x.div>;
};

interface DescriptionSectionProps {
  content: ContentResults;
  freeSamples?: FreeSamples;
}

const DescriptionSection = ({ content, freeSamples }: DescriptionSectionProps) => {
  const { viewFreePrimerButton, primerDescriptionSection, descriptionSectionAccordion } = useContentFlyoutStyles();
  const location = useLocation();

  const freeLink =
    content.contentType &&
    freeSamples &&
    freeSamples[content.contentType] &&
    `${location.pathname}?selectedContent=${freeSamples[content.contentType]}`;

  return content.primer ? (
    <AccordionItem title="What is a Primer" open {...descriptionSectionAccordion}>
      <x.div {...primerDescriptionSection}>
        <PrimerDescription />
        {freeLink && (
          <Button
            variant="secondary"
            size="small"
            {...viewFreePrimerButton}
            data-testid="view-free-primer-button"
            onClick={() => window.open(freeLink, "_blank")}
          >
            View a free Company Primer
          </Button>
        )}
      </x.div>
    </AccordionItem>
  ) : (
    <>
      {content.agenda?.trim() && (
        <AccordionItem title="Topics" open {...descriptionSectionAccordion}>
          <ContentTopics topics={content.agenda} />
        </AccordionItem>
      )}
    </>
  );
};

interface ExpertsSectionProps {
  content: ContentResults;
  openRequestExpertOverlay: () => void;
  canRequestForProject: boolean;
}

const ExpertsSection = ({ content, openRequestExpertOverlay, canRequestForProject }: ExpertsSectionProps) => {
  const { expertsContainer, descriptionSectionAccordion } = useContentFlyoutStyles();

  return (
    <AccordionItem title="Featured Experts" open {...descriptionSectionAccordion}>
      <x.div {...expertsContainer}>
        {content.primer ? <PrimerExperts experts={content.speakers} /> : <ContentExperts experts={content.speakers} />}
        <RequestExpertsButton
          experts={content.speakers}
          openRequestExpertOverlay={openRequestExpertOverlay}
          canRequestForProject={canRequestForProject}
        />
      </x.div>
    </AccordionItem>
  );
};

interface ContentTopicsProps {
  topics?: string;
}

const ContentTopics = ({ topics }: ContentTopicsProps) => {
  return <ReactMarkdownStyled source={topics}></ReactMarkdownStyled>;
};

interface ContentExpertsProps {
  experts: Speaker[];
}

const ContentExperts = ({ experts }: ContentExpertsProps) => {
  const { contentExpertsList } = useContentFlyoutStyles();

  return (
    <x.ul {...contentExpertsList}>
      {experts.map((expert: Speaker) => (
        <li data-testid={`expert-${expert.id}`}>
          <Typography component="span" variant="body-em">
            {expert.company}
          </Typography>
          <Typography component="span">{` - ${expert.jobTitle}`}</Typography>
        </li>
      ))}
    </x.ul>
  );
};

interface PrimerExpertsProps {
  experts: Speaker[];
}

const PrimerExperts = ({ experts }: PrimerExpertsProps) => {
  const { primerExperts, primerExpertsAngle, primerExpertsList, primerExpertsListContainer } = useContentFlyoutStyles();

  const expertsByAngleType = groupAndSortExpertsByAngleTypeName(experts);
  const sortedExperts = [...expertsByAngleType.values()];

  return (
    <x.div {...primerExperts}>
      {sortedExperts.map((experts) => {
        const name = angleTypeName(formatAngleTypeName(experts[0]));
        return (
          <x.div data-testid={`expert-angle-${name}`}>
            <x.div {...primerExpertsAngle}>
              <Typography color="secondary" variant="pre-title">
                {name}
              </Typography>
              <Icon color="secondary">
                <Expert />
              </Icon>
              <Typography color="secondary" variant="pre-title" data-testid="experts-count">
                {experts.length}
              </Typography>
            </x.div>
            <x.div {...primerExpertsListContainer}>
              <x.ul {...primerExpertsList}>
                {experts.map((expert) => (
                  <x.li data-testid={`expert-${expert.id}`}>
                    <Typography component="span" variant="body-em">
                      {expert.company}
                    </Typography>
                    <Typography component="span">{` - ${expert.jobTitle}`}</Typography>
                  </x.li>
                ))}
              </x.ul>
            </x.div>
          </x.div>
        );
      })}
    </x.div>
  );
};

interface CloseButtonProps {
  onClick: () => void;
}

export const CloseButton = ({ onClick }: CloseButtonProps) => (
  <x.div>
    <Icon color="secondary" onClick={onClick} size="medium" data-testid="close-flyout">
      <Close />
    </Icon>
  </x.div>
);

interface RequestExpertsButtonProps {
  experts: Speaker[];
  openRequestExpertOverlay: () => void;
  canRequestForProject: boolean;
}

const RequestExpertsButton = ({
  experts,
  openRequestExpertOverlay,
  canRequestForProject,
}: RequestExpertsButtonProps) => {
  const { requestExpertsButton } = useContentFlyoutStyles();
  return (
    <Button
      size="small"
      variant="secondary"
      onClick={openRequestExpertOverlay}
      {...requestExpertsButton}
      disabled={!canRequestForProject}
      data-testid={DataTestIds.requestExpert}
    >
      Request Expert{experts.length > 1 ? "s" : ""}
    </Button>
  );
};

export const ReactMarkdownStyled = styled(ReactMarkdown as any)`
  & > ul {
    margin-left: ${tokens.spacing.inner.base05};
    list-style-type: disc;
  }
`;
