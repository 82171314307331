import { HitOrigin } from "@alphasights/portal-api-client";
import React, { ReactNode, useCallback, useContext, useState } from "react";
import { HideExpertReasonPopover } from "views/HideExpertReason/HideExpertReasonPopover";
import { ENABLE_PORTAL_COMMENTS, useProjectBadgeContext } from "./BadgeProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";

type OnToggleHiden = (interaction: Interaction, origin: HitOrigin) => Promise<void>;
type OnToggleHidenWithMessage = (interaction: Interaction, origin: HitOrigin, context: string) => void;
type SetHideRef = (interactionId: string, ref: HTMLElement | null, context: string) => void;

interface HideExpertProviderState {
  onToggleHiddenWithMessage: OnToggleHidenWithMessage;
  onToggleHiddenWithoutMessage: OnToggleHiden;
  setHideRef: SetHideRef;
  isLoading: (interactionId: string) => boolean;
  unhideExpert: (interaction: Interaction, origin: HitOrigin) => Promise<void>;
  showReasonPopover: boolean;
  withHideExpertReasonPopover: <P extends object>(
    WrappedComponent: React.ComponentType<P>
  ) => React.ForwardRefExoticComponent<
    React.PropsWithoutRef<
      P & {
        interaction: Interaction;
        origin: HitOrigin;
        context: string;
      }
    > &
      React.RefAttributes<unknown>
  >;
}

interface HideExpertProviderProps {
  children: ReactNode;
  onToggleHidden: any;
  onSaveHideExpertReason: any;
}

export const HideExpertContext = React.createContext<HideExpertProviderState | null>(null);

export const HideExpertProvider = ({
  children,
  onToggleHidden,
  onSaveHideExpertReason,
  ...props
}: HideExpertProviderProps) => {
  const [anchorRef, setAnchorRef] = useState<HTMLElement | null>();
  const [requestReasonData, setRequestReasonData] = useState<{
    interaction: Interaction;
    context: string;
    origin: HitOrigin;
  } | null>();
  const [interactionIdsLoading, setInteractionIdsLoading] = useState<string[]>([]);
  const { hasProjectBadge } = useProjectBadgeContext();
  const currentUser = useCurrentUser();
  const showReasonPopover = currentUser && hasProjectBadge(ENABLE_PORTAL_COMMENTS);

  const setHideRef: SetHideRef = useCallback(
    (interactionId, ref, context) => {
      if (!requestReasonData) return;
      if (requestReasonData.interaction.id !== interactionId) return;
      if (requestReasonData.context !== context) return;

      setAnchorRef(ref);
    },
    [requestReasonData]
  );

  const toggleHidden: OnToggleHiden = useCallback(
    (interaction, origin) => {
      setInteractionIdsLoading((prev) => [...prev, interaction.id]);
      return onToggleHidden(interaction, origin).then(() => {
        setInteractionIdsLoading((prev) => prev.filter((id) => id !== interaction.id));
      });
    },
    [onToggleHidden]
  );

  const onPopoverClose = useCallback(() => {
    if (requestReasonData) {
      toggleHidden(requestReasonData.interaction, requestReasonData.origin);
    }
    setRequestReasonData(null);
    setAnchorRef(null);
  }, [requestReasonData, toggleHidden]);

  const onToggleHiddenWithMessage: OnToggleHidenWithMessage = useCallback(
    (interaction, origin, context) => {
      const hidden = interaction.hidden;
      if (!hidden && showReasonPopover) {
        setRequestReasonData({
          interaction,
          context,
          origin,
        });
      } else {
        toggleHidden(interaction, origin);
      }
    },
    [showReasonPopover, toggleHidden]
  );

  const unhideExpert = useCallback(
    (interaction: Interaction, origin: HitOrigin) => {
      const hidden = interaction.hidden;
      return hidden ? toggleHidden(interaction, origin) : Promise.resolve();
    },
    [toggleHidden]
  );

  const withHideExpertReasonPopover = <P extends object>(WrappedComponent: React.ComponentType<P>) =>
    React.forwardRef(
      (
        {
          interaction,
          origin,
          context,
          ...props
        }: P & { interaction: Interaction; origin: HitOrigin; context: string },
        ref
      ) => {
        const onClick = (event: React.MouseEvent) => {
          event.stopPropagation();
          onToggleHiddenWithMessage(interaction, origin, context);
        };

        return (
          <>
            <WrappedComponent ref={ref} onClick={onClick} {...(props as P)} />
            {requestReasonData?.interaction.id === interaction.id && showReasonPopover && (
              <HideExpertReasonPopover
                onSaveHideExpertReason={onSaveHideExpertReason}
                anchorRef={ref}
                interactionId={interaction.id}
                onClose={onPopoverClose}
              />
            )}
          </>
        );
      }
    );

  const value: HideExpertProviderState = {
    onToggleHiddenWithMessage,
    onToggleHiddenWithoutMessage: toggleHidden,
    unhideExpert,
    setHideRef,
    isLoading: (interactionId) => interactionIdsLoading.includes(interactionId),
    withHideExpertReasonPopover,
    showReasonPopover: !!showReasonPopover,
  };

  return (
    <HideExpertContext.Provider value={value} {...props}>
      {children}
      {anchorRef && requestReasonData && showReasonPopover && (
        <HideExpertReasonPopover
          onSaveHideExpertReason={onSaveHideExpertReason}
          anchorRef={{ current: anchorRef }}
          interactionId={requestReasonData.interaction.id}
          onClose={onPopoverClose}
        />
      )}
    </HideExpertContext.Provider>
  );
};

export const useHideExpertContext = () => useContext(HideExpertContext);
