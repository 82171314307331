import React, { useEffect, useMemo, useState } from "react";
import { Pill, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { getStateColorFlyout, getStateName, getSurveyStateColorFlyout } from "components/AdvisorCard/stateFunctions";
import { CidStatus } from "components/CidBadge/cid-status";
import { Globe, AlphaCircle, Recommended, Angle, Lock, Interpreter, Credit } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useInteractionHeaderStyles } from "./InteractionHeader.styles";
import { InfoBanner } from "components/Banner";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { canCreateResearchRequest } from "components/PastProjectsBanner";
import { Badge } from "components/Badge";
import { PORTAL_WORKSTREAM_BADGE, ProjectBadged, useProjectBadgeContext } from "providers/BadgeProvider";
import { InteractionActionIcons } from "../Topbar/InteractionActionIcons";
import { HighlightTextTypography } from "components/AdvisorCard/HighlightText";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { getLanguageFromCode } from "helpers/displayHelpers";
import { PortalTooltip } from "../../../../components/Tooltip/Tooltip";
import { CtaButton } from "../Topbar/Topbar";
import { useHideExpertContext } from "../../../../providers/HideExpertProvider";
import { isPotentialSurveyResponse, shouldShowTransId } from "components/SurveysPage/helpers";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { getWorkstream } from "components/InteractionsPage/helpers/Workstreams";
import { TooltipStateDetail } from "components/InteractionsPage/TooltipStateDetail";
import { InteractionLabel } from "components/InteractionLabel/InteractionLabel";
import { useStarExpertContext } from "providers/StarExpertProvider";
import { InteractionsService } from "services/interactionsService";

export const InteractionHeader = ({
  interaction,
  isProjectCidEnabled,
  pendingRequest = undefined,
  showCalendarView = false,
  userCidEnabled,
  researchRequest = undefined,
  showActionIcons = false,
  showNewlyAddedMark = false,
  onClick = undefined,
  isMobileLayout = false,
  locked,
  onRequest = undefined,
  currentView = undefined,
  statusComponent,
  customAlphaCircle = null,
  setPlusXInteractionsElem = undefined,
  ...props
}) => {
  const {
    container,
    reEngageBannerStyles,
    firstLineStyles,
    actionsIconsStyles,
    labelStyles,
  } = useInteractionHeaderStyles({
    isMobile: isMobileLayout,
  });

  const {
    backgroundSyncRunning,
    onCancelRequest,
    onOpenComments,
    onRequestRescheduleInteraction,
    pccOptOutWindow,
  } = props;

  const origin = props.origin || currentView;

  const { setHideRef, onToggleHiddenWithMessage } = useHideExpertContext();
  const { onToggleStar } = useStarExpertContext();

  const hideContext = `${origin}flyout`;

  const { project } = useCurrentProjectContext();
  const selectedWorkstream = getWorkstream(project, props.workstreamId);
  const isSurveyWorkstream = selectedWorkstream?.workstreamType === "survey";
  const { isMobile } = useCheckScreen();

  const canRequestForSurvey =
    isSurveyWorkstream &&
    (interaction.state === "available" || interaction.state === "proposed") &&
    (interaction.surveyResponse?.state === "available" || isPotentialSurveyResponse(interaction));

  const alphaCircleMultiplier = customAlphaCircle ?? interaction.alphaCircleMultiplier;

  return (
    <x.div {...container} data-testid="interaction-header">
      <x.div {...firstLineStyles}>
        <AdvisorBadges
          interaction={interaction}
          isProjectCidEnabled={isProjectCidEnabled}
          userCidEnabled={userCidEnabled}
          locked={locked}
          alphaCircleMultiplier={alphaCircleMultiplier}
        />
        {showActionIcons && (
          <InteractionActionIcons
            backgroundSyncRunning={backgroundSyncRunning}
            isActiveProject={interaction.isActiveProject}
            pccOptOutWindow={pccOptOutWindow}
            interaction={interaction}
            showCommentsButton
            showMoreOptionsButton
            onClickComments={() => onOpenComments(interaction)}
            isExpertHidden={interaction.hidden}
            onClickStar={() => onToggleStar(interaction, origin)}
            isExpertStarred={interaction.starred}
            onCancelRequest={() => onCancelRequest(interaction.id, origin)}
            onRequestRescheduleInteraction={onRequestRescheduleInteraction}
            origin={origin}
            token={props.token}
            showLabelButton={currentView === "list-view"}
            {...actionsIconsStyles}
          />
        )}
      </x.div>
      <AdvisorPrimaryPosition
        interaction={interaction}
        pendingRequest={pendingRequest}
        showCalendarView={showCalendarView}
        onClick={onClick}
        showNewlyAddedMark={showNewlyAddedMark}
        statusComponent={statusComponent}
        setPlusXInteractionsElem={setPlusXInteractionsElem}
      />
      <AdvisorSecondaryPosition interaction={interaction} />
      {isMobile && <HasAvailabilityPill interaction={interaction} />}
      <InteractionLabel interaction={interaction} {...labelStyles} />
      {interaction.showRequestToReEngage && !canCreateResearchRequest(researchRequest) && (
        <InfoBanner {...reEngageBannerStyles}>
          Request to re-engage sent by {researchRequest.requester} on{" "}
          <FormattedDateTime date={researchRequest.createdAt} format="dd MMM yyyy" />
        </InfoBanner>
      )}
      <ProjectBadged badge={PORTAL_WORKSTREAM_BADGE}>
        {canRequestForSurvey && (
          <x.div flex row justifyContent={"space-between"} mt={"12px"} w={"100%"}>
            <CtaButton
              data-testid="survey-request"
              loading={interaction.runningAction === "followUp"}
              disabled={backgroundSyncRunning}
              mr={"4px"}
              flex={"1 1 0"}
              onClick={() => onRequest(interaction.id, `${currentView}-regular`)}
            >
              Request for Survey
            </CtaButton>
            <x.div ref={(ref) => setHideRef(interaction.id, ref, hideContext)} ml={"4px"} flex={"1 1 0"}>
              <CtaButton
                data-testid="survey-hide"
                variant={"outline"}
                loading={interaction.runningAction === "followUp"}
                disabled={backgroundSyncRunning}
                w={"100%"}
                onClick={() => onToggleHiddenWithMessage(interaction, origin, hideContext)}
              >
                {interaction.hidden ? "Show" : "Hide"}
              </CtaButton>
            </x.div>
          </x.div>
        )}
      </ProjectBadged>
    </x.div>
  );
};

const AdvisorPrimaryPosition = ({
  interaction,
  pendingRequest,
  showCalendarView,
  onClick,
  showNewlyAddedMark,
  statusComponent,
  setPlusXInteractionsElem,
}) => {
  const {
    color: { text },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  const { primaryPositionDiv } = useInteractionHeaderStyles();
  const isNewlyAdded = interaction.newlyAdded;

  const newlyAddedMark = (
    <x.div
      data-testid={`newly-added-mark-${interaction.id}`}
      w={isNewlyAdded ? "8px" : 0}
      h={isNewlyAdded ? "8px" : 0}
      backgroundColor="background-selected-strong-default"
      borderRadius="50%"
      alignSelf="center"
      mr={isNewlyAdded ? "8px" : 0}
      visibility={isNewlyAdded ? "visible" : "hidden"}
      opacity={isNewlyAdded ? "1" : "0"}
      transition="all 0.3s"
      flexShrink={0}
    />
  );

  return (
    <>
      <x.div onClick={onClick} cursor={onClick ? "pointer" : undefined} data-testid="advisor-card-highlight-group">
        <x.div {...primaryPositionDiv}>
          {showNewlyAddedMark && newlyAddedMark}
          <>
            {statusComponent ? (
              statusComponent
            ) : (
              <AdvisorStatus pendingRequest={pendingRequest} showCalendarView={showCalendarView} {...interaction} />
            )}
            <InteractionsCount interaction={interaction} setPlusXInteractionsElem={setPlusXInteractionsElem} />
            <Typography variant={isMobile ? "body-em" : "body-large-em"} component="span" color={text.strong._}>
              {" "}
              •{" "}
            </Typography>
            {interaction.advisorCompany && interaction.role ? (
              <>
                <HighlightTextTypography
                  highlights={interaction.doubleBlinded ? [] : interaction.highlights}
                  fieldNames={[
                    "advisorships.relevant_company_name",
                    "advisorships.relevant_company_name.concat",
                    "relevantPrimaryJob.companyName",
                    "relevantPrimaryJob.companyName.concat",
                  ]}
                  text={`${interaction.advisorCompany}`}
                  variant={isMobile ? "body-em" : "body-large-em"}
                  component="span"
                  color={text.strong._}
                />
                <Typography variant={isMobile ? "body-em" : "body-large-em"} component="span" color={text.strong._}>
                  {" - "}
                </Typography>
                <HighlightTextTypography
                  highlights={interaction.doubleBlinded ? [] : interaction.highlights}
                  fieldNames={[
                    "advisorships.relevant_job_title",
                    "advisorships.relevant_job_title.concat",
                    "relevantPrimaryJob.jobTitle",
                    "relevantPrimaryJob.jobTitle.concat",
                  ]}
                  text={interaction.role}
                  variant={isMobile ? "body-em" : "body-large-em"}
                  component="span"
                  color={text.strong._}
                />
              </>
            ) : (
              <Typography variant={isMobile ? "body-em" : "body-large-em"} component="span">
                No Position Found
              </Typography>
            )}
            <ProjectBadged badge={PORTAL_WORKSTREAM_BADGE}>
              {shouldShowTransId(interaction) && (
                <PortalTooltip title={"Expert Identifier"} position={"bottom"} dark>
                  <Typography
                    variant={isMobile ? "body-em" : "body-large-em"}
                    data-testid="flyout-survey-transId"
                    component="span"
                    textTransform="none"
                    color={text.strong._}
                  >
                    {` • ${interaction.surveyResponse.transId}`}
                  </Typography>
                </PortalTooltip>
              )}
            </ProjectBadged>
          </>
        </x.div>
        {interaction.rolePeriod && interaction.rolePeriod.length > 0 && (
          <Typography variant={isMobile ? "body" : "body-large"} component="div" color={text.strong._}>
            {interaction.rolePeriod}
          </Typography>
        )}
      </x.div>
    </>
  );
};

export const AdvisorStatus = (props) => {
  const {
    hidden,
    state,
    followUpId,
    previousAdvisorshipId,
    showCalendarView,
    pendingRequest,
    surveyResponse,
    interactionType,
  } = props;

  const { isMobile } = useCheckScreen();
  const { color } = useThemeTokens();
  const { advisorStatus } = useInteractionHeaderStyles();
  const { hasProjectBadge } = useProjectBadgeContext();

  const hasPortalWorkstreams = hasProjectBadge(PORTAL_WORKSTREAM_BADGE);

  const textColor = (hasPortalWorkstreams, interactionType, surveyResponse, state) => {
    const isSurvey = interactionType === "Industry Survey";
    const isSurveyState = isSurvey && hasPortalWorkstreams && surveyResponse;
    const stateColor = isSurveyState
      ? getSurveyStateColorFlyout(surveyResponse.state, color)
      : getStateColorFlyout(state, color);

    const textColor = hidden ? color.text.secondary : stateColor;

    const getSurveyStateName = (str) => {
      const tmp = str.replace(/_([a-z])/g, (g, w) => ` ${w.toUpperCase()}`);
      return tmp[0].toUpperCase() + tmp.substring(1);
    };

    const stateName = isSurveyState
      ? getSurveyStateName(surveyResponse.state)
      : getStateName({
          state,
          previousAdvisorshipId,
          hidden,
          followUpId,
          showCalendarView,
          pendingRequest,
        });

    return (
      <TooltipStateDetail interaction={props} pendingRequest={pendingRequest}>
        <Typography
          variant={isMobile ? "body-em" : "body-large-em"}
          color={textColor}
          component="span"
          {...advisorStatus}
        >
          {stateName}
        </Typography>
      </TooltipStateDetail>
    );
  };

  return (
    <x.div display="inline">
      <Typography variant={isMobile ? "h2" : "h3"} data-testid={`advisor-name-${state}`} component="span">
        {textColor(hasPortalWorkstreams, interactionType, surveyResponse, state)}
      </Typography>
    </x.div>
  );
};

const InteractionsCount = ({ interaction, setPlusXInteractionsElem }) => {
  const { isMobile } = useCheckScreen();
  const { color } = useThemeTokens();

  const otherInteractions = interaction.othersCounts
    ? Object.values(interaction.othersCounts).reduce((partialSum, a) => partialSum + a, 0)
    : 0;

  if (!otherInteractions) {
    return null;
  }

  return (
    <Typography
      ref={setPlusXInteractionsElem}
      variant={isMobile ? "body-em" : "body-large-em"}
      component="span"
      color={color.text.secondary}
    >
      {`, +${otherInteractions} more`}
    </Typography>
  );
};

const AdvisorSecondaryPosition = ({ interaction }) => {
  const { isMobile } = useCheckScreen();
  const { secondaryPositionDiv, secondaryPositionTitle } = useInteractionHeaderStyles();

  if (!interaction.secondaryTitle) {
    return null;
  }

  return (
    <x.div {...secondaryPositionDiv}>
      {interaction.doubleBlinded ? (
        <span>{interaction.secondaryTitle}</span>
      ) : (
        <Typography component="span" variant={isMobile ? "body-small" : "body"} {...secondaryPositionTitle}>
          {`Secondary: ${interaction.secondaryTitle}`}
          {interaction.secondaryRolePeriod && ` • ${interaction.secondaryRolePeriod}`}
        </Typography>
      )}
    </x.div>
  );
};

const AdvisorBadges = ({ interaction, isProjectCidEnabled, userCidEnabled, locked, alphaCircleMultiplier }) => {
  const { advisorBadges } = useInteractionHeaderStyles();
  const { project } = useCurrentProjectContext();
  const needsInterpreter = useMemo(() => getLanguageFromCode(interaction.needsInterpreter), [
    interaction.needsInterpreter,
  ]);

  return (
    <x.div {...advisorBadges}>
      {locked && <LockedBadge />}
      {interaction.group?.name && <AngleBadge value={interaction.group.name} />}
      {interaction.isActiveProject && isProjectCidEnabled && interaction.externalCidStatus && (
        <CidBadge userCidEnabled={userCidEnabled} externalCidStatus={interaction.externalCidStatus}></CidBadge>
      )}
      {interaction.highlyRecommended && (
        <HighlyRecommendedBadge
          reason={interaction.highlyRecommendedReason}
          primaryContact={interaction.primaryContact}
        />
      )}
      {!project.showAdvisorRateAsCurrency && interaction.isActiveProject && alphaCircleMultiplier > 1.0 && (
        <AlphaCircleBadge value={alphaCircleMultiplier} />
      )}
      {interaction.geographies && interaction.geographies.length > 0 && (
        <GeographicKnowledgeBadge value={interaction.geographies} />
      )}
      {interaction.needsInterpreter && <NeedsInterpreterBadge value={needsInterpreter} />}

      {project.showAdvisorRateAsCurrency && <PriceEstimationBadge interaction={interaction} />}
    </x.div>
  );
};

const getCidStatusColor = (status, colorTokens) => {
  switch (status) {
    case CidStatus.BLOCKED:
      return colorTokens.base.orange[100];
    case CidStatus.CLEARED:
      return colorTokens.background.success;
    case CidStatus.APPROVED:
      return colorTokens.background.processing.default;
    case CidStatus.PENDING:
      return colorTokens.background.neutral.default;
    default:
      return null;
  }
};

export const CidBadge = ({ userCidEnabled, externalCidStatus, useFullWidth = false }) => (
  <>
    {userCidEnabled ? (
      <VisibleCidStatusBadge externalCidStatus={externalCidStatus} />
    ) : (
      <HiddenCidStatusBadge useFullWidth={useFullWidth} />
    )}
  </>
);

const VisibleCidStatusBadge = ({ externalCidStatus }) => {
  const { color } = useThemeTokens();

  if (!externalCidStatus) return null;

  const colour = getCidStatusColor(externalCidStatus, color) || null;
  return (
    <Badge
      value={externalCidStatus.toLowerCase()}
      backgroundColor={colour}
      textTransform="capitalize"
      data-testid="cid-status-badge"
    />
  );
};

const HiddenCidStatusBadge = ({ useFullWidth }) => (
  <Badge useFullWidth={useFullWidth} tooltipText="You must be signed in to view the CID Status." />
);

const AngleBadge = ({ value }) => (
  <Badge value={value} icon={<Angle />} data-testid="angle-badge" characterLimit="26" />
);

const AlphaCircleBadge = ({ value }) => (
  <Badge
    tooltipText={`Available at a rate of ${value} credits per hour`}
    value={value}
    icon={<AlphaCircle />}
    data-testid="alphacircle-badge"
  />
);

export const getGeographicKnowledgeText = (value) => {
  return value[0] + (value.length > 1 ? `, + ${value.length - 1} more` : "");
};

const GeographicKnowledgeBadge = ({ value }) => (
  <Badge
    tooltipText={
      <>
        Geographic knowledge:{" "}
        <Typography variant="body-small-em" component="span">
          {value.join(", ")}
        </Typography>
      </>
    }
    value={getGeographicKnowledgeText(value)}
    icon={<Globe />}
    data-testid="geographic-knowledge-badge"
  />
);

export const HighlyRecommendedBadge = () => (
  <Badge
    tooltipText="This expert is highly recommended."
    value="Highly Recommended"
    icon={<Recommended />}
    data-testid="highly-recommended-badge"
  />
);

const LockedBadge = () => <Badge icon={<Lock />} data-testid="locked-advisor-badge" />;

const NeedsInterpreterBadge = ({ value }) => (
  <Badge
    tooltipText={
      <>
        Needs interpreter:{" "}
        <Typography variant="body-small-em" component="span">
          {value}
        </Typography>
      </>
    }
    value={value}
    icon={<Interpreter />}
    data-testid="needs-interpreter-badge"
  />
);

const PriceEstimationBadge = ({ interaction }) => {
  const [estimatedPrice, setEstimatedPrice] = useState(null);

  useEffect(() => {
    InteractionsService.getEstimatedPrice(interaction.projectToken, interaction.id).then((response) => {
      setEstimatedPrice(response.estimatedPrice);
    });
  }, [interaction.id, interaction.projectToken]);

  if (!estimatedPrice) {
    return null;
  }

  return (
    <Badge
      tooltipText={<>Advisor available for {estimatedPrice} per hour.</>}
      value={`${estimatedPrice}/hr`}
      icon={<Credit />}
      data-testid="price-estimation-badge"
    />
  );
};

const HasAvailabilityPill = ({ interaction }) => {
  const hasAvailability = (interaction?.advisorAvailability || []).length;
  const { spacing } = useThemeTokens();

  return hasAvailability && !interaction?.scheduled ? (
    <Pill
      isInteractive={false}
      size="small"
      style={{ height: spacing.inner.base06, marginTop: spacing.inner.base03 }}
      variant="green"
    >
      Expert has availability
    </Pill>
  ) : null;
};
