import { useCallback, useMemo, useState } from "react";
import { AngleTypeSynthesis } from "@alphasights/portal-api-client";
import _ from "lodash";
import { question } from "../synthesisTypeGuards";

export type SynthesisForm = {
  angleType: string | null;
  question: string;
  errors?: {
    angleType?: boolean;
    question?: boolean;
  };
};

export const useNewSynthesisModule = ({
  projectSynthesis,
  onSubmitQuestion,
}: {
  projectSynthesis: AngleTypeSynthesis[];
  onSubmitQuestion: (question: string, angleTypeName: string, suggestionAcceptedId?: string) => Promise<any> | null;
}) => {
  const [form, setForm] = useState<SynthesisForm>({ angleType: null, question: "" });
  const [selectedSuggestion, setSelectedSuggestion] = useState<string | undefined>();
  const anglesTypes = useMemo(() => projectSynthesis.map((ps) => ps.angleType), [projectSynthesis]);
  const suggestions = useMemo(
    () =>
      projectSynthesis.flatMap((ps) =>
        ps.modules
          .filter((m) => m.contentType === "SUGGESTED_INQUIRY" && m.revisions[0])
          .map((m) => ({
            angleType: ps.angleType,
            question: question(m.revisions[0].contents).question,
            id: m.id,
          }))
      ),
    [projectSynthesis]
  );

  const suggestionMap = useMemo(
    () =>
      _.groupBy(
        suggestions.filter((s) => s.id !== selectedSuggestion),
        "angleType"
      ),
    [suggestions, selectedSuggestion]
  );

  const updateForm = useCallback((field: string, value: string) => {
    setForm((form) => ({
      ...form,
      [field]: value,
      errors: {
        ...form.errors,
        [field]: undefined,
      },
    }));
  }, []);

  const angleOptions = useMemo(() => {
    return anglesTypes.map((a) => ({
      id: a,
      label: a,
      value: a,
      onClick: () => updateForm("angleType", a),
    }));
  }, [updateForm, anglesTypes]);

  const consumeSuggestion = useCallback(
    (angleType: string, question: string, id: string) => {
      updateForm("angleType", angleType);
      updateForm("question", question);
      setSelectedSuggestion(id);
    },
    [updateForm]
  );

  const questionHasError = useCallback((question: string) => {
    const trimmedQuestion = question.trim();
    return !trimmedQuestion || trimmedQuestion.length < 25;
  }, []);

  const onSubmit = useCallback(() => {
    let errors = {
      angleType: !form.angleType,
      question: questionHasError(form.question),
    };

    setForm((form) => ({
      ...form,
      errors,
    }));

    if (Object.values(errors).some(_.identity)) return null;

    return onSubmitQuestion(form.question, form.angleType!, selectedSuggestion);
  }, [form.angleType, form.question, onSubmitQuestion, selectedSuggestion, questionHasError]);

  const reset = useCallback(() => {
    setForm({ angleType: anglesTypes.length === 1 ? anglesTypes[0] : null, question: "" });
    setSelectedSuggestion(undefined);
  }, [anglesTypes]);

  return useMemo(
    () => ({
      angleOptions,
      form,
      consumeSuggestion,
      updateForm,
      onSubmit,
      reset,
      selectedSuggestion,
      suggestionMap,
      questionHasError,
    }),
    [
      angleOptions,
      form,
      consumeSuggestion,
      updateForm,
      onSubmit,
      selectedSuggestion,
      suggestionMap,
      reset,
      questionHasError,
    ]
  );
};
