import React, { useEffect, useState } from "react";
import { Spinner } from "../../components/Spinner";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import {
  FormBody,
  FormContainer,
  FormHeader,
  PasswordConfirmationField,
  PasswordField,
  PasswordRulesList,
  FormFooter,
  FormContent,
} from "./Form";
import { getValidationRules, validatePasswordForm } from "./validators";
import { fetch } from "../../hooks/useApi";
import { BackgroundPage, ModalForm } from "./BackgroundPage";
import { NotFoundPage } from "@alphasights/client-portal-shared";
import { ErrorNotification } from "./ErrorNotification";
import { useParams, Navigate } from "router-utils";
import { Help, LimitationOfUsage } from "./index";
import { Button, Typography, useAlphaToast, Link } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { SignInFooter } from "./index";
import { useLoginStyles } from "./index.styles";
import isEmpty from "lodash/isEmpty";
import useRedirect from "hooks/useRedirect";
import useQueryParams from "hooks/useQueryParams";
import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";

export const CreatePasswordPage = () => {
  const queryParams = useQueryParams();
  const { token } = useParams();
  const [error, setError] = useState(null);
  const [invitation, setInvitation] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingInvitation, setLoadingInvitation] = useState(true);
  const [step, setCurrentStep] = useState("create-password");
  const [stepHistory, setStepHistory] = useState([]);
  const { toast } = useAlphaToast();
  const portal = useClientPortalOperations();

  const performRedirect = useRedirect();

  const setStep = (newstep) => {
    setStepHistory([...stepHistory, step]);
    setCurrentStep(newstep);
  };

  useEffect(() => {
    fetch({
      url: `/api/invitations/${token}`,
      skipAlert: true,
    })
      .then((res) => res.json())
      .then((invitation) => {
        setInvitation(invitation);
        setLoadingInvitation(false);
        setError(null);
      })
      .catch((err) => {
        setError(err);
        setLoadingInvitation(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const acceptInvitation = async (input) => {
    setLoading(true);
    fetch({
      url: `/api/invitations/${token}/accept`,
      method: "POST",
      body: JSON.stringify({ password: input.password }),
      skipAlert: true,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.redirectUrl) {
          queryParams.set("next", data.redirectUrl);
        }
        setStep("success");
        setLoading(false);
        setError(null);
      })
      .catch(async (err) => {
        const { message } = await err.json();
        toast.error({ message });
        setLoading(false);
      });
  };

  const goToPreviousStep = () => {
    const previousStep = stepHistory.length ? stepHistory[stepHistory.length - 1] : step;
    setCurrentStep(previousStep);
    setStepHistory(stepHistory.slice(0, stepHistory.length - 1));
  };

  if (isLoadingInvitation) {
    return (
      <div className="aui-flex aui-justify-center aui-items-center aui-h-screen">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <NotFoundPage />;
  }

  if (invitation?.state === "CANCELED") {
    return <InvitationCanceledNotification />;
  }

  if (invitation?.state === "ACCEPTED") {
    return <InvitationAcceptedNotification />;
  }

  return (
    <SwitchTransition>
      <CSSTransition
        key={step}
        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
        classNames="LoginTransition"
      >
        <>
          {step === "create-password" && (
            <ModalForm slotHeight="529px">
              <CreatePasswordForm
                email={invitation.email}
                isLoading={isLoading}
                onSubmit={acceptInvitation}
                onClickLOU={() => setStep("lou")}
                onClickHelp={() => setStep("help")}
              />
            </ModalForm>
          )}
          {step === "success" && (
            <>
              {
                <PasswordCreated
                  email={invitation.email}
                  onClickGoToPlatform={() => portal.authenticate().then(() => performRedirect())}
                  onClickLOU={() => setStep("lou")}
                  onClickHelp={() => setStep("help")}
                />
              }
            </>
          )}
          {step === "sign-in" && <Navigate to="/sign-in" />}
          {step === "help" && (
            <ModalForm step={step}>
              <Help onClickReturn={goToPreviousStep} />
            </ModalForm>
          )}
          {step === "lou" && (
            <ModalForm step={step}>
              <LimitationOfUsage onClickReturn={goToPreviousStep} onClickHelp={() => setStep("help")} />
            </ModalForm>
          )}
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};

const InvitationAcceptedNotification = () => (
  <InvalidStateNotification>
    <Typography>
      It looks like you've already created an account. Please try{" "}
      <Link href="/sign-in" size="medium">
        signing in
      </Link>
      . If you continue having a problem signing in, please contact us at{" "}
      <Link href="mailto:clientsupport@alphasights.com" size="medium" target="_blank">
        clientsupport@alphasights.com
      </Link>
    </Typography>
  </InvalidStateNotification>
);

const InvitationCanceledNotification = () => (
  <InvalidStateNotification>
    <Typography>
      This link is no longer valid. Please{" "}
      <Link href="/sign-in" size="medium">
        sign in
      </Link>{" "}
      with your current email address. If you continue having a problem signing in, please contact us at{" "}
      <Link href="mailto:clientsupport@alphasights.com" size="medium" target="_blank">
        clientsupport@alphasights.com
      </Link>
    </Typography>
  </InvalidStateNotification>
);

const InvalidStateNotification = ({ children }) => (
  <BackgroundPage>
    <ErrorNotification>{children}</ErrorNotification>
  </BackgroundPage>
);

export const CreatePasswordForm = ({ onSubmit, email, error, isLoading, onClickLOU, onClickHelp }) => {
  const { modalTitle, modalDescription, validationMessage, passwordField, createPasswordButton } = useLoginStyles();

  const [validationRules, setValidationRules] = useState([]);

  useEffect(() => {
    getValidationRules().then((res) => setValidationRules(res));
  }, []);

  return (
    <FormContainer
      initialValues={{
        email,
        password: "",
        passwordConfirmation: "",
      }}
      onSubmit={onSubmit}
      isLoading={isLoading}
      validate={(props) => validatePasswordForm({ ...props, validationRules })}
    >
      {({ touched, errors }) => (
        <>
          <FormHeader
            title={
              <Typography variant="h3" {...modalTitle}>
                Create password
              </Typography>
            }
            description={
              <Typography {...modalDescription}>Enter a password to complete setting up your account.</Typography>
            }
            error={error}
          />
          <FormBody>
            <FormContent>
              <PasswordField showErrorText={false} />
              <x.div {...passwordField}>
                <PasswordConfirmationField showErrorText={false} />
                <Typography variant="body-small" {...validationMessage}>
                  {!isEmpty(touched) && errors.password}
                </Typography>
              </x.div>
              <x.div {...passwordField}>
                <PasswordRulesList validationRules={validationRules} />
              </x.div>
              <Button variant="secondary" type="submit" isLoading={isLoading} {...createPasswordButton}>
                <Typography variant="body-em">Create Password</Typography>
              </Button>
            </FormContent>
          </FormBody>
          <FormFooter>
            <SignInFooter showLimitationsOfUse showSignUp={false} onClickLOU={onClickLOU} onClickHelp={onClickHelp} />
          </FormFooter>
        </>
      )}
    </FormContainer>
  );
};

const PasswordCreated = ({ email, onClickGoToPlatform, onClickLOU, onClickHelp }) => {
  const { modalTitle, backButton, emailLabel, emailHeaderDiv } = useLoginStyles();

  return (
    <ModalForm>
      <FormContainer>
        <FormHeader
          title={
            <Typography variant="h3" {...modalTitle}>
              Your account has been created
            </Typography>
          }
          description={
            <>
              <Typography component="div" {...emailLabel} {...emailHeaderDiv}>
                {email}
              </Typography>
              <Typography component="div" {...emailHeaderDiv}>
                Your account has been successfully created.
              </Typography>
            </>
          }
        />
        <FormBody>
          <Button variant="secondary" onClick={onClickGoToPlatform} {...backButton}>
            <Typography variant="body-em">Go To Platform</Typography>
          </Button>
        </FormBody>
        <FormFooter>
          <SignInFooter showLimitationsOfUse showSignUp={false} onClickLOU={onClickLOU} onClickHelp={onClickHelp} />
        </FormFooter>
      </FormContainer>
    </ModalForm>
  );
};
