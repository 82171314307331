import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ClientContact } from "models/ClientContact";
import { projectLauncherService } from "services/projectLauncherService";
import { ExternalAlphaCompaniesService } from "services/externalAlphaCompaniesService";
import { usePortalStoreOperations } from "@alphasights/portal-auth-react";
import { usePreference } from "hooks/usePreference";
import { PreferenceType } from "./types";
import { useTimezone } from "./TimezoneProvider";
import { ExternalAlphaCompany } from "models/ExternalAlphaCompany";
import { CompanyCreationRequest } from "models/ProjectLauncher";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { debounce } from "lodash";

export interface ProjectLauncherContextState {
  projectBrief: string;
  numberOfCalls?: number;
  surveyRequested: boolean;
  clientContacts: ClientContact[];
  companies: ExternalAlphaCompany[];
  mainCompany?: ExternalAlphaCompany;
  onProjectBriefChange: (value: string) => void;
  onNumberOfCallsChange: (value: number) => void;
  onClientContactsChange: (value: ClientContact[]) => void;
  onCompaniesChange: (companies: ExternalAlphaCompany[]) => void;
  onMainCompanyChange: (company?: ExternalAlphaCompany) => void;
  onLaunchProject: () => Promise<string>;
  onSurveyRequestedChange: (value: boolean) => void;
  searchRecommendedContent: () => void;
  recommendedContent: ContentResults[];
}

export const ProjectLauncherContext = React.createContext<undefined | ProjectLauncherContextState>(undefined);

export const ProjectLauncherProvider = ({
  service = projectLauncherService,
  companiesService = ExternalAlphaCompaniesService,
  children,
}: {
  service?: typeof projectLauncherService;
  companiesService?: typeof ExternalAlphaCompaniesService;
  children: JSX.Element;
}) => {
  const { logHit } = useTrackUserAction();
  const [surveyRequested, setSurveyRequested] = useState<boolean>(false);
  const [projectBrief, setProjectBrief] = useState("");
  const [clientContacts, setClientContacts] = useState<ClientContact[]>([]);
  const [recommendedContent, setRecommendedContent] = useState<ContentResults[]>([]);
  const [numberOfCalls, setNumberOfCalls] = useState<number>();
  const [companies, setCompanies] = useState<ExternalAlphaCompany[]>([]);
  const [mainCompany, setMainCompany] = useState<ExternalAlphaCompany>();
  const { preference } = usePreference(PreferenceType.TIMEZONE);
  const { currentTimezone } = useTimezone();

  const {
    projects: { operations: projectOperations },
  } = usePortalStoreOperations();

  const onLaunchProject = () => {
    return service
      .launchProject({
        title: "[Project Name Pending]",
        brief: projectBrief,
        expectedInteractionsCount: numberOfCalls ?? 0,
        clientContactIds: clientContacts.map((contact) => contact.id),
        recommendedContentIds: recommendedContent.map((content) => content.id),
        companies: companies.map(
          (company) =>
            ({
              externalCompanyId: company.id > 0 ? company.id : undefined,
              name: company.name,
              main: company.name === mainCompany?.name,
            } as CompanyCreationRequest)
        ),
        preferredTimezone: preference?.attributes?.timezone ?? currentTimezone,
        surveyRequested: surveyRequested,
      })
      .then(({ token }) => {
        projectOperations.loadMyProjects();
        return token;
      });
  };

  const onCompaniesChange = useCallback(
    (companies: ExternalAlphaCompany[]) => {
      const companyNames = companies.map((company) => company.name);
      const mainCompanyName = mainCompany?.name;
      const mainCompanyRemoved = mainCompanyName && !companyNames.includes(mainCompanyName);

      setCompanies(companies);

      if (mainCompanyRemoved) {
        setMainCompany(undefined);
      }
    },
    [mainCompany]
  );

  const onMainCompanyChange = (mainCompany?: ExternalAlphaCompany) => {
    setMainCompany(mainCompany);
  };

  const searchRecommendedContent = useCallback(() => {
    if (companies.length === 0) {
      setRecommendedContent([]);
      return;
    }

    projectLauncherService.fetchRecommendedResearch(companies.map((company) => company.id)).then((results) => {
      logHit({
        origin: HitOrigin.projectLaunchPage,
        action: HitAction.projectLaunchRecommendedResearchPopulated,
        details: {
          contentIds: results.map((result) => result.id),
        },
      });
      setRecommendedContent(results);
    });
  }, [companies, logHit]);

  const onProjectBriefChange = (brief: string) => {
    setProjectBrief(brief);
    debouncedProjectBriefChange(brief);
  };

  const companiesRef = useRef(companies);

  useEffect(() => {
    companiesRef.current = companies;
  }, [companies]);

  const debouncedProjectBriefChange = useMemo(
    () =>
      debounce((e: string) => {
        if (!e) return;
        companiesService.companyMatch(e).then((briefCompanies: ExternalAlphaCompany[]) => {
          let newCompanies = briefCompanies.filter(
            (company) => !companiesRef.current.some((c) => c.name === company.name)
          );
          onCompaniesChange([...companiesRef.current, ...newCompanies]);
        });
      }, 3000),
    [companiesService, onCompaniesChange]
  );

  const context = {
    projectBrief,
    onProjectBriefChange,
    numberOfCalls,
    onNumberOfCallsChange: setNumberOfCalls,
    clientContacts,
    onClientContactsChange: setClientContacts,
    companies,
    mainCompany,
    onCompaniesChange,
    onMainCompanyChange,
    onLaunchProject,
    surveyRequested,
    onSurveyRequestedChange: setSurveyRequested,
    searchRecommendedContent,
    recommendedContent,
  };

  return <ProjectLauncherContext.Provider value={context} children={children} />;
};

export const useProjectLauncherContext = () => {
  const context = useContext(ProjectLauncherContext);

  if (!context) throw new Error("ProjectLauncherContext should only be used within ProjectLauncherProvider");

  return context;
};
