import React, { FC } from "react";
import styled from "@xstyled/styled-components";
import { Info } from "@alphasights/alphadesign-icons";
import { AlphaTableTypes } from "@alphasights/alphadesign-table";
import { Icon, Loading, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";

import {
  FILE_STATUS_DISPLAY,
  FILE_STATUS_TOOLTIP,
  FileStatus,
} from "views/DeliverablesView/ThirdPartyDocUploadModal/constants";
import { FileRow } from "views/DeliverablesView/ThirdPartyDocUploadModal/FileTable/types";

const InfoIcon: FC<{ status: FileStatus }> = ({ status }) => {
  if ([FileStatus.failed, FileStatus.informationRequired].includes(status)) {
    const tooltipTitle = FILE_STATUS_TOOLTIP[status];
    return (
      <Tooltip title={tooltipTitle} variant="dark" size="small" disabled={!tooltipTitle}>
        <StyledIcon>
          <Info />
        </StyledIcon>
      </Tooltip>
    );
  }
  return null;
};

const StatusCell: FC<AlphaTableTypes.CellContext<FileRow, any>> = ({ row: { original: selectedFile } }) => {
  const { color } = useThemeTokens();
  const status = selectedFile.status;
  const textColor = status === FileStatus.failed ? color.text.danger : color.text.strong._;
  const displayedStatus = FILE_STATUS_DISPLAY[status];

  if (selectedFile.status === FileStatus.processing) {
    return <Loading size="sm" />;
  }

  return (
    <Wrapper>
      <StyledTypography color={textColor}>{displayedStatus}</StyledTypography>
      <InfoIcon status={status} />
    </Wrapper>
  );
};

const Wrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
      display: flex;
      gap: ${spacing.inner.base};
      align-items: center;
    `;
});

const StyledTypography = styled(Typography).attrs({ variant: "body-small", component: "span" })`
  white-space: nowrap;
  overflow: hidden;
`;

const StyledIcon = styled(Icon).attrs({ size: "small" })`
  &:hover {
    cursor: pointer;
  }
`;

export default StatusCell;
