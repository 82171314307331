import { AiAskQuestionInput } from "components/AiAskQuestionInput";
import { useStyles } from "./NewSynthesisModuleContent.styles";
import React, { useEffect } from "react";
import { x } from "@xstyled/styled-components";
import { Button, ListOption, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Add } from "@alphasights/alphadesign-icons";
import styled from "styled-components";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import { HitAction } from "@alphasights/portal-api-client";

export const NewSynthesisModuleContent = () => {
  const { newSynthesisModule, synthesisLogHit } = useProjectSynthesisContext();

  const styles = useStyles();
  const tokens = useThemeTokens();

  useEffect(() => newSynthesisModule.reset(), []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <x.div {...styles.wrapper}>
      <x.div display="flex" gap="8px">
        <Typography variant="body-em" color="secondary">
          Create a new module that synthesizes transcripts with
        </Typography>
        {newSynthesisModule.angleOptions.length === 1 ? (
          <Typography variant="body-em">{newSynthesisModule.form.angleType}</Typography>
        ) : (
          <Button
            variant="ghost"
            dropdown
            splitOptions={newSynthesisModule.angleOptions}
            color={newSynthesisModule.form.errors?.angleType ? tokens.color.text.danger : undefined}
            dataAttributes={{
              "data-testid": "angle-handle",
            }}
          >
            {newSynthesisModule.form.angleType ?? "[Select Angle]"}
          </Button>
        )}
      </x.div>

      <AiAskQuestionInput
        text={newSynthesisModule.form.question}
        onChange={(question: string) => newSynthesisModule.updateForm("question", question)}
        onSubmit={() => newSynthesisModule.onSubmit()}
        error={
          newSynthesisModule.form.errors?.question
            ? "Input too brief. Please rephrase the question and try again."
            : undefined
        }
        data-testid="ai-question"
        onBlur={() => {}}
        isNewQuestion
        isLoading={false}
      />

      {Object.keys(newSynthesisModule.suggestionMap)
        .filter((angleType) => newSynthesisModule.suggestionMap[angleType]?.length)
        .filter((angleType) => !newSynthesisModule.form.angleType || angleType === newSynthesisModule.form.angleType)
        .map((angleType) => {
          return (
            <x.div key={angleType} {...styles.suggestionWrapper} data-testid={`suggestions-${angleType}`}>
              <Typography variant="body-em" {...styles.suggestionAngle}>
                Ask {angleType}
              </Typography>
              {newSynthesisModule.suggestionMap[angleType].map((s) => {
                return (
                  <WordWrapListOption
                    dataAttributes={{ "data-testid": `suggestion-${s.angleType}-${s.id}` }}
                    key={s.question}
                    type="text"
                    label={s.question}
                    onChange={() => {
                      synthesisLogHit({
                        action: HitAction.projectSynthesisSuggestionSelected,
                        details: { question: s.question, angleType },
                      });
                      newSynthesisModule.consumeSuggestion(angleType, s.question, s.id);
                    }}
                    leftIcon={<Add />}
                  />
                );
              })}
            </x.div>
          );
        })}
    </x.div>
  );
};

const WordWrapListOption = styled(ListOption)`
  .list-option-label {
    overflow: visible;
    white-space: unset;
    ellipsis: unset;
  }
`;
