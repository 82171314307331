import React, { useEffect, useState, useCallback } from "react";
import { x } from "@xstyled/styled-components";
import { AccordionItem, Alert, Link, Typography } from "@alphasights/alphadesign-components";

import { fetch, useApi } from "hooks/useApi";
import { CidStatus } from "components/CidBadge/cid-status";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { CidStatusRadioInputGroup } from "./CidStatusRadioButtons";
import { OneOffApprovalPanel } from "./OneOffApprovalPanel";
import { CidOneOffApprovalModal } from "./CidOneOffApprovalModal";
import { useEmploymentHistoryStyles } from "./EmploymentHistory.styles";
import { DAY_FORMAT } from "helpers/interactionHelpers";
import { Badge } from "components/Badge";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { NoXMarginSkeleton } from "components/NoXMarginSkeleton";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";

export const EmploymentHistoryContainer = React.forwardRef(
  ({ isVisible = true, interaction, isProjectCidEnabled, projectToken, userCidEnabled, expanded }, ref) => {
    const [oneOffApprovalModalOpen, setOneOffApprovalModalOpen] = useState(false);
    const [isLoading, jobs] = useApi(
      {
        url: `/api/projects/${projectToken}/interactions/${interaction.id}/jobs`,
        method: "GET",
      },
      [projectToken, interaction.id]
    );
    const {
      cidLastUpdateInformation,
      isJobsCidStatusesLoading,
      jobCidStatuses,
      jobCidStatusError,
      persistCidStatusChange,
      persistOneOffApprovals,
    } = useCidInformation({
      interaction,
      userCidEnabled,
      projectToken,
    });

    const { isExpandedExpertProfile } = useFlyoutContext();

    if (!isVisible) return null;

    const title = (
      <Title
        isProjectCidEnabled={isProjectCidEnabled}
        lastUpdate={cidLastUpdateInformation}
        userCidEnabled={userCidEnabled}
      ></Title>
    );

    return (
      <AccordionItem title={title} open={isExpandedExpertProfile || expanded} ref={ref}>
        <EmploymentHistory
          isActiveProject={interaction.isActiveProject}
          isJobsCidStatusesLoading={isJobsCidStatusesLoading}
          isLoading={isLoading}
          isProjectCidEnabled={isProjectCidEnabled}
          jobCidStatusError={jobCidStatusError}
          jobCidStatuses={jobCidStatuses}
          jobs={jobs}
          oneOffApprovalModalOpen={oneOffApprovalModalOpen}
          persistCidStatusChange={persistCidStatusChange}
          persistOneOffApprovals={persistOneOffApprovals}
          setOneOffApprovalModalOpen={setOneOffApprovalModalOpen}
          userCidEnabled={userCidEnabled}
          withholdAll={interaction.role?.toLowerCase()?.includes("position withheld")}
        />
      </AccordionItem>
    );
  }
);

const EmploymentHistory = ({
  isActiveProject,
  isJobsCidStatusesLoading,
  isLoading,
  isProjectCidEnabled,
  jobCidStatusError,
  jobCidStatuses,
  jobs = [],
  oneOffApprovalModalOpen,
  persistCidStatusChange,
  persistOneOffApprovals,
  setOneOffApprovalModalOpen,
  userCidEnabled,
  withholdAll,
}) => {
  const [showOneOffApprovalBanner, setShowOneOffApprovalBanner] = useState(false);
  const [showCidClearanceBanner, setShowCidClearanceBanner] = useState();
  const [currentJobs, setCurrentJobs] = useState([]);
  const { jobCidError, jobsDiv } = useEmploymentHistoryStyles();

  useEffect(() => {
    setCurrentJobs((jobs || []).filter((job) => job.current));
  }, [jobs]);

  const findJobCidStatus = useCallback(
    (jobId) => {
      const jobCidStatus = jobCidStatuses.find((jobCidStatus) => jobCidStatus.id === jobId);
      return jobCidStatus ? jobCidStatus.cidStatus : "";
    },
    [jobCidStatuses]
  );

  useEffect(() => {
    const hasBlockedApprovedJob = () =>
      !!jobCidStatuses.filter((jobCidStatus) =>
        [CidStatus.RESTRICTED, CidStatus.APPROVED].includes(jobCidStatus.cidStatus)
      ).length;

    const areAllCurrentJobsActioned = () => currentJobs.every((job) => findJobCidStatus(job.id) !== CidStatus.PENDING);

    const hasPendingRestrictedJob = () =>
      currentJobs.some((job) => [CidStatus.PENDING, CidStatus.RESTRICTED].includes(findJobCidStatus(job.id)));

    if (jobCidStatuses.length && currentJobs.length) {
      setShowOneOffApprovalBanner(hasBlockedApprovedJob() && areAllCurrentJobsActioned());
    }

    if (hasPendingRestrictedJob()) {
      setShowCidClearanceBanner(true);
    }
  }, [currentJobs, findJobCidStatus, jobCidStatuses, jobs]);

  if (isLoading) {
    return <NoXMarginSkeleton count={5} />;
  }
  if (!jobs || jobs.length === 0) {
    return <></>;
  }
  return (
    <div data-testid="flyout-employment-history">
      {isProjectCidEnabled && userCidEnabled && (
        <>
          {showOneOffApprovalBanner && (
            <OneOffApprovalPanel
              currentJobs={currentJobs}
              findJobCidStatus={findJobCidStatus}
              persistOneOffApproval={() => setOneOffApprovalModalOpen(true)}
            />
          )}
          {showCidClearanceBanner && <CidClearanceBanner onDismiss={() => setShowCidClearanceBanner(false)} />}
          <CidOneOffApprovalModal
            currentJobs={currentJobs}
            isOpen={oneOffApprovalModalOpen}
            jobCidStatuses={jobCidStatuses}
            onClose={() => setOneOffApprovalModalOpen(false)}
            persistOneOffApprovals={persistOneOffApprovals}
          />
        </>
      )}
      {jobCidStatusError && (
        <Alert variant="danger" {...jobCidError}>
          {jobCidStatusError}
        </Alert>
      )}
      <x.div display="flex" flexDirection="column" {...jobsDiv}>
        {jobs.map((job) => (
          <Job
            cidStatus={findJobCidStatus(job.id)}
            isActiveProject={isActiveProject}
            isJobsCidStatusesLoading={isJobsCidStatusesLoading}
            isProjectCidEnabled={isProjectCidEnabled}
            job={job}
            key={`job-${job.id}`}
            persistCidStatusChange={persistCidStatusChange}
            userCidEnabled={userCidEnabled}
            withholdAll={withholdAll}
          />
        ))}
      </x.div>
    </div>
  );
};

const Job = ({
  cidStatus,
  isActiveProject,
  isJobsCidStatusesLoading,
  isProjectCidEnabled,
  job,
  persistCidStatusChange,
  userCidEnabled,
  withholdAll,
}) => {
  const [error, setError] = useState("");
  const { jobPeriod, jobError } = useEmploymentHistoryStyles();

  const formatString = (dateAsString) =>
    dateAsString ? <FormattedDateTime date={dateAsString} format="MMM yyyy" /> : "N/A";

  return (
    <x.div data-testid={`job-${job.id}`}>
      <div>
        <Typography component="span" variant="body-em">
          {job.company}
        </Typography>
        <Typography component="span">{` - ${job.title}`}</Typography>
      </div>
      <div>
        <Typography variant="body-small" {...jobPeriod}>
          {withholdAll ? (
            "[period withheld]"
          ) : (
            <>
              {formatString(job.startDate)} - {job.current ? "Current" : formatString(job.finishDate)}
            </>
          )}
        </Typography>
      </div>
      {isActiveProject && isProjectCidEnabled && (
        <CidStatusRadioInputGroup
          cidStatus={cidStatus}
          isJobsCidStatusesLoading={isJobsCidStatusesLoading}
          job={job}
          persistCidStatusChange={persistCidStatusChange}
          setError={setError}
          userCidEnabled={userCidEnabled}
        />
      )}
      {error && <x.div {...jobError}>{error}</x.div>}
    </x.div>
  );
};

const Title = ({ isProjectCidEnabled, userCidEnabled, lastUpdate }) => {
  const { title } = useEmploymentHistoryStyles();

  return (
    <x.span {...title}>
      <Typography variant="body-large-em" component="span">
        Employment History
      </Typography>
      {isProjectCidEnabled && userCidEnabled && lastUpdate && (
        <Badge
          value={<>CID updated</>}
          tooltipText={
            <>
              Updated by {lastUpdate.name} on <FormattedDateTime date={lastUpdate.performedAt} format={DAY_FORMAT} />
            </>
          }
        />
      )}
    </x.span>
  );
};

const CidClearanceBanner = ({ onDismiss }) => {
  const { cidClearanceBanner } = useEmploymentHistoryStyles();

  return (
    <Alert variant="info" {...cidClearanceBanner}>
      {`CID clearing a company for one expert will result in clearance for the entire project. `}
      <Link component="button" onClick={onDismiss}>
        Dismiss
      </Link>
    </Alert>
  );
};

const useCidInformation = ({ interaction, userCidEnabled, projectToken }) => {
  const [cidLastUpdateInformation, setCidLastUpdateInformation] = useState();
  const [isJobsCidStatusesLoading, setIsJobsCidStatusesLoading] = useState(false);
  const [jobCidStatuses, setJobCidStatuses] = useState([]);
  const [jobCidStatusError, setJobCidStatusError] = useState("");
  const { performSearchSync } = useProjectInteractionsContext();

  const fetchAdvisorshipCidInformation = useCallback(async () => {
    if (interaction.isActiveProject && userCidEnabled) {
      try {
        setIsJobsCidStatusesLoading(true);
        const response = await fetch({
          url: `/api/projects/${projectToken}/interactions/${interaction.id}/jobs/cid-statuses`,
          method: "GET",
        });
        const jsonResponse = await response.json();
        setJobCidStatuses(jsonResponse.jobStatuses);
        setCidLastUpdateInformation(jsonResponse.lastUpdateInformation);
        setJobCidStatusError("");
      } catch (error) {
        setJobCidStatusError(
          "Failed to load CID status for current position(s). Please contact your project lead if the problem persists."
        );
      } finally {
        setIsJobsCidStatusesLoading(false);
      }
    }
  }, [userCidEnabled, interaction.isActiveProject, interaction.id, projectToken]);

  useEffect(() => {
    fetchAdvisorshipCidInformation();
  }, [fetchAdvisorshipCidInformation]);

  const persistCidStatusChange = async (jobCidStatus, jobId) => {
    await fetch({
      url: `/api/projects/${projectToken}/interactions/${interaction.id}/jobs/${jobId}/cid-status`,
      skipAlert: true,
      method: "POST",
      body: JSON.stringify({
        jobCidStatus,
      }),
    });
    performSearchSync();
    fetchAdvisorshipCidInformation();
  };

  const persistOneOffApprovals = async (oneOffApprovals) => {
    await fetch({
      url: `/api/projects/${projectToken}/interactions/${interaction.id}/jobs/cid-one-off-approval`,
      method: "POST",
      skipAlert: true,
      body: JSON.stringify({
        oneOffApprovals,
      }),
    });
    performSearchSync();
    fetchAdvisorshipCidInformation();
  };

  return {
    cidLastUpdateInformation,
    isJobsCidStatusesLoading,
    jobCidStatuses,
    jobCidStatusError,
    persistCidStatusChange,
    persistOneOffApprovals,
  };
};
