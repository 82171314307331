import React, { useState } from "react";
import { TextArea } from "components/TextArea";
import { x } from "@xstyled/styled-components";
import { Button, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { ResponsiveModal } from "components/ResponsiveModal";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { ButtonWithMargin } from "@alphasights/client-portal-shared";
import { onGracePeriod } from "pages/InteractionPage/utils";

export const CancelRequestPopup = ({ id, onClose, onSubmit, origin, interaction }) => {
  return (
    <RequestPopup
      id={id}
      onClose={onClose}
      onSubmit={(reason) => onSubmit({ id, reason, type: "CANCEL_REQUEST", origin })}
      interaction={interaction}
    />
  );
};

export const RequestPopup = ({
  id,
  onClose,
  onSubmit,
  actionName = "Cancel",
  actionPastName = "cancelled",
  interaction,
}) => {
  const { isMobile } = useCheckScreen();
  const {
    spacing: { inner },
  } = useThemeTokens();

  const [reason, setReason] = useState();
  const [running, setRunning] = useState();
  const isOnGracePeriod = onGracePeriod(interaction);

  return (
    <ResponsiveModal
      open
      onClose={onClose}
      title={`Request to ${actionName} Interaction`}
      p={inner.base06}
      zIndex={160}
    >
      <Typography component="div" data-testid={`interaction-request-popup-${id}`}>
        <x.div pt={inner.base05}>
          Please tell us why you would like to {actionName.toLowerCase()} this interaction:
        </x.div>
        <TextArea
          value={reason}
          onChange={({ target: { value } }) => setReason(value)}
          placeholder="Enter reason here..."
          rows="5"
          my={inner.base06}
        />
        {!isOnGracePeriod && (
          <x.div>
            This interaction will not be {actionPastName.toLowerCase()} until approved by your project lead.
          </x.div>
        )}
        <x.div display="flex" flexDirection={isMobile ? "column" : "row"} mt={inner.base06}>
          <Button
            variant="secondary"
            onClick={() => {
              setRunning(true);
              onSubmit(reason).then(() => {
                setRunning(false);
                onClose();
              });
            }}
            loading={running}
          >
            <Typography variant="body-em">{isOnGracePeriod ? actionName : `Request to ${actionName}`}</Typography>
          </Button>
          <ButtonWithMargin variant="ghost" onClick={onClose}>
            <Typography variant="body-em">Go Back</Typography>
          </ButtonWithMargin>
        </x.div>
      </Typography>
    </ResponsiveModal>
  );
};
