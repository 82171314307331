import { fetch as _fetch, FetchProps } from "hooks/useApi";
import { RequestQuestionsResponse, ThirdPartyDocument } from "types";

const defaultOptions = {
  handleForbidden: true,
  skipAlert: true,
};

const fetch = (options: FetchProps) => _fetch({ ...defaultOptions, ...options });

type RequestUploadUrlResponse = {
  id: string;
  presignedUrl: string;
};

type DocumentAttributes = {
  documentTitle?: string;
  date?: string;
  angle?: string;
  expertPosition?: string;
  expertCompanyName?: string;
  expertCompanyCdsAlphaCompanyId?: number;
};

const thirdPartyDocumentsService = {
  async fetchDocumentUploadUrl(projectToken: string, fileName: string): Promise<RequestUploadUrlResponse> {
    return fetch({
      url: `/api/projects/${projectToken}/third-party-documents/document-upload-requests`,
      method: "POST",
      body: JSON.stringify({ fileName }),
    }).then((res) => res.json());
  },

  async uploadDocumentToS3(url: string, file: ArrayBuffer | string | null | undefined): Promise<Response> {
    return fetch({
      url,
      method: "PUT",
      body: file,
      headers: {},
    });
  },

  async createDocument(projectToken: string, documentUploadRequestId: string): Promise<ThirdPartyDocument> {
    return fetch({
      url: `/api/projects/${projectToken}/third-party-documents/documents`,
      method: "POST",
      body: JSON.stringify({ documentUploadRequestId }),
    }).then((res) => res.json());
  },

  async addProjectIdToDocument(projectToken: string, documentId: string): Promise<void> {
    return fetch({
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}`,
      method: "PUT",
      body: JSON.stringify({ documentId }),
    }).then((res) => res.json());
  },

  async updateDocumentAttributes(projectToken: string, documentId: string, attributes: DocumentAttributes = {}) {
    const { documentTitle, date, ...rest } = attributes;
    const payload = {
      title: documentTitle,
      documentDate: date,
      attributes: { ...rest },
    };
    return fetch({
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/attributes`,
      method: "PUT",
      body: JSON.stringify(payload),
    }).then((res) => res.json());
  },

  async deleteDocument(projectToken: string, documentId: string): Promise<Response> {
    return fetch({
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}`,
      method: "DELETE",
    });
  },

  async fetchDocument(projectToken: string, documentId: string): Promise<ThirdPartyDocument> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}`,
    }).then((res) => res.json());
  },

  async fetchDocuments(projectToken: string): Promise<ThirdPartyDocument[]> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents`,
    }).then((res) => res.json());
  },

  async fetchDocumentSummary(projectToken: string, documentId: string): Promise<ThirdPartyDocument[]> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/summary`,
    }).then((res) => res.json());
  },

  async fetchDocumentQuestions(projectToken: string, documentId: string): Promise<ThirdPartyDocument[]> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/questions`,
    }).then((res) => res.json());
  },

  async submitDocumentQuestion(
    projectToken: string,
    documentId: string,
    question: string = ""
  ): Promise<RequestQuestionsResponse> {
    return fetch({
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/questions`,
      method: "POST",
      body: JSON.stringify({ question }),
    }).then((res) => res.json());
  },

  async fetchFile(projectToken: string, documentId: string): Promise<Response> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/file`,
    });
  },

  async fetchFileAsPdf(projectToken: string, documentId: string): Promise<Response> {
    return fetch({
      method: "GET",
      url: `/api/projects/${projectToken}/third-party-documents/documents/${documentId}/pdf`,
    });
  },
};

export default thirdPartyDocumentsService;
export type { RequestUploadUrlResponse, DocumentAttributes };
