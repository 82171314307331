import React, { useMemo, useRef } from "react";
import _ from "lodash";
import { Alert, Divider, Icon, Loading } from "@alphasights/alphadesign-components";
import { GenericMessagePage } from "views/DeliverablesView/DeliverablesPage/GenericMessagePage";
import { ThirdPartyInteraction } from "types";
import {
  NO_SUMMARY_MESSAGE,
  SUMMARY_FAILED_MESSAGE,
  SUMMARY_LOADING_MESSAGE,
  SUMMARY_LOADING_TITLE,
  SummaryType,
} from "./consts";
import { DocumentSummary, SummaryTheme } from "./types";
import { ErrorContainer, SummaryContainer, SummaryViewContainer } from "./DocumentSummaryTab.styled";
import OverviewDisplay from "./OverviewDisplay";
import ThemeDisplay from "./ThemeDisplay";
import { useHideDeliverablesContent } from "views/DeliverablesView/DeliverablesPage/useHideDeliverablesContent";
import { QueryStatus as QueryStatusType } from "query-utils";
import { QueryStatus } from "views/DeliverablesView/ThirdPartyDocumentPage/consts";

const dataTestIds = {
  summaryView: "rendered-summary-view",
};

const DocumentSummaryTab = ({
  summary,
  setSearchTargetEl,
  interaction,
  documentSummaryStatus,
}: {
  summary: DocumentSummary;
  setSearchTargetEl: any;
  interaction: ThirdPartyInteraction;
  documentSummaryStatus: QueryStatusType;
}) => {
  const { contentStyle } = useHideDeliverablesContent();

  const summaryRef = useRef<HTMLDivElement>(null);

  const hasSummary = summary.parts.length > 0;

  const { overview, themes } = useMemo(() => {
    const parts = summary.parts;

    const overview = parts.find(({ type }) => type === SummaryType.overview)?.text || null;

    const themes = parts
      .filter(({ type }) => type !== SummaryType.overview)
      .reduce((acc: SummaryTheme[], part: any) => {
        if (part.type === SummaryType.theme) {
          return [...acc, { title: part.text, quotes: [], points: [] } as SummaryTheme];
        } else {
          const theme = _.last(acc) as SummaryTheme;
          const quotes = part.type === SummaryType.quote ? [part] : [];
          const points = part.type === SummaryType.point ? [part] : [];

          const updated = {
            ...theme,
            quotes: [...theme.quotes, ...quotes],
            points: [...theme.points, ...points],
          };

          return [..._.initial(acc), updated];
        }
      }, []) as SummaryTheme[];

    return { overview, themes };
  }, [summary]);

  if (documentSummaryStatus === QueryStatus.Loading) {
    return (
      <GenericMessagePage
        title={SUMMARY_LOADING_TITLE}
        message={SUMMARY_LOADING_MESSAGE}
        pre={
          <Icon>
            <Loading />
          </Icon>
        }
      />
    );
  }

  if (documentSummaryStatus === QueryStatus.Error) {
    return (
      <ErrorContainer>
        <Alert variant="danger" w="auto">
          {SUMMARY_FAILED_MESSAGE}
        </Alert>
      </ErrorContainer>
    );
  }

  if (!hasSummary) {
    return (
      <ErrorContainer>
        <Alert variant="info" w="auto">
          {NO_SUMMARY_MESSAGE}
        </Alert>
      </ErrorContainer>
    );
  }

  return (
    <SummaryViewContainer ref={setSearchTargetEl} data-testid={dataTestIds.summaryView}>
      {themes.length > 0 && (
        <SummaryContainer ref={summaryRef} {...contentStyle}>
          <OverviewDisplay overview={overview} />
          {themes.map((t, idx) => {
            return (
              <React.Fragment key={t.title}>
                <ThemeDisplay theme={t} ix={idx} interaction={interaction} />
                {idx !== themes.length - 1 && <Divider w="100%" />}
              </React.Fragment>
            );
          })}
        </SummaryContainer>
      )}
    </SummaryViewContainer>
  );
};

export { DocumentSummaryTab as default };
