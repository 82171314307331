import React from "react";
import { useCardsSidebarControlContext } from "../CardsSidebarControlProvider/CardsSidebarControlProvider";
import { IconButton } from "@alphasights/alphadesign-components";
import { LeftArrow } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";

export const ExpandSidebarButton = ({ ...props }) => {
  const { isCollapsed, onExpand } = useCardsSidebarControlContext();

  if (!isCollapsed) return null;
  return (
    <x.div {...props}>
      <IconButton onClick={onExpand} size="small" variant="outline" testId="expand-sidebar-button">
        <LeftArrow />
      </IconButton>
    </x.div>
  );
};
