import React, { useMemo, useRef } from "react";
import { Divider } from "@alphasights/alphadesign-components";
import { WorkstreamToggler } from "components/InteractionsPage/WorkstreamToggler";
import { CUSTOMER_KNOWLEDGE, PORTAL_WORKSTREAM_BADGE, useProjectBadgeContext } from "providers/BadgeProvider";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { hasSufficientCustomerKnowledge } from "pages/CustomerExpertPage/helpers";
import { ViewToggler } from "./ViewToggler/ViewToggler";
import { CustomerKnowledgeViewToggler } from "./CustomerKnowledgeViewToggler/CustomerKnowledgeViewToggler";
import { useObserveOverflow } from "hooks/useObserveOverflow";
import * as S from "./AdvisorsHeaderNewUx.styled";

export type AdvisorsHeaderNewUxProps = {
  experts?: { angleTypeName: string }[];
  onChangeLayout: (layout: string) => void;
  searchComponent: React.ReactNode;
  filterComponent: (isCompact: boolean) => React.ReactNode;
  counter: React.ReactNode;
  currentView: string;
};

export const AdvisorsHeaderNewUx = ({
  experts,
  onChangeLayout,
  searchComponent,
  filterComponent: filterComponentFn,
  counter,
  currentView,
  ...props
}: AdvisorsHeaderNewUxProps) => {
  const workstreamTogglerRef = useRef(null);
  const { portalCustomerKnowledgeEnabled } = useCurrentUser() ?? {};
  const { hasProjectBadge } = useProjectBadgeContext();
  const { elementRef, isOverflow } = useObserveOverflow();
  const filterComponent = useMemo(() => {
    return filterComponentFn(isOverflow);
  }, [filterComponentFn, isOverflow]);

  const hasPortalWorkstreams = hasProjectBadge(PORTAL_WORKSTREAM_BADGE);
  const customerViewEnabled = portalCustomerKnowledgeEnabled || hasProjectBadge(CUSTOMER_KNOWLEDGE);
  const showCustomerViewToggler =
    customerViewEnabled &&
    hasSufficientCustomerKnowledge(experts) &&
    ["table-view", "customer-view"].includes(currentView);

  return (
    <S.Wrapper data-testid="advisors-header-wrapper" ref={elementRef}>
      <S.Group>
        {hasPortalWorkstreams && <WorkstreamToggler ref={workstreamTogglerRef} />}
        {workstreamTogglerRef.current && <Divider vertical h="30px" mx="10px" />}
        {showCustomerViewToggler && (
          <>
            <CustomerKnowledgeViewToggler onChangeLayout={onChangeLayout} currentView={currentView} />
            <Divider vertical h="30px" mx="10px" />
          </>
        )}
        {searchComponent}
        <S.Spacing />
        {filterComponent}
      </S.Group>
      <S.GroupWithGap>
        <ViewToggler />
      </S.GroupWithGap>
    </S.Wrapper>
  );
};
