import React, { useMemo } from "react";
import { x } from "@xstyled/styled-components";
import { Avatar, Button, IconButton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import {
  InboxResponse,
  ThreadResponse,
  MessageResponse,
  ParticipantRole,
  AttachmentResponse,
  ResponseStatusType,
} from "types";
import { useMessengerContext } from "providers/MessengerProvider";
import { useMobileMessageCardStyles } from "../MobileMessageList/MobileMessageList.styles";
import { isSameDay } from "date-fns";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { Attachment, Reply } from "@alphasights/alphadesign-icons";
import { useMobileQuestionContextStyles } from "./MobileQuestionContext.styles";
import { DownloadAttachmentsDrawer } from "../DownloadAttachmentsDrawer";
import { statusVariantMap } from "pages/MessengerPage/components/MessengerHeader/MessengerHeader";
import { useMessengerStatusPillStyles } from "pages/MessengerPage/components/MessengerStatusPill/MessengerStatusPill.styles";
import { sanitizeMessage } from "pages/MessengerPage/utils";

export const MobileQuestionContext = ({
  inbox,
  setSelectedThreadId,
}: {
  inbox: InboxResponse;
  projectToken: string;
  setSelectedThreadId: (threadId: string | undefined) => void;
}) => {
  const styles = useMobileMessageCardStyles();
  const firstMessage = inbox?.threads[0]?.messages[0];

  const isFirstMessageFromToday = useMemo(() => isSameDay(new Date(), new Date(firstMessage.sentAt)), [
    firstMessage.sentAt,
  ]);

  const allThreads = useMemo(() => {
    const threadsWithReplies = inbox?.threads.filter((thread) => thread.messages.length > 1);
    const threadsWithoutReplies = inbox?.threads.filter((thread) => thread.messages.length === 1);
    return [...threadsWithReplies, ...threadsWithoutReplies];
  }, [inbox]);

  return (
    <x.div {...styles.messageQuestionContextWrapper}>
      <QuestionCard message={firstMessage} isMessageFromToday={isFirstMessageFromToday} />
      {allThreads.map((thread, index) => (
        <MobileMessengerAdvisorCard
          key={thread.id}
          thread={thread}
          isFirstThread={inbox?.threads.length === 1}
          setSelectedThreadId={setSelectedThreadId}
        />
      ))}
    </x.div>
  );
};

export const QuestionCard = ({
  message,
  isMessageFromToday,
}: {
  message: MessageResponse;
  isMessageFromToday: boolean;
}) => {
  const { sanitizedContent } = useMemo(() => sanitizeMessage(message), [message]);
  const styles = useMobileQuestionContextStyles();

  return (
    <x.div {...styles.questionWrapper} data-testid="question-card">
      <x.div>
        <Typography variant="body-em" display="inline">
          Question
        </Typography>
        <Typography variant="body-small" color="secondary" float="right" display="inline">
          {isMessageFromToday && "Today "}
          <FormattedDateTime date={message.sentAt} format={isMessageFromToday ? "HH:mm" : "d LLL yyyy, HH:mm"} />
        </Typography>
      </x.div>
      <Typography dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
      {message.attachments.length > 0 && <MessageDownloadAttachment attachments={message.attachments} />}
    </x.div>
  );
};

export const MobileMessengerAdvisorCard = ({
  thread,
  showReplies = false,
  setSelectedThreadId,
}: {
  thread: ThreadResponse;
  isFirstThread: boolean;
  showReplies?: boolean;
  setSelectedThreadId?: (threadId: string | undefined) => void;
}) => {
  const styles = useMobileQuestionContextStyles();
  const { experts } = useMessengerContext();
  const advisor = useMemo(() => experts.find((e) => e.id === thread.advisor.id), [experts, thread.advisor.id]);
  const replies = thread.messages.slice(2);

  return (
    <x.div {...styles.advisorCardWrapper}>
      {thread.messages.length > 1 ? (
        <>
          <MessengerMessage
            message={thread.messages[1]}
            isLastMessage={replies.length === 0}
            onReplyClick={setSelectedThreadId ? () => setSelectedThreadId(thread.id) : undefined}
            repliesLength={replies.length}
          />
          {showReplies && (
            <>
              {replies.map((message, index) => (
                <x.div {...styles.replyWrapper}>
                  <MessengerMessage key={message.id} message={message} isLastMessage={index === replies.length - 1} />
                </x.div>
              ))}
            </>
          )}
        </>
      ) : (
        <x.div {...styles.headerOnlyMessageWrapper}>
          <MessengerMessageHeader
            name={advisor?.name ?? ""}
            relevantCompany={advisor?.relevantCompany}
            relevantPosition={advisor?.relevantPosition}
            role={thread.messages[0].sender.role}
            sentAt={thread.messages[0].sentAt}
            hasReplies={false}
            status={thread.status}
          />
        </x.div>
      )}
    </x.div>
  );
};

const MessengerMessageHeader = ({
  name,
  role,
  relevantCompany,
  relevantPosition,
  sentAt,
  hasReplies = true,
  status,
}: {
  name: string;
  role: ParticipantRole;
  sentAt: string;
  relevantCompany?: string;
  relevantPosition?: string;
  hasReplies?: boolean;
  status?: ResponseStatusType;
}) => {
  const styles = useMobileQuestionContextStyles();
  const { spacing } = useThemeTokens();
  const isCurrentMessageFromToday = useMemo(() => isSameDay(new Date(), new Date(sentAt)), [sentAt]);
  const avatarColor = !hasReplies ? "base02" : role === ParticipantRole.Advisor ? "base05" : "base03";

  const { variantStyle } = useMessengerStatusPillStyles({ variant: statusVariantMap[status as ResponseStatusType] });

  return (
    <x.div display="flex" justifyContent="space-between" gap={spacing.inner.base02}>
      <x.div display="flex" gap={spacing.inner.base02}>
        <Avatar text={name} color={avatarColor} flexShrink="0" />
        <x.div display="flex" flexDirection="column">
          <x.div display="flex">
            <Typography variant="body-small-em" component="span" style={styles.companyAndRole}>
              {name}
            </Typography>
            {status !== undefined && (
              <Typography variant="body-small-em" component="span" flexShrink={0}>
                &nbsp;•&nbsp;
                <Typography variant="body-small" component="span" color={variantStyle.styles.iconColor as any}>
                  {status}
                </Typography>
              </Typography>
            )}
          </x.div>
          <Typography variant="body-small" color="secondary" component="span" style={styles.companyAndRole}>
            {relevantCompany} - {relevantPosition}
          </Typography>
        </x.div>
      </x.div>

      <x.div>
        <Typography variant="body-small" color="secondary" float="right">
          <FormattedDateTime date={sentAt} format={isCurrentMessageFromToday ? "HH:mm" : "d LLL yyyy, HH:mm"} />
        </Typography>
      </x.div>
    </x.div>
  );
};

const MessengerMessage = ({
  message,
  isLastMessage,
  onReplyClick,
  repliesLength,
}: {
  message: MessageResponse;
  isLastMessage: boolean;
  onReplyClick?: () => void;
  repliesLength?: number;
}) => {
  const { experts } = useMessengerContext();
  const isAdvisor = message.sender.role === ParticipantRole.Advisor;

  const { content: messageContent } = useMemo(() => sanitizeMessage(message), [message]);

  const styles = useMobileQuestionContextStyles();

  const expert = useMemo(() => experts.find((e) => e.id === message.sender.id), [experts, message.sender.id]);

  const isRepliesMoreThanZero = repliesLength !== undefined && repliesLength > 0;

  return (
    <x.div data-testid="thread-message" {...styles.messageWrapper}>
      <MessengerMessageHeader
        name={message.sender.name ?? ""}
        relevantCompany={expert?.relevantCompany}
        relevantPosition={expert?.relevantPosition}
        role={message.sender.role}
        sentAt={message.sentAt}
      />
      <x.div>
        <Typography
          data-testid={`message-content-${message.id}`}
          style={{
            filter: message.obfuscated ? "blur(2.5px)" : "none",
            userSelect: message.obfuscated ? "none" : "auto",
          }}
          dangerouslySetInnerHTML={{ __html: messageContent }}
        />
      </x.div>
      <x.div
        data-testid="reply-message-data"
        display="flex"
        justifyContent={message.attachments.length > 0 ? "space-between" : "flex-end"}
        h="16px"
      >
        {message.attachments.length > 0 && <MessageDownloadAttachment attachments={message.attachments} />}
        {onReplyClick && isRepliesMoreThanZero && !isLastMessage && !isAdvisor && (
          <x.div data-testid="view-more-button">
            <Button variant="ghost" size="small" onClick={onReplyClick} h="16px">
              {`View ${repliesLength} ${repliesLength > 1 ? "replies" : "reply"}`}
            </Button>
          </x.div>
        )}
        {!message.obfuscated && onReplyClick && isLastMessage && isAdvisor && (
          <x.div data-testid="reply-message-button">
            <Button variant="ghost" size="small" onClick={onReplyClick} h="16px" startIcon={<Reply />}>
              Reply
            </Button>
          </x.div>
        )}
      </x.div>
    </x.div>
  );
};

const MessageDownloadAttachment = ({ attachments }: { attachments: AttachmentResponse[] }) => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  return (
    <>
      {attachments.length > 0 && (
        <x.div display="flex" gap="8px">
          <IconButton
            size="small"
            color="secondary"
            variant="basic"
            onClick={() => setIsDrawerOpen(true)}
            testId="download-attachments-btn"
          >
            <Attachment />
          </IconButton>
          <Typography variant="body-small-em">{attachments.length}</Typography>
        </x.div>
      )}
      <DownloadAttachmentsDrawer
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        attachments={attachments}
      />
    </>
  );
};
