import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDeliverableContext } from "./DeliverableProvider";
import {
  Sentence,
  Summary,
  SummaryStatus,
  SummaryType,
  ViewType,
} from "views/DeliverablesView/AiSummaries/AiSummaries.types";
import { ENABLE_AI_SUMMARIZATION, useProjectBadgeContext } from "./BadgeProvider";
import {
  getDefaultRecording,
  getDefaultTranscript,
  getDefaultTranscriptForInteraction,
  getRecordingForTranscript,
  selectIndexHighlight,
} from "views/DeliverablesView/helpers";
import { toSeconds } from "views/DeliverablesView/transcripts/adaptedTranscript";
import { useAudioPlayer } from "hooks/useAudioPlayer";
import { useLoggableAudioPlayer } from "views/DeliverablesView/useLoggableAudioPlayer";
import { useCurrentProjectContext } from "./CurrentProjectProvider";
import { HitAction, HitOrigin, TranscriptQuestion, transcriptQuestions } from "@alphasights/portal-api-client";
import { highlightWordsInElement } from "utils/frontendElementHighlight";
import useQueryParams from "hooks/useQueryParams";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCurrentUser, useIsInternalUser } from "@alphasights/portal-auth-react";
import _ from "lodash";
import { debugTranscript, findLongestMatchingQuote } from "utils/sentenceMatcher";

export const InteractionDeliverablesContext = React.createContext<InteractionDeliverablesProviderState | undefined>(
  undefined
);

export interface InteractionDeliverablesProviderState {
  interaction: Partial<Interaction>;
  currentTranscript?: TranscriptRequest;
  currentRecording?: Recording;
  currentSummaries: Summary[];
  summaryComprehensive?: Summary;
  availableRecordings: Recording[];
  transcripts: TranscriptRequest[];
  hasTranscripts: boolean;
  questions: TranscriptQuestion[] | null;
  regenerateQuestions: () => void;
  submitQuestion: (question: TranscriptQuestion) => Promise<TranscriptQuestion> | Promise<void>;
  updateQuestion: (question: TranscriptQuestion) => Promise<void>;
  deleteQuestion: (question: TranscriptQuestion) => Promise<void>;
  selectTranscript: (tr: TranscriptRequest, keywords?: string[]) => void;
  selectRecording: (r: Recording) => void;
  sentences: Sentence[];
  isLoading: boolean;
  isAiSummariesLoading: boolean;
  mentions: undefined | any[];
  totalMentions: undefined | number;
  totalMentionsFe: undefined | number;
  transcriptIndexToFocus?: FocusedTranscriptPart;
  focusOnTranscriptIndex: (focus: FocusedTranscriptPart) => void;
  refreshSummaries: () => void;
  onSummariesUpdated: (summaries: Summary[]) => void;
  canPlayAudio: boolean;
  audioPlayer: AudioPlayer;
  onSearch: (keywords: string[]) => void;
  searchCriteria: string[];
  selectedView: ViewType;
  setSelectedView: (v: ViewType) => void;
  keywordSearchTargetEl: any;
  onUpdateSummaryFeedback: (summaryType: SummaryType, feedbackType: string) => void;
  logHitDuration: (openedAt: number, maxScrollReached: number, action: HitAction) => void;
  aiFeaturesEnabled: boolean;
  transcriptFeaturesEnabled: boolean;
}
export type FocusedTranscriptPart = {
  transcriptIx?: number;
  text?: string;
  matchIxStart?: number;
  matchLength?: number;
};

export interface AudioPlayer {
  controls: any;
  render: any;
  progress: any;
  toggle: () => void;
  showPlayer: () => void;
  hidePlayer: () => void;
  visible: boolean;
  explicitlyClosed: boolean;
  explicitlyClose: () => void;
}

type PollsMonitor = {
  questions?: ReturnType<typeof setTimeout>;
  summaries?: ReturnType<typeof setTimeout>;
};

export const InteractionDeliverablesProvider = ({
  interaction,
  keywords,
  pollDelay = 10000,
  ...props
}: {
  interaction: Partial<Interaction>;
  keywords: string[];
  children: ReactNode;
  pollDelay?: number;
}) => {
  const [currentTranscript, setCurrentTranscript] = useState<TranscriptRequest>();
  const [currentRecording, setCurrentRecording] = useState<Recording>();
  const [currentSummaries, setCurrentSummaries] = useState<Summary[]>([]);
  const [canPlayAudio, setCanPlayAudio] = useState(false);
  const [showAudioPlayer, setShowAudioPlayer] = useState(false);
  const [sentences, setSentences] = useState<Sentence[]>([]);
  const [mentions, setMentions] = useState<any[] | undefined>();
  const [totalMentions, setTotalMentions] = useState<number | undefined>();
  const [totalMentionsFe, setTotalMentionsFe] = useState<number | undefined>();
  const [isTranscriptLoading, setIsTranscriptLoading] = useState(false);
  const [isAiSummariesLoading, setIsAiSummariesLoading] = useState(false);
  const [transcriptIndexToFocus, setTranscriptIndexToFocus] = useState<FocusedTranscriptPart | undefined>();
  const [searchCriteria, setSearchCriteria] = useState<string[]>(keywords ?? []);
  const [searchTargetEl, setSearchTargetEl] = useState<HTMLElement | null>(null);
  const [questions, setQuestions] = useState<TranscriptQuestion[] | null>([]);
  const { fetchDeliverable, getAiSummaries } = useDeliverableContext();
  const { hasProjectBadge } = useProjectBadgeContext();
  const { project } = useCurrentProjectContext();
  const queryParams = useQueryParams();
  const [selectedView, setSelectedView] = useState(() =>
    hasProjectBadge(ENABLE_AI_SUMMARIZATION) && interaction.language === "en" ? ViewType.Summary : ViewType.Transcript
  );
  const [explicitlyClosedAudioPlayer, setExplicitlyClosedAudioPlayer] = useState(false);
  const { logHit } = useTrackUserAction();
  const isInternal = useIsInternalUser();
  const currentUser = useCurrentUser();
  const transcriptPreSearchScroll = useRef<number>(0);

  const hasTranscripts = !!interaction.recordings
    ?.flatMap((r) => r.transcriptRequests ?? [])
    .find((tr) => !tr.purgedAt);

  const aiFeaturesEnabled = useMemo(() => hasProjectBadge(ENABLE_AI_SUMMARIZATION) && interaction.language === "en", [
    hasProjectBadge,
    interaction.language,
  ]);

  const transcriptFeaturesEnabled = useMemo(() => !currentUser?.aiSynthesisOnly, [currentUser]);

  const changeView = (view: ViewType) => {
    setTotalMentionsFe(0);
    setSelectedView(view);
  };

  useEffect(function consumeUrlTranscriptLink() {
    const idxFromUrl = parseInt(queryParams.get("part") || "");
    queryParams.delete("part");
    if (!isNaN(idxFromUrl)) {
      changeView(ViewType.Transcript);
      setTranscriptIndexToFocus({ transcriptIx: idxFromUrl });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const visibleTranscripts = useMemo(() => {
    const trs = currentRecording?.transcriptRequests?.filter((tr) => tr.completed) ?? [];
    const hasRegular = trs.find((tr) => tr.transcriptType === "regular");
    return hasRegular ? trs.filter((tr) => tr.transcriptType !== "ai") : trs;
  }, [currentRecording]);

  const visibleRecordings = useMemo(
    () => interaction.recordings?.filter((r) => r.visibleToClient && !r.purgedAt) ?? [],
    [interaction]
  );

  useEffect(
    function highlightKeywordsFe() {
      if (searchTargetEl) {
        const matchCount = highlightWordsInElement(searchTargetEl, searchCriteria);
        setTotalMentionsFe(searchCriteria.length ? matchCount : undefined);
        selectIndexHighlight(0, matchCount, { noScroll: true });
      } else {
        setTotalMentionsFe(undefined);
      }
    },
    [searchCriteria, searchTargetEl, selectedView]
  );
  const polls = useRef<PollsMonitor>({});

  const loadQuestions = useCallback(() => {
    clearTimeout(polls.current.questions);
    if (!project?.token || !interaction.id) {
      setQuestions([]);
    } else {
      transcriptQuestions
        .getTranscriptQuestions(project?.token, interaction.id)
        .then((resp) => {
          setQuestions(resp);
          if (resp.length === 0 || resp.find((q) => q.status === "PROCESSING")) {
            polls.current.questions = setTimeout(loadQuestions, pollDelay);
          }
        })
        .catch((ex) => {
          console.error("Error fetching interaction questions", ex);
          setQuestions(null);
        });
    }
  }, [interaction, project, pollDelay]);

  const regenerateQuestions = useCallback(() => {
    if (project?.token && interaction.id && currentTranscript?.id)
      transcriptQuestions
        .regenerateTranscriptQuestions(project!.token, interaction.id, currentTranscript.id)
        .then(loadQuestions);
  }, [project, interaction.id, currentTranscript, loadQuestions]);

  const createQuestion = useCallback(
    (question: TranscriptQuestion) => {
      const canSubmit = project?.token && currentTranscript && interaction.id;
      return canSubmit
        ? transcriptQuestions.createTranscriptQuestion(project!.token, question).then((resp: object) => {
            setQuestions((qs) => [resp as TranscriptQuestion, ...(qs ?? [])]);
            polls.current.questions = setTimeout(loadQuestions, pollDelay);
            return resp as TranscriptQuestion;
          })
        : Promise.resolve();
    },
    [project, interaction.id, currentTranscript, pollDelay, loadQuestions]
  );

  const updateQuestion = useCallback(
    (question: TranscriptQuestion) => {
      const canSubmit = project?.token && currentTranscript && interaction.id;
      return canSubmit
        ? transcriptQuestions.updateTranscriptQuestion(project!.token, question).then((resp: TranscriptQuestion) => {
            setQuestions((qs) => {
              return [...(qs ?? []).map((q) => (q.id === question.id ? resp : q))];
            });
            polls.current.questions = setTimeout(loadQuestions, pollDelay);
          })
        : Promise.resolve();
    },
    [project, interaction.id, currentTranscript, pollDelay, loadQuestions]
  );

  const removeQuestion = useCallback(
    (question: TranscriptQuestion) => {
      const canSubmit = project?.token && currentTranscript && interaction.id;
      return canSubmit
        ? transcriptQuestions.deleteTranscriptQuestion(project!.token, question).then(() => {
            setQuestions((qs) => [...(qs ?? []).filter((q) => q.id !== question.id)]);
          })
        : Promise.resolve();
    },
    [project, interaction.id, currentTranscript]
  );

  useEffect(function stopPollingOnUnmount() {
    return () => {
      clearTimeout(polls.current.questions);
      clearTimeout(polls.current.summaries); // eslint-disable-line react-hooks/exhaustive-deps
    };
  }, []);

  useEffect(
    function onInteractionChange() {
      if (!interaction.recordings) return;

      const mainTranscript = getDefaultTranscriptForInteraction(interaction);

      const mainRecording = mainTranscript
        ? getRecordingForTranscript(interaction, mainTranscript)
        : getDefaultRecording(interaction);

      setCurrentRecording(mainRecording);
      setCurrentTranscript(mainTranscript);
      if (!mainTranscript && mainRecording && mainRecording?.visibleToClient) {
        setShowAudioPlayer(true);
      }
      if (mainTranscript?.completed) loadQuestions();
    },
    [interaction] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const refreshSummaries = () => {
    if (currentTranscript && hasProjectBadge(ENABLE_AI_SUMMARIZATION)) {
      loadSummariesContents(interaction, currentTranscript);
    } else {
      setCurrentSummaries([]);
    }
  };

  const reloadTranscript = (transcript?: TranscriptRequest, keywords: string[] = []) => {
    refreshSummaries();
    if (!transcript) return;
    if (!transcriptFeaturesEnabled) return;
    setIsTranscriptLoading(true);
    transcriptPreSearchScroll.current = document.querySelector("[data-transcript-scrollable-area]")?.scrollTop ?? 0;
    fetchDeliverable(transcript, keywords)
      .then(({ sentences, mentions, totalMatches }: any) => {
        setMentions(keywords.length ? mentions : undefined);
        setTotalMentions(keywords.length ? totalMatches : undefined);
        setSentences(
          sentences.map((s: Sentence) => ({ ...s, startSeconds: toSeconds(s.start), endSeconds: toSeconds(s.end) }))
        );
      })
      .catch((e: any) => {
        setMentions([]);
        setSentences([]);
        setTotalMentions(0);
        console.error(e);
      })
      .finally(() => setIsTranscriptLoading(false));
  };

  useEffect(
    function scrollBackAfterTranscriptReload() {
      if (!isTranscriptLoading) {
        document.querySelector("[data-transcript-scrollable-area]")?.scrollTo(0, transcriptPreSearchScroll.current);
        transcriptPreSearchScroll.current = 0;
      }
    },
    [isTranscriptLoading]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    function onTranscriptSelected() {
      reloadTranscript(currentTranscript, searchCriteria);
    },
    [currentTranscript, searchCriteria] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    function onRecordingSelected() {
      if (currentRecording) {
        setCanPlayAudio(
          currentRecording.visibleToClient &&
            !currentRecording.purgedAt &&
            !currentRecording.obfuscated &&
            transcriptFeaturesEnabled
        );
        setCurrentTranscript(getDefaultTranscript(currentRecording));
      } else {
        setCanPlayAudio(false);
      }
    },
    [currentRecording, transcriptFeaturesEnabled]
  );

  const loadSummariesContents = (interaction: Partial<Interaction>, transcript: TranscriptRequest) => {
    const recording = interaction.recordings?.find((r) => r.transcriptRequests.find((tr) => tr.id === transcript.id));

    if (!recording) {
      console.error("There's no recording to load summaries for");
      return;
    }

    setIsAiSummariesLoading(true);
    getAiSummaries(interaction.id, recording.id)
      .then((summaries: Summary[]) => {
        setCurrentSummaries(summaries);
        const summaryComprehensive = summaries[0];

        if (!summaryComprehensive || summaryComprehensive.status === SummaryStatus.Generating) {
          polls.current.summaries = setTimeout(() => loadSummariesContents(interaction, transcript), pollDelay);
        }
      })
      .catch((e: any) => {
        console.error(e);
      })
      .finally(() => {
        setIsAiSummariesLoading(false);
      });
  };

  const summaryComprehensive = useMemo(() => currentSummaries[0], [currentSummaries]);

  const audioPlayer = useLoggableAudioPlayer({
    audioPlayer: useAudioPlayer({
      audioUrl: currentRecording?.url,
      vttUrl: null,
      disabled: !canPlayAudio,
    }),
    currentTranscript,
    interaction,
  });

  useEffect(
    function stopPlayingOnPlayerHidden() {
      if (!showAudioPlayer) {
        audioPlayer.controlsProps.onStopPlay();
      }
    },
    [audioPlayer.controlsProps, showAudioPlayer]
  );

  const onUpdateSummaryFeedback = useCallback((type: SummaryType, feedbackType: string) => {
    setCurrentSummaries((p) => p.map((s) => (s.type === type ? { ...s, feedbackType } : s)));
  }, []);

  useEffect(
    function logHitViewChange() {
      if (selectedView === ViewType.Transcript) {
        if (!currentTranscript || !hasTranscripts) return;
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.transcriptOpened,
          advisorshipId: interaction.id,
          projectToken: interaction.projectToken,
          details: {
            transcriptType: currentTranscript?.transcriptType,
          },
          references: { transcriptId: currentTranscript?.id },
        });
      }
      if (selectedView === ViewType.Questions) {
        if (!questions || questions.length === 0) return;
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.transcriptQuestionsView,
          advisorshipId: interaction.id,
          projectToken: project?.token,
        });
      }
      if (selectedView === ViewType.Summary) {
        if (isInternal) return;
        if (summaryComprehensive?.status !== SummaryStatus.Generated) return;
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.aiSummaryViewed,
          projectToken: interaction.projectToken,
          advisorshipId: interaction.id,
          details: {
            aiSummaryType: summaryComprehensive.type,
          },
          references: { transcriptId: currentTranscript?.id },
        });
      }
      if (selectedView === ViewType.Mentions) {
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.transcriptMentionsView,
          advisorshipId: interaction.id,
          projectToken: project?.token,
        });
      }
    },
    [selectedView, JSON.stringify(summaryComprehensive?.content)] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const logHitDuration = useCallback(
    (openedAt: number, maxScrollReached: number, action: HitAction) => {
      const closedAt = Date.now();
      const durationInSeconds = (closedAt - openedAt) / 1000;

      logHit({
        origin: HitOrigin.deliverablesView,
        action,
        advisorshipId: interaction.id,
        projectToken: project?.token,
        details: {
          ...(action === HitAction.transcriptRead && { transcriptType: currentTranscript?.transcriptType }),
          durationInSeconds: durationInSeconds,
          maxScrollReached: _.round(maxScrollReached, 2),
        },
        ...(action === HitAction.transcriptRead && { references: { transcriptId: currentTranscript?.id } }),
      });
    },
    [currentTranscript, interaction.id, logHit, project]
  );

  const focusOnTranscriptIndex = (focus: FocusedTranscriptPart) => {
    if (focus.text) {
      const matches = sentences.map((sentence) => {
        debugTranscript("Matching " + sentence.position, document.querySelector(`#cue-${sentence.position}`));
        const matchingSequece = findLongestMatchingQuote(sentence.value, focus.text!!);
        return {
          sentence,
          matchingSequece,
        };
      });

      const bestMatch = _.maxBy(
        matches.filter((a) => a.matchingSequece),
        (t) => t.matchingSequece!!.length
      );

      if (bestMatch) {
        const newFocus = {
          text: focus.text,
          transcriptIx: bestMatch.sentence.position,
          matchIxStart: bestMatch.matchingSequece?.ix,
          matchLength: bestMatch.matchingSequece?.length,
        };

        const requestedEl = document.querySelector(`#cue-${focus.transcriptIx}`);
        const adjustedEl = document.querySelector(`#cue-${newFocus.transcriptIx}`);
        const matching = bestMatch.sentence.value.slice(
          newFocus.matchIxStart,
          newFocus.matchIxStart!! + newFocus.matchLength!!
        );

        debugTranscript("Adjusting transcript.", `ix ${focus.transcriptIx} new ${newFocus.transcriptIx}`, {
          requested: { ...focus, el: requestedEl },
          adjusted: { ...newFocus, el: adjustedEl, matching },
        });

        setTranscriptIndexToFocus(newFocus);
        return;
      } else {
        debugTranscript("No best match found, using gpt raw result", {
          ...focus,
          el: document.querySelector(`#cue-${focus.transcriptIx}`),
        });
      }
    }

    setTranscriptIndexToFocus(focus);
  };

  const context = {
    interaction,
    selectTranscript: setCurrentTranscript,
    selectRecording: setCurrentRecording,
    focusOnTranscriptIndex,
    refreshSummaries,
    transcriptIndexToFocus,
    currentTranscript,
    currentRecording,
    currentSummaries,
    summaryComprehensive,
    canPlayAudio,
    transcripts: visibleTranscripts,
    hasTranscripts,
    sentences,
    availableRecordings: visibleRecordings,
    isLoading: isTranscriptLoading,
    isAiSummariesLoading,
    mentions,
    totalMentions,
    totalMentionsFe,
    onSummariesUpdated: setCurrentSummaries,
    questions,
    regenerateQuestions,
    submitQuestion: createQuestion,
    updateQuestion: updateQuestion,
    deleteQuestion: removeQuestion,
    setSelectedView: changeView,
    selectedView,
    audioPlayer: {
      toggle: () => setShowAudioPlayer((p) => !p),
      showPlayer: () => setShowAudioPlayer(true),
      hidePlayer: () => setShowAudioPlayer(false),
      visible: showAudioPlayer && canPlayAudio,
      controls: audioPlayer.controlsProps,
      render: audioPlayer.renderElements,
      progress: audioPlayer.progressBarProps,
      explicitlyClosed: explicitlyClosedAudioPlayer,
      explicitlyClose: () => setExplicitlyClosedAudioPlayer(true),
    },
    searchCriteria,
    onSearch: setSearchCriteria,
    keywordSearchTargetEl: setSearchTargetEl,
    onUpdateSummaryFeedback,
    logHitDuration,
    aiFeaturesEnabled,
    transcriptFeaturesEnabled,
  };
  return <InteractionDeliverablesContext.Provider value={context} {...props} />;
};

export const useInteractionDeliverablesContext = () => useContext(InteractionDeliverablesContext);
