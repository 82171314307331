import React from "react";
import {
  QueryClient,
  QueryClientProvider,
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

const CustomQueryClientProvider = ({ children }: { children: React.ReactNode }) => {
  const client = React.useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            networkMode: "offlineFirst",
            refetchOnWindowFocus: false,
            retry: false,
          },
          mutations: {
            networkMode: "offlineFirst",
          },
        },
      }),
    []
  );

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
};

function useQueryCustom<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "queryKey" | "queryFn">
): Omit<UseQueryResult<TData, TError>, "data"> & { data?: TData } {
  const [data, setData] = React.useState<TData>();
  const { onSuccess } = options ?? {};
  const result = useQuery(queryKey, queryFn, {
    ...options,
    onSuccess: (data) => {
      setData(data);
      onSuccess?.(data);
    },
  });

  return {
    ...result,
    data: data ?? result.data,
    isLoading: result.isInitialLoading,
  };
}

// re-export everything
export * from "@tanstack/react-query";

// override exports
export { CustomQueryClientProvider as QueryClientProvider, useQueryCustom as useQuery };
