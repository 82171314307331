import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useStyles = () => {
  const { spacing, shape, color } = useThemeTokens();

  return {
    expertCardWrapper: {
      border: "solid 1px " + color.border.divider,
      borderRadius: shape.border.radius.medium,
      p: spacing.inner.base03,
      display: "flex",
      gap: spacing.inner.base,
      alignItems: "center",
      cursor: "pointer",
      backgroundColor: {
        hover: color.background.surface.page.hover,
      },
    },
    interactionContent: {
      maxW: 276,
    },
    vendorWrapper: {
      display: "flex",
      gap: "12px",
      maxW: "100%",
      w: "100%",
    },
    vendorDataColumn: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      minWidth: 0,
    },
    vendorMentionsTotal: {
      display: "flex",
      gap: "12px",
    },
    carousel: {
      display: "flex",
      gap: "8px",
      mt: "4px",
    },
    contentWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: "24px",
    },
  };
};
