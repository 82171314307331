import React from "react";
import { x } from "@xstyled/styled-components";
import { Tooltip, useThemeTokens } from "@alphasights/alphadesign-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { useCustomerPrimersStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state";
import { useGetCompanyProperties } from "pages/AlphaNowPage/primers/hooks";

const YAxis = ({ vendors }: { vendors: string[] }) => {
  const { icons, spacing, typography } = useThemeTokens();
  const companiesV2 = useCustomerPrimersStore(({ companiesV2 }) => companiesV2);
  const { getCompanyProperties } = useGetCompanyProperties([], companiesV2);
  const nameMaxLenght = 15;

  return (
    <x.div flex="0 0 auto" paddingTop="30px" display="flex" flexDirection="column">
      {vendors.map((company) => (
        <x.div
          key={company}
          h="51px"
          w="100%"
          alignItems="center"
          display="flex"
          justifyContent="right"
          columnGap={spacing.inner.base02}
          {...typography.body.small}
        >
          <PrimerCompanyLogo size={icons.size.lg} src={getCompanyProperties(company).logoLink} />
          {company.length > nameMaxLenght ? (
            <Tooltip title={company} variant="dark">
              {`${company.substring(0, nameMaxLenght)}...`}
            </Tooltip>
          ) : (
            company
          )}
        </x.div>
      ))}
    </x.div>
  );
};

export default YAxis;
