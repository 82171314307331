import React from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { ConditionalTooltip, PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { useCustomerPrimersStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state";
import { useGetCompanyProperties } from "pages/AlphaNowPage/primers/hooks";
import { getTruncatedTextAndTooltip } from "pages/AlphaNowPage/primers/utils/utils";

type CellIconProps = {
  icons: string[];
  isGrayScaled: boolean;
};

const CellIcon = ({ icons, isGrayScaled = false }: CellIconProps) => {
  const { color, icons: adsIcons, spacing } = useThemeTokens();
  const companies = useCustomerPrimersStore(({ companies }) => companies);
  const companiesV2 = useCustomerPrimersStore(({ companiesV2 }) => companiesV2);
  const { getCompanyProperties } = useGetCompanyProperties(companies, companiesV2);

  return (
    <x.div
      display="flex"
      flexWrap="wrap"
      gap={spacing.inner.base02}
      color={isGrayScaled ? color.text.disabled : color.text.strong}
    >
      {icons.length === 0
        ? "-"
        : icons.map((entry, index) => (
            <PrimerCompanyLogo
              key={index.toString()}
              size={adsIcons.size.lg}
              src={getCompanyProperties(entry).logoLink}
              tooltip={entry}
              isGrayscale={isGrayScaled}
            />
          ))}
    </x.div>
  );
};

type TableRowProps = {
  cellsWidth: string;
  customerEntry: UsageAndAwarenessCustomerEntry;
  expert: Speaker;
  isGrayScaled: boolean;
  setSelectedCustomer: (speakerId: number) => void;
};

const TableRow = ({ cellsWidth, customerEntry, expert, isGrayScaled = false, setSelectedCustomer }: TableRowProps) => {
  const { color, spacing, typography } = useThemeTokens();
  const { uses, churned, evaluated, aware, unaware } = customerEntry;

  const [expertCompany, expertCompanyTooltip] = getTruncatedTextAndTooltip(expert.company, 40);
  const [expertJobTitle, expertJobTitleTooltip] = getTruncatedTextAndTooltip(expert.jobTitle, 40);

  return (
    <x.div
      position="relative"
      display="grid"
      gridTemplateColumns={cellsWidth}
      padding={`${spacing.inner.base03} 0`}
      borderBottom={`1px solid ${color.border.divider}`}
      columnGap="48px"
      bg={isGrayScaled ? color.background.surface.recessed : ""}
    >
      <x.div onClick={() => setSelectedCustomer(expert.speakerId)} cursor="pointer">
        <x.div {...typography.body.smallEm} color={isGrayScaled ? color.text.disabled : color.text.strong._}>
          <ConditionalTooltip tooltip={(expertCompanyTooltip as string) ?? ""}>
            {expertCompany as string}
          </ConditionalTooltip>
        </x.div>
        <x.div {...typography.body.small} color={isGrayScaled ? color.text.disabled : color.text.secondary}>
          <ConditionalTooltip tooltip={(expertJobTitleTooltip as string) ?? ""}>
            {expertJobTitle as string}
          </ConditionalTooltip>
        </x.div>
      </x.div>
      <CellIcon icons={uses} isGrayScaled={isGrayScaled} />
      <CellIcon icons={churned} isGrayScaled={isGrayScaled} />
      <CellIcon icons={evaluated} isGrayScaled={isGrayScaled} />
      <CellIcon icons={aware} isGrayScaled={isGrayScaled} />
      <CellIcon icons={unaware} isGrayScaled={isGrayScaled} />
    </x.div>
  );
};

export default TableRow;
