import React, { FC } from "react";
import { x } from "@xstyled/styled-components";
import ReactMarkdown from "react-markdown";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import {
  CONTENT_TYPE_DISPLAY_NAME,
  FLAGS,
  AlphaNowSRMProductType,
  ContentCardFlags,
} from "@alphasights/client-portal-shared";
import CompanyLogos from "./Components/CompanyLogos";
import { CardHeaderWrapper, CardWrapper } from "./styled";

const DataTestIds = {
  primerCard: "primer-card",
};

interface PrimerCardProps {
  primerType: AlphaNowSRMProductType;
  title?: string;
  description?: string;
  logos?: string[];
  handleOnClick?: () => void;
  disabled?: boolean;
  isBookmarked?: boolean;
  showPurchasedFlag?: boolean;
}

const PrimerCard: FC<PrimerCardProps> = ({
  primerType,
  title = "",
  description = "",
  logos = [],
  disabled = false,
  showPurchasedFlag = false,
  isBookmarked = false,
  handleOnClick,
}) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  const flagItems = [...(showPurchasedFlag ? [FLAGS.Purchased] : []), ...(isBookmarked ? [FLAGS.Bookmarked] : [])];

  return (
    <CardWrapper onClick={handleOnClick} data-testid={DataTestIds.primerCard} disabled={disabled}>
      <CompanyLogos logos={logos} primerType={primerType} disabled={disabled} />
      <x.div p={inner.base02}>
        <CardHeaderWrapper>
          <Typography variant="body-large-em" color={text.strong._}>
            {CONTENT_TYPE_DISPLAY_NAME[primerType]}
          </Typography>
          <ContentCardFlags items={flagItems} />
        </CardHeaderWrapper>
        <Typography variant="body-small-em" color={text.secondary}>
          {title}
        </Typography>
        <Typography variant="body-small" color={text.secondary} pt={inner.base03}>
          <ReactMarkdown source={description} />
        </Typography>
      </x.div>
    </CardWrapper>
  );
};

export { PrimerCard as default, DataTestIds };
