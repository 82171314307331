import React from "react";
import { Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { Popover } from "components/Popover";

export const PortalTooltip = (inputProps) => {
  const {
    title,
    dark = false,
    children,
    useAds = true,
    disabled,
    position = "top",
    styleOverrides = {},
    ...props
  } = inputProps;
  const {
    spacing,
    components: {
      tooltip: {
        body,
        variant: {
          default: { body: defaultBody },
          dark: { body: darkBody },
        },
      },
    },
  } = useThemeTokens();

  if (!title || disabled) return children;

  const style = {
    ...body,
    ...(dark ? darkBody : defaultBody),
    ...(styleOverrides || {}),
  };
  return (
    <>
      {useAds ? (
        <Tooltip
          title={<Typography variant="body-small">{title}</Typography>}
          variant="dark"
          position={position}
          padding={spacing.inner.base02}
          enterDelay={0}
          closeOnScroll
          zIndex={160}
          data-testid="popover-wrapper"
          {...props}
        >
          {children}
        </Tooltip>
      ) : (
        <Popover
          disableArrow={true}
          borderless={true}
          style={style}
          body={<Typography variant="body-small">{title}</Typography>}
        >
          {children}
        </Popover>
      )}
    </>
  );
};

export const withPortalTooltip = (WrappedComponent) => ({ tooltip, dark, ...props }) => (
  <PortalTooltip title={tooltip} dark={dark}>
    <WrappedComponent {...props} />
  </PortalTooltip>
);
