import styled, { x } from "@xstyled/styled-components";
import { Skeleton, useThemeTokens } from "@alphasights/alphadesign-components";
import { AlphaTable } from "@alphasights/alphadesign-table";

export const AlphaTableWrapper = styled(x.div)((props: { hasVirtualization: boolean }) => {
  const {
    color: { background },
    spacing: { inner },
  } = useThemeTokens();

  return `
    background: ${background.surface.page.default};
    display: grid;
    height: ${() => (props.hasVirtualization ? "100%" : "auto")};

    tbody tr:hover {
      background-color: ${background.neutral.hover};
    }
    .row-selected {
      background-color: ${background.selected.subtle};
    }
    .row-hidden {
      background-color: ${background.neutral.subtle};
    }
    .action-column {
      width: 100%;
      padding-right: ${inner.base04};
    }
    .action-column > button {
      width: 100%;
    }
    .action-column > div > button:first-child {
      flex-grow: 1;
    }

  table {
    border-collapse: separate;
    border-spacing: 0;
  }
`;
});

export const ShowHideExpertsButtonWrapper = styled(x.div)(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return `
    display: flex;
    justify-content: center;
    margin-top: ${inner.base06};
  `;
});

export const SkeletonWithMarginBottom = styled(Skeleton)(() => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return `
    .skeleton-loader-wrapper {
      margin-bottom: ${inner.base06};
    }
  `;
});

export const StyledAlphaTable = styled(AlphaTable)<{ areaHeight?: number }>`
  flex-grow: 1;
  height: ${({ areaHeight }) => (!!areaHeight ? `${areaHeight}px` : "auto")};
`;
