import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { isEqual } from "lodash";
import { offset } from "@floating-ui/react-dom";
import {
  Button,
  Icon,
  Modal,
  SelectMenu,
  SelectOption,
  SelectValue,
  Tooltip,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { CalendarToday, Info } from "@alphasights/alphadesign-icons";

import useOnClickOutside from "hooks/useOnClickHooks";
import {
  Angle,
  ANGLE_DISPLAY,
  ANGLE_INFO_TOOLTIP_TEXT,
} from "views/DeliverablesView/ThirdPartyDocUploadModal/constants";
import { MODAL_TITLE, MODAL_WIDTH, SAVE, BACK, FormFieldLabel } from "./constants";
import { formatDate, getPopoverOffset } from "./utils";
import { DocumentAttributes } from "services/thirdPartyDocumentsService";
import CompanySearchField, { Company } from "./CompanySearchField";

import {
  TextAndIconWrapper,
  StyledInteractiveIcon,
} from "views/DeliverablesView/ThirdPartyDocUploadModal/ThirdPartyDocUploadModal.styled";
import * as EditDocumentModalStyles from "./EditDocumentModal.styled";

const S = { ...EditDocumentModalStyles, TextAndIconWrapper, StyledInteractiveIcon };

type EditDocumentModalProps = {
  onClickSave: (attributes: DocumentAttributes) => void;
  onClickCancel: () => void;
  fileName: string;
  isTranscript?: boolean;
} & DocumentAttributes;

const EditDocumentModal: FC<EditDocumentModalProps> = ({
  onClickSave,
  onClickCancel,
  isTranscript = false,
  fileName,
  ...props
}) => {
  const { color } = useThemeTokens();

  const initialFormState = useRef({ ...props, documentTitle: props.documentTitle ?? fileName });
  const dateFieldRef = useRef<HTMLInputElement>(null);
  const datePopoverRef = useRef<HTMLDivElement>(null);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement>();
  const [formState, setFormState] = useState<DocumentAttributes>(initialFormState.current);

  const formattedDate = useMemo(() => formatDate(new Date(formState.date!)), [formState.date]);

  const isSaveDisabled = useMemo(() => {
    return isEqual(formState, initialFormState.current);
  }, [formState]);

  useOnClickOutside(datePopoverRef, (event: MouseEvent | TouchEvent) => {
    if (!dateFieldRef.current?.contains(event.target as Node)) {
      setIsDatePickerOpen(false);
    }
  });

  useEffect(() => {
    if (dateFieldRef?.current) {
      setPopoverAnchor(dateFieldRef.current);
    }
  }, [dateFieldRef]);

  const updateFormState = (newState: Partial<DocumentAttributes>) => {
    setFormState({ ...formState, ...newState });
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormState({ documentTitle: e.target.value });
  };

  const handleExpertCompanyChange = (value: Company) => {
    const { id, name } = value ?? {};
    updateFormState({ expertCompanyName: name, expertCompanyCdsAlphaCompanyId: id });
  };

  const handleExpertPositionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFormState({ expertPosition: e.target.value });
  };

  const handleRelevantDateChange = (date: Date) => {
    updateFormState({ date: date.toISOString() });
    setIsDatePickerOpen(false);
  };

  const handleAngleChange = (value: SelectValue) => {
    updateFormState({ angle: value as Angle });
  };

  const handleClickDateField = (_: React.MouseEvent) => {
    setIsDatePickerOpen(true);
  };

  const handleDateFieldMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
  };

  const handleSaveEdits = () => {
    if (!isEqual(formState, props)) {
      onClickSave(formState);
    }
  };

  return (
    <Modal
      title={MODAL_TITLE}
      size="small"
      variant="complex"
      open
      slotWidth={MODAL_WIDTH}
      slotHeight="auto"
      transition="opacity 0.3s ease-out"
      shouldShowFooter
      onClose={onClickCancel}
      primaryButton={<SaveButton onClick={handleSaveEdits} disabled={isSaveDisabled} />}
      secondaryButton={<BackButton onClick={onClickCancel} />}
    >
      <S.FormContainer>
        <S.FormRow>
          <S.FormItem>
            <S.Label disabled={isTranscript}>{FormFieldLabel.documentTitle}</S.Label>
            <S.StyledTextField
              value={formState.documentTitle}
              disabled={isTranscript}
              isControlled
              onChange={handleTitleChange}
            />
          </S.FormItem>
        </S.FormRow>
        <S.FormRow>
          <S.FormItem>
            <S.Label>{FormFieldLabel.expertCompany}</S.Label>
            <CompanySearchField
              onChange={handleExpertCompanyChange}
              value={{
                name: formState.expertCompanyName,
                id: formState.expertCompanyCdsAlphaCompanyId,
              }}
            />
          </S.FormItem>
          <S.FormItem>
            <S.Label>{FormFieldLabel.expertPosition}</S.Label>
            <S.StyledTextField value={formState.expertPosition} isControlled onChange={handleExpertPositionChange} />
          </S.FormItem>
        </S.FormRow>
        <S.FormRow>
          <S.FormItem>
            <S.Label>{FormFieldLabel.relevantDate}</S.Label>
            <S.DateField
              ref={dateFieldRef}
              value={formattedDate}
              isControlled
              onClick={handleClickDateField}
              onMouseDown={handleDateFieldMouseDown}
              endAdornment={
                <Icon size="small" color={color.icon.secondary}>
                  <CalendarToday />
                </Icon>
              }
            />
            {isDatePickerOpen && (
              <S.DatePopover
                ref={datePopoverRef}
                open={isDatePickerOpen}
                anchorEl={popoverAnchor}
                middleware={[offset(getPopoverOffset())]}
              >
                <S.StyledDatePicker
                  firstDate={new Date(formState.date!)}
                  onChange={handleRelevantDateChange}
                  disableFutureDays
                />
              </S.DatePopover>
            )}
          </S.FormItem>
          <S.FormItem>
            <S.Label>
              <S.TextAndIconWrapper>
                {FormFieldLabel.angle}
                <Tooltip variant="dark" size="small" title={ANGLE_INFO_TOOLTIP_TEXT}>
                  <S.StyledInteractiveIcon color={color.icon.strong._}>
                    <Info />
                  </S.StyledInteractiveIcon>
                </Tooltip>
              </S.TextAndIconWrapper>
            </S.Label>
            <SelectMenu
              size="small"
              value={formState.angle}
              onChange={(value: SelectValue | SelectValue[]) => handleAngleChange(value as SelectValue)}
            >
              {Object.values(Angle).map((angleOption) => (
                <SelectOption key={angleOption} value={angleOption}>
                  {ANGLE_DISPLAY[angleOption]}
                </SelectOption>
              ))}
            </SelectMenu>
          </S.FormItem>
        </S.FormRow>
      </S.FormContainer>
    </Modal>
  );
};

type ModalButtonProps = {
  onClick: () => void;
};
const SaveButton: FC<ModalButtonProps & { disabled: boolean }> = ({ onClick, disabled }) => {
  const { spacing } = useThemeTokens();
  return (
    <Button size="small" variant="outline" disabled={disabled} onClick={onClick} ml={spacing.inner.base04}>
      {SAVE}
    </Button>
  );
};

const BackButton: FC<ModalButtonProps> = ({ onClick }) => (
  <Button size="small" variant="ghost" onClick={onClick}>
    {BACK}
  </Button>
);

export default EditDocumentModal;
export type { EditDocumentModalProps };
