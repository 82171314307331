import React from "react";
import { Skeleton } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import * as S from "./AngleCards.styled";

export const AngleCards = ({ loading }: { loading: boolean }) => {
  return (
    <S.Wrapper>
      {loading ? (
        <>
          <SidebarCardLoading />
          <SidebarCardLoading />
          <SidebarCardLoading />
        </>
      ) : (
        <h1>TODO: AngleCards</h1>
      )}
    </S.Wrapper>
  );
};

const SidebarCardLoading = () => (
  <S.SidebarCardLoadingWrapper>
    <x.div w="70%" maxW="200px">
      <Skeleton variant="noMargin" width="100%" height="20px" />
    </x.div>
    <x.div w="30%" maxW="130px">
      <Skeleton variant="noMargin" width="100%" height="20px" />
    </x.div>
  </S.SidebarCardLoadingWrapper>
);
