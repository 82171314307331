import React from "react";
import { useCompanyPrimerV3Store } from "../../../store/store";
import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { usePrimersStore } from "pages/AlphaNowPage/primers/state/store";
import RevenueDetails from "./RevenueDetails";
import OverviewDetailsRow from "./OverviewDetailsRow";
import CitationTextWrapper from "pages/AlphaNowPage/primers/components/CitationWrapper/CitationTextWrapper";

const OverviewDetails = () => {
  const {
    spacing: { inner },
    font,
  } = useThemeTokens();

  const { location, revenue, fullTimeEquivalent, ownership, markets } = useCompanyPrimerV3Store(
    ({ overview }) => overview
  );
  const { currency } = usePrimersStore();

  const { year, city, country, state } = location;
  const { max: fteMax, min: fteMin, year: fteYear } = fullTimeEquivalent;
  const { ownershipType, vcBackedSeries, ownershipYear, ownershipQuarter, companyName: ownershipCompany } = ownership;

  const getOwnershipValue = () => {
    if (ownershipType.value === "VC backed") {
      return (
        <x.div display="flex" columnGap={inner.base}>
          <x.div display="flex">
            <CitationTextWrapper value={ownershipType} />
          </x.div>
          {Boolean(vcBackedSeries?.value) && (
            <x.div display="flex">
              {"("}
              <CitationTextWrapper value={vcBackedSeries} />
              {")"}
            </x.div>
          )}
        </x.div>
      );
    }

    if (
      ownershipType.value === "Publicly held" ||
      ownershipType.value === "Privately held" ||
      !ownershipCompany.value ||
      !ownershipQuarter ||
      !ownershipYear
    ) {
      return <CitationTextWrapper value={ownershipType} />;
    }

    return (
      <x.div display="flex" columnGap={inner.base}>
        <x.div display="flex">
          <CitationTextWrapper value={ownershipType} />,
        </x.div>
        <x.div display="flex">
          {"("}
          <CitationTextWrapper value={ownershipCompany} />,
        </x.div>
        <x.div
          display="flex"
          fontSize={font.size["03"]}
          alignItems="center"
        >{`Q${ownershipQuarter} ${ownershipYear})`}</x.div>
      </x.div>
    );
  };

  const detailsRow = [
    {
      label: "Founded",
      value: year.value?.toString(),
    },
    {
      label: "HQ",
      value: (
        <x.div display="flex" columnGap="4px">
          <x.div display="flex">
            <CitationTextWrapper value={city} />,
          </x.div>
          {!!state.value && (
            <x.div display="flex">
              <CitationTextWrapper value={state} />,
            </x.div>
          )}
          <x.div display="flex">
            <CitationTextWrapper value={country} />
          </x.div>
        </x.div>
      ),
    },
    {
      label: "Revenue",
      value: <RevenueDetails revenue={revenue} currency={currency} />,
    },
    {
      label: "FTE's",
      value: fteMax.value ? `${fteMin.value} to ${fteMax.value} (${fteYear})` : `${fteMin.value}+ (${fteYear})`,
    },
    {
      label: "Ownership",
      value: getOwnershipValue(),
    },
    {
      label: "Markets",
      value: (
        <x.div display="flex" gap={inner.base} flexDirection="column">
          {markets.map((market, index) => (
            <Typography key={index}>
              <x.div display="flex" columnGap={inner.base}>
                <CitationTextWrapper value={market.name} />
                <CitationTextWrapper
                  value={{ ...market.name, value: market.name.isSensitive ? market.name.value : `(${market.region})` }}
                />
              </x.div>
            </Typography>
          ))}
        </x.div>
      ),
    },
  ];

  return (
    <div>
      {detailsRow.map((row, index) => {
        const hasChildrenValue = typeof row.value === "object";

        return hasChildrenValue ? (
          <OverviewDetailsRow key={index} label={row.label}>
            {row.value}
          </OverviewDetailsRow>
        ) : (
          <OverviewDetailsRow key={index} label={row.label} value={row.value as string} />
        );
      })}
    </div>
  );
};

export default OverviewDetails;
