import React, { useEffect, useState } from "react";
import { fetch } from "../../hooks/useApi";
import { ProjectClosedPage } from "../../components/Project/ClosedPage";
import { useLocation, useParams } from "router-utils";
import { ErrorPage } from "../../components/ErrorPage";
import { CaptchaPageWrapper } from "../../components/CaptchaPage";
import { Cookie } from "../../Cookie";
import { CookiePolicyModal, useCookiePolicy, useEnv, useTrackUserAction } from "@alphasights/client-portal-shared";
import { DeliverableProvider } from "providers/DeliverableProvider";
import LogRocket from "logrocket";
import { LOG_ROCKET } from "../../constants/CookiePolicy";
import { CurrentProjectProvider } from "providers/CurrentProjectProvider";
import { ProjectMembersProvider } from "providers/ProjectMembersProvider";
import { ProjectBadgeProvider, normalizeProjectBadges } from "providers/BadgeProvider";
import { ProjectAccessForbiddenPage } from "../../components/ErrorPage/ProjectAccessForbiddenPage";
import { currentProjectView } from "../../helpers/currentView";
import { RememberSearchParamsProvider } from "providers/RememberSearchParamsProvider";
import { ProjectRoutes } from "./ProjectRoutes";
import { useCurrentUser, useIsAuthenticated, useMyProjects, usePortalStore } from "@alphasights/portal-auth-react";
import { useClientPortalEvents, useClientPortalSelector } from "app/wrappers/ClientPortalWrapper/ClientPortalHooks";
import { getShouldSkipSignIn } from "app/selectors/ClientPortal.selectors";
import { x } from "@xstyled/styled-components";
import { LoginModal } from "pages/Login/LoginModal";
import { ExpertCompareProvider } from "providers/ExpertCompareProvider";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { AngleQuestionsProvider } from "providers/AngleQuestionsProvider";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const Wrapper = ({
  children,
  isLoading,
  project,
  token,
  ...props
}: {
  children: React.ReactNode;
  isLoading: boolean;
  project?: Project;
  token: string;
  h: string;
}) => {
  const { color } = useThemeTokens();
  return (
    <x.div background={color.background.neutral.default} display="flex" flexDirection="column" flexGrow={1} {...props}>
      <ProjectContextProviders project={project} token={token} isProjectLoading={isLoading}>
        {children}
      </ProjectContextProviders>
    </x.div>
  );
};

const ProjectPageComponent = () => {
  const env = useEnv();
  const { onCloseLoginModal } = useClientPortalEvents();
  const shouldSkipSignIn = useClientPortalSelector(getShouldSkipSignIn);
  const currentUser = useCurrentUser();
  const isUserAuthtenticaded = useIsAuthenticated();
  const { isPolicyAccepted } = useCookiePolicy();
  const { trackUserViewed } = useTrackUserAction();
  const portalStoreState = usePortalStore();
  const { isMobile } = useCheckScreen();
  const projects = useMyProjects() ?? [];
  const [project, setProject] = useState<Project>();
  const [error, setError] = useState<{ status: number }>();

  const params = useParams() ?? {};
  const token = params.token ?? "";
  const { pathname } = useLocation();

  useEffect(
    function fetchCurrentProject() {
      if (!token) return;
      const projectToFetchTitle = projects.find((p) => p.token === token)?.title;
      if (projectToFetchTitle) {
        // We're updating the project title so we don't have to show
        // a loading state while fetching the project data.
        setProject((prev) => (prev ? { ...prev, title: projectToFetchTitle } : prev));
      }
      setError(undefined);
      fetch({ url: `/api/projects/${token}` })
        .then(async (data) => {
          const p: Project = await data.json();
          setProject(p);
        })
        .catch((err) => setError(err));
    },
    [token] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const isLoading = !project;

  useEffect(() => {
    // When the user is not logged in, we use the project token as identification.
    // This allows us to have usage metrics in Heap even when we don't know exactly
    // which client contact is using the portal.
    if (
      project &&
      !currentUser &&
      env?.enableLogRocket &&
      typeof LogRocket !== "undefined" &&
      isPolicyAccepted(LOG_ROCKET)
    ) {
      LogRocket.identify(token);
    }
  }, [token, project, currentUser, env, isPolicyAccepted]);

  const currentView = currentProjectView(pathname);
  useEffect(() => {
    if (currentUser?.pastProjectsEnabled && project && project.inactive && currentView) {
      trackUserViewed("past-project-view", project.token, undefined, undefined, {
        origin: currentView,
      });
    }
  }, [project, currentUser, currentView]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error) {
    if (error.status === 403) {
      return <ProjectAccessForbiddenPage error={error} projectToken={token} />;
    }
    return <ErrorPage error={error} />;
  }

  const { enableLimitationsOfUse = false } = project || {};

  const accessible = project?.accessible && (project?.active || currentUser?.pastProjectsEnabled);

  if (
    !isLoading &&
    !isUserAuthtenticaded &&
    !shouldSkipSignIn &&
    !env?.internalUser &&
    !pathname.includes("/third-party")
  ) {
    return <LoginModal isOpen={true} onClose={onCloseLoginModal} projectToken={token} />;
  }

  const limitationsOfUseAccepted = Cookie.findByName("limitations_of_use");
  const showCaptcha =
    !isLoading && enableLimitationsOfUse && env?.enableCaptcha && !limitationsOfUseAccepted && !currentUser;

  if (showCaptcha) {
    return <CaptchaPageWrapper env={{ ...env }} />;
  }
  return (
    <>
      <Wrapper project={project} token={token} isLoading={isLoading} h={!isMobile ? "100%" : "100dvh"}>
        {!isLoading && (
          <>{accessible ? <ProjectRoutes project={project} /> : <ProjectClosedPage project={project} />}</>
        )}
      </Wrapper>
      <CookiePolicyModal isPageLoading={isLoading} portalStoreState={portalStoreState} />
    </>
  );
};

export const ProjectPage = React.memo(ProjectPageComponent);

const ProjectContextProviders = ({
  project,
  token,
  isProjectLoading,
  children,
}: {
  project?: Project;
  token: string;
  isProjectLoading: boolean;
  children: React.ReactNode;
}) => {
  return (
    <CurrentProjectProvider project={project} isLoading={isProjectLoading}>
      <ProjectBadgeProvider badges={normalizeProjectBadges(project)}>
        <ProjectMembersProvider projectToken={token}>
          <RememberSearchParamsProvider>
            <DeliverableProvider project={project}>
              <ExpertCompareProvider projectToken={token} project={project}>
                <AngleQuestionsProvider project={project}>{children}</AngleQuestionsProvider>
              </ExpertCompareProvider>
            </DeliverableProvider>
          </RememberSearchParamsProvider>
        </ProjectMembersProvider>
      </ProjectBadgeProvider>
    </CurrentProjectProvider>
  );
};
