import React from "react";
import { Typography, Avatar, useThemeTokens } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

export const CustomReason = ({ reason, primaryContact }) => {
  const {
    spacing: { inner },
    color: { text },
  } = useThemeTokens();

  return (
    <x.div display="flex" flexDirection="column" p={inner.base03}>
      <Typography component="span">
        <Typography variant="body-em" component="span">
          Highly Recommended:{" "}
        </Typography>
        {reason}
      </Typography>
      <x.div pt={inner.base02} display="flex" alignItems="center">
        <Avatar size="small" src={primaryContact.avatar.thumbUrl} text={primaryContact.name} />
        <x.div pl={inner.base02}>
          <Typography component="span" color={text.secondary}>
            {primaryContact.name}
          </Typography>
        </x.div>
      </x.div>
    </x.div>
  );
};
