import React, { FC } from "react";
import { useQuery } from "query-utils";
import { x } from "@xstyled/styled-components";
import {
  IconButton,
  IconButtonSizes,
  IconButtonVariant,
  useThemeTokens,
  useAlphaToast,
  Loading,
} from "@alphasights/alphadesign-components";
import { Download } from "@alphasights/alphadesign-icons";
import ActionBarMenuItem from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptActionBar/ActionBarMenuItem/ActionBarMenuItem";
import { contentService } from "services/content";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { systemError, downloadFailedBody } from "content/AlphaNow";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { AlphaNowContentType, AlphaNowProductType } from "@alphasights/client-portal-shared";
import { useCurrentUser, useIsInternalUser } from "@alphasights/portal-auth-react";

const DataTestIds = {
  downloadButton: "download-button",
  mobileDownloadButton: "mobile-view-download-button",
};

export interface DownloadButtonProps {
  contentId: any;
  variant?: IconButtonVariant;
  size?: IconButtonSizes;
  asListOption?: boolean;
  onCloseMobilePopover?: () => void;
  contentType?: AlphaNowContentType | AlphaNowProductType;
  projectToken?: string;
  className?: string;
  label?: string;
  downloadFileName: string;
}

const DownloadButton: FC<DownloadButtonProps> = ({
  contentId,
  variant = "basic",
  size = "medium",
  asListOption = false,
  onCloseMobilePopover = () => {},
  contentType = AlphaNowContentType.alphaview,
  projectToken,
  className,
  label = "Download",
  downloadFileName,
}) => {
  const { logHit } = useTrackUserAction();
  const { toast } = useAlphaToast();
  const internalUser = useIsInternalUser();
  const currentUser = useCurrentUser() as (User & { servicePlan?: { trial: boolean } }) | undefined;
  const hasTrialServicePlan = currentUser?.servicePlan?.trial;

  const {
    color: { icon },
    spacing: { inner },
  } = useThemeTokens();

  const setDownloadError = () => toast.error({ message: `${systemError}${downloadFailedBody}` });

  const { isFetching, refetch: fetchFile } = useQuery(
    ["download"],
    () => contentService.downloadTranscript(contentId, projectToken),
    {
      enabled: false,
      onSuccess: (res: Blob) => {
        try {
          const downloadUrl = URL.createObjectURL(new Blob([res], { type: "application/pdf" }));
          const downloadLink = document.createElement("a");
          downloadLink.href = downloadUrl;
          downloadLink.download = downloadFileName;
          downloadLink.setAttribute("download", downloadFileName);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          downloadLink.parentNode!!.removeChild(downloadLink);
        } catch {
          setDownloadError();
        }
      },
      onError: setDownloadError,
      onSettled: onCloseMobilePopover,
    }
  );

  const onClickDownload = () => {
    const logHitActions = {
      [AlphaNowContentType.alphaview]: HitAction.alphaNowDownloadContent,
      [AlphaNowProductType.companyPrimer]: HitAction.alphaNowDownloadCompanyPrimer,
      [AlphaNowProductType.marketPrimer]: HitAction.alphaNowDownloadMarketPrimer,
      [AlphaNowProductType.customerPrimer]: HitAction.alphaNowDownloadCustomerPrimer,
      [AlphaNowContentType.pcc]: HitAction.alphaNowDownloadContent,
    } as Record<string, HitAction>;

    logHit({
      origin: HitOrigin.alphaNow,
      //@ts-ignore
      action: logHitActions[contentType as string],
      details: {
        contentId,
        // @ts-ignore
        ...(internalUser && {
          // @ts-ignore
          internalUser,
        }),
      },
    });
    fetchFile();
  };

  if (hasTrialServicePlan) return null;

  if (asListOption)
    return (
      <ActionBarMenuItem
        icon={isFetching ? <Loading padding={inner.base} /> : <Download size="small" />}
        dataTestId={DataTestIds.mobileDownloadButton}
        label={label}
        onClick={onClickDownload}
        size={size}
      />
    );

  if (isFetching) {
    return (
      <x.div mt={inner.base02} display="flex" alignItems="center">
        <Loading size="sm" padding={inner.base} />
      </x.div>
    );
  }

  return (
    <x.div className={className} display="flex" alignItems="center">
      <IconButton onClick={onClickDownload} color={icon.secondary} size={size} variant={variant}>
        <Download data-testid={DataTestIds.downloadButton} />
      </IconButton>
    </x.div>
  );
};

export { DownloadButton as default, DataTestIds };
