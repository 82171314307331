const MODAL_TITLE = "Edit Document Details";
const MODAL_WIDTH = "676px";

const SAVE = "Save";
const BACK = "Back";

enum FormFieldLabel {
  documentTitle = "Uploaded Document Title",
  expertCompany = "Expert Company",
  expertPosition = "Expert Position",
  relevantDate = "Relevant Date",
  angle = "Angle",
}

export { MODAL_TITLE, MODAL_WIDTH, SAVE, BACK, FormFieldLabel };
