import React, { useEffect, useMemo, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import { IconButton, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { ChevronDown, ChevronUp } from "@alphasights/alphadesign-icons";
import { useInteractionDeliverablesContext } from "providers/InteractionDeliverableProvider";
import { useMobileDeliverablesStyles } from "./MobileDeliverablesView.styles";
import { format, parseISO } from "date-fns";
import { DeliverableTranscript } from "views/DeliverablesView/DeliverableTranscript";
import { selectIndexHighlight } from "views/DeliverablesView/helpers";
import { noop } from "lodash";
import { Summary, SummaryStatus, SummaryType } from "views/DeliverablesView/AiSummaries/AiSummaries.types";
import styled from "styled-components";
import { useAudioPlayer, useAutoScroll } from "hooks/useAudioPlayer";
import { useLoggableAudioPlayer } from "views/DeliverablesView/useLoggableAudioPlayer";
import { useNoDrag } from "utils/mobileHacks";
import { AudioPlayer } from "views/DeliverablesView/AudioPlayer";
import { MainContentAudioOnly, MainContentTranscriptProcessing } from "views/DeliverablesView/MainContentViews";
import { useHideDeliverablesContent } from "views/DeliverablesView/DeliverablesPage/useHideDeliverablesContent";
import { SummaryTab } from "views/DeliverablesView/DeliverablesPage/SummaryTab/SummaryTab";

export const AiSummaryWrapper = ({
  headerRef,
  keywords,
  audioPlayer,
}: {
  headerRef: any;
  keywords: string[];
  audioPlayer: any;
}) => {
  const {
    interaction,
    currentTranscript,
    currentRecording,
    currentSummaries,
    totalMentionsFe,
  } = useInteractionDeliverablesContext()!;
  const { fullScreenFlexColWrapper, aiSummaryReaderWrapper } = useMobileDeliverablesStyles();

  const [currentHit, setCurrentHit] = useState(0);

  useEffect(() => {
    setTimeout(() => selectIndexHighlight(currentHit, totalMentionsFe), 100);
  }, [currentHit, totalMentionsFe]);

  const summary = useMemo(() => {
    return (
      currentSummaries.find((sum: Summary) => sum.type === SummaryType.Comprehensive) ||
      ({
        type: SummaryType.Comprehensive,
        status: SummaryStatus.NotRequested,
      } as Summary)
    );
  }, [currentSummaries]);

  if (!currentRecording || !currentTranscript) {
    return null;
  }

  return (
    <x.div {...fullScreenFlexColWrapper}>
      <x.div {...aiSummaryReaderWrapper} marginTop={headerRef.current?.clientHeight}>
        {summary.status === SummaryStatus.Generated && <ExpertInfo interaction={interaction} />}
        <SummaryTab />
        {keywords.length ? (
          <HitNav
            totalHits={totalMentionsFe ?? 0}
            setCurrentHit={setCurrentHit}
            currentHit={currentHit}
            audioPlayerOpen={!!audioPlayer}
          />
        ) : null}
      </x.div>
      {audioPlayer}
    </x.div>
  );
};

export const TranscriptReaderWrapper = ({
  headerRef,
  transcriptProps,
  showPlayer,
  audioPlayer,
}: {
  headerRef: any;
  transcriptProps: any;
  showPlayer: boolean;
  audioPlayer: any;
}) => {
  const { currentTranscript, interaction, canPlayAudio } = useInteractionDeliverablesContext()!;

  const { transcriptReaderWrapper, fullScreenFlexColWrapper } = useMobileDeliverablesStyles();

  const ErrorPage = useMemo(() => {
    const allTranscripts = interaction.recordings?.flatMap((r) => r.transcriptRequests) || [];
    if (allTranscripts.length === 0) {
      return MainContentAudioOnly;
    }
    if (!currentTranscript) {
      return MainContentTranscriptProcessing;
    }
    return undefined;
  }, [currentTranscript, interaction.recordings]);

  if (ErrorPage) {
    return (
      <x.div {...fullScreenFlexColWrapper}>
        <ErrorPage />
        {audioPlayer}
      </x.div>
    );
  }

  return (
    <x.div {...fullScreenFlexColWrapper}>
      <x.div
        {...transcriptReaderWrapper}
        marginTop={headerRef.current?.clientHeight || transcriptReaderWrapper.marginTop}
      >
        <ExpertInfo interaction={interaction} />
        {currentTranscript && (
          <TranscriptReader transcriptProps={transcriptProps} canPlayAudio={canPlayAudio} showPlayer={showPlayer} />
        )}
      </x.div>
      {audioPlayer}
    </x.div>
  );
};

const TypographyNoWrap = styled(Typography)`
  text-wrap: nowrap;
`;

const ExpertInfo = ({ interaction }: { interaction: Partial<Interaction> }) => {
  const { color } = useThemeTokens();

  return (
    <x.div>
      <x.div display={"flex"} justifyContent={"space-between"}>
        <Typography variant="body-small-em" color={color.text.strong._}>
          {interaction.advisorName}
        </Typography>
        <TypographyNoWrap variant="body-small" color={color.text.secondary}>
          {format(parseISO(interaction.scheduledCallTime!), "dd MMM yyyy")}
        </TypographyNoWrap>
      </x.div>
      <Typography variant="body-small" color={color.text.secondary}>
        {interaction.advisorCompany} | {interaction.role}
      </Typography>
    </x.div>
  );
};

const TranscriptReader = ({
  transcriptProps,
  canPlayAudio,
  showPlayer,
}: {
  transcriptProps: any;
  canPlayAudio: boolean;
  showPlayer: boolean;
}) => {
  const {
    interaction,
    currentTranscript,
    currentRecording,
    sentences,
    isLoading,
    totalMentions,
  } = useInteractionDeliverablesContext()!;
  const [currentHit, setCurrentHit] = useState(0);
  useEffect(() => {
    setTimeout(() => selectIndexHighlight(currentHit, totalMentions), 100);
  }, [currentHit, totalMentions]);

  const { contentStyle } = useHideDeliverablesContent();

  return currentTranscript && !isLoading ? (
    <x.div data-testid="deliverables-main-text" data-transcript-scrollable-area {...contentStyle}>
      <DeliverableTranscript
        interaction={interaction}
        transcriptRequest={currentTranscript}
        transcriptProps={transcriptProps}
        recording={currentRecording}
        canPlayAudio={canPlayAudio}
        sentences={sentences}
        fragmentIds={[]}
        showPlayer={showPlayer}
        isMobile
      />{" "}
      {totalMentions !== undefined && (
        <HitNav
          totalHits={totalMentions}
          setCurrentHit={setCurrentHit}
          currentHit={currentHit}
          audioPlayerOpen={showPlayer}
        />
      )}
    </x.div>
  ) : null;
};

export const HitNav = ({
  totalHits,
  currentHit,
  setCurrentHit,
  audioPlayerOpen = false,
}: {
  totalHits: number;
  currentHit: number;
  setCurrentHit: (fn: (i: number) => number) => void;
  audioPlayerOpen?: boolean;
}) => {
  const { mentionsNav } = useMobileDeliverablesStyles();
  const { color, spacing } = useThemeTokens();
  return (
    <x.div {...mentionsNav} data-testid="keyword-hits-nav" mb={audioPlayerOpen ? 70 : undefined}>
      {totalHits ? (
        <>
          <IconButton
            variant="ghost"
            onClick={() => setCurrentHit((a) => (a > 0 ? a - 1 : totalHits - 1))}
            testId="previous-keyword-hit"
          >
            <ChevronUp />
          </IconButton>
          <span>
            <Typography component="span" variant="body-small-em" color={color.text.danger}>
              {currentHit + 1}{" "}
            </Typography>
            <Typography component="span" variant="body-small-em" color={color.text.secondary}>
              / {totalHits}
            </Typography>
          </span>
          <IconButton
            variant="ghost"
            onClick={() => setCurrentHit((a) => (a < totalHits - 1 ? a + 1 : 0))}
            testId="next-keyword-hit"
          >
            <ChevronDown />
          </IconButton>
        </>
      ) : (
        <Typography p={spacing.inner.base03} variant="body-small-em" color={color.text.danger}>
          No results found
        </Typography>
      )}
    </x.div>
  );
};

export const DeliverableContent = ({
  selectedTab,
  onHidePlayer,
  headerRef,
  showPlayer,
  keywords,
}: {
  selectedTab: string;
  onHidePlayer: () => void;
  headerRef: any;
  showPlayer: boolean;
  keywords: string[];
}) => {
  const { currentTranscript, interaction, currentRecording, canPlayAudio } = useInteractionDeliverablesContext()!;
  const audioPlayer = useAudioPlayer({
    audioUrl: currentRecording?.url,
    vttUrl: null,
    disabled: !canPlayAudio,
  });

  const scrollableAudioPlayer = useAutoScroll({
    audioPlayer,
    onNonAutomaticScrollEnded: noop,
  });

  const {
    transcriptProps,
    renderElements: playerRenderElements,
    controlsProps,
    progressBarProps,
  } = useLoggableAudioPlayer({
    audioPlayer: scrollableAudioPlayer,
    currentTranscript,
    interaction,
  });

  useEffect(() => {
    !showPlayer && controlsProps.playing && controlsProps.onTogglePlay();
  }, [showPlayer, controlsProps]);

  const ref = useRef<HTMLDivElement>(null);
  useNoDrag({ ref });

  const { audioBar } = useMobileDeliverablesStyles();

  const audioPlayerComponent = showPlayer && canPlayAudio && (
    <x.div ref={ref} data-testid="mobile-deliverables-player" {...audioBar}>
      <AudioPlayer
        controlsProps={controlsProps}
        progressBarProps={progressBarProps}
        onClose={onHidePlayer}
        mobile={true}
      />
    </x.div>
  );

  return (
    <>
      {selectedTab === "transcript" && (
        <TranscriptReaderWrapper
          headerRef={headerRef}
          transcriptProps={transcriptProps}
          showPlayer={showPlayer}
          audioPlayer={audioPlayerComponent}
        />
      )}
      {selectedTab === "aiSummary" && (
        <AiSummaryWrapper headerRef={headerRef} keywords={keywords} audioPlayer={audioPlayerComponent} />
      )}
      {playerRenderElements}
    </>
  );
};
