import React from "react";
import { Button, Skeleton } from "@alphasights/alphadesign-components";
import { Add } from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import * as S from "./QuestionThemeCard.styled";

export const QuestionThemeCardLoading = () => (
  <S.CardWrapper>
    <S.CardTitleWrapper>
      <x.div w="50%" maxW="400px" padding="6px 12px">
        <Skeleton variant="noMargin" height="20px" width="100%" />
      </x.div>
    </S.CardTitleWrapper>
    <S.CardContentWrapper>
      <S.QuestionLoading />
      <S.QuestionLoading />
      <S.QuestionLoading />
      <AddQuestionButton disabled />
    </S.CardContentWrapper>
  </S.CardWrapper>
);

const AddQuestionButton = ({ disabled }: { disabled: boolean }) => {
  return (
    <Button variant="ghost-padded" startIcon={<Add />} size="small" disabled={disabled}>
      Add question
    </Button>
  );
};
