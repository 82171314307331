import React from "react";
import { AdvisorsPageHeader } from "views/AdvisorsPage/AdvisorsPageHeader";
import { ExpertFilters } from "./ExpertFilters";
import { ComparisonViewHeader } from "views/ComparisonView/ComparisonViewHeader/ComparisonViewHeader";
import { useComparisonViewParams } from "views/ComparisonView/helpers";
import { useCheckScreen } from "@alphasights/ads-community-hooks";

type AppliedFilters = { [key: string]: string[] };
type CurrentViewHeaderProps = {
  currentView: string;
  experts: { angleTypeName: string }[];
  loading: boolean;
  onChangeLayout: (layout: string) => void;
  onResetAllFilters: () => void;
  onSearch: (searchQuery?: string) => void;
  onSearchQueryChange: (searchQuery: string) => void;
  onSubmitFilters: (filters: AppliedFilters) => void;
  onToggleFilter: () => void;
  pastProjectsEnabled: boolean;
  project: Project;
  searchQuery: string;
  showing: number;
};

export const CurrentViewHeader = ({
  currentView,
  experts,
  loading,
  onChangeLayout,
  onResetAllFilters,
  onSearch,
  onSearchQueryChange,
  onSubmitFilters,
  onToggleFilter,
  pastProjectsEnabled,
  project,
  searchQuery,
  showing,
}: CurrentViewHeaderProps) => {
  const { isMobile } = useCheckScreen();
  const { questions, angles, selectedAngle } = useComparisonViewParams();

  if (isMobile || !["table-view", "list-view", "comparison-view"].includes(currentView)) {
    return null;
  }

  return currentView === "comparison-view" ? (
    <ComparisonViewHeader
      onSubmitFilters={onSubmitFilters}
      onToggleFilter={onToggleFilter}
      onResetAllFilters={onResetAllFilters}
      angles={angles}
      selectedAngle={selectedAngle}
      questions={questions}
    />
  ) : (
    <AdvisorsPageHeader
      currentView={currentView}
      experts={experts}
      loading={loading}
      onChangeLayout={onChangeLayout}
      onSearch={onSearch}
      onSearchQueryChange={onSearchQueryChange}
      pastProjectsEnabled={pastProjectsEnabled}
      project={project}
      searchQuery={searchQuery}
      showing={showing}
      filterComponent={(isCompact) => (
        <ExpertFilters
          isCompact={isCompact}
          onResetAllFilters={onResetAllFilters}
          onSubmitFilters={onSubmitFilters}
          onToggleFilter={onToggleFilter}
          searchQuery={searchQuery}
        />
      )}
    />
  );
};
