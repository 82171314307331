import { useCheckScreen } from "@alphasights/ads-community-hooks";
import {
  Avatar,
  Button,
  Icon,
  MobileSidebar,
  MobileSidebarDivider,
  MobileSidebarOption,
  MobileSidebarSubOption,
  ToastContainer,
  useAlphaToast,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { CalendarAvailable, Dashboard, Info, Library, Settings, Warning } from "@alphasights/alphadesign-icons";
import { openLoginUrl } from "@alphasights/auth-components";
import { CookiePolicyModal, CookiePolicyScript, LoadingOverlay, useEnv } from "@alphasights/client-portal-shared";
import {
  getUserIsReady,
  useCurrentUser,
  useMyOrderedProjects,
  useMyTopProjects,
  usePortalStore,
  usePortalStoreSelector,
  useUserAuthIsReady,
} from "@alphasights/portal-auth-react";
import { x } from "@xstyled/styled-components";
import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { BrowserRouter, Routes, useLocation, Route, Outlet, useParams, Navigate, useNavigate } from "router-utils";
import ReactTooltip from "react-tooltip";

import { useClientPortalOperations } from "app/wrappers/ClientPortalWrapper";
import { useFeatures } from "app/wrappers/FeatureFlagsWrapper";
import ErrorBoundary from "components/ErrorPage/ErrorBoundary";
import { withNavigationContainer } from "components/NavigationContainer/NavigationContainer";
import { VIEW_ALPHA_GPT_PERMISSION, VIEW_CONTENT_PERMISSION } from "constants/AlphaNow";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";
import { useAccessControl } from "hooks/useAccessControl";
import useQueryParams from "hooks/useQueryParams";
import useRedirect from "hooks/useRedirect";
import { useLogger } from "logging/Logger";
import { AlphaGPTPage } from "pages/AlphaGPTPage";
import { AlphaNowPage } from "pages/AlphaNowPage";
import { AlphaNowContentContext } from "pages/AlphaNowPage/components/AlphaNowContentContext";
import useContentAccessLevel from "pages/AlphaNowPage/hooks/useContentAccessLevel";
import { CallMePage } from "pages/CallMePage";
import { CreatePasswordPage } from "pages/Login/CreatePasswordPage";
import { LoginPage } from "pages/Login/LoginPage";
import { MagicLinkLogin } from "pages/Login/MagicLinkLogin";
import { ReactivateAccount } from "pages/Login/ReactivateAccount";
import { ResetPasswordPage } from "pages/Login/ResetPasswordPage";
import NewsletterPreferencesPage from "pages/NewsletterPreferencesPage";
import { ProjectLaunchPage } from "pages/ProjectLaunchPage/ProjectLaunchPage";
import { ProjectPage } from "pages/ProjectPage";
import { UpcomingCallsPage } from "pages/UpcomingCallsPage";
import { AppSearchProvider } from "providers/AppSearchProvider";
import { BackdropProvider } from "providers/BackdropProvider";
import { UserBadgeProvider } from "providers/BadgeProvider";
import { MobileSidebarProvider, useMobileSidebarContext } from "providers/MobileSidebarProvider";
import { RealTimeCreditBalanceProvider } from "providers/RealTimeCreditBalanceProvider";
import { TwilioProvider } from "providers/TwilioProvider";
import { MobileComplianceResourcesPage } from "views/MobileComplianceResoucesPage";
import { TypographyEllipsis } from "views/MobileDeliverablesView/MobileContentCommons.component";
import { MobileProfilePage } from "../../pages/MyProfilePage/MobileProfilePage";
import { MyAlphasightsPage } from "./realtime/MyAlphasightsPage";

import "./index.css";

const App = React.memo(({ honeybadger }: { honeybadger: unknown }) => {
  const currentUser = useCurrentUser();
  const { isMobile } = useCheckScreen();
  const hasAlphaGptPermission = useAccessControl([VIEW_ALPHA_GPT_PERMISSION]);
  const hasContentPermission = useAccessControl([VIEW_CONTENT_PERMISSION]);
  const hasProjectsPermission = useAccessControl(["view_projects"]);
  const alphaNowEnabled = currentUser ? currentUser.alphaNowEnabled && hasContentPermission : false;
  const alphaGPTEnabled = currentUser
    ? currentUser.alphaGPTEnabled && (!currentUser.internalUser || hasAlphaGptPermission)
    : false;
  const myAlphaSightsEnabled = !isMobile && !currentUser?.accessOnly;

  const features = useFeatures();

  const { toast } = useAlphaToast();
  const toastRef = useRef(toast);

  useLayoutEffect(() => {
    // @ts-ignore
    window.toast = toastRef.current;
  }, []);

  return (
    <ErrorBoundary honeybadger={honeybadger}>
      <Providers>
        <>
          <DoNotUseIe />
          <BrowserRouter>
            {/* AppSearchProvider uses react router location and therefore has to be inside BrowserRouter */}
            <AppSearchProvider>
              <PortalMobileSidebar
                showMobileView={isMobile}
                currentUser={currentUser}
                alphaNowEnabled={alphaNowEnabled}
                hasProjectsPermission={hasProjectsPermission}
              />
              <Routes>
                <Route path={"/"} element={<RedirectToNextRoute />} />
                <Route path={"/services/*"} element={<Navigate to="/" replace />} />
                <Route path="/:token/call-me/:interactionId" element={<CallMePage />} />
                <Route element={<OutletWithNavigationContainer />}>
                  <Route path={"/sign-up"} element={features.launchNewLogin ? <RedirectToLogin /> : <LoginPage />} />
                  <Route path={"/sign-in"} element={features.launchNewLogin ? <RedirectToLogin /> : <LoginPage />} />

                  <Route path={"/login-success"} element={<RedirectToNextRoute />} />
                  <Route
                    path={"/reactivations/:token"}
                    element={features.launchNewLogin ? <RedirectToReactivations /> : <ReactivateAccount />}
                  />
                  <Route
                    path={"/deactivated-account"}
                    element={features.launchNewLogin ? <RedirectToDeactivatedAccount /> : <ReactivateAccount />}
                  />
                  <Route
                    path="/invitations/:token"
                    element={features.launchNewLogin ? <RedirectToInvitations /> : <CreatePasswordPage />}
                  />
                  <Route
                    path="/magic-links/:token"
                    element={features.launchNewLogin ? <RedirectToMagicLinkLogin /> : <MagicLinkLogin />}
                  />
                  <Route
                    path="/password-reset/:token"
                    element={features.launchNewLogin ? <RedirectToResetPassword /> : <ResetPasswordPage />}
                  />
                  <Route
                    path="/alphagpt/*"
                    element={<ProtectedRoute element={<AlphaGPTPageWrapper />} isDisabled={!alphaGPTEnabled} />}
                  />
                  <Route
                    path="/alphanow/*"
                    element={<ProtectedRoute element={<AlphaNowPageWrapper />} isDisabled={!alphaNowEnabled} />}
                  />
                  <Route
                    path="/content/*"
                    element={<ProtectedRoute isDisabled={true} disabledRedirectTo="/alphanow" />}
                  />
                  <Route
                    path="/projects/*"
                    element={<ProtectedRoute isDisabled={true} disabledRedirectTo={`/${myAlphaSightsBaseUrl}`} />}
                  />
                  <Route
                    path="/project-launch"
                    element={
                      <ProtectedRoute isDisabled={!currentUser?.projectLaunchEnabled} element={<ProjectLaunchPage />} />
                    }
                  />
                  <Route
                    path="/my-projects/*"
                    element={<ProtectedRoute isDisabled={true} disabledRedirectTo={`/${myAlphaSightsBaseUrl}`} />}
                  />

                  <Route
                    path="/upcoming-calls"
                    element={
                      <ProtectedRoute
                        element={<UpcomingCallsWrapper />}
                        isDisabled={!isMobile || currentUser?.accessOnly}
                        disabledRedirectTo={`/${myAlphaSightsBaseUrl}`}
                      />
                    }
                  />

                  <Route
                    path="/compliance-resources"
                    element={<ProtectedRoute element={<MobileComplianceResourcesPage />} isDisabled={!isMobile} />}
                  />

                  <Route
                    path="/my-profile"
                    element={<ProtectedRoute element={<MobileProfilePage />} isDisabled={!isMobile} />}
                  />
                  <Route
                    path={`/${myAlphaSightsBaseUrl}${myAlphaSightsEnabled ? "" : "/*"}`}
                    element={
                      <ProtectedRoute
                        isDisabled={!myAlphaSightsEnabled}
                        disabledRedirectTo="/upcoming-calls"
                        element={<MyAlphasightsRedirector />}
                      />
                    }
                  />
                  <Route path="/newsletter/content/:trackingId/unsubscribe" element={<NewsletterPreferencesPage />} />
                  {myAlphaSightsEnabled && (
                    <Route path={`/${myAlphaSightsBaseUrl}/projects/:token`} element={<ProjectPage />} />
                  )}
                  <Route path={"/:token/*"} element={<ProjectPage />} />
                </Route>
              </Routes>
            </AppSearchProvider>
          </BrowserRouter>
          <ReactTooltip className="custom-react-tooltip" multiline={true} arrowColor="transparent" />
          <ToastContainer />
        </>
      </Providers>
      <CookiePolicyScript />
    </ErrorBoundary>
  );
});

export default App;

const OutletWithNavigationContainer = withNavigationContainer(Outlet);

const MyAlphasightsRedirector = () => {
  const projects = useMyOrderedProjects() ?? [];
  const firstActiveProject = React.useMemo(() => projects.find((project) => !project.inactive), [projects]);
  return firstActiveProject ? (
    <Navigate to={`/${myAlphaSightsBaseUrl}/projects/${firstActiveProject.token}`} replace />
  ) : (
    <MyAlphasightsPage />
  );
};

const useGetLoginVariables = () => {
  const env = useEnv();

  return {
    loginUrl: env!.login!.loginUrl,
    loginRedirectHost: env!.login!.loginRedirectHost!,
  };
};

const RedirectToInvitations = () => {
  const { token } = useParams();
  const { loginUrl, loginRedirectHost } = useGetLoginVariables();
  const logger = useLogger();

  useEffect(() => {
    logger.log("new login launched");

    const invitationUrl = `${loginUrl}/invitations/${token}?redirectHost=${loginRedirectHost}&next=/my-projects`;
    window.location.href = invitationUrl;
  }, [loginRedirectHost, loginUrl, token, logger]);

  return null;
};

const RedirectToDeactivatedAccount = () => {
  const { loginUrl, loginRedirectHost } = useGetLoginVariables();

  useEffect(() => {
    const deactivatedAccountUrl = `${loginUrl}/deactivated-account?redirectHost=${loginRedirectHost}&next=/my-projects`;
    window.location.href = deactivatedAccountUrl;
  }, [loginRedirectHost, loginUrl]);

  return null;
};

const RedirectToResetPassword = () => {
  const { token } = useParams();
  const { loginUrl, loginRedirectHost } = useGetLoginVariables();

  useEffect(() => {
    const resetPasswordUrl = `${loginUrl}/password-reset/${token}?redirectHost=${loginRedirectHost}&next=/my-projects`;
    window.location.href = resetPasswordUrl;
  }, [loginRedirectHost, loginUrl, token]);

  return null;
};

const RedirectToReactivations = () => {
  const { token } = useParams();
  const { loginUrl, loginRedirectHost } = useGetLoginVariables();

  useEffect(() => {
    const reactivationUrl = `${loginUrl}/reactivations/${token}?redirectHost=${loginRedirectHost}&next=/my-projects`;
    window.location.href = reactivationUrl;
  }, [loginRedirectHost, loginUrl, token]);

  return null;
};

const RedirectToMagicLinkLogin = () => {
  const { token } = useParams();
  const { loginUrl, loginRedirectHost } = useGetLoginVariables();

  useEffect(() => {
    const magicLinkUrl = `${loginUrl}/magic-links/${token}?redirectHost=${loginRedirectHost}&next=/my-projects`;
    window.location.href = magicLinkUrl;
  }, [loginRedirectHost, loginUrl, token]);

  return null;
};

const RedirectToLogin = () => {
  const { loginUrl, loginRedirectHost } = useGetLoginVariables();
  const location = useLocation();
  const next = new URLSearchParams(location.search).get("next") ?? "/login-success";

  useEffect(() => {
    openLoginUrl({
      redirectHost: loginRedirectHost,
      loginUrl: loginUrl,
      next,
    });
  }, [loginRedirectHost, loginUrl, next]);

  return null;
};

/**
 * This Component is used to redirect the user to the next route.
 * It needs to know whether user is logged in to redirect to the correct route.
 * So it waits for the user being ready.
 */
const RedirectToNextRoute = () => {
  const redirect = useRedirect();
  const isUserReady = usePortalStoreSelector(getUserIsReady);

  useEffect(() => {
    if (isUserReady) {
      redirect();
    }
  }, [redirect, isUserReady]);

  if (!isUserReady) {
    return <LoadingOverlay />;
  }

  return null;
};

const UpcomingCallsWrapper = () => (
  <x.div className="aui-h-screen aui-bg-grey-1" h="100dvh">
    <UpcomingCallsPage />
  </x.div>
);

const AlphaNowPageWrapper = () => {
  const contentAccessLevel = useContentAccessLevel();

  return (
    <div className="aui-flex aui-flex-col aui-h-screen aui-overflow-hidden">
      <AlphaNowContentContext.Provider value={contentAccessLevel}>
        <BackdropProvider>
          <AlphaNowPage />
        </BackdropProvider>
      </AlphaNowContentContext.Provider>
    </div>
  );
};

const AlphaGPTPageWrapper = () => (
  <div className="aui-flex aui-flex-col aui-h-screen aui-overflow-hidden">
    <AlphaGPTPage />
  </div>
);

const DoNotUseIe = () => {
  if (navigator.userAgent.indexOf("MSIE") < 0 && navigator.userAgent.indexOf("Trident") < 0) {
    return null;
  }

  return (
    <div className="aui-border-0 aui-border-b aui-border-solid aui-border-warning-2 aui-bg-warning-1 aui-p-5 aui-text-center aui-text-grey-5">
      <Icon>
        <Warning />
      </Icon>
      Browser not supported. Please use{" "}
      <a className="aui-no-underline aui-text-primary-1 aui-cursor-pointer" href="https://www.microsoft.com/en-us/edge">
        Microsoft Edge
      </a>
      ,{" "}
      <a className="aui-no-underline aui-text-primary-1 aui-cursor-pointer" href="https://www.google.com/chrome/">
        Google Chrome
      </a>{" "}
      or{" "}
      <a
        className="aui-no-underline aui-text-primary-1 aui-cursor-pointer"
        href="https://www.mozilla.org/en-US/firefox/new/"
      >
        Mozilla Firefox
      </a>{" "}
      to access the AlphaSights Client Platform.
    </div>
  );
};

const PortalMobileSidebar = ({
  showMobileView,
  currentUser,
  alphaNowEnabled,
  hasProjectsPermission,
}: {
  showMobileView?: boolean;
  currentUser?: User;
  alphaNowEnabled?: boolean;
  hasProjectsPermission?: boolean;
}) => {
  const { isOpen: isNewMobileSidebarOpen, toggleSidebar: toggleNewMobileSidebar } = useMobileSidebarContext();
  const allProjects = useMyTopProjects();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { pathname } = useLocation();
  const { openLoginModal } = useClientPortalOperations();
  const {
    spacing: { inner },
  } = useThemeTokens();

  useEffect(() => {
    if (showMobileView && queryParams.get("openSidebar")) {
      toggleNewMobileSidebar();
      queryParams.delete("openSidebar");
    }
  }, [showMobileView, queryParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const onActionLoginNeeded = useCallback(
    (action = () => {}) => () => {
      if (currentUser) {
        action();
      } else {
        toggleNewMobileSidebar();
        openLoginModal({ allowAnonymousContinue: true });
      }
    },
    [openLoginModal, toggleNewMobileSidebar, currentUser]
  );

  if (!showMobileView) return null;

  const footerContent = currentUser ? (
    <x.div display="flex" flexDirection="row" alignItems="center" gap={inner.base02}>
      <Avatar size="large" text={currentUser.name} />
      <x.div maxWidth="200px">
        <TypographyEllipsis variant="body">{currentUser.name}</TypographyEllipsis>
        <TypographyEllipsis variant="body" color="text-assistive">
          {currentUser.email}
        </TypographyEllipsis>
      </x.div>
    </x.div>
  ) : (
    <x.div display="flex" flexDirection="row" alignItems="center" gap={inner.base02} flex="1">
      <Button variant="outline" flex="1" onClick={onActionLoginNeeded()}>
        Sign In
      </Button>
    </x.div>
  );

  const onOpenNewPage = (url: string) => {
    navigate(url);
    toggleNewMobileSidebar();
  };

  const projectsToShow = hasProjectsPermission && (allProjects?.length ?? 0) > 0 ? allProjects : [];

  const termsUrl = currentUser?.accessOnly
    ? "https://www.alphasights.com/subscription-user-platform-terms/"
    : "https://www.alphasights.com/clientfacingdomainterms-platformterms/";
  const privacyPolicyUrl = "https://www.alphasights.com/policies/";
  const dataProcessingAgreementUrl = "https://www.alphasights.com/clientfacingdomainterms-dpa/";

  const isActive = (path: string) => {
    return pathname.includes(path);
  };

  const tokenMatches = pathname.matchAll(/^\/(\w{15})\//g).next().value;
  const projectToken = tokenMatches ? tokenMatches[1] : null;

  return (
    <>
      <MobileSidebar
        isOpen={isNewMobileSidebarOpen}
        onClose={toggleNewMobileSidebar}
        footerContent={footerContent}
        data-testid="mobile-side-bar"
        h="100dvh"
      >
        {!currentUser?.accessOnly && (
          <MobileSidebarOption
            title="Upcoming Calls"
            leftDecorator={<CalendarAvailable />}
            onClick={onActionLoginNeeded(() => onOpenNewPage("/upcoming-calls"))}
            isActive={isActive("/upcoming-calls")}
          />
        )}
        {alphaNowEnabled && (
          <MobileSidebarOption
            title="Library"
            leftDecorator={<Library />}
            onClick={onActionLoginNeeded(() => onOpenNewPage("/alphanow"))}
            isActive={isActive("/alphanow")}
          />
        )}
        <MobileSidebarDivider />
        {!currentUser && (
          <>
            <MobileSidebarOption
              title="View all projects"
              leftDecorator={<Dashboard />}
              onClick={onActionLoginNeeded()}
            />
            <MobileSidebarDivider />
          </>
        )}
        {!currentUser?.accessOnly && (projectsToShow?.length ?? 0) > 0 && (
          <MobileSidebarOption title="View all projects" leftDecorator={<Dashboard />}>
            {projectsToShow?.map((project) => (
              <MobileSidebarSubOption
                key={project.token}
                title={project.title}
                onClick={() => onOpenNewPage(`/${project.token}/experts`)}
                isActive={isActive(`/${project.token}`)}
              />
            ))}
          </MobileSidebarOption>
        )}
        {!currentUser?.accessOnly &&
          projectsToShow
            ?.slice(0, 3)
            .map((project) => (
              <MobileSidebarOption
                key={project.token}
                title={project.title}
                onClick={() => onOpenNewPage(`/${project.token}/experts`)}
                isActive={isActive(`/${project.token}`)}
              />
            ))}
        {!currentUser?.accessOnly && (projectsToShow?.length ?? 0) > 0 && <MobileSidebarDivider />}
        <MobileSidebarOption title="Info" leftDecorator={<Info />}>
          <MobileSidebarSubOption
            title="AlphaSights Compliance"
            isActive={isActive("/compliance-resources")}
            onClick={() =>
              (window.location.href = projectToken ? `/${projectToken}/compliance-resources` : "/compliance-resources")
            }
          />
          <MobileSidebarSubOption title="Platform Terms" onClick={() => (window.location.href = termsUrl)} />
          <MobileSidebarSubOption
            title="Data Processing Agreement"
            onClick={() => (window.location.href = dataProcessingAgreementUrl)}
          />
          <MobileSidebarSubOption
            title="Privacy and Cookie Policy"
            onClick={() => (window.location.href = privacyPolicyUrl)}
          />
          <MobileSidebarSubOption title="Manage Cookies" className="cky-banner-element" />
        </MobileSidebarOption>
        <MobileSidebarOption
          title="Preferences"
          leftDecorator={<Settings />}
          onClick={onActionLoginNeeded(() => onOpenNewPage("/my-profile"))}
          isActive={isActive("/my-profile")}
        />
      </MobileSidebar>
    </>
  );
};

const Providers = ({ children }: { children: JSX.Element }) => {
  const currentUser = useCurrentUser();

  return (
    <UserBadgeProvider badges={currentUser?.badges ?? []}>
      <MobileSidebarProvider>
        <TwilioProvider>
          <RealTimeCreditBalanceProvider>{children}</RealTimeCreditBalanceProvider>
        </TwilioProvider>
      </MobileSidebarProvider>
    </UserBadgeProvider>
  );
};

const ProtectedRoute = ({
  element,
  requiredBadges = [],
  isDisabled = false,
  disabledRedirectTo = "/my-projects",
  ...props
}: {
  element?: JSX.Element;
  requiredBadges?: string[];
  isDisabled?: boolean;
  disabledRedirectTo?: string;
}) => {
  const isReady = useUserAuthIsReady();
  const currentUser = useCurrentUser();
  const portalStoreState = usePortalStore();
  const location = useLocation();

  if (!isReady) return <LoadingOverlay />;

  if (!currentUser) {
    const next = location.pathname + location.search;
    return <Navigate to={"/sign-in?" + new URLSearchParams({ next })} />;
  }

  if (isDisabled || requiredBadges.find((badge) => !currentUser.badges?.includes(badge)))
    return <Navigate {...props} to={disabledRedirectTo} replace />;

  return (
    <>
      {element}
      <CookiePolicyModal portalStoreState={portalStoreState} />
    </>
  );
};
