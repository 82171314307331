import React, { useCallback, useEffect, useRef, useState } from "react";
import { Icon } from "@alphasights/alphadesign-components";
import { Intercom as IntercomIcon } from "@alphasights/alphadesign-icons";
import useOnClickOutside from "hooks/useOnClickHooks";
import * as S from "../SidebarNav.styled";

interface IntercomLauncherProps {
  isIntercomOpen: boolean;
  toggleIntercom: () => void;
}

export const IntercomLauncher = ({ isIntercomOpen, toggleIntercom }: IntercomLauncherProps) => {
  const [intercomBooted, setIntercomBooted] = useState(false);
  const Intercom = window.Intercom;
  const intercomRef = useRef<HTMLDivElement>(null);

  const openIntercomPanel = () => {
    Intercom(isIntercomOpen ? "hide" : "show");
    toggleIntercom();
  };

  const checkIntercomBooted = useCallback(() => {
    const timeout = setTimeout(() => clearInterval(interval), 30000);
    const interval = setInterval(() => {
      if (Intercom.booted) {
        setIntercomBooted(true);
        clearInterval(interval);
        clearTimeout(timeout);
      }
    }, 100);
  }, [Intercom]);

  useEffect(() => {
    checkIntercomBooted();
    Intercom("onHide", () => {
      if (isIntercomOpen) {
        toggleIntercom();
      }
    });
  }, [Intercom, checkIntercomBooted, isIntercomOpen, toggleIntercom]);

  useOnClickOutside(intercomRef, () => {
    if (isIntercomOpen) {
      Intercom("hide");
      toggleIntercom();
    }
  });

  return (
    <S.IntercomWrapper
      ref={intercomRef}
      open={isIntercomOpen}
      intercomBooted={intercomBooted}
      onClick={openIntercomPanel}
      data-testid="intercom-launcher"
    >
      <Icon size="large" color="inverse">
        <IntercomIcon />
      </Icon>
    </S.IntercomWrapper>
  );
};
