import React, { useMemo } from "react";
import styled, { th, x } from "@xstyled/styled-components";
import useStyles from "../styles";
import { tagHighlight } from "../transcripts/support";
import { EmphasisTypography } from "../transcripts/EmphasisTypography";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { css } from "styled-components";

export const ProjectSummaryRender = ({ sentences, summaryRef }) => {
  const styles = useStyles();

  const parts = useMemo(() => {
    return sentences.map((s) => ({
      ...s,
      value: s.hasHighlight ? tagHighlight(s.value) : s.value,
    }));
  }, [sentences]);

  return (
    <x.div ref={summaryRef} {...styles.summaryWrapper}>
      <SummaryContents parts={parts} />
    </x.div>
  );
};

const SummaryContents = ({ parts }) => {
  const renderMap = {
    overview: SummaryOverview,
    section: SummarySection,
    question: SummaryQuestion,
    theme: SummaryTheme,
    quote: SummaryVerbatim,
    copyablePoint: SummaryCopyablePoint,
    citation: SummaryCitation,
  };

  return parts.map((p, idx) => {
    const Renderer = renderMap[p.speaker] || SummaryPoint;
    return Renderer ? (
      <Renderer key={`part-${p.position}`} previousType={parts[idx - 1]?.speaker ?? ""} {...p} />
    ) : null;
  });
};

const SummaryOverview = ({ value, position }) => {
  const styles = useStyles();
  return (
    <x.div data-testid={`overview-${position}`}>
      <EmphasisTypography {...styles.summaryOverview}>{value}</EmphasisTypography>
    </x.div>
  );
};

const SummarySection = ({ value, position }) => {
  const styles = useStyles();
  return (
    <x.div data-testid={`section-${position}`}>
      <EmphasisTypography {...styles.summarySection} variant="h3">
        {value}
      </EmphasisTypography>
    </x.div>
  );
};

const SummaryQuestion = ({ value, position }) => {
  const styles = useStyles();
  return (
    <x.div data-testid={`question-${position}`}>
      <EmphasisTypography {...styles.summaryQuestion}>{value}</EmphasisTypography>
    </x.div>
  );
};

const SummaryTheme = ({ value, position, previousType }) => {
  const {
    spacing: { inner },
  } = useThemeTokens();
  return (
    <>
      {previousType && (
        <x.div lineHeight={inner.base08}>
          <br />
        </x.div>
      )}
      <x.div data-testid={`theme-${position}`} mb={inner.base03}>
        <EmphasisTypography variant="body-em">{value}</EmphasisTypography>
      </x.div>
    </>
  );
};

const SummaryVerbatim = ({ value, position, previousType }) => {
  const styles = useStyles({ previousSentenceType: previousType });
  return (
    <x.div data-testid={`verbatim-${position}`} {...styles.summaryVerbatimWrapper}>
      <x.div lineHeight="0px">
        <br />
      </x.div>
      <EmphasisTypography component="i" {...styles.summaryVerbatim}>
        "{value}"
      </EmphasisTypography>
    </x.div>
  );
};

const SummaryPoint = ({ value, speaker, position }) => {
  const styles = useStyles();
  const depth = parseInt(speaker.replace("point-lvl-", ""));
  const depthIcons = ["•", "⚬", "●", "○", "⬩"];
  const depthIcon = depthIcons[depth] || "⬩";

  return (
    <x.div>
      <FakeListItem
        paddingLeft={`${(depth + 1) * 15}px`}
        content={`"${depthIcon}"`}
        data-testid={`point-${depth}-${position}`}
      >
        &nbsp;
        <EmphasisTypography component="span" {...styles.summaryPoint}>
          {value}
        </EmphasisTypography>
      </FakeListItem>
    </x.div>
  );
};

const SummaryCopyablePoint = ({ value, keyword, position, part, interaction, onSelectInsight }) => {
  const styles = useStyles();

  const onHoverClick =
    part?.sources?.length > 0 && onSelectInsight && interaction
      ? () => onSelectInsight({ insight: part, interaction })
      : null;

  return (
    <x.ul {...styles.summaryCopyablePoint} data-testid={`copyable-point-${position}`}>
      <EmphasisTypography component="li">
        <SentenceHighlightHover onClick={onHoverClick}>
          {keyword ? (
            <>
              <Typography component="span" variant={"body-small-em"}>
                {keyword}:{" "}
              </Typography>
              {value}
            </>
          ) : (
            <>{value}</>
          )}
        </SentenceHighlightHover>
      </EmphasisTypography>
    </x.ul>
  );
};

const FakeListItem = styled.div`
  padding-left: ${(props) => props.paddingLeft};
  &:before {
    content: ${(props) => props.content};
  }
`;

const SummaryCitation = ({ value, position, expertName, part, interaction, onSelectInsight }) => {
  const styles = useStyles();

  const onHoverClick =
    part?.sources?.length > 0 && onSelectInsight && interaction
      ? () => onSelectInsight({ insight: part, interaction })
      : null;

  return (
    <x.div data-testid={`citation-${position}`} {...styles.summaryCitationWrapper}>
      <SentenceHighlightHover onClick={onHoverClick}>
        <EmphasisTypography component="i">"{value}"</EmphasisTypography>
        {expertName && (
          <EmphasisTypography>
            <i>- {expertName}</i>
          </EmphasisTypography>
        )}
      </SentenceHighlightHover>
    </x.div>
  );
};

const SentenceHighlightHover = styled(x.div)`
  ${(props) => {
    return props.onClick
      ? css`
          cursor: pointer;
          &:hover {
            background-color: ${th.color("background-highlightBase")};
          }
        `
      : null;
  }}
`;
