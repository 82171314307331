import React from "react";
import { Icon, PillVariants } from "@alphasights/alphadesign-components";
import {
  StatusDefault,
  StatusFull,
  StatusHalf,
  StatusOneQuarter,
  StatusOneSixth,
  StatusPending,
  StatusThreeQuarters,
} from "@alphasights/alphadesign-icons";

export interface SurveyStateKeys {
  [key: string]: SurveyStateConfig;
}

export interface SurveyStateConfig {
  description: string;
  icon: JSX.Element;
  variant: PillVariants;
}

export const usePillStateConfig = () => {
  return {
    answered: {
      description: "Answered",
      icon: (
        <Icon size="small" color="processing">
          <StatusThreeQuarters />
        </Icon>
      ),
      variant: "violet",
    },
    available: {
      description: "Available",
      icon: (
        <Icon size="small" color="primary">
          <StatusPending />
        </Icon>
      ),
      variant: "light",
    },
    completed: {
      description: "Completed",
      icon: (
        <Icon size="small" color="success">
          <StatusFull />
        </Icon>
      ),
      variant: "green",
    },
    partially_completed: {
      description: "Partially Completed",
      icon: (
        <Icon size="small" color="processing">
          <StatusHalf />
        </Icon>
      ),
      variant: "violet",
    },
    qc_removal: {
      description: "QC Removal",
      icon: (
        <Icon size="small" color="decorativeBase03">
          <StatusDefault />
        </Icon>
      ),
      variant: "orange",
    },
    quota_met: {
      description: "Quota Met",
      icon: (
        <Icon size="small" color="decorativeBase03">
          <StatusDefault />
        </Icon>
      ),
      variant: "orange",
    },
    requested: {
      description: "Requested",
      icon: (
        <Icon size="small" color="warning">
          <StatusOneSixth />
        </Icon>
      ),
      variant: "yellow",
    },
    screened_out: {
      description: "Screened Out",
      icon: (
        <Icon size="small" color="decorativeBase03">
          <StatusDefault />
        </Icon>
      ),
      variant: "orange",
    },
    sent: {
      description: "Sent",
      icon: (
        <Icon size="small" color="processing">
          <StatusOneQuarter />
        </Icon>
      ),
      variant: "violet",
    },
  } as SurveyStateKeys;
};

export interface SurveyStateSortOrderKeys {
  [key: string]: SurveyStateSortOrder;
}

export interface SurveyStateSortOrder {
  sortOrder: number;
}

export const surveyStateSortOrderKeys = [
  "pending",
  "available",
  "requested",
  "sent",
  "partially_completed",
  "answered",
  "qc_removal",
  "quota_met",
  "screened_out",
  "completed",
].reduce(
  (acc, val, idx) => ({
    [val]: { sortOrder: idx },
    ...acc,
  }),
  {}
) as SurveyStateSortOrderKeys;
