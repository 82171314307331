import React, { useEffect, useState } from "react";
import { fetch } from "../../hooks/useApi";
import { BackgroundPage, ModalForm } from "./BackgroundPage";
import { Spinner } from "../../components/Spinner";
import { FormBody, FormContainer, FormFooter, FormHeader } from "./Form";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { useLocation, useParams, Navigate } from "router-utils";
import { EmailHeader, Help, SignInFooter } from ".";
import { useLoginStyles } from "./index.styles";
import { Button, Link, Typography } from "@alphasights/alphadesign-components";
import { useNotifications, useEnv } from "@alphasights/client-portal-shared";

export const ReactivateAccount = () => {
  const { token } = useParams();
  const { state } = useLocation();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState(state?.email);
  const [currentStep, setCurrentStep] = useState(state?.step ?? "loading");
  const [previousStep, setPreviousStep] = useState();
  const [isLoading, setLoading] = useState(false);
  const actionType = window.location.href.includes("unlock") ? "PASSWORD_RESET" : "REACTIVATE_ACCOUNT";
  const { showSuccessBanner } = useNotifications();

  const setStep = (newstep) => {
    setPreviousStep(currentStep);
    setCurrentStep(newstep);
  };

  const addCustomMessages = (error, actionType) => {
    const { message, request } = error;
    const linkType = actionType === "REACTIVATE_ACCOUNT" ? "verification" : "password reset";
    const messages = {
      USED: {
        title: "The Link Has Already Been Used",
        description: `This ${linkType} link has already been used. Please try signing in.`,
      },
      CANCELED: {
        title: "The link is no longer valid",
        description: `The ${linkType} link you are trying to use is no longer valid.`,
      },
      EXPIRED: {
        title: "The link has expired",
        description: `The ${linkType} link you are trying to use has expired.`,
      },
    };
    return request
      ? { ...error, ...messages[request.state], state: request.state }
      : { ...error, title: "Invalid verification link", description: message };
  };

  useEffect(() => {
    if (currentStep !== "loading") return;

    fetch({
      url: `/api/user-action-requests/${token}/execute`,
      method: "POST",
      body: JSON.stringify({ type: actionType }),
      skipAlert: true,
    })
      .then(() => {
        setError(null);
        window.location.href = `/my-projects`;
      })
      .catch((err) => {
        err
          .json()
          .then((error) => addCustomMessages(error, actionType))
          .then((error) => {
            setError(error);
            if (error.request) {
              setEmail(error.request.email);
              setStep(error.state === "USED" ? "used-link" : "invalid-state");
            } else {
              setStep("sign-in");
            }
          });
      });
  }, [currentStep]); // eslint-disable-line react-hooks/exhaustive-deps

  const requestVerificationLink = async ({ type }) => {
    setLoading(true);
    try {
      await fetch({
        url: `/api/user-action-requests`,
        method: "POST",
        body: JSON.stringify({ email, type }),
      });
      setLoading(false);
      showSuccessBanner(`${type === "REACTIVATE_ACCOUNT" ? "Verification" : "Unlock"} email resent.`, {
        position: "bottom",
        useAlphaUI: false,
      });
    } catch (err) {
      const { message } = await err.json();
      setError(message);
      setLoading(false);
    }
  };

  const redirectToSignIn = () => {
    setStep("sign-in");
  };

  const redirectToHelp = () => {
    setStep("help");
  };

  const onClickReturn = () => setStep(previousStep);

  return (
    <SwitchTransition>
      <CSSTransition
        key={currentStep}
        addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
        classNames="LoginTransition"
      >
        <>
          {currentStep === "loading" && (
            <BackgroundPage>
              <div className="aui-flex aui-flex-col aui-justify-around aui-items-center aui-h-8/10">
                <Spinner />
              </div>
            </BackgroundPage>
          )}
          {currentStep === "invalid-state" && (
            <>
              {
                <ReactivateAccountInvalidState
                  title={error.title}
                  description={error.description}
                  onRequestVerificationLink={() => requestVerificationLink({ type: "REACTIVATE_ACCOUNT" })}
                  onClickReturnSignIn={redirectToSignIn}
                  onClickHelp={redirectToHelp}
                  isLoading={isLoading}
                />
              }
            </>
          )}
          {currentStep === "deactivated_inactivity" && (
            <>
              {
                <AccountDeactivatedInactivity
                  email={email}
                  onClickReturnSignIn={redirectToSignIn}
                  isLoading={isLoading}
                  onRequestVerificationLink={() => requestVerificationLink({ type: "REACTIVATE_ACCOUNT" })}
                  onClickHelp={redirectToHelp}
                />
              }
            </>
          )}
          {currentStep === "locked" && (
            <>
              {
                <AccountLocked
                  email={email}
                  isLoading={isLoading}
                  onRequestVerificationLink={() => requestVerificationLink({ type: "PASSWORD_RESET" })}
                  onClickHelp={redirectToHelp}
                />
              }
            </>
          )}
          {currentStep === "sign-in" && <Navigate to="/sign-in" />}
          {currentStep === "used-link" && <Navigate to="/sign-in?link" />}
          {currentStep === "help" && (
            <ModalForm>
              <Help onClickReturn={onClickReturn} />
            </ModalForm>
          )}
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};

const ReactivateAccountInvalidState = ({
  title,
  isLoading,
  description,
  onClickReturnSignIn,
  onClickHelp,
  onRequestVerificationLink,
}) => {
  const { modalTitle, backButton, reactivateAccountText } = useLoginStyles();

  return (
    <ModalForm>
      <FormContainer isLoading={isLoading}>
        <FormHeader
          title={
            <Typography variant="h3" {...modalTitle}>
              {title}
            </Typography>
          }
          description={
            <Typography component="div" {...reactivateAccountText}>
              {description}
              &nbsp;To resend a new link&nbsp;
              <Link component="button" onClick={onRequestVerificationLink}>
                <Typography variant="body-em">click here.</Typography>
              </Link>
            </Typography>
          }
        />
        <FormBody>
          <Button variant="outline" onClick={onClickReturnSignIn} {...backButton}>
            <Typography variant="body-em">Return to Sign In</Typography>
          </Button>
        </FormBody>
        <FormFooter>
          <SignInFooter onClickHelp={onClickHelp} showSignUp={false} />
        </FormFooter>
      </FormContainer>
    </ModalForm>
  );
};

export const AccountDeactivatedInactivity = ({
  email,
  isLoading,
  onRequestVerificationLink,
  onClickReturnSignIn,
  onClickHelp,
}) => {
  const { modalTitle, backButton, reactivateAccountText } = useLoginStyles();

  return (
    <ModalForm>
      <FormContainer isLoading={isLoading}>
        <FormHeader
          title={
            <Typography variant="h3" {...modalTitle}>
              Verify your identity
            </Typography>
          }
          description={
            <>
              <EmailHeader email={email} onClickEmail={onClickReturnSignIn} />
              <Typography component="div" {...reactivateAccountText}>
                We would like to confirm this account is yours. Check your inbox and click on the link provided in the
                email to verify.&nbsp;
                <Link component="button" onClick={onRequestVerificationLink}>
                  <Typography variant="body-em">Didn’t receive the email? Resend.</Typography>
                </Link>
              </Typography>
            </>
          }
        />
        <FormBody>
          <Button variant="outline" onClick={onClickReturnSignIn} {...backButton}>
            <Typography variant="body-em">Return to Sign In</Typography>
          </Button>
        </FormBody>
        <FormFooter>
          <SignInFooter onClickHelp={onClickHelp} showSignUp={false} />
        </FormFooter>
      </FormContainer>
    </ModalForm>
  );
};

export const AccountLocked = ({ email, isLoading, onRequestVerificationLink, onClickHelp }) => {
  const { modalTitle, reactivateAccountText, emailLinkText } = useLoginStyles();
  const env = useEnv();

  return (
    <ModalForm step="locked">
      <FormContainer isLoading={isLoading}>
        <FormHeader
          title={
            <Typography variant="h3" {...modalTitle}>
              Your account has been locked
            </Typography>
          }
          description={
            <>
              <Typography component="div" {...reactivateAccountText}>
                Following too many failed sign in attempts, the account associated with {email} has been locked. To
                access your account, please check your email and click on the link within{" "}
                {env.userActionRequestsValidityInHours} hours to reset your password.&nbsp;
                <Link component="button" onClick={onRequestVerificationLink}>
                  <Typography variant="body-em">Didn't receive the email? Resend.</Typography>
                </Link>
              </Typography>
              <Typography component="div" {...reactivateAccountText}>
                If you're not responsible for the failed sign in attempts, please contact&nbsp;
                <Typography {...emailLinkText} href={`mailto:clientsupport@alphasights.com`} component="a">
                  clientsupport@alphasights.com
                </Typography>
                .
              </Typography>
            </>
          }
        />
        <FormFooter>
          <SignInFooter onClickHelp={onClickHelp} showSignUp={false} />
        </FormFooter>
      </FormContainer>
    </ModalForm>
  );
};
