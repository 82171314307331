import React, { useCallback, useMemo, useRef } from "react";
import { SynthesisQuote, KpcItem } from "@alphasights/portal-api-client";
import { Icon, IconButton, Popover, Tooltip, Typography } from "@alphasights/alphadesign-components";
import { BarChart } from "@alphasights/alphadesign-charts";
import { x } from "@xstyled/styled-components";
import { ArrowRight, Dot, Expert } from "@alphasights/alphadesign-icons";
import tokens from "@alphasights/alphadesign-tokens";
import { useNavigate } from "router-utils";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useKpcChartStyles } from "./KpcChart.styles";

export const KpcChart = ({ kpcItems }: { kpcItems: KpcItem[] }) => {
  const chartData = getChartData(kpcItems);
  const [popoverSettings, setPopoverSettings] = React.useState<PopoverSettings>({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const timeoutId = useRef<NodeJS.Timeout | undefined>(undefined);
  const ghostElementRef = React.useRef(null);
  const canClosePopover = useRef(true);
  const popoverData = useMemo(
    () =>
      popoverSettings.seriesIndex !== undefined &&
      popoverSettings.dataPointIndex !== undefined &&
      chartData[popoverSettings.seriesIndex ?? 0].data[popoverSettings.dataPointIndex ?? 0],
    [chartData, popoverSettings.dataPointIndex, popoverSettings.seriesIndex]
  );

  const handleClosePopover = useCallback(() => {
    if (!canClosePopover.current) return;
    setPopoverSettings({ top: 0, left: 0, width: 0, height: 0, seriesIndex: undefined, dataPointIndex: undefined });
  }, []);

  const handleDataPointMouseEnter = (
    event: React.MouseEvent<HTMLElement>,
    _chartContext: unknown,
    opts: { seriesIndex: number; dataPointIndex: number }
  ) => {
    const dataBoudingRect = (event.target as HTMLElement).getBoundingClientRect();
    if (popoverSettings.seriesIndex === opts.seriesIndex && popoverSettings.dataPointIndex === opts.dataPointIndex)
      return;
    clearTimeout(timeoutId.current);
    setTimeout(() => {
      setPopoverSettings({
        top: dataBoudingRect.top,
        left: dataBoudingRect.left,
        width: dataBoudingRect.width,
        height: dataBoudingRect.height,
        seriesIndex: opts.seriesIndex,
        dataPointIndex: opts.dataPointIndex,
      });
    });
  };

  const handleDataPointMouseLeave = () => {
    timeoutId.current = setTimeout(() => handleClosePopover(), 200);
  };

  const chartOptions = getChartOptions({
    kpcItems,
    dataLength: kpcItems.length,
    handleDataPointMouseEnter,
    handleDataPointMouseLeave,
  });

  return (
    <>
      <BarChart options={chartOptions} series={chartData} height={370} />
      <x.div
        ref={ghostElementRef}
        position="fixed"
        background="transparent"
        top={popoverSettings.top}
        left={popoverSettings.left}
        w={popoverSettings.width}
        h={popoverSettings.height}
        pointerEvents="none"
      >
        <Popover
          animationFrame={true}
          anchorEl={ghostElementRef?.current ?? undefined}
          open={!!popoverData}
          placement="right"
          onMouseEnter={() => (canClosePopover.current = false)}
          closeOnMouseLeave
          onClose={() => {
            canClosePopover.current = true;
            handleClosePopover();
          }}
        >
          {popoverData && <BarChartPopoverContent dataPoint={popoverData} />}
        </Popover>
      </x.div>
    </>
  );
};

const BarChartPopoverContent = ({ dataPoint }: { dataPoint: BarChartDataPoint }) => {
  const { popoverItemWrapper, iconGroup, popoverContentWrapper } = useKpcChartStyles();
  const { project } = useCurrentProjectContext();
  const navigate = useNavigate();

  const navigateToTranscript = (quote: SynthesisQuote) => {
    navigate(
      `/${project?.token}/experts/deliverables-view/?part=${quote.transcriptIndex}&selectedInteraction=${quote.interactionId}`
    );
  };

  return (
    <x.ul {...popoverContentWrapper} data-testid="kpc-chart-popover">
      <x.li {...popoverItemWrapper}>
        <x.div {...iconGroup}>
          <Icon color={sentimentColors[dataPoint.sentiment]} size="medium">
            <Dot />
          </Icon>
          <Typography variant="body-small-em">{sentimentLabels[dataPoint.sentiment]}</Typography>
        </x.div>
        <Typography variant="body-small" color="secondary">
          {dataPoint.y}
        </Typography>
      </x.li>
      {dataPoint.quotes
        .filter((quote) => quote.transcriptIndex && quote.interactionId)
        .map((quote) => (
          <x.li key={quote.id} {...popoverItemWrapper}>
            <x.div {...iconGroup}>
              <Icon color="secondary" size="medium">
                <Expert />
              </Icon>
              <Typography variant="body-small">
                {quote.companyName} - {quote.role}
              </Typography>
            </x.div>
            <Tooltip title="View in transcript" position="bottom" zIndex={1700}>
              <IconButton
                color="secondary"
                size="small"
                variant="basic"
                onClick={() => navigateToTranscript(quote)}
                testId={`view-in-transcript`}
              >
                <ArrowRight />
              </IconButton>
            </Tooltip>
          </x.li>
        ))}
    </x.ul>
  );
};

const getChartData = (kpcItems: KpcItem[]): { name: string; data: BarChartDataPoint[] }[] => {
  const validSentiments = ["VERY_IMPORTANT", "IMPORTANT", "SOMEWHAT_IMPORTANT"] as const;
  return validSentiments.map((sentiment) => ({
    name: sentimentLabels[sentiment],
    color: sentimentColors[sentiment],
    data: kpcItems.map((item) => {
      const sentimentData = item.sentiments.find((s) => s.sentiment === sentiment);
      return {
        y: sentimentData?.count ?? 0,
        x: item.name,
        sentiment: sentiment,
        quotes: sentimentData?.quotes ?? [],
      };
    }),
  }));
};

const getChartOptions = ({
  kpcItems,
  dataLength,
  handleDataPointMouseEnter,
  handleDataPointMouseLeave,
}: {
  kpcItems: KpcItem[];
  dataLength: number;
  handleDataPointMouseEnter: (
    event: React.MouseEvent<HTMLElement>,
    _chartContext: unknown,
    opts: { seriesIndex: number; dataPointIndex: number }
  ) => void;
  handleDataPointMouseLeave: () => void;
}) => {
  // Since we can't control the column width direcly in px, we calculate the optimal column width in percent.
  // This is a simple formula that makes the chart look good with different number of categories.
  const optimalColumnWidthPercent = dataLength <= 3 ? 10 * dataLength : 100 / Math.pow(dataLength, 0.7);

  return {
    chart: {
      id: "kpc-stacked-bar-chart",
      events: {
        dataPointMouseEnter: handleDataPointMouseEnter,
        dataPointMouseLeave: handleDataPointMouseLeave,
      },
      type: "bar",
      height: 370,
      stacked: true,
    },
    plotOptions: {
      bar: {
        columnWidth: optimalColumnWidthPercent + "%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: kpcItems.map((item) => item.name),
    },
    yaxis: {
      forceNiceScale: true,
      title: { text: "" },
    },
    legend: {
      markers: {
        shape: "circle",
      },
    },
    tooltip: {
      enabled: false,
    },
    states: {
      hover: {
        filter: {
          type: "lighten",
        },
      },
    },
  };
};

const sentimentLabels = {
  VERY_IMPORTANT: "Very Important",
  IMPORTANT: "Important",
  SOMEWHAT_IMPORTANT: "Somewhat Important",
};

const sentimentColors = {
  VERY_IMPORTANT: tokens.color.chart.sequential.base,
  IMPORTANT: tokens.color.chart.sequential.base03,
  SOMEWHAT_IMPORTANT: tokens.color.chart.sequential.base05,
};

interface BarChartDataPoint {
  x: string;
  y: number;
  sentiment: "VERY_IMPORTANT" | "IMPORTANT" | "SOMEWHAT_IMPORTANT";
  quotes: SynthesisQuote[];
}

interface PopoverSettings {
  top: number;
  left: number;
  width: number;
  height: number;
  seriesIndex?: number;
  dataPointIndex?: number;
}
