import React, { useEffect } from "react";
import { x } from "@xstyled/styled-components";
import { MessengerSidebar } from "./components/MessengerSidebar/MessengerSidebar";
import { MessengerContent } from "./components/MessengerContent/MessengerContent";
import { MessengerProvider, useMessengerContext } from "providers/MessengerProvider";
import { Interaction } from "types";
import { MessengerSettings } from "./components/MessengerSettings/MessengerSettings";
import { FlyoutMode } from "pages/InteractionPage/enums";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";

export const MessengerPage = ({
  project,
  interactions,
  onSendNewMessageFromTableView,
  isFlyoutOpen,
  onCloseFlyout,
  loading: interactionsLoading,
}: {
  project: Project;
  interactions: Interaction[];
  onSendNewMessageFromTableView: Function;
  isFlyoutOpen: boolean;
  onCloseFlyout: () => void;
  loading: boolean;
}) => {
  return (
    <MessengerProvider
      projectToken={project.token}
      interactions={interactions}
      interactionsLoading={interactionsLoading}
      onSendNewMessageFromTableView={onSendNewMessageFromTableView}
      isAdvisorFlyoutOpened={isFlyoutOpen}
      onCloseFlyout={onCloseFlyout}
    >
      <MessengerPageBody interactions={interactions}></MessengerPageBody>
    </MessengerProvider>
  );
};

const MessengerPageBody = ({ interactions }: { interactions: Interaction[] }) => {
  const { selectedExpertId } = useMessengerContext();
  const { onSelectCard } = useProjectInteractionsContext();

  useEffect(() => {
    if (selectedExpertId) {
      const interaction = interactions.find((interaction) => interaction.advisorId === selectedExpertId);
      onSelectCard(interaction, "messages-view", FlyoutMode.Interaction);
    }
  }, [selectedExpertId, interactions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <x.div display="flex" flexGrow={1} flexDirection="row" minH="0" data-testid="messenger-page">
      <MessengerSidebar />
      <MessengerContent />
      <MessengerSettings />
    </x.div>
  );
};
