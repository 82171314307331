import React, { useCallback, useContext, useEffect, useState } from "react";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useThemeTokens, Icon, Typography } from "@alphasights/alphadesign-components";
import { Search } from "@alphasights/alphadesign-icons";
import { AdvisorsTableViewSkeleton } from "views/AdvisorsTableView";
import { AlphaTable, useAlphaTable } from "@alphasights/alphadesign-table";
import { ENABLE_PORTAL_COMMENTS, ENABLE_PORTAL_MESSAGES, useProjectBadgeContext } from "providers/BadgeProvider";
import { usePreference } from "hooks/usePreference";
import styled from "@xstyled/styled-components";
import { useCurrentUser, useMyProjects } from "@alphasights/portal-auth-react";
import { useCustomerExpertColumnDefinitions } from "./ColumnsDefinitions";
import {
  buildColumnsOptions,
  columnIds,
  columnPreferenceExists,
  hasOrderChanged,
  hasPinningChange,
  hasVisibilityChanged,
} from "../../helpers/portalTablePreferencesHelper";
import { useCustomerKnowledgeContext } from "providers/CustomerKnowledgeProvider";
import { x } from "@xstyled/styled-components";
import { CustomerKnowledgeFilters } from "./CustomerKnowledgeFilters/CustomerKnowledgeFilters";
import useCustomerExpertViewStyles from "./CustomerExpertView.styles";
import { GroupedBarChart } from "./GroupedBarChart/GroupedBarChart";
import { ActionBar } from "views/AdvisorsTableView";
import { DispatchContext } from "components/InteractionsPage/DispatchContext";
import { NewMessageOrigin } from "pages/InteractionPage/enums";
import _, { isEqual } from "lodash";
import { selectExperts } from "components/InteractionsPage/reducer";
import { AdvisorsHeaderNewUx } from "components/AdvisorsHeaderNewUx";
import { HitAction } from "@alphasights/portal-api-client";
import { EmptySearchResults } from "components/InteractionsPage/EmptySearchResults";
import { useLocation, useNavigate } from "router-utils";
import { CollapsibleSearch } from "components/CollapsibleSearch/CollapsibleSearch.component";
import { PageFooter } from "@alphasights/client-portal-shared";
import { HeaderPortal } from "components/InteractionsPage/HeaderPortal";
import { PreferenceType } from "providers/types";

export const CustomerExpertView = (props) => {
  const {
    state: { isBackgroundSyncRunning },
  } = useProjectInteractionsContext();

  const customerViewPreferences = usePreference(PreferenceType.TABLE_VIEW_COLUMNS, {
    token: props.project.token,
  });
  const { isLoading: isColumnPreferenceLoading } = customerViewPreferences;

  if (isBackgroundSyncRunning || isColumnPreferenceLoading) {
    return <AdvisorsTableViewSkeleton />;
  }

  return <CustomerExpertTableWrapper {...props} customerViewPreferences={customerViewPreferences} />;
};

const CustomerExpertTableWrapper = (props) => {
  const { onSelectCard, project } = props;
  const styles = useCustomerExpertViewStyles();

  // TODO should we move this to CustomerExpertTable
  const onRowClicked = useCallback(
    (row) => {
      const { original: interaction } = row;

      if (!interaction.hidden) {
        onSelectCard({
          ...interaction,
          showChainList: false,
          scrollToCardId: interaction.id,
        });
      }
    },
    [onSelectCard]
  );

  return (
    <div
      className="aui-flex aui-flex-col aui-flex-grow aui-min-h-0"
      style={{
        marginRight: "0px",
      }}
    >
      <div
        className="AdvisorsTableView aui-relative aui-flex-grow aui-flex aui-flex-col aui-min-h-0"
        style={{ display: false ? "none" : "" }}
      >
        <CustomerExpertHeader {...props} />
        <x.div {...styles.contentWrapper}>
          <x.div {...styles.barChartWrapper}>
            <GroupedBarChart
              isClosed={props.isFlyoutOpen}
              onClose={props.onCloseFlyout}
              targetCompanies={project.investmentTargetCompanies}
            />
          </x.div>
          <x.div {...styles.tableWrapper}>
            <CustomerExpertTable {...props} onRowClicked={onRowClicked} />
            <PageFooter />
          </x.div>
        </x.div>
      </div>
    </div>
  );
};

const CustomerExpertTable = (props) => {
  const {
    onRowClicked,
    selectedCardId,
    isProjectCidEnabled,
    customerViewPreferences,
    onDownloadCompaniesCid,
    isPCCEnabledClient,
    hasClientPortalMessages,
    newMessageOrigin,
    selectedExpertsIds,
    project,
  } = props;
  const currentUser = useCurrentUser();
  const allProjects = useMyProjects();

  const { hasProjectBadge } = useProjectBadgeContext();
  const { color } = useThemeTokens();
  const { preference: columnPreference, updatePreference } = customerViewPreferences;
  const { interactions, filteredInteractions, logCKHit, clearFilters, appliedFilters } = useCustomerKnowledgeContext();

  const [hideHeader, setHideHeader] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);

  const rowClassRules = [
    {
      className: "row-selected",
      ruleFunction: (row) => row.original.id === selectedCardId,
    },
    {
      className: "row-hidden",
      ruleFunction: (row) => row.original.hidden,
    },
  ];

  const portalMessagesEnabled = hasProjectBadge(ENABLE_PORTAL_MESSAGES);

  const saveColumnPreferences = () => {
    const attributes = buildColumnPreferences(columnIds(columns));
    updatePreference(attributes);
  };

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const access = queryParams.get("access") ?? "navigation";

    if (queryParams.get("access")) {
      queryParams.delete("access");
      navigate({ search: queryParams.toString() }, { replace: true });
    }

    logCKHit({
      action: HitAction.viewCustomerKnowledgePage,
      details: { access },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!columnPreferenceExists(columnPreference)) {
      saveColumnPreferences();
    }
  }, [columnPreference?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const dispatch = useContext(DispatchContext);

  const onRowSelectionChange = (selection) => {
    const selectedRows = filteredInteractions.filter((item, index) => index in selection && selection[index]);
    setSelectedRows(selectedRows);
    setHideHeader((portalMessagesEnabled || isProjectCidEnabled) && Object.keys(selectedRows).length > 0);
    const expertsIds = selectedRows.map((interaction) => interaction.advisorId);
    if (newMessageOrigin === NewMessageOrigin.TableView && !isEqual(expertsIds, selectedExpertsIds) && !firstLoad) {
      dispatch(selectExperts(expertsIds));
    }
  };

  const onSelectAll = () => {
    table.toggleAllRowsSelected(true);
  };

  const onUnselectAll = () => {
    table.resetRowSelection(true);
  };

  useEffect(() => setFirstLoad(false), []);

  const downloadCidCompaniesCsv = () => {
    onDownloadCompaniesCid(selectedRows);
    table.resetRowSelection(true);
  };

  const columns = useCustomerExpertColumnDefinitions(
    { currentUser, allProjects, interactions: filteredInteractions },
    hasProjectBadge(ENABLE_PORTAL_COMMENTS),
    props
  );

  const options = buildColumnsOptions({
    columnPreference,
    columns,
    hideSelectColumn: !currentUser || (!isProjectCidEnabled && !portalMessagesEnabled),
    onRowClicked,
    rowClassRules,
    hideHeader,
    enableRowSelection: ({ original: interaction }) => !interaction.hidden,
    onRowSelectionChange,
    onColumnOrderChange: (updatedColumnOrders) => {
      if (hasOrderChanged(updatedColumnOrders, columnPreference)) {
        saveColumnPreferences();
      }
    },
    onColumnVisibilityChange: (updatedColumnVisibilities) => {
      if (hasVisibilityChanged(updatedColumnVisibilities, columnPreference, columns)) {
        saveColumnPreferences();
      }
    },
    onColumnPinningChange: (updatedColumnPinning) => {
      if (hasPinningChange(updatedColumnPinning, columnPreference)) {
        saveColumnPreferences();
      }
    },
  });

  const table = useAlphaTable(filteredInteractions, columns, options);

  useEffect(
    function updateColumnOrderOnNewColumnsAdded() {
      table.setColumnOrder(options.state.columnOrder);
    },
    [columns.length] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const buildColumnPreferences = useCallback(
    (columns) => {
      const columnIds = table.getState().columnOrder.length > 0 ? table.getState().columnOrder : columns;
      return columnIds.reduce((obj, column, index) => {
        const isColumnVisible = _.get(table.getState(), `columnVisibility.${column}`, true);
        const isColumnHiddenInPreferences = _.get(columnPreference, `attributes.${column}.hide`, false);
        return {
          ...obj,
          [column]: {
            position: index,
            hide: columns.includes(column) ? !isColumnVisible : isColumnHiddenInPreferences,
            pinned: table.getState().columnPinning.left.includes(column),
          },
        };
      }, {});
    },
    [table, columnPreference]
  );

  return (
    <x.div minH={0} overflow="auto" display="flex" flexDirection="column" flexGrow="1">
      <AlphaTableWrapper
        background={color.background.surface.page.default}
        selectedRowColor={color.background.selected.subtle}
        hiddenRowColor={color.background.neutral.subtle}
      >
        {hideHeader && (
          <ActionBar
            interactions={filteredInteractions}
            selectedInteractions={selectedRows}
            onDownloadCid={downloadCidCompaniesCsv}
            pcc={isPCCEnabledClient}
            isActiveProject={project.active}
            hasClientPortalMessages={hasClientPortalMessages}
            onSelectAll={onSelectAll}
            onUnselectAll={onUnselectAll}
            isProjectCidEnabled={isProjectCidEnabled}
          />
        )}
        <AlphaTable table={table} />
      </AlphaTableWrapper>
      {filteredInteractions.length === 0 && (
        <x.div
          display="flex"
          flexGrow="1"
          justifyContent="center"
          alignItems="center"
          data-testid="customer-view-empty-results"
        >
          <EmptySearchResults
            hasAppliedFilter={Object.values(appliedFilters).length > 0}
            onResetAll={clearFilters}
            totalResults={interactions.length}
            projectLeadName={project.lead.name}
          />
        </x.div>
      )}
    </x.div>
  );
};

const AlphaTableWrapper = styled.div`
  background: ${(props) => props.background};
  display: grid;
  .row-selected {
    background-color: ${(props) => props.selectedRowColor};
  }
  .row-hidden {
    background-color: ${(props) => props.hiddenRowColor};
  }
`;

const CustomerExpertHeader = (props) => {
  const { filteredInteractions } = useCustomerKnowledgeContext();
  const { filterOptions } = props;
  const {
    spacing: { inner },
  } = useThemeTokens();

  const counter = (
    <Typography color="secondary" whiteSpace="nowrap" ml={inner.base02}>
      {filteredInteractions.length} {filteredInteractions.length === 1 ? "result" : "results"}
    </Typography>
  );

  const searchComponent = <SearchInput />;
  const filterComponent = () => <CustomerKnowledgeFilters interactionsFilterOptions={filterOptions} />;

  return (
    <HeaderPortal>
      <AdvisorsHeaderNewUx
        {...props}
        searchComponent={searchComponent}
        filterComponent={filterComponent}
        counter={counter}
      />
    </HeaderPortal>
  );
};

const SearchInput = () => {
  const { applyFilters, appliedFilters } = useCustomerKnowledgeContext();

  const props = {
    onSubmit: (event) => {
      event.preventDefault();
      applyFilters({
        keywords: event.target.query.value
          .trim()
          .split(" ")
          .filter((v) => v.trim().length > 0),
      });
    },
    onClear: (event) => {
      event.stopPropagation();
      applyFilters({ keywords: [] });
    },
    placeholder: "Search in experts",
  };

  return (
    <CollapsibleSearch
      data-testid="search-input"
      name="query"
      value={appliedFilters.keywords?.join(" ")}
      startAdornment={
        <Icon size="small">
          <Search />
        </Icon>
      }
      {...props}
    />
  );
};
