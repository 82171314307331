import React, { useCallback, useMemo, useRef, useState } from "react";
import { x } from "@xstyled/styled-components";
import {
  ENABLE_AI_SUMMARIZATION,
  ENABLE_REGULAR_TRANSCRIPT_DOWNLOAD,
  ENABLE_AI_TRANSCRIPT_DOWNLOAD,
  useProjectBadgeContext,
} from "providers/BadgeProvider";
import {
  IconButton,
  Link,
  ListOption,
  Popover,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@alphasights/alphadesign-components";
import { BookmarkSelected, BookmarkUnselected, Download, InsertLink, MoreVert } from "@alphasights/alphadesign-icons";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { selectIndexHighlight, trySingularAngleName } from "views/DeliverablesView/helpers";
import { useCallbackContext } from "providers/CallbackProvider";
import { FLYOUT_SECTIONS } from "providers/FlyoutProvider";
import { useStyles } from "./TopBar.styles";
import { SummaryStatus, ViewType } from "views/DeliverablesView/AiSummaries/AiSummaries.types";
import { useDeliverableContext } from "providers/DeliverableProvider";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { identity } from "lodash";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { useInteractionDeliverablesContext } from "providers/InteractionDeliverableProvider";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { TranscriptSearchBar } from "views/DeliverablesView/TranscriptSearchBar";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { AudioPlayer } from "views/DeliverablesView/AudioPlayer";
import { formatDistanceToNow, parseISO } from "date-fns";
import { useHideDeliverablesContent } from "../useHideDeliverablesContent";
import { ExpandSidebarButton } from "views/DeliverablesView/ExpandSidebarButton/ExpandSidebarButton";
import { ViewInSystem } from "components/DebugSupport";

interface TopBarProps {
  interaction: Partial<Interaction>;
  selectedView: ViewType;
  setSelectedView: (value: ViewType) => void;
  rightAccessory: React.ReactNode;
  showTabs: boolean;
  audioPlayer: any;
  currentRecording?: Recording | null;
}

const TopBar = ({
  interaction,
  selectedView,
  setSelectedView,
  rightAccessory,
  showTabs,
  audioPlayer,
  currentRecording,
}: TopBarProps) => {
  const styles = useStyles();

  return (
    <x.div {...styles.pageStyle}>
      <x.div {...styles.headerStyle}>
        <TopBarHeader interaction={interaction} />
        <MoreOptionsButton interaction={interaction} />
      </x.div>
      {showTabs && (
        <x.div {...styles.navBarStyle}>
          <SearchElement />
          <NavBar selectedView={selectedView} setSelectedView={setSelectedView} />
          {rightAccessory}
        </x.div>
      )}
      {audioPlayer && currentRecording?.visibleToClient && !currentRecording?.purgedAt && (
        <AudioPlayer
          enableRefocus={false}
          controlsProps={audioPlayer.controls}
          progressBarProps={audioPlayer.progress}
          onClose={null}
          // @ts-ignore
          progressBarChildren={
            currentRecording?.expiresAt && (
              <Typography variant="body-small" color="assistive">
                Audio expires in {formatDistanceToNow(parseISO(currentRecording.expiresAt!!))}
              </Typography>
            )
          }
        />
      )}
    </x.div>
  );
};

const TopBarHeader = ({ interaction }: { interaction: Partial<Interaction> }) => {
  const {
    callbacks: { onSelectCard },
  } = useCallbackContext();

  return (
    <>
      <x.div>
        <x.div display="flex" alignItems="center" verticalAlign="middle">
          <ExpandSidebarButton mr="8px" />
          <Typography component="span" variant="body-large-em">
            {interaction.companyName}
          </Typography>
          <Typography component="span" px={2}>
            -
          </Typography>
          <Typography component="span" variant="body-large">
            {interaction.role}
          </Typography>
          <ViewInSystem interactionId={interaction?.id} />
        </x.div>
        <Typography component="span" variant="body" color="secondary">
          {trySingularAngleName(interaction)}
        </Typography>
        <Typography component="span" variant="body" color="secondary" px={2}>
          |
        </Typography>
        <Link
          pl={4}
          href="#"
          size="small"
          onClick={() =>
            onSelectCard({
              ...interaction,
              flyoutAction: FLYOUT_SECTIONS.expandExpert,
            })
          }
        >
          <Typography component="span" variant="body" color="secondary">
            {interaction.advisorName}
          </Typography>
        </Link>
        <Typography component="span" variant="body" color="secondary" px={2}>
          |
        </Typography>
        <Typography component="span" variant="body" color="secondary" pr={2}>
          <FormattedDateTime date={interaction.scheduledCallTime} format="d LLL yyyy" />
        </Typography>
      </x.div>
    </>
  );
};

const NavBar = ({
  selectedView,
  setSelectedView,
}: {
  selectedView: ViewType;
  setSelectedView: (value: ViewType) => void;
}) => {
  const { aiFeaturesEnabled, transcriptFeaturesEnabled, interaction } = useInteractionDeliverablesContext()!;
  const { logHit } = useTrackUserAction();
  const { project } = useCurrentProjectContext();

  const tabs = [
    { label: "Summary", value: ViewType.Summary, testId: "summary-view-button", enabled: aiFeaturesEnabled },
    { label: "Questions", value: ViewType.Questions, testId: "questions-view-button", enabled: aiFeaturesEnabled },
    { label: "Mentions", value: ViewType.Mentions, testId: "mentions-view-button", enabled: transcriptFeaturesEnabled },
    {
      label: "Transcript",
      value: ViewType.Transcript,
      testId: "transcript-view-button",
      enabled: transcriptFeaturesEnabled,
    },
  ].filter((tab) => tab.enabled);

  const onClickTab = useCallback(
    function selectViewAndLogHit(view: ViewType) {
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.interactionDeliverablesTabClicked,
        advisorshipId: interaction.id,
        projectToken: project?.token,
        details: { tabClicked: view },
      });
      setSelectedView(view);
    },
    [interaction.id, logHit, project, setSelectedView]
  );

  return (
    <x.div display="flex" w="100%">
      {/* @ts-ignore */}
      <ToggleButtonGroup
        value={selectedView}
        allowUnselection={false}
        wrapperProps={{
          flex: 1,
          minWidth: "fit-content",
        }}
      >
        <>
          {tabs.map((tab) => (
            <ToggleButton
              value={tab.value}
              onClick={() => onClickTab(tab.value)}
              dataAttributes={{ "data-testid": tab.testId }}
            >
              {tab.label}
            </ToggleButton>
          ))}
        </>
      </ToggleButtonGroup>
    </x.div>
  );
};

const MoreOptionsButton = ({ interaction }: { interaction: Partial<Interaction> }) => {
  const [moreOpen, setMoreOpen] = useState(false);
  const popoverAnchor = useRef<HTMLButtonElement>(null);
  return (
    <>
      <IconButton
        variant="ghost"
        onClick={() => setMoreOpen(!moreOpen)}
        ref={popoverAnchor}
        testId="deliverables-more-options-button"
      >
        <MoreVert />
      </IconButton>
      {popoverAnchor.current && (
        <Popover
          placement="bottom-end"
          anchorEl={popoverAnchor.current}
          open={moreOpen}
          data-testid="more-options-header-popover"
          onClose={() => setMoreOpen(false)}
        >
          <MoreOptionsButtonDropdown interaction={interaction} />
        </Popover>
      )}
    </>
  );
};

const MoreOptionsButtonDropdown = ({ interaction }: { interaction: Partial<Interaction> }) => {
  const { toggleInteractionBookmark } = useDeliverableContext();
  const [shareText, setShareText] = useState("Share");
  const { logHit } = useTrackUserAction();
  const {
    selectTranscript,
    transcripts,
    summaryComprehensive,
    transcriptFeaturesEnabled,
    currentTranscript,
  } = useInteractionDeliverablesContext()!;
  const { hasProjectBadge } = useProjectBadgeContext();
  const aiSummaryEnabled = hasProjectBadge(ENABLE_AI_SUMMARIZATION);

  const onShare = () => {
    navigator.clipboard.writeText(window.location.toString());
    setShareText("Link Copied!");

    logHit({
      origin: HitOrigin.deliverablesView,
      action: HitAction.transcriptShared,
      advisorshipId: interaction.id,
      projectToken: interaction.projectToken,
    });
  };

  const transcriptTypeLabel = {
    regular: "Human Transcript",
    ai: "AI Transcript",
    recording: "Recording",
  };

  const allowSummaryDownload = useMemo(() => {
    const hasSummaryCompleted = summaryComprehensive?.status === SummaryStatus.Generated;
    return (
      aiSummaryEnabled &&
      hasSummaryCompleted &&
      currentTranscript &&
      !currentTranscript.purgedAt &&
      currentTranscript.completed
    );
  }, [aiSummaryEnabled, summaryComprehensive, currentTranscript]);

  const downloadables = [
    transcripts
      .filter(
        ({ purgedAt, completed, transcriptType }) =>
          transcriptFeaturesEnabled &&
          completed &&
          !purgedAt &&
          ((transcriptType === "ai" && hasProjectBadge(ENABLE_AI_TRANSCRIPT_DOWNLOAD)) ||
            (transcriptType === "regular" && hasProjectBadge(ENABLE_REGULAR_TRANSCRIPT_DOWNLOAD)))
      )
      .map((tr, ix) => ({
        label: transcriptTypeLabel[tr.transcriptType],
        type: tr.transcriptType,
        id: tr.id,
        url: transcriptFileDownload(tr, interaction, ix),
        target: "_blank",
      })),
    allowSummaryDownload && {
      label: "Call Summary",
      type: "ai-summary",
      id: null,
      url: `/api/projects/${interaction.projectToken}/ai-summaries/${interaction.id}/0/export/pdf`,
      target: "_blank",
    },
  ]
    .filter(identity)
    .flat();

  return (
    <x.div>
      <ListOption
        type="text"
        label={shareText}
        leftIcon={<InsertLink />}
        onChange={() => onShare()}
        size="small"
        dataAttributes={{ "data-testid": "share-option-button" }}
      />
      <ListOption
        type="text"
        label="Bookmark"
        leftIcon={interaction.bookmarked ? <BookmarkSelected /> : <BookmarkUnselected />}
        onChange={() => toggleInteractionBookmark(interaction)}
        size="small"
        dataAttributes={{ "data-testid": "bookmark-option-button" }}
      />
      {/* if only 1 transcript, no need to allow toggling */}
      {transcripts.length > 1 ? (
        <>
          <ListOption type="divider" />
          <ListOption type="title" label="Transcript Type" size="small" />{" "}
          {transcripts.some((tr) => tr.transcriptType === "regular") && (
            <ListOption
              type="text"
              label="Human Transcript"
              size="small"
              dataAttributes={{ "data-testid": "regular-transcript-option" }}
              onChange={() => {
                const selectedTranscript = transcripts.find(
                  (a) => a.id === interaction.id && a.transcriptType === "regular"
                );
                if (selectedTranscript) {
                  selectTranscript(selectedTranscript);
                }
              }}
            />
          )}
          {transcripts.some((tr) => tr.transcriptType === "ai") && (
            <ListOption
              type="text"
              label="AI Transcript"
              size="small"
              dataAttributes={{ "data-testid": "ai-transcript-option" }}
              onChange={() => {
                const selectedTranscript = transcripts.find(
                  (a) => a.id === interaction.id && a.transcriptType === "ai"
                );
                if (selectedTranscript) {
                  selectTranscript(selectedTranscript);
                }
              }}
            />
          )}
        </>
      ) : null}
      {downloadables.length > 0 && (
        <>
          <ListOption type="divider" /> <ListOption type="title" label="Download" />
        </>
      )}
      {downloadables.map((downloadable) => (
        <DownloadTranscriptListOption
          {...(downloadable as {
            label: string;
            type: string;
            id: string | null;
            url: string;
            target: string;
          })}
          interaction={interaction}
        />
      ))}
    </x.div>
  );
};

const DownloadTranscriptListOption = ({
  label,
  type,
  id,
  url,
  target,
  interaction,
}: {
  label: string;
  type: string;
  id: string | null;
  url: string;
  target: string;
  interaction: Partial<Interaction>;
}) => {
  const { logHit } = useTrackUserAction();
  const currentUser = useCurrentUser();
  const { showContent } = useHideDeliverablesContent();

  const startDownload = useCallback(
    (interaction: Partial<Interaction>, type: any, id: string | null, url: string) => {
      if (!showContent) return;
      window.open(url, target ?? "_self");
      logHit({
        origin: HitOrigin.deliverablesView,
        action: HitAction.downloadTranscript,
        advisorshipId: interaction.id,
        projectToken: interaction.projectToken,
        details: { transcriptType: type },
        references: { transcriptId: id },
      });
    },
    [currentUser] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <ListOption
      disabled={!showContent}
      type="text"
      label={label}
      leftIcon={<Download />}
      onChange={() => startDownload(interaction, type, id, url)}
      size="small"
      dataAttributes={{
        "data-testid": `${label}-download-button`,
        style: !showContent ? { cursor: "not-allowed" } : null,
      }}
    />
  );
};

const SearchElement = () => {
  const { isUltraWide } = useCheckScreen();
  const {
    interaction,
    searchCriteria,
    onSearch,
    totalMentionsFe,
    isLoading,
    selectedView,
    aiFeaturesEnabled,
    transcriptFeaturesEnabled,
  } = useInteractionDeliverablesContext()!;
  const { logHit } = useTrackUserAction();
  const { project } = useCurrentProjectContext();

  const onSearchCallback = useCallback(
    (keywords: string[]) => {
      onSearch(keywords);
      if (keywords.length > 0)
        logHit({
          origin: HitOrigin.deliverablesView,
          action: HitAction.transcriptSearch,
          projectToken: project?.token,
          advisorshipId: interaction.id,
          details: { filters: keywords },
        });
    },
    [interaction.id, logHit, onSearch, project]
  );

  return (
    <TranscriptSearchBar
      variant={isUltraWide || !aiFeaturesEnabled || !transcriptFeaturesEnabled ? "inline" : "popover"}
      forceOpen={selectedView === ViewType.Mentions}
      placeholder={"Keyword search..."}
      onKeywordsUpdated={onSearchCallback}
      onNav={(idx) => selectIndexHighlight(idx, totalMentionsFe)}
      totalResults={totalMentionsFe ?? 0}
      loading={isLoading}
      queryKeywords={searchCriteria}
    />
  );
};

const transcriptFileDownload = (
  { id, token, transcriptType }: TranscriptRequest,
  interaction: Partial<Interaction>,
  number: number
) => {
  const formatedCallTime = interaction.scheduledCallTime ? ` - ${interaction.scheduledCallTime.slice(0, 10)}` : "";

  const transcriptFileName = `${interaction.projectTitle} - ${interaction.advisorName} ${formatedCallTime}${
    number ? " - " + number : ""
  }.docx`.replace(/[[\],]/g, "");

  return `/api/projects/${interaction.projectToken}/transcripts/${token}?filename=${encodeURIComponent(
    transcriptFileName
  )}`;
};

export { TopBar as NewTopBar };
