import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Popover as PopoverAlphaDesign,
  TextField,
  useThemeTokens,
} from "@alphasights/alphadesign-components";
import { ButtonWithMargin } from "@alphasights/client-portal-shared";
import { debounce } from "lodash";
import { HitOrigin } from "@alphasights/portal-api-client";
import { useLocation } from "router-utils";
import { currentProjectView } from "helpers/currentView";

export const HideExpertReasonPopover = ({ onSaveHideExpertReason, interactionId, anchorRef, onClose }) => {
  const {
    typography: { body },
    spacing,
  } = useThemeTokens();
  const location = useLocation();
  const currentView = currentProjectView(location?.pathname);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // prevents flickering on table view
  const setAnchorElDebounced = useMemo(() => {
    return debounce(setAnchorEl, 50);
  }, []);

  const handleHideExpertSaveReason = () => {
    onSaveHideExpertReason(interactionId, reasonValues, customReason, currentView ?? HitOrigin.tableView).then(() => {
      onClose();
    });
  };

  const [customReason, setCustomReason] = useState("");
  const [skipCancelLabel, setSkipCancelLabel] = useState("Skip");

  const [reasonValues, setReasonValues] = useState([]);
  const handleReasonChange = (value) => {
    setReasonValues(value);
    setSkipCancelLabel(value.length > 0 ? "Cancel" : "Skip");
    toggleCustomReasonDisplay(value.includes("CUSTOM_REASON"));
  };

  const [customReasonDisplay, setCustomReasonDisplay] = useState(false);
  const toggleCustomReasonDisplay = (display) => {
    setCustomReasonDisplay(display);
    if (!display) setCustomReason("");
  };

  useEffect(() => {
    setAnchorElDebounced(anchorRef.current);
  }, [anchorRef, setAnchorElDebounced]);

  const popoverStyle = {
    padding: 0,
    zIndex: 100,
  };

  const titleStyle = {
    ...body.em,
  };

  const checkboxStyle = {
    body: body.regular,
    padding: spacing.inner.base02,
  };

  const customReasonDivStyle = {
    marginTop: "8px",
    display: customReasonDisplay ? "" : "none",
  };

  const customReasonTextStyle = {
    body: body.small,
  };

  const reasonOptions = [
    {
      value: "LACKING_RECENT_EXPERIENCE",
      text: "Lacking recent experience",
    },
    {
      value: "LACKING_RELEVANT_EXPERIENCE",
      text: "Lacking relevant experience",
    },
    {
      value: "SPOKEN_WITH_ALREADY",
      text: "Spoken with already",
    },
    {
      value: "CUSTOM_REASON",
      text: "Other",
    },
  ];

  return (
    <PopoverAlphaDesign
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      closeOnScroll={true}
      data-testid="hide-expert-popover"
      {...popoverStyle}
    >
      <div className="aui-p-3">
        <div style={titleStyle}>Hide expert with reason</div>
      </div>
      <div
        className="aui-border-0 aui-border-t aui-border-grey-2 aui-border-solid aui-p-3"
        onClick={(e) => e.stopPropagation()}
      >
        <CheckboxGroup onChange={handleReasonChange}>
          {reasonOptions.map((item) => (
            <Checkbox
              value={item.value}
              style={checkboxStyle}
              data-testid={`hide-expert-popover-checkbox-${item.value}`}
              key={`hide-expert-popover-checkbox-${item.value}`}
            >
              {item.text}
            </Checkbox>
          ))}
        </CheckboxGroup>
        <div style={customReasonDivStyle}>
          <TextField
            value={customReason}
            onChange={(e) => setCustomReason(e.target.value)}
            style={customReasonTextStyle}
            data-testid="hide-expert-popover-custom-reason"
          />
        </div>
      </div>
      <div className="aui-border-0 aui-border-t aui-border-grey-2 aui-border-solid aui-p-3">
        <Button
          onClick={(event) => {
            event.stopPropagation();
            handleHideExpertSaveReason();
          }}
          variant="secondary"
          size="small"
          data-testid="hide-expert-popover-button-save"
        >
          Save
        </Button>
        <ButtonWithMargin
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
          variant="ghost"
          size="small"
        >
          {skipCancelLabel}
        </ButtonWithMargin>
      </div>
    </PopoverAlphaDesign>
  );
};
