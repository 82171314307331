import { interactions, SynthesisModuleRevision } from "@alphasights/portal-api-client";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isVendorContent } from "views/ProjectSynthesisView/synthesisTypeGuards";

export type MentionsResult = Awaited<ReturnType<typeof interactions.searchSync>>;

export const useVendorMentions = ({ revision }: { revision: SynthesisModuleRevision }) => {
  const mounted = useRef(true);

  const [mentions, setMentions] = useState<Record<string, MentionsResult>>({});

  const { project } = useCurrentProjectContext();

  const loadTranscriptData = useCallback(async () => {
    if (!isVendorContent(revision.contents)) return;
    const content = revision.contents;
    const vendors = [...content.featuredVendors, ...content.emergingVendors];

    for (const v of vendors) {
      const result = await interactions.searchSync({
        project,
        searchQuery: v.companyName,
        params: { searchTranscripts: "true" },
      });

      if (!mounted.current) return;

      setMentions((prev) => ({ ...prev, [v.companyName]: result }));
    }
  }, [project, revision.contents]);

  useEffect(() => {
    loadTranscriptData();

    return () => {
      mounted.current = false;
    };
  }, [revision.contents, loadTranscriptData]);

  return useMemo(() => ({ mentions }), [mentions]);
};
