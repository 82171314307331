import React from "react";
import { useProjectSynthesisContext } from "providers/ProjectSynthesisProvider";
import {
  Divider,
  Typography,
  Link,
  Tile,
  Pill,
  Icon,
  EllipsisText,
  Tooltip,
  Carousel,
  Skeleton,
} from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import { useStyles } from "./VendorModuleContent.styles";
import { EnrichedInteraction, VendorItem } from "@alphasights/portal-api-client";
import { Quote, RightArrow, ThirdParty } from "@alphasights/alphadesign-icons";
import { vendors } from "views/ProjectSynthesisView/synthesisTypeGuards";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { MentionsResult, useVendorMentions } from "./useVendorMentions";
import { useNavigate } from "router-utils";

export const VendorModuleContent = () => {
  const { selectedModule, revision } = useProjectSynthesisContext();

  if (!revision || !selectedModule) return null;

  return <Render />;
};

const Render = () => {
  const styles = useStyles();

  const { revision } = useProjectSynthesisContext();

  const content = vendors(revision?.contents);

  const { mentions } = useVendorMentions({ revision: revision! });

  return (
    <x.div {...styles.contentWrapper}>
      {content.featuredVendors.map((v) => {
        return (
          <React.Fragment key={v.companyName}>
            <VendorRender vendor={v} mentions={mentions[v.companyName]} expanded />
            <Divider mx={"-24px"} />
          </React.Fragment>
        );
      })}

      {content.emergingVendors.length > 0 && (
        <>
          <Typography variant="body-large-em">Additional Vendors</Typography>
          <Divider mx={"-24px"} />
          {content.emergingVendors.map((v) => {
            return (
              <React.Fragment key={v.companyName}>
                <VendorRender vendor={v} mentions={mentions[v.companyName]} />
                <Divider mx={"-24px"} />
              </React.Fragment>
            );
          })}
        </>
      )}
    </x.div>
  );
};

const VendorRender = ({
  vendor,
  expanded = false,
  mentions,
}: {
  vendor: VendorItem;
  expanded?: boolean;
  mentions?: MentionsResult;
}) => {
  const styles = useStyles();

  const { project } = useCurrentProjectContext();

  // needs a page reload as there are conflicts on params when redirecting through router
  const link = `/${project!.token}/experts/deliverables-view/?keywords=${vendor.companyName}`;

  return (
    <x.div {...styles.vendorWrapper}>
      <x.div>
        <Tile variant="image" size="medium" image={vendor.companyLogo} />
      </x.div>
      <x.div {...styles.vendorDataColumn}>
        <Typography variant="body-large-em" data-testid="vendor-title">
          {vendor.companyName}
        </Typography>
        <x.div {...styles.vendorMentionsTotal}>
          {mentions ? (
            <>
              <Typography variant="body-small-em" color="secondary">
                Mentioned by {mentions!.interactions.length} experts
              </Typography>
              <Link
                endIcon={<RightArrow />}
                size="small"
                href={link}
                fontWeight="600 !important"
                data-testid="link-view-all-mentions"
              >
                View all mentions
              </Link>
            </>
          ) : (
            <Skeleton variant="noMargin" width="300px" height="16px" />
          )}
        </x.div>
        {expanded && (
          <>
            <Typography data-testid="vendor-summary">{vendor.summary}</Typography>
            <Carousel shouldScrollOnFocus={false} {...styles.carousel}>
              {mentions
                ? mentions.interactions.map((i) => {
                    return <ExpertCard key={i.id} interaction={i} />;
                  })
                : [1, 2, 3].map(() => {
                    return <Skeleton variant="noMargin" width="340px" height="46px" />;
                  })}
            </Carousel>
          </>
        )}
      </x.div>
    </x.div>
  );
};

const ExpertCard = ({ interaction }: { interaction: EnrichedInteraction }) => {
  const styles = useStyles();

  const navigate = useNavigate();

  const { project } = useCurrentProjectContext();

  const link = `/${project!.token}/experts/deliverables-view/?selectedInteraction=${interaction.id}`;

  const onClick = () => {
    navigate(link);
  };

  return (
    <Tooltip title={`View ${interaction.score} mentions in transcript`} variant="dark">
      <x.div {...styles.expertCardWrapper} onClick={onClick} data-testid={`expert-mention-${interaction.id}`}>
        <Tile variant="icon" size={"x-small"} color="tertiary" icon={<ThirdParty />} />
        <x.div {...styles.interactionContent}>
          <EllipsisText variant="body-small" disableTooltip>
            {interaction.companyName} - {interaction.role}
          </EllipsisText>
        </x.div>
        <Pill
          leftAccessories={
            <Icon color="secondary">
              <Quote />
            </Icon>
          }
          size="x-small"
        >
          {interaction.score}
        </Pill>
      </x.div>
    </Tooltip>
  );
};
