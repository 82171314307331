import React from "react";
import { Skeleton, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

export const CardWrapper = styled(x.div)(() => {
  const { color, shape } = useThemeTokens();

  return `
    background-color: ${color.background.surface.overlay};
    border: 1px solid ${color.border.neutral.default};
    border-radius: ${shape.border.radius.medium};
  `;
});

export const CardTitleWrapper = styled(x.div)(() => {
  const { spacing, color } = useThemeTokens();

  return `
    padding: ${spacing.inner.base03} ${spacing.inner.base04};
    border-bottom: 1px solid ${color.border.neutral.default};
  `;
});

export const CardContentWrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();

  return `
    padding: ${spacing.inner.base04};
  `;
});

export const QuestionWrapper = styled(x.div)(() => {
  const { spacing } = useThemeTokens();

  return `
    padding: ${spacing.inner.base02} ${spacing.inner.base03};
  `;
});

export const QuestionLoading = () => {
  const { spacing } = useThemeTokens();

  return (
    <QuestionWrapper>
      <Skeleton variant="noMargin" height={spacing.layout.base02} width="100%" />
      <x.div h={spacing.inner.base} />
      <Skeleton variant="noMargin" height={spacing.layout.base02} width="200px" />
    </QuestionWrapper>
  );
};

export const CardTitleLoading = () => (
  <x.div w="50%" maxW="400px" padding="6px 12px">
    <Skeleton variant="noMargin" height="20px" width="100%" />
  </x.div>
);
