import React, { ForwardedRef, useCallback, useMemo } from "react";
import useQueryParams from "hooks/useQueryParams";
import { useCurrentWorkstreamContext } from "providers/CurrentWorkstreamProvider";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { useNavigate } from "router-utils";
import { filterSurveysWithInteractions } from "./helpers/Workstreams";
import { SelectOption, SelectMenu, useThemeTokens } from "@alphasights/alphadesign-components";
import { first, flatMap } from "lodash";
import styled, { x } from "@xstyled/styled-components";

export const WorkstreamToggler = React.forwardRef((params, ref: ForwardedRef<HTMLDivElement>) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const { allInteractionsForProject } = useProjectInteractionsContext();
  const { project, selectedSurveyId, selectedWorkstream } = useCurrentWorkstreamContext();

  const buildSelectId = (workstream: Workstream, surveyId?: string) =>
    surveyId
      ? `${workstream.workstreamType}_${workstream.id}_${surveyId}`
      : `${workstream.workstreamType}_${workstream.id}`;

  const options = useMemo(
    () =>
      project.workstreams.flatMap((w) => {
        return w.workstreamType === "survey" && w.clientSurveys?.length > 0
          ? filterSurveysWithInteractions(allInteractionsForProject, w.clientSurveys).map((s: ClientSurvey) => ({
              id: buildSelectId(w, s.id),
              value: s.description,
            }))
          : { id: buildSelectId(w), value: "Call" };
      }),
    [allInteractionsForProject, project.workstreams]
  );

  const onSelectChange = useCallback(
    (id: string) => {
      const [workstreamType, workstreamId, surveyId] = id.split("_");
      if (workstreamId === selectedWorkstream?.id && workstreamType === "survey") {
        queryParams.set("currentSurvey", surveyId);
      } else if (workstreamType === "survey") {
        navigate(`/${project.token}/workstream/${workstreamId}/experts?currentSurvey=${surveyId}`);
      } else {
        navigate(`/${project.token}/workstream/${workstreamId}/experts`);
      }
    },
    [navigate, project.token, queryParams, selectedWorkstream]
  );

  const dropdownWidth = useMemo(() => {
    const longestDescription = Math.max(...options.map((opt) => opt.value).map((el) => el.length));
    const charCountLimited = Math.min(longestDescription, 24);
    const charSize = 11;
    return charCountLimited * charSize;
  }, [options]);

  if (!selectedWorkstream || options.length < 2) {
    return null;
  }

  return (
    <SelectMenuWrapper ref={ref}>
      <SelectMenu
        value={buildSelectId(selectedWorkstream, selectedSurveyId)}
        onChange={(value) => {
          const newValue = first(flatMap([value]));
          newValue && onSelectChange(newValue.toString());
        }}
        data-testid="workstreams-select"
        isClearButtonEnabled={false}
        maxWidth={`${dropdownWidth}px`}
        size="small"
        dataAttributes={{ "data-testid": "workstreams-select" }}
      >
        {options.map((opt) => (
          <SelectOption
            key={`workstreams-select-${opt.id}`}
            data-testid={`workstreams-select-${opt.id}`}
            value={opt.id}
          >
            {opt.value}
          </SelectOption>
        ))}
      </SelectMenu>
    </SelectMenuWrapper>
  );
});

const SelectMenuWrapper = styled(x.div)(() => {
  const {
    shape: { border },
    color,
  } = useThemeTokens();

  return `
    .select-box {
      border-radius: ${border.radius.max};
      &[aria-expanded="true"] {
        outline-color: ${color.border.neutral.default};
        background-color: ${color.background.action.outline.pressed};
      }
    }

    [data-testid="formatted-value"] {
      color: ${color.text.secondary};
    }
  `;
});
