import React from "react";
import { x } from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { ConditionalTooltip, PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { Citation } from "components/CitationContext/Citation";
import { useGetCompanyProperties } from "pages/AlphaNowPage/primers/hooks";
import { useCustomerPrimersStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state/store";
import { getTruncatedTextAndTooltip } from "pages/AlphaNowPage/primers/utils/utils";

type OutlookEmergingVendorsProps = {
  emerging: {
    companyName: string;
    expertsResponses: ExpertResponse[];
  }[];
};

const OutlookEmergingVendors = ({ emerging }: OutlookEmergingVendorsProps) => {
  const { color, icons: adsIcons, spacing, typography } = useThemeTokens();
  const companies = useCustomerPrimersStore(({ companies }) => companies);
  const companiesV2 = useCustomerPrimersStore(({ companiesV2 }) => companiesV2);
  const { getCompanyProperties } = useGetCompanyProperties(companies, companiesV2);

  return (
    <x.div mt={spacing.inner.base02}>
      {emerging.map(({ companyName, expertsResponses }, index) => {
        const companyProperties = getCompanyProperties(companyName);

        const [companyNameTruncated, tooltip] = getTruncatedTextAndTooltip(companyName, 25);

        return (
          <x.div
            key={companyName ?? index.toString()}
            display="flex"
            columnGap={spacing.layout.base03}
            padding={`${spacing.inner.base03} 0`}
            borderBottom={`1px solid ${color.border.divider}`}
            alignItems="start"
          >
            <x.div flex="0 0 auto" display="flex" columnGap={spacing.inner.base02} minWidth="200px" alignItems="center">
              <PrimerCompanyLogo size={adsIcons.size.lg} src={companyProperties.logoLink} />
              <x.span {...typography.body.em} color={color.icon.strong._}>
                <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
                  {companyNameTruncated as string}
                </ConditionalTooltip>
              </x.span>
            </x.div>
            <div>
              {expertsResponses.map(({ response }, index) => {
                return (
                  <x.div
                    key={index.toString()}
                    {...typography.body.regular}
                    fontStyle="italic"
                    color={color.text.strong._}
                  >
                    <i>
                      <Citation
                        value={{
                          value: `‘${response.value}’`,
                          citedBy: response.citedBy,
                        }}
                      />
                    </i>
                  </x.div>
                );
              })}
            </div>
          </x.div>
        );
      })}
    </x.div>
  );
};

export default OutlookEmergingVendors;
