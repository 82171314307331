import {
  KpcItem,
  SynthesisAnswer,
  SynthesisModule,
  SynthesisOverview,
  SynthesisQuote,
} from "@alphasights/portal-api-client";
import { useCallback, useEffect, useRef, useState } from "react";
import { kpc, question } from "../synthesisTypeGuards";

export interface EditSynthesisModuleOperations {
  updateQuestion: (newQuestion: string, revisionIdx: number) => void;
  updateOverview: (newOverview: SynthesisOverview, revisionIdx: number) => void;
  deleteOverview: (deletedOverview: SynthesisOverview, revisionIdx: number) => void;
  updateQuote: (newQuote: SynthesisQuote, revisionIdx: number) => void;
  deleteQuote: (deletedQuote: SynthesisQuote, revisionIdx: number) => void;
  updateAnswer: (newAnswer: SynthesisAnswer, revisionIdx: number) => void;
  deleteAnswer: (deletedAnswer: SynthesisAnswer, revisionIdx: number) => void;
  updateKpcQuote: (newQuote: SynthesisQuote, revisionIdx: number, kpcItemIndex: number) => void;
  updateKpcItemName: (newItemName: string, revisionIdx: number, kpcItemIndex: number) => void;
  updateKpcItemSummary: (newSummary: string, revisionIdx: number, kpcItemIndex: number) => void;
  deleteKpcQuote: (deletedQuote: SynthesisQuote, revisionIdx: number, kpcItemIndex: number) => void;
  deleteKpcItem: (kpcItemIndex: number, revisionIdx: number) => void;
  addKpcItem: (revisionIdx: number) => void;
}

export const useEditSynthesisModule = (
  module?: SynthesisModule
): {
  editedModule: SynthesisModule | undefined;
  editOperations: EditSynthesisModuleOperations;
  cancelChanges: () => void;
  undo: () => void;
  redo: () => void;
  clearUndoRedo: () => void;
} => {
  const [editedModule, setEditedModule] = useState(module);
  const undoStack = useRef<SynthesisModule[]>([]);
  const redoStack = useRef<SynthesisModule[]>([]);

  useEffect(() => {
    setEditedModule(module);
  }, [module]);

  const updateStacksAndReturn = useCallback((prevModule: SynthesisModule, newModule: SynthesisModule) => {
    undoStack.current.push(prevModule);
    redoStack.current = [];
    return newModule;
  }, []);

  const updateQuestion = useCallback(
    (newQuestion: string, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    contents: {
                      ...revision.contents,
                      question: newQuestion,
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const updateOverview = useCallback(
    (newOverview: SynthesisOverview, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    contents: {
                      ...revision.contents,
                      overviews: question(revision.contents).overviews.map((overview) =>
                        overview.id === newOverview.id ? newOverview : overview
                      ),
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const deleteOverview = useCallback(
    (deletedOverview: SynthesisOverview, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    contents: {
                      ...revision.contents,
                      overviews: question(revision.contents).overviews.filter(
                        (overview) => overview.id !== deletedOverview.id
                      ),
                      answers: question(revision.contents).answers.map((answer) => ({
                        ...answer,
                        quotes: answer.quotes.filter((quote) => quote.topic !== deletedOverview.title),
                      })),
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const updateQuote = useCallback(
    (newQuote: SynthesisQuote, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    contents: {
                      ...revision.contents,
                      answers: question(revision.contents).answers.map((answer) => ({
                        ...answer,
                        quotes: answer.quotes.map((quote) => (quote.id === newQuote.id ? newQuote : quote)),
                      })),
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const deleteQuote = useCallback(
    (deletedQuote: SynthesisQuote, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    contents: {
                      ...revision.contents,
                      answers: question(revision.contents).answers.map((answer) => ({
                        ...answer,
                        quotes: answer.quotes.filter((quote) => quote.id !== deletedQuote.id),
                      })),
                      overviews: question(revision.contents).overviews.map((overview) =>
                        overview.title === deletedQuote.topic
                          ? {
                              ...overview,
                              expertCount: overview.expertCount - 1,
                            }
                          : overview
                      ),
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const updateAnswer = useCallback(
    (newAnswer: SynthesisAnswer, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    contents: {
                      ...revision.contents,
                      answers: question(revision.contents).answers.map((answer) =>
                        answer.id === newAnswer.id ? newAnswer : answer
                      ),
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const deleteAnswer = useCallback(
    (deletedAnswer: SynthesisAnswer, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    expertCount: revision.expertCount ? revision.expertCount - 1 : 0,
                    contents: {
                      ...revision.contents,
                      answers: question(revision.contents).answers.filter((answer) => answer.id !== deletedAnswer.id),
                      overviews: question(revision.contents).overviews.map((overview) =>
                        deletedAnswer.quotes.map((q) => q.topic).includes(overview.title)
                          ? {
                              ...overview,
                              expertCount: overview.expertCount - 1,
                            }
                          : overview
                      ),
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const updateKpcQuote = useCallback(
    (newQuote: SynthesisQuote, revisionIdx: number, kpcItemIndex: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    contents: {
                      ...revision.contents,
                      kpcItems: kpc(revision.contents).kpcItems.map((kpcItem, idx) =>
                        idx === kpcItemIndex
                          ? {
                              ...kpcItem,
                              quotes: kpcItem.quotes.map((quote) => (quote.id === newQuote.id ? newQuote : quote)),
                            }
                          : kpcItem
                      ),
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const updateKpcItemName = useCallback(
    (newItemName: string, revisionIdx: number, kpcItemIndex: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    contents: {
                      ...revision.contents,
                      kpcItems: kpc(revision.contents).kpcItems.map((kpcItem, idx) =>
                        idx === kpcItemIndex
                          ? {
                              ...kpcItem,
                              name: newItemName,
                            }
                          : kpcItem
                      ),
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const updateKpcItemSummary = useCallback(
    (newSummary: string, revisionIdx: number, kpcItemIndex: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    contents: {
                      ...revision.contents,
                      kpcItems: kpc(revision.contents).kpcItems.map((kpcItem, idx) =>
                        idx === kpcItemIndex
                          ? {
                              ...kpcItem,
                              summary: newSummary,
                            }
                          : kpcItem
                      ),
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const deleteKpcQuote = useCallback(
    (deletedQuote: SynthesisQuote, revisionIdx: number, kpcItemIndex: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    contents: {
                      ...revision.contents,
                      kpcItems: kpc(revision.contents).kpcItems.map((kpcItem, idx) =>
                        idx === kpcItemIndex
                          ? {
                              ...kpcItem,
                              quotes: kpcItem.quotes.filter((quote) => quote.id !== deletedQuote.id),
                            }
                          : kpcItem
                      ),
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const deleteKpcItem = useCallback(
    (kpcItemIndex: number, revisionIdx: number) => {
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    contents: {
                      ...revision.contents,
                      kpcItems: kpc(revision.contents).kpcItems.filter((_, idx) => idx !== kpcItemIndex),
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const addKpcItem = useCallback(
    (revisionIdx: number) => {
      const newKpcItem: KpcItem = {
        name: "",
        summary: "",
        quotes: [],
        sentiments: [],
      };
      setEditedModule(
        (prevModule) =>
          prevModule &&
          updateStacksAndReturn(prevModule, {
            ...prevModule,
            revisions: prevModule.revisions.map((revision, idx) =>
              idx === revisionIdx
                ? {
                    ...revision,
                    contents: {
                      ...revision.contents,
                      kpcItems: [newKpcItem, ...kpc(revision.contents).kpcItems],
                    },
                  }
                : revision
            ),
          })
      );
    },
    [updateStacksAndReturn]
  );

  const clearUndoRedo = useCallback(() => {
    undoStack.current = [];
    redoStack.current = [];
  }, []);

  const cancelChanges = useCallback(() => {
    setEditedModule(module);
    undoStack.current = [];
    redoStack.current = [];
  }, [module]);

  const undo = useCallback(() => {
    if (undoStack.current.length === 0) return;
    editedModule && redoStack.current.push(editedModule);
    setEditedModule(undoStack.current.pop());
  }, [editedModule]);

  const redo = useCallback(() => {
    if (redoStack.current.length === 0) return;
    editedModule && undoStack.current.push(editedModule);
    setEditedModule(redoStack.current.pop());
  }, [editedModule]);

  const handleUndoRedoShortcut = useCallback(
    (event: KeyboardEvent) => {
      const platform = (navigator as any)?.userAgentData?.platform || navigator?.platform;
      const isMac = platform.toLowerCase().indexOf("mac") >= 0;
      const ctrlKey = isMac ? event.metaKey : event.ctrlKey;

      if (ctrlKey === true && event.shiftKey === false && event.key === "z") {
        event.preventDefault();
        undo();
      }
      if (
        (ctrlKey === true && event.shiftKey === true && event.key === "z") ||
        (ctrlKey === true && event.key === "y")
      ) {
        event.preventDefault();
        redo();
      }
    },
    [redo, undo]
  );

  useEffect(() => {
    const targetNode = document;

    targetNode && targetNode.addEventListener("keydown", handleUndoRedoShortcut as EventListener);

    return () => targetNode && targetNode.removeEventListener("keydown", handleUndoRedoShortcut as EventListener);
  }, [handleUndoRedoShortcut]);

  const editOperations = {
    updateQuestion,
    updateOverview,
    deleteOverview,
    updateQuote,
    deleteQuote,
    updateAnswer,
    deleteAnswer,
    updateKpcQuote,
    updateKpcItemName,
    updateKpcItemSummary,
    deleteKpcQuote,
    deleteKpcItem,
    addKpcItem,
  };

  return {
    editedModule,
    editOperations,
    cancelChanges,
    undo,
    redo,
    clearUndoRedo,
  };
};
