import React, { ReactNode, useCallback, useContext, useState } from "react";
import { HitOrigin } from "@alphasights/portal-api-client";

type OnToggleStar = (interaction: Interaction, origin: HitOrigin) => Promise<void>;

interface StarExpertProviderState {
  onToggleStar: OnToggleStar;
  isLoading: (interactionId: string) => boolean;
}

interface StarExpertProviderProps {
  children: ReactNode;
  onToggleStar: any;
}

export const StarExpertContext = React.createContext<StarExpertProviderState | null>(null);

export const StarExpertProvider = ({ children, onToggleStar, ...props }: StarExpertProviderProps) => {
  const [interactionIdsLoading, setInteractionIdsLoading] = useState<string[]>([]);

  const toggleStar: OnToggleStar = useCallback(
    (interaction, origin) => {
      setInteractionIdsLoading((prev) => [...prev, interaction.id]);

      return onToggleStar(interaction.id, !interaction.starred, origin).finally(() => {
        setInteractionIdsLoading((prev) => prev.filter((id) => id !== interaction.id));
      });
    },
    [onToggleStar]
  );

  const value: StarExpertProviderState = {
    onToggleStar: toggleStar,
    isLoading: (interactionId) => interactionIdsLoading.includes(interactionId),
  };

  return (
    <StarExpertContext.Provider value={value} {...props}>
      {children}
    </StarExpertContext.Provider>
  );
};

export const useStarExpertContext = () => useContext(StarExpertContext);
