import { useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { x } from "@xstyled/styled-components";

const DocumentWrapper = styled(x.div)(({ hasWhiteBackground }: { hasWhiteBackground: boolean }) => {
  const { color, spacing } = useThemeTokens();
  const backgroundColor = hasWhiteBackground
    ? color.background.surface.page.default
    : color.background.surface.recessed;
  return `
    background: ${backgroundColor};
    padding: ${spacing.inner.base06} 0 ${spacing.inner.base06} ${spacing.inner.base02};
  `;
});

const SpinnerContainer = styled(x.div)`
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PagesContainer = styled(x.div)(() => {
  const { color, spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base04};
    background: ${color.background.surface.recessed};
  `;
});

const PageWrapper = styled(x.div)(() => {
  const { color, shape } = useThemeTokens();
  return `
  display: flex;
  justify-content: center;

  
  .react-pdf__Page__canvas {
    border: ${shape.border.width.small} solid ${color.border.neutral.default};
    border-radius: ${shape.border.radius.small};
  }
`;
});

const ErrorContainer = styled(x.div)(() => {
  const { color, spacing } = useThemeTokens();
  return `
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${spacing.inner.base04};
    background: ${color.background.surface.page.default};
  `;
});

export { DocumentWrapper, SpinnerContainer, PagesContainer, PageWrapper, ErrorContainer };
