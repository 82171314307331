import React from "react";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import * as S from "./CustomerKnowledgeViewToggler.styled";
import { ToggleButton } from "components/ToggleButton/ToggleButton";

export type CustomerKnowledgeViewTogglerProps = {
  onChangeLayout: (layout: string) => void;
  currentView?: string;
};

export const CustomerKnowledgeViewToggler = ({
  onChangeLayout,
  currentView = "customer-view",
}: CustomerKnowledgeViewTogglerProps) => {
  const { logHit } = useTrackUserAction();
  const { project } = useCurrentProjectContext();

  const handleOnChangeLayout = (layout: string) => {
    if (layout === "table-view") {
      logHit({
        projectToken: project!.token,
        origin: HitOrigin.customerView,
        action: HitAction.allExpertsTabClicked,
      });
    } else if (layout === "customer-view") {
      logHit({
        projectToken: project!.token,
        origin: HitOrigin.tableView,
        action: HitAction.customerKnowledgeTabClicked,
      });
    }
    onChangeLayout(layout);
  };

  return (
    <S.Wrapper role="listbox">
      <ToggleButton
        title="All"
        onClick={() => handleOnChangeLayout("table-view")}
        selected={currentView === "table-view"}
        data-testid="all-experts-toggle"
      />
      <ToggleButton
        title="Customers"
        onClick={() => handleOnChangeLayout("customer-view")}
        selected={currentView === "customer-view"}
        data-testid="customers-toggle"
      />
    </S.Wrapper>
  );
};
