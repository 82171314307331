import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AccordionItem, Alert, Icon, IconButton, List, Popover, Typography } from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";
import {
  AddCall,
  AiTranscript,
  AudioRecording,
  Blinded,
  ConsultantLed,
  HumanTranscript,
  TranslatedTranscript,
  VideoCall,
  PresentToAll,
  Info,
  Interpreted,
} from "@alphasights/alphadesign-icons";
import { useInteractioUpgradesStyles } from "./InteractionUpgrades.styles";
import { addSeconds, isWithinInterval, isPast, isBefore, subHours } from "date-fns";
import { parseISO } from "providers/TimezoneProvider";
import { fetch, useApi } from "hooks/useApi";
import { UpgradeItem } from "./UpgradeItem";
import { getFirstName } from "pages/InteractionPage/utils";
import { getTranscriptReadyWithinText } from "./transcriptReadyWithinMap";
import { withLoginWall } from "components/LoginWall/LoginWall";
import { useProjectInteractionsContext } from "providers/ProjectInteractionsProvider";
import { getLanguageFromCode, languages } from "helpers/displayHelpers";
import { useFlyoutContext } from "providers/FlyoutProvider";
import { DeliverablesInformation, EnhancementsInformation } from "./InformationBox";
import { isCallAboutToStart } from "components/InteractionsPage/helpers/Interaction";
import { NoXMarginSkeleton } from "components/NoXMarginSkeleton";
import { useIsAuthenticated } from "@alphasights/portal-auth-react";
import pluralize from "pluralize";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { InteractionsService } from "services/interactionsService";

const isDuringInteraction = ({ scheduledCallTime, expectedDuration }) =>
  scheduledCallTime &&
  expectedDuration &&
  isWithinInterval(new Date(), {
    start: parseISO(scheduledCallTime),
    end: addSeconds(parseISO(scheduledCallTime), expectedDuration),
  });

const isPostInteraction = ({ state, scheduledCallTime, expectedDuration }) =>
  state === "completed" ||
  (scheduledCallTime && expectedDuration && isPast(addSeconds(parseISO(scheduledCallTime), expectedDuration)));

const isPostScheduledCallTime = ({ scheduledCallTime }) => scheduledCallTime && isPast(parseISO(scheduledCallTime));

const isAtLeastHoursBeforeInteraction = ({ scheduledCallTime, hoursBefore }) =>
  !scheduledCallTime || isBefore(new Date(), subHours(parseISO(scheduledCallTime), hoursBefore));

const translatedTranscriptLanguages = [
  "en",
  "zh",
  "fr",
  "de",
  "es",
  "ko",
  "jp",
  "it",
  "pt",
  "nl",
  "sv",
  "ru",
  "zh-HK",
  "id",
  "vi",
];

const TRANSCRIPT_WINDOWS = {
  transcript: 8,
  transcript_ai: 1,
  translated: 0,
};

const ChargeUnit = {
  hour: "per_hour",
  interaction: "per_interaction",
};

export const InteractionUpgrades = React.forwardRef(({ interaction, isVisible = true, expanded, ...props }, ref) => {
  const isLoggedIn = useIsAuthenticated();
  const { isExpandedUpgrade } = useFlyoutContext();

  if (!isVisible) return null;

  return (
    <AccordionItemWithLoginWall ref={ref} title={<Title />} open={isExpandedUpgrade || expanded} disabled={!isLoggedIn}>
      {isLoggedIn && <InteractionUpgradesSection interaction={interaction} {...props} />}
    </AccordionItemWithLoginWall>
  );
});

const InteractionUpgradesSection = ({
  projectToken,
  projectLead,
  interaction,
  upgrades,
  loadingUpgrades,
  deliverablesViewEnabled,
  requiresComplianceApproval,
  ...props
}) => {
  const [accessPlan, setAccessPlan] = useState();
  const selectedUpgrades = useMemo(() => {
    return upgrades?.map((u) => u.value) ?? [];
  }, [upgrades, interaction]); // eslint-disable-line react-hooks/exhaustive-deps

  const { onAddUpgrade: onAddUpgradeFn, onRemoveUpgrade: onRemoveUpgradeFn } = useProjectInteractionsContext();
  const onAddUpgrade = useCallback(
    (type, locale) =>
      onAddUpgradeFn({
        projectToken,
        interactionId: interaction.id,
        type,
        locale,
      }),
    [interaction.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onRemoveUpgrade = useCallback(
    (type) => onRemoveUpgradeFn({ projectToken, interactionId: interaction.id, type }),
    [interaction.id] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // We are processing upgrade events sequentially to avoid
  // race conditions that can result in duplicate or inconsistent upgrades
  const [upgradeEventQueue, setUpgradeEventQueue] = useState([]);
  const [isProcessingEvent, setIsProcessingEvent] = useState(false);

  useEffect(
    () => {
      const processUpgradeEvents = () => {
        const event = upgradeEventQueue[0];
        if (!event) {
          setIsProcessingEvent(false);
          return;
        }

        setIsProcessingEvent(true);
        const { eventType, args } = event;
        const handleEvent = eventType === "add" ? onAddUpgrade : onRemoveUpgrade;
        handleEvent(...args).then(() => {
          setIsProcessingEvent(false);
          setUpgradeEventQueue((prev) => prev.slice(1));
        });
      };

      !isProcessingEvent && processUpgradeEvents();
    },
    [upgradeEventQueue] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const requestUpgradeEvent = useCallback((eventType, args) => {
    setUpgradeEventQueue((prev) => [...prev, { eventType, args }]);
  }, []);

  const onRequestAddUpgrade = (type, locale) => {
    requestUpgradeEvent("add", [type, locale]);
  };

  const onRequestRemoveUpgrade = (type) => {
    requestUpgradeEvent("remove", [type]);
  };

  const [, terms] = useApi(
    {
      url: `/api/projects/${projectToken}/interactions/${interaction.id}/terms`,
      method: "GET",
    },
    [projectToken, interaction.id]
  );

  useEffect(() => {
    InteractionsService.getAccessPlan(projectToken, interaction.id).then(setAccessPlan);
  }, [interaction.id, projectToken]);

  const duringInteraction = isDuringInteraction(interaction) || isCallAboutToStart(interaction);
  const postInteraction = isPostInteraction(interaction);
  const showDeliverables =
    (!isPostScheduledCallTime(interaction) || terms?.transcriptConsentAccepted) && interaction.callQuality !== "bad";

  const { sectionStyle } = useInteractioUpgradesStyles({});
  return (
    <>
      {loadingUpgrades || !accessPlan ? (
        <NoXMarginSkeleton count={5} />
      ) : (
        <x.div {...sectionStyle} data-testid={`interaction-upgrades-${interaction.id}`}>
          {showDeliverables && (
            <Deliverables
              consentGiven={terms?.transcriptConsentAccepted}
              duringInteraction={duringInteraction}
              postInteraction={postInteraction}
              selected={selectedUpgrades}
              aiTranscriptEnabled={props.aiTranscriptEnabled}
              recordedEnabled={props.recordedEnabled}
              humanTranscriptEnabled={props.humanTranscriptEnabled}
              projectLead={projectLead}
              interaction={interaction}
              onAddUpgrade={onRequestAddUpgrade}
              onRemoveUpgrade={onRequestRemoveUpgrade}
              deliverablesViewEnabled={deliverablesViewEnabled}
              projectToken={projectToken}
              requiresComplianceApproval={requiresComplianceApproval}
              showMobileView={props.showMobileView}
              accessPlan={accessPlan}
            />
          )}
          <Enhancements
            upgrades={upgrades ?? []}
            termsAccepted={!!terms?.acceptedAt}
            duringInteraction={duringInteraction}
            postInteraction={postInteraction}
            selected={selectedUpgrades}
            projectLead={projectLead}
            videoCallAvailable={props.videoCallAvailable}
            blindedUpgradeAvailable={props.blindedUpgradeAvailable}
            interaction={interaction}
            onAddUpgrade={onRequestAddUpgrade}
            onRemoveUpgrade={onRequestRemoveUpgrade}
            accessPlan={accessPlan}
          />
        </x.div>
      )}
    </>
  );
};

const Deliverables = ({
  selected: selectedInput = [],
  consentGiven,
  duringInteraction,
  projectLead,
  postInteraction,
  interaction,
  aiTranscriptEnabled,
  recordedEnabled,
  humanTranscriptEnabled,
  onAddUpgrade: onAddUpgradeInput,
  onRemoveUpgrade: onRemoveUpgradeInput,
  deliverablesViewEnabled,
  projectToken,
  requiresComplianceApproval,
  showMobileView,
  accessPlan,
}) => {
  const { peerContentContributor, privateTranscriptContributor } = interaction;
  const { updgradeTypeHeaderStyle, upgradeListStyle, bannerStyle } = useInteractioUpgradesStyles();

  const recording = useMemo(() => interaction.recordings?.sort((a, b) => b.createdAt - a.createdAt)[0], [
    interaction.recordings,
  ]);

  const recordingExpired = useMemo(
    () => recording?.purgedAt || (recording?.expiresAt && isPast(parseISO(recording.expiresAt))),
    [recording]
  );

  const recordingAvailable = useMemo(() => !recordingExpired && recording?.visibleToClient, [
    recordingExpired,
    recording,
  ]);

  const translationAvailable = useMemo(() => translatedTranscriptLanguages.includes(interaction.language), [
    interaction.language,
  ]);

  const [transcriptTargetLanguage, setTranscriptTargetLanguage] = useState(interaction.transcriptTargetLanguage);
  const [selected, setSelected] = useState(selectedInput);
  const [pending, setPending] = useState([]);

  const transcriptLanguage = useMemo(
    () =>
      selected.includes("translated") && interaction.language && transcriptTargetLanguage
        ? `${getLanguageFromCode(interaction.language)} to ${getLanguageFromCode(transcriptTargetLanguage)}`
        : translationAvailable
        ? "Multiple language options"
        : "No options available in the Platform. Reach out to your project lead.",
    [selected, interaction.language, translationAvailable, transcriptTargetLanguage]
  );

  const getDescriptionText = () => {
    if (peerContentContributor && privateTranscriptContributor) {
      return "neither be eligible for the peer contribution discount, nor added to your organization's private library";
    } else if (peerContentContributor) {
      return "not be eligible for the peer contribution discount";
    } else {
      return "not be added to your organization's private library";
    }
  };

  const transcriptRequests = useMemo(() => recording?.transcriptRequests ?? [], [recording]);

  const transcriptForType = useCallback(
    (transcriptRequestType) => transcriptRequests.find((ta) => ta.transcriptType === transcriptRequestType),
    [transcriptRequests]
  );

  const transcriptExpired = useCallback((transcriptRequestType) => transcriptForType(transcriptRequestType)?.purgedAt, [
    transcriptForType,
  ]);

  const transcriptAvailable = useCallback(
    (transcriptRequestType) =>
      !transcriptExpired(transcriptRequestType) && transcriptForType(transcriptRequestType)?.completed,
    [transcriptExpired, transcriptForType]
  );

  const transcriptableUntilFor = useCallback(
    (type) => {
      if (!recording || TRANSCRIPT_WINDOWS[type] === undefined) return true;

      if (recording.purgedAt) return false;

      return isBefore(new Date(), subHours(parseISO(recording.expiresAt), TRANSCRIPT_WINDOWS[type]));
    },
    [recording]
  );

  const [transcriptRequestsStatus, setTranscriptRequestsStatus] = useState([]);

  useEffect(
    function loadTranscriptRequestsStatus() {
      if (transcriptRequests?.length > 0) {
        fetch({
          url: `/api/projects/${projectToken}/transcripts/status?transcriptIds=${transcriptRequests
            .map((tr) => tr.id)
            .join(",")}`,
          method: "GET",
        })
          .then((res) => res.json())
          .then((status) => {
            setTranscriptRequestsStatus(status);
          });
      } else {
        setTranscriptRequestsStatus([]);
      }
    },
    [projectToken, transcriptRequests]
  );

  const deliverableTypes = [];
  if (
    postInteraction &&
    recordedEnabled &&
    selected.includes("recorded") &&
    selected.includes("transcript_ai") &&
    !(
      (recordingExpired && !transcriptExpired("ai")) ||
      (!recordingExpired && transcriptExpired("ai")) ||
      (!recordingAvailable && transcriptAvailable("ai"))
    )
  ) {
    deliverableTypes.push({
      type: "transcript_and_recorded",
      name: "AI Transcript & Audio Recording",
      price: "Free",
      language: "English",
      icon: <AiTranscript />,
      disabledInPost: true,
      availableMessage: transcriptAvailable("ai")
        ? "Transcript and recording available"
        : "Recording available, transcript ready within 20 minutes",
      expiredMessage: "Transcript and recording expired",
      transcriptRequestType: "ai",
      downloadable: false,
      available: recordingAvailable,
      shareLink: true,
      deliveryTime: "Ready within 20 minutes",
      visible: true,
      recording: recording,
      expired: transcriptExpired("ai"),
    });
  } else {
    deliverableTypes.push({
      type: "recorded",
      name: "Audio Recording",
      price: "Free",
      language: interaction.language,
      icon: <AudioRecording />,
      disabled: true,
      visible: recordedEnabled && selected?.includes("recorded"),
      disabledInPost: true,
      availableMessage: "Recording available",
      expiredMessage: "Recording expired",
      downloadable: false,
      available: recordingAvailable,
      shareLink: true,
      deliveryTime: "Ready within 5 minutes | Expires after 21 days",
      recording: recording,
      expired: recordingExpired,
    });
    deliverableTypes.push({
      type: "transcript_ai",
      name: "AI Transcript",
      price: "Free",
      language: interaction.language,
      icon: <AiTranscript />,
      availableMessage: "Transcript available",
      expiredMessage: "Transcript expired",
      transcriptRequestType: "ai",
      downloadable: false,
      shareLink: true,
      visible: aiTranscriptEnabled && interaction.language === "en" && !transcriptAvailable("regular"),
      recording: recording,
      deliveryTime: "Ready within 20 minutes",
    });
  }

  deliverableTypes.push({
    type: "transcript",
    name: "Human Transcript",
    price: interaction.peerContentContributor
      ? ""
      : `+${accessPlan.transcriptRate} ${pluralize("credit", accessPlan.transcriptRate)}${
          accessPlan.transcriptChargeUnit === ChargeUnit.hour ? "/h" : ""
        }`,
    language: interaction.language,
    description: "Professionally transcribed",
    icon: <HumanTranscript />,
    availableMessage: "Transcript available",
    expiredMessage: "Transcript expired",
    transcriptRequestType: "regular",
    downloadable: true,
    shareLink: !deliverablesViewEnabled,
    deliveryTime: getTranscriptReadyWithinText("regular", interaction.language),
    visible: humanTranscriptEnabled,
    requireRemovalConfirmation: peerContentContributor || privateTranscriptContributor,
    removalConfirmationText: `A transcript of this call will not be created. As such, this call will ${getDescriptionText()}.`,
    disabled: !translationAvailable && selected.includes("translated"),
  });
  deliverableTypes.push({
    type: "translated",
    name: "Translated Transcript",
    language: transcriptLanguage,
    sourceLanguage: getLanguageFromCode(interaction.language),
    icon: <TranslatedTranscript />,
    availableMessage: "Transcript available via email",
    expiredMessage: "Transcript expired",
    transcriptRequestType: "regular",
    price: "+1 credit",
    downloadable: true,
    visible: humanTranscriptEnabled && !showMobileView,
    requireLanguageSelection: true,
    disabled: !translationAvailable,
    notAvailableOnPlatformMessage:
      "Translated Transcripts are unavailable on the Platform. Please check your email inbox.",
    languageNotAvailableMessage: "No options available in the Platform. Reach out to your project lead.",
    languages: languages.filter(
      (l) =>
        translatedTranscriptLanguages.includes(l.locale) &&
        l.locale !== interaction.language &&
        (interaction.language === "en" || l.locale === "en")
    ),
    innerBanner: <Alert variant="info">A human transcript is required alongside a translated transcript.</Alert>,
    indeterminateStateHint: getLanguageFromCode(interaction.language) + " to (select translated language)",
    dependsOn: "transcript",
  });

  let deliverables = deliverableTypes.map((d) => ({
    ...d,
    selected: selected?.includes(d.type),
    available: d.available ?? transcriptAvailable(d.transcriptRequestType),
    expired: d.expired ?? transcriptExpired(d.transcriptRequestType),
    visible: d.visible && (transcriptableUntilFor(d.type) || selected?.includes(d.type)),
    transcript: transcriptForType(d.transcriptRequestType),
    dependents: deliverableTypes.filter((dep) => dep.dependsOn === d.type).map((dep) => dep.type),
  }));

  deliverables = deliverables
    .filter((d) => d.visible)
    .filter((d) => {
      if (!d.dependsOn) return true;
      const dependency = deliverables.find((dep) => dep.type === d.dependsOn);
      return dependency && dependency.visible;
    });

  const showDeliverables = !duringInteraction || consentGiven;

  const hasAnyTranscriptPending = transcriptRequestsStatus?.some((trs) => {
    const transcriptRequest = transcriptRequests.find((tr) => tr.id === trs.transcriptId);
    const transcriptProcessingFinished = trs.status.some((s) => s.value === "FINISHED");
    return (
      transcriptRequest &&
      transcriptRequest.transcriptType !== "ai" &&
      !transcriptRequest.completed &&
      !transcriptRequest.purgedAt &&
      transcriptProcessingFinished
    );
  });

  const needsConsent = useMemo(
    () => ["recorded", "transcript", "transcript_ai", "translated"].some((upgrade) => selected.includes(upgrade)),
    [selected]
  );

  const deliverableConsentBanner = (
    <x.div {...bannerStyle}>
      {duringInteraction ? (
        <Alert variant="danger">The expert did not give consent to be recorded and/or transcribed.</Alert>
      ) : (
        <Alert variant="info">
          Audio Recordings & Transcripts require expert consent. Your project lead {getFirstName(projectLead.name)} is
          working on this.
        </Alert>
      )}
    </x.div>
  );

  const deliverablePendingBanner = (
    <x.div {...bannerStyle}>
      <Alert variant="warning">
        Your transcript(s) have been processed and are awaiting review from your internal compliance team.
      </Alert>
    </x.div>
  );

  const onAddUpgrade = (type, locale) => {
    if (type === "translated") {
      setTranscriptTargetLanguage(locale);
    }

    const deliverable = deliverables.find((d) => d.type === type);
    setSelected([...selected, deliverable.dependsOn, deliverable.type].filter(Boolean));
    setPending(pending.filter((p) => p !== deliverable.dependsOn));
    onAddUpgradeInput(type, locale);
  };

  const onRemoveUpgrade = (type) => {
    const deliverable = deliverables.find((d) => d.type === type);
    const dependents = deliverable.dependents ?? [];
    setSelected(selected.filter((e) => ![...dependents, deliverable.dependsOn, deliverable.type].includes(e)));

    onRemoveUpgradeInput(type);
  };

  if (!deliverables.length) {
    return null;
  }

  return (
    <x.div>
      <x.div {...updgradeTypeHeaderStyle} data-testid="deliverables-section">
        <Typography variant="body-large-em" color="secondary">
          Deliverables
        </Typography>
        {showDeliverables && (
          <InteractionUpgradesHelp>
            <DeliverablesInformation upgrades={deliverables.map((d) => d.type)} />
          </InteractionUpgradesHelp>
        )}
      </x.div>
      {!consentGiven && needsConsent && deliverableConsentBanner}
      {interaction.state === "completed" &&
        requiresComplianceApproval &&
        hasAnyTranscriptPending &&
        deliverablePendingBanner}
      {showDeliverables && (
        <List {...upgradeListStyle}>
          {deliverables.map((deliverable) => (
            <UpgradeItem
              key={`deliverable-${deliverable.type}`}
              postInteraction={postInteraction}
              interaction={interaction}
              transcriptRequests={transcriptRequests}
              recordingExpired={recordingExpired}
              onAddUpgrade={onAddUpgrade}
              onRemoveUpgrade={onRemoveUpgrade}
              selectedUpgrades={selected}
              pendingUpgrades={pending}
              setPendingUpgrades={setPending}
              deliverablesViewEnabled={deliverablesViewEnabled}
              projectToken={projectToken}
              {...deliverable}
            />
          ))}
        </List>
      )}
    </x.div>
  );
};

const Enhancements = ({
  upgrades,
  selected,
  duringInteraction,
  interaction,
  postInteraction,
  projectLead,
  termsAccepted,
  onAddUpgrade,
  onRemoveUpgrade,
  videoCallAvailable,
  blindedUpgradeAvailable,
  accessPlan,
}) => {
  const {
    updgradeTypeHeaderStyle,
    upgradeListStyle,
    upgradeDisabledListStyle,
    lockedEnhancementsTitleStyle,
    lockedEnhancementsDetailStyle,
    upgradeIconStyle,
  } = useInteractioUpgradesStyles({});

  const { project } = useCurrentProjectContext();

  const atLeastThreeHoursBeforeInteraction = isAtLeastHoursBeforeInteraction({
    scheduledCallTime: interaction.scheduledCallTime,
    hoursBefore: 3,
  });
  const enhancementTypes = [
    {
      type: "outsourced",
      name: "Consultant-led",
      description: `Further information available from project lead ${getFirstName(projectLead.name)}`,
      icon: <ConsultantLed />,
      disabled: true,
      visible: selected?.includes("outsourced"),
      available: true,
    },
    {
      type: "video_and_screen_share",
      name: "Video Call",
      price: "Free",
      icon: <VideoCall />,
      visible: videoCallAvailable,
      available: videoCallAvailable,
    },
    {
      type: "screen_sharing",
      name: "Screen Sharing",
      price: "Free",
      icon: <PresentToAll />,
      visible: project.screenSharingAvailable,
      available: project.screenSharingAvailable,
    },
    {
      type: "hosted",
      name: "Blinded",
      price: `+${accessPlan.hostedCallMultiplier} ${pluralize("credit", accessPlan.hostedCallMultiplier)}${
        accessPlan.hostedCallChargeUnit === ChargeUnit.hour ? "/h" : ""
      }`,
      description: "Identity not disclosed to expert",
      icon: <Blinded />,
      disabled: termsAccepted || upgrades.find((u) => u.value === "hosted")?.actionerType === "cst",
      visible: (!termsAccepted || selected?.includes("hosted")) && interaction.newspeakProvider !== "teams",
      available: blindedUpgradeAvailable,
    },
    {
      type: "interpreted",
      name: "Interpreted",
      price: `+${accessPlan.interpretedCallMultiplier} ${pluralize("credit", accessPlan.interpretedCallMultiplier)}${
        accessPlan.interpretedCallChargeUnit === ChargeUnit.hour ? "/h" : ""
      }`,
      language: interaction.needsInterpreter,
      icon: <Interpreted />,
      visible: isAtLeastHoursBeforeInteraction({
        scheduledCallTime: interaction.scheduledCallTime,
        hoursBefore: 48,
      }),
      disabled: !atLeastThreeHoursBeforeInteraction,
      requireLanguageSelection: true,
      selectedLanguage: interaction.needsInterpreter,
      requireRemovalConfirmation: true,
      languages: languages,
      removalConfirmationText: `By removing the interpreter upgrade, you are confirming that you or a colleague can conduct the call in ${
        getLanguageFromCode(interaction.needsInterpreter) ?? "the expert's language"
      }.`,
      banner: atLeastThreeHoursBeforeInteraction ? (
        interaction.needsInterpreter && (
          <Alert variant="info">
            {getLanguageFromCode(interaction.language)} - {getLanguageFromCode(interaction.needsInterpreter)}{" "}
            interpreter required for this expert.
          </Alert>
        )
      ) : (
        <Alert variant="info">
          You can no longer change this interpreter option - contact your project lead {getFirstName(projectLead.name)}{" "}
          for any further queries.
        </Alert>
      ),
      available: true,
    },
  ];

  const enhancements = enhancementTypes
    .map((d) => {
      return { ...d, selected: selected?.includes(d.type) };
    })
    .filter((d) => d.available && (d.selected || d.visible));

  return (
    <x.div>
      <x.div {...updgradeTypeHeaderStyle}>
        <Typography variant="body-large-em" color="secondary">
          Enhancements
        </Typography>
        {!duringInteraction && !postInteraction && (
          <InteractionUpgradesHelp>
            <EnhancementsInformation upgrades={enhancements.map((d) => d.type)} />
          </InteractionUpgradesHelp>
        )}
      </x.div>
      {!duringInteraction && !postInteraction ? (
        <List {...upgradeListStyle}>
          {enhancements.map((enhancement) => (
            <UpgradeItem
              key={`enhancement-${enhancement.type}`}
              interaction={interaction}
              postInteraction={postInteraction}
              onAddUpgrade={onAddUpgrade}
              onRemoveUpgrade={onRemoveUpgrade}
              {...enhancement}
            />
          ))}
        </List>
      ) : (
        <x.div {...upgradeDisabledListStyle}>
          <Icon {...upgradeIconStyle}>
            <AddCall />
          </Icon>
          <x.div>
            <Typography {...lockedEnhancementsTitleStyle}>Interaction enhancements</Typography>
            <Typography variant="body-small" {...lockedEnhancementsDetailStyle}>
              {enhancements.filter((e) => e.selected).length > 0
                ? enhancements
                    .filter((e) => e.selected)
                    .map((e) => e.name)
                    .join(" • ")
                : "None"}
            </Typography>
          </x.div>
        </x.div>
      )}
    </x.div>
  );
};

const InteractionUpgradesHelp = ({ children }) => {
  const { isMobile } = useCheckScreen();
  const { helpButtonStyle, popoverStyle } = useInteractioUpgradesStyles();

  const [anchorEl, setAnchorEl] = useState();
  const ref = useRef();

  const handlePopoverOpen = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton
        variant="ghost"
        onMouseEnter={!isMobile && handlePopoverOpen}
        onClick={isMobile && ((event) => (anchorEl ? handlePopoverClose() : handlePopoverOpen(event)))}
        {...helpButtonStyle}
      >
        <Info />
      </IconButton>
      <Popover
        ref={ref}
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        placement="bottom"
        closeOnMouseLeave={!isMobile}
        {...popoverStyle}
      >
        {children}
      </Popover>
    </>
  );
};

const Title = () => (
  <Typography component="span" variant="body-large-em">
    Interaction Upgrades
  </Typography>
);

const AccordionItemWithLoginWall = withLoginWall(AccordionItem);
