import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMessengerContext } from "providers/MessengerProvider";
import { MessageType } from "types";
import { useAlphaToast } from "@alphasights/alphadesign-components";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { ENABLE_LEGACY_BCG_CLARIFICATIONS, useProjectBadgeContext } from "providers/BadgeProvider";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { NewMessagePanel } from "components/NewMessagePanel/NewMessagePanel";

export const NewMessageBody = ({
  sendMessageButtonRef,
  mobileVariant = false,
  onMessageSent,
  attachTextBoxToNavbar = false,
  selectedExpertId,
  onMobileTextBoxHeightChange,
}: {
  sendMessageButtonRef?: React.MutableRefObject<HTMLButtonElement | null>;
  mobileVariant?: boolean;
  onMessageSent?: () => void;
  attachTextBoxToNavbar?: boolean;
  selectedExpertId?: string;
  onMobileTextBoxHeightChange?: (newHeight: number) => void;
}) => {
  const {
    newMessageType,
    onSendNewMessage,
    experts,
    onSubmitMessage,
    projectToken,
    maximumTaskDuration,
    setMaximumTaskDuration,
    selectedExperts,
    setSelectedExperts,
    onSendNewMessageFromTableView,
  } = useMessengerContext();
  const { toast } = useAlphaToast();
  const [messageText, setMessageText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { hasProjectBadge } = useProjectBadgeContext();
  const enableLegacyBCGClarifications = hasProjectBadge(ENABLE_LEGACY_BCG_CLARIFICATIONS);
  const { bulkLogHit } = useTrackUserAction();

  const handleSubmit = useCallback(
    (params: any, attachment = null) => {
      setIsLoading(true);
      return onSubmitMessage(params, attachment)
        .then(() => {
          bulkLogHit(
            selectedExperts.map((expert) => ({
              origin: HitOrigin.messagesPage,
              action:
                newMessageType === MessageType.Clarification
                  ? HitAction.submitClarification
                  : newMessageType === MessageType.CallGuide
                  ? HitAction.submitCallGuide
                  : HitAction.submitWorkRequest,
              advisorshipId: expert.interaction.id,
              projectToken: projectToken,
            }))
          );
          toast.success({
            message: `${newMessageType} sent to ${selectedExperts.length} expert${
              selectedExperts.length > 1 ? "s" : ""
            }.`,
          });
        })
        .finally(() => setIsLoading(false));
    },
    [newMessageType, onSubmitMessage, projectToken, selectedExperts, toast, bulkLogHit]
  );

  const isBlinded = useMemo(() => selectedExperts.some((expert) => expert.hostedInteraction === true), [
    selectedExperts,
  ]);

  useEffect(() => {
    if (selectedExpertId) {
      const expert = experts.find((expert) => expert.id === selectedExpertId);
      if (expert) {
        setSelectedExperts([expert]);
      }
    }
  }, [selectedExpertId, experts, setSelectedExperts]);

  if (!newMessageType) return null;

  const handelClickExternalLink = () => {
    onSendNewMessageFromTableView(selectedExperts, newMessageType);
  };

  return (
    <NewMessagePanel
      enableLegacyBCGClarifications={enableLegacyBCGClarifications}
      onChangeMessageType={(messageType: MessageType) => onSendNewMessage(messageType)}
      switchToWorkRequest={() => onSendNewMessage(MessageType.WorkRequest)}
      newMessageType={newMessageType}
      experts={experts}
      selectedExperts={selectedExperts}
      setSelectedExperts={setSelectedExperts}
      handleSubmit={handleSubmit}
      messageText={messageText}
      setMessageText={setMessageText}
      isLoading={isLoading}
      blinded={isBlinded}
      sendMessageButtonRef={sendMessageButtonRef}
      setMaximumTaskDuration={setMaximumTaskDuration}
      showInfoBanner={false}
      showNonElegibleExperts={false}
      maximumTaskDuration={maximumTaskDuration}
      externalLinkLabel={!mobileVariant ? "Select from table view" : ""}
      onClickExternalLink={handelClickExternalLink}
      mobileVariant={mobileVariant}
      onMessageSent={onMessageSent}
      attachTextBoxToNavbar={attachTextBoxToNavbar}
      onMobileTextBoxHeightChange={onMobileTextBoxHeightChange}
    />
  );
};
