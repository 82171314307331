import React, { FC, useState, useEffect } from "react";
import { useQueryClient, useMutation, useQuery } from "query-utils";
import {
  Alert,
  Button,
  Typography,
  TextField,
  useThemeTokens,
  Drawer,
  Modal,
  RadioButton,
  RadioGroup,
  useAlphaToast,
  ToastContainer,
  ComponentSize,
} from "@alphasights/alphadesign-components";
import { x } from "@xstyled/styled-components";

import { default as useLocationQuery } from "hooks/useQuery";
import {
  accessCompanyPrimer,
  accessNow,
  accessTranscript,
  availableAccessMessage,
  cancel,
  confirmRequest,
  optionalCaseCode,
  requestAccessMessage,
  callHasAlreadyBegun,
  requestAccess,
  credit,
  purchasedWithinOrganization,
  transcriptSelectedAlert,
  transcriptAndDialInSelectedAlert,
  accessMarketPrimer,
  accessCustomerPrimer,
  transcriptOnlyRequestAccessMessage,
} from "content/AlphaNow";
import {
  confirmMarketPrimerPurchase,
  confirmCompanyPrimerPurchase,
  confirmTranscriptPurchase,
  confirmUpcomingPurchase,
  confirmCustomerPrimerPurchase,
} from "content/AlphaNow/utils";
import { useTrackUserAction } from "@alphasights/client-portal-shared";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import {
  AlphaNowContentType,
  AlphaNowProductType,
  ContentPurchaseStatus,
  ContentStatus,
  CONTENT_TYPE,
} from "@alphasights/client-portal-shared";
import { HitAction, HitOrigin } from "@alphasights/portal-api-client";
import { contentService } from "services/content";
import { ContentApprovalStatus } from "models/AlphaShield";
import { ContentAccessText } from "constants/AlphaShield";
import { isContentAccessible, passedPayment } from "pages/AlphaNowPage/utils/isContentAccessible";
import pluralize from "pluralize";
import { useIsInternalUser } from "@alphasights/portal-auth-react";
import { UpcomingContentAccessRequest, UPCOMING_REQUEST_DISPLAY_NAME, CONTENT_REQUEST } from "constants/AlphaNow";
import { noop } from "lodash";
import { useRealTimeCreditBalanceContext } from "providers/RealTimeCreditBalanceProvider";

export const DataTestIds = {
  confirmAccessContentButton: "confirm-access-content-button",
  accessContentButton: "access-content-button",
  accessContentDrawer: "access-drawer-container",
  accessContentModal: "access-modal-container",
};

interface logHitActionsMap {
  purchaseContentAttempt: HitAction;
  purchaseContent: HitAction;
}

const logHitActions: Record<string, logHitActionsMap> = {
  [AlphaNowContentType.alphaview]: {
    purchaseContentAttempt: HitAction.alphaNowPurchaseTranscriptAttempt,
    purchaseContent: HitAction.alphaNowPurchaseTranscript,
  },
  [AlphaNowContentType.pcc]: {
    purchaseContentAttempt: HitAction.alphaNowPurchaseTranscriptAttempt,
    purchaseContent: HitAction.alphaNowPurchaseTranscript,
  },
  [AlphaNowProductType.companyPrimer]: {
    purchaseContentAttempt: HitAction.alphaNowPurchaseCompanyPrimerAttempt,
    purchaseContent: HitAction.alphaNowPurchaseCompanyPrimer,
  },
  [AlphaNowProductType.marketPrimer]: {
    purchaseContentAttempt: HitAction.alphaNowPurchaseMarketPrimerAttempt,
    purchaseContent: HitAction.alphaNowPurchaseMarketPrimer,
  },
  [AlphaNowProductType.customerPrimer]: {
    purchaseContentAttempt: HitAction.alphaNowPurchaseCustomerPrimerAttempt,
    purchaseContent: HitAction.alphaNowPurchaseCustomerPrimer,
  },
};

interface PurchaseContentRequestBody {
  caseCode: string;
  contentId: string;
  projectToken?: string;
}

interface PurchaseUpcomingContentRequestBody {
  caseCode: string;
  contentId: string;
  requestType: string;
  projectToken?: string;
}

interface Content {
  id: string;
  contentType: string;
  upcomingContentAccessRequest?: string;
  status?: string;
  productType?: string;
  recommendationId?: string;
  canDialIn?: boolean;
}

interface AlphaNowPurchaseModalProps {
  content: Content;
  contentTitle: string;
  price?: number;
  isContentPreScheduledTime?: boolean;
  isUpcomingTransitionActive?: boolean;
  contentApprovalStatus: ContentApprovalStatus;
  contentPurchaseStatus: ContentPurchaseStatus;
  onError: (error: string) => void;
  onPurchaseSuccess: (params: OnPurchasedContentChangedParam) => void;
  setPrimerStatus?: (value: string) => void;
  projectToken?: string;
  origin?: HitOrigin;
  handleButtonSize?: ComponentSize;
  onRequestAccessSuccess?: () => void;
}

const PurchaseModalContent = ({
  caseCode,
  setCaseCode,
  confirmationMessage,
  isUpcomingTransitionActive,
}: {
  caseCode: string;
  setCaseCode: (value: string) => void;
  confirmationMessage: React.ReactNode;
  isUpcomingTransitionActive: boolean;
}) => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  return (
    <>
      <Typography mb={inner.base05} component="p" variant="body">
        {confirmationMessage}
      </Typography>
      <TextField
        value={caseCode}
        label={optionalCaseCode}
        placeholder="Input text"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCaseCode(e.target.value)}
      />
      {isUpcomingTransitionActive && (
        <x.div mt={inner.base05}>
          <Alert variant="warning" size="small">
            {callHasAlreadyBegun}
          </Alert>
        </x.div>
      )}
    </>
  );
};

const AlphaNowPurchaseModal: FC<AlphaNowPurchaseModalProps> = ({
  content,
  contentTitle,
  price,
  isUpcomingTransitionActive = false,
  isContentPreScheduledTime = false,
  contentApprovalStatus,
  contentPurchaseStatus,
  onError,
  onPurchaseSuccess,
  setPrimerStatus = () => {},
  projectToken,
  origin = HitOrigin.alphaNow,
  handleButtonSize = "medium",
  onRequestAccessSuccess = noop,
}) => {
  const internalUser = useIsInternalUser();
  const query = useLocationQuery();
  const queryClient = useQueryClient();
  const { logHit } = useTrackUserAction();
  const { isMobile } = useCheckScreen();
  const {
    spacing: { inner },
  } = useThemeTokens();
  const { toast } = useAlphaToast();
  const { updateCreditBalance } = useRealTimeCreditBalanceContext();

  const [caseCode, setCaseCode] = useState("");
  const [trackingId, setTrackingId] = useState<string | null>(null);
  const [fragmentIds, setFragmentIds] = useState<string | null>();
  const [referrer, setReferrer] = useState<string | null>();
  const [open, setOpen] = useState(false);
  const [upcomingRequest, setUpcomingRequest] = useState<string>(CONTENT_REQUEST.dialInAndTranscript);

  const { id: contentId, contentType, upcomingContentAccessRequest, status, productType, recommendationId } = content;
  const isAccessible = isContentAccessible(contentPurchaseStatus, contentApprovalStatus);
  const isUpcoming = status === ContentStatus.upcoming;
  const published = status === ContentStatus.published;
  const joinableUpcomingContent = isContentPreScheduledTime && !isUpcomingTransitionActive;
  const contentPassedPayment = passedPayment(contentPurchaseStatus);

  const upcomingContentPurchasedWithinOrganization =
    contentPassedPayment && upcomingContentAccessRequest === UpcomingContentAccessRequest.REQUIRED;
  const isCompanyPrimer = productType === AlphaNowProductType.companyPrimer;
  const isMarketPrimer = productType === AlphaNowProductType.marketPrimer;
  const isCustomerPrimer = productType === AlphaNowProductType.customerPrimer;

  const dialInDisabled = content.canDialIn !== true;

  useEffect(() => {
    const upcomingRequestType =
      dialInDisabled || isUpcomingTransitionActive || !isContentPreScheduledTime
        ? CONTENT_REQUEST.transcript
        : CONTENT_REQUEST.dialInAndTranscript;

    setUpcomingRequest(upcomingRequestType);
  }, [isUpcomingTransitionActive, isContentPreScheduledTime, dialInDisabled]);

  useEffect(() => {
    const trackingId = recommendationId || query.get("tracking-id") || "";
    setTrackingId(trackingId);

    const referrer = query.get("referrer");
    setReferrer(referrer);

    const fragmentIds = query.get("fragmentIds");
    setFragmentIds(fragmentIds);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePurchaseSuccess = () => {
    queryClient.invalidateQueries(["content"]);
    updateCreditBalance();
    onPurchaseSuccess({ contentId, isPurchased: true });
  };

  const handlePurchaseError = (e: any) => {
    // The error handling is done here rather than on the content service because the fetch api doesn't return errors with codes 4xx 5xx
    // Catching the error with the fetch api and returning the json() object of the error gets treated as "successful" by useQuery
    // We need to avoid catching the error so that it gets treated as an error by useQuery
    // @ts-ignore
    e?.type ? e.json().then((json: any) => onError(json?.type)) : onError(e);
  };

  const { refetch: requestApproval, isLoading: isRequestingApproval } = useQuery(
    ["requestApproval", contentId],
    () => contentService.requestAlphaShieldApproval({ caseCode, contentId, contentTitle, projectToken }),
    {
      enabled: false,
      onSuccess: () => {
        queryClient.invalidateQueries(["content"]);
        onRequestAccessSuccess();
      },
      onError: handlePurchaseError,
    }
  );

  const purchaseContentMutation = useMutation(
    // TODO - make this reusable when company primers has added the ability to purchase
    (requestBody: PurchaseContentRequestBody) => {
      // @ts-ignore
      return contentService.purchaseTranscript(requestBody, trackingId);
    },
    {
      onSuccess: handlePurchaseSuccess,
      onError: handlePurchaseError,
    }
  );
  const purchaseUpcomingContentMutation = useMutation(
    (requestBody: PurchaseUpcomingContentRequestBody) => {
      // @ts-ignore
      return contentService.purchaseUpcomingAlphaView(requestBody, trackingId);
    },
    {
      onSuccess: () => {
        handlePurchaseSuccess();
        toast.success({ message: getUpcomingContentPurchaseAlert() });
      },
      onError: (e) => {
        handlePurchaseError(e);
      },
    }
  );

  useEffect(() => {
    if (contentType === CONTENT_TYPE.companyPrimer) return setPrimerStatus(purchaseContentMutation.status);
  }, [purchaseContentMutation.status]); // eslint-disable-line react-hooks/exhaustive-deps

  const purchaseAlphaNowContent = () => {
    logHit({
      origin,
      action:
        contentType === AlphaNowContentType.srm
          ? logHitActions[productType ?? ""].purchaseContent
          : logHitActions[contentType].purchaseContent,
      details: {
        contentId,
        status,
        requestType: isUpcoming ? upcomingRequest : CONTENT_REQUEST.transcript,
        ...(internalUser && {
          internalUser,
        }),
        ...(fragmentIds && { fragmentIds }),
        referrer: referrer,
        projectToken,
      },
    });

    return isUpcoming
      ? purchaseUpcomingContentMutation.mutate({ caseCode, contentId, requestType: upcomingRequest, projectToken })
      : purchaseContentMutation.mutate({ caseCode, contentId, projectToken });
  };

  const getHeading = (): string => {
    if (contentApprovalStatus === ContentApprovalStatus.ApprovalRequired || isContentPreScheduledTime) {
      return requestAccess;
    }

    if (isCompanyPrimer) return accessCompanyPrimer;
    else if (isMarketPrimer) return accessMarketPrimer;
    else if (isCustomerPrimer) return accessCustomerPrimer;

    return accessTranscript;
  };

  const getUpcomingContentPurchaseAlert = (): string => {
    switch (upcomingRequest) {
      case CONTENT_REQUEST.transcript:
        return transcriptSelectedAlert;
      default:
        return transcriptAndDialInSelectedAlert;
    }
  };

  const getConfirmationMessage = () => {
    const cost = pluralize(credit, price, true);

    const purchaseMessage = upcomingContentPurchasedWithinOrganization
      ? purchasedWithinOrganization
      : confirmUpcomingPurchase(price);

    let approvalRequiredMessagePayg;
    let approvalRequiredMessagePremium;

    if (isCompanyPrimer) {
      approvalRequiredMessagePayg = ContentAccessText.ApprovalRequiredModalDetailsPaygPrimer;
      approvalRequiredMessagePremium = ContentAccessText.ApprovalRequiredModalDetailsPremiumPrimer;
    } else if (isMarketPrimer) {
      approvalRequiredMessagePayg = ContentAccessText.ApprovalRequiredModalDetailsPaygMarketPrimer;
      approvalRequiredMessagePremium = ContentAccessText.ApprovalRequiredModalDetailsPremiumMarketPrimer;
    } else if (isCustomerPrimer) {
      approvalRequiredMessagePayg = ContentAccessText.ApprovalRequiredModalDetailsPaygCustomerPrimer;
      approvalRequiredMessagePremium = ContentAccessText.ApprovalRequiredModalDetailsPremiumCustomerPrimer;
    }
    //transcript
    else {
      approvalRequiredMessagePayg = ContentAccessText.ApprovalRequiredModalDetailsPayg;
      approvalRequiredMessagePremium = ContentAccessText.ApprovalRequiredModalDetailsPremium;
    }

    if (contentApprovalStatus === ContentApprovalStatus.ApprovalRequired) {
      return contentPassedPayment ? (
        approvalRequiredMessagePremium
      ) : (
        <>
          <Typography>{approvalRequiredMessagePayg}</Typography>
          <Typography>{`${ContentAccessText.ApprovalRequiredDetails} ${cost}.`}</Typography>
        </>
      );
    }

    if (isCompanyPrimer) {
      return confirmCompanyPrimerPurchase(price);
    } else if (isMarketPrimer) {
      return confirmMarketPrimerPurchase(price);
    } else if (isCustomerPrimer) {
      return confirmCustomerPrimerPurchase(price);
    }

    if (joinableUpcomingContent) {
      return (
        <>
          <x.div spaceY={inner.base05}>
            <Typography>{purchaseMessage}</Typography>
            <Typography>{dialInDisabled ? transcriptOnlyRequestAccessMessage : requestAccessMessage}</Typography>
          </x.div>
          {!dialInDisabled && (
            <x.div mt={inner.base05}>
              <RadioGroup
                name="request-upcoming-workflow"
                value={CONTENT_REQUEST.dialInAndTranscript}
                label="Select preference:"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUpcomingRequest(event.target.value)}
              >
                {Object.values(CONTENT_REQUEST).map((value) => (
                  <RadioButton value={value}>{UPCOMING_REQUEST_DISPLAY_NAME[value]}</RadioButton>
                ))}
              </RadioGroup>
            </x.div>
          )}
        </>
      );
    }

    if (!published) {
      return (
        <x.div spaceY={inner.base05}>
          <Typography>{purchaseMessage}</Typography>
          <Typography>{availableAccessMessage}</Typography>
        </x.div>
      );
    }

    return confirmTranscriptPurchase(price);
  };

  const getConfirmButtonText = () =>
    contentApprovalStatus === ContentApprovalStatus.ApprovalRequired || !published ? confirmRequest : accessNow;

  const heading = getHeading();
  const confirmationMessage = getConfirmationMessage();
  const confirmButtonText = getConfirmButtonText();

  const handleClosePurchaseModal = () => {
    setOpen(false);
  };

  const handlePurchaseContent = async () => {
    try {
      await (contentApprovalStatus === ContentApprovalStatus.ApprovalRequired
        ? handleRequestApproval()
        : purchaseAlphaNowContent());
    } finally {
      handleClosePurchaseModal();
    }
  };

  const handleRequestApproval = () => {
    logHit({
      origin,
      action: HitAction.alphaNowRequestComplianceApproval,
      details: {
        contentId,
        status,
        // @ts-ignore
        ...(internalUser && {
          // @ts-ignore
          internalUser,
        }),
        projectToken,
      },
    });
    return requestApproval();
  };

  const accessContentButton = () => {
    const isAccessContentButtonDisabled = price === undefined;
    const isAccessContentButtonVisible =
      contentApprovalStatus !== ContentApprovalStatus.ApprovalRequested &&
      contentApprovalStatus !== ContentApprovalStatus.Declined &&
      (upcomingContentPurchasedWithinOrganization || !isAccessible);

    const accessContentButtonText = (): string => {
      if (contentApprovalStatus === ContentApprovalStatus.ApprovalRequired || !published) {
        return requestAccess;
      }
      return accessNow;
    };

    const handleOpenModal = () => {
      setOpen(true);
      logHit({
        origin,
        action: logHitActions[contentType].purchaseContentAttempt,
        details: {
          contentId,
          status,
          projectToken,
          // @ts-ignore
          ...(internalUser && {
            // @ts-ignore
            internalUser,
          }),
          ...(fragmentIds && { fragmentIds }),
        },
      });
    };

    if (isAccessContentButtonVisible)
      return (
        <Button
          ml={{ xs: 0, sm: "auto" }}
          mt={{ xs: inner.base06, sm: 0 }}
          w={{ xs: "100%", sm: "auto" }}
          onClick={handleOpenModal}
          variant="primary"
          disabled={isAccessContentButtonDisabled}
          data-testid={DataTestIds.accessContentButton}
          size={handleButtonSize}
          whiteSpace="nowrap"
        >
          {accessContentButtonText()}
        </Button>
      );
  };

  if (isMobile) {
    const drawerProps = {
      open,
      title: heading,
      onClose: handleClosePurchaseModal,
      primaryButtonProps: {
        children: confirmButtonText,
        onClick: handlePurchaseContent,
        loading: isRequestingApproval || purchaseContentMutation.isLoading || purchaseUpcomingContentMutation.isLoading,
      },
      secondaryButtonProps: {
        children: cancel,
        onClick: handleClosePurchaseModal,
      },
    };

    return (
      <x.div w="100%">
        {accessContentButton()}
        <Drawer
          variant="complex"
          {...drawerProps}
          data-testid={DataTestIds.accessContentDrawer}
          id="access-content-drawer"
        >
          <PurchaseModalContent
            caseCode={caseCode}
            setCaseCode={setCaseCode}
            confirmationMessage={confirmationMessage}
            isUpcomingTransitionActive={isUpcomingTransitionActive}
          />
        </Drawer>
        <ToastContainer />
      </x.div>
    );
  }

  return (
    <>
      {accessContentButton()}
      <Modal
        data-testid={DataTestIds.accessContentModal}
        title={<x.span textTransform="none">{heading}</x.span>}
        open={open}
        onClose={handleClosePurchaseModal}
        slotWidth="400px"
        slotHeight="unset"
        variant="complex"
        shouldShowFooter={true}
        primaryButton={
          <Button
            data-testid={DataTestIds.confirmAccessContentButton}
            variant="secondary"
            onClick={handlePurchaseContent}
            loading={
              isRequestingApproval || purchaseContentMutation.isLoading || purchaseUpcomingContentMutation.isLoading
            }
          >
            {confirmButtonText}
          </Button>
        }
        secondaryButton={
          <Button marginRight={inner.base04} variant="ghost" onClick={handleClosePurchaseModal}>
            Cancel
          </Button>
        }
      >
        <PurchaseModalContent
          caseCode={caseCode}
          setCaseCode={setCaseCode}
          confirmationMessage={confirmationMessage}
          isUpcomingTransitionActive={isUpcomingTransitionActive}
        />
      </Modal>
      <ToastContainer />
    </>
  );
};

export default AlphaNowPurchaseModal;
