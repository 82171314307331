import React, { useCallback, useEffect, useRef, useState } from "react";
import { Logo, LogoVariation } from "@alphasights/alphadesign-components";
import { GenerativeAi, Library } from "@alphasights/alphadesign-icons";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { UserNotificationsProvider } from "components/SidebarNav/NotificationsOption/UserNotificationsContextProvider";
import { VIEW_ALPHA_GPT_PERMISSION, VIEW_CONTENT_PERMISSION } from "constants/AlphaNow";
import { AvatarOption } from "./AvatarOption";
import { NotificationsOption } from "./NotificationsOption";
import * as S from "./SidebarNav.styled";
import { SidebarOption } from "./SidebarOption";
import { ProjectsOption } from "./ProjectsOption/ProjectsOption";
import { useEnv } from "@alphasights/client-portal-shared";
import { useLocation } from "router-utils";
import { myAlphaSightsBaseUrl } from "helpers/modulesHelpers";
import { IntercomLauncher } from "./IntercomOption/IntercomOption";
import { SearchOption } from "./SearchOption/SearchOption";

export const SidebarNavContent = () => {
  const currentUser = useCurrentUser();
  const isInternalUser = !!currentUser?.internalUser;
  const [isSelected, setIsSelected] = useState(false);
  const [isIntercomOpen, setIsIntercomOpen] = useState(false);
  const env = useEnv();
  const location = useLocation();
  const previousLocation = useRef(location);

  const memoizedSetIsSelected = useCallback((value: boolean) => {
    setIsSelected(value);
  }, []);

  useEffect(() => {
    if (previousLocation.current !== location && isIntercomOpen) {
      window.Intercom("hide");
      setIsIntercomOpen(false);
    }
    previousLocation.current = location;
  }, [location, isIntercomOpen]);

  const projectsPath = `/${myAlphaSightsBaseUrl}`;

  return (
    <S.SidebarNavWrapper isInternalUser={isInternalUser}>
      <S.TopContainer>
        <S.NavLogoContainer>
          <Logo data-testid="vertical-nav-logo" variation={LogoVariation.Light} size="xxs" />
        </S.NavLogoContainer>

        <S.OptionsContainer>
          <SearchOption />

          {currentUser?.alphaNowEnabled && (
            <SidebarOption
              id="library"
              to="/alphanow"
              permission={VIEW_CONTENT_PERMISSION}
              label="Library"
              icon={<Library />}
            />
          )}

          {!currentUser?.accessOnly && <ProjectsOption projectsPath={projectsPath} />}

          {currentUser?.alphaGPTEnabled && (
            <SidebarOption
              id="alphagpt"
              to="/alphagpt"
              permission={VIEW_ALPHA_GPT_PERMISSION}
              label="AlphaGPT"
              icon={<GenerativeAi />}
            />
          )}
        </S.OptionsContainer>
      </S.TopContainer>

      <S.BottomContainer>
        <>
          {currentUser && (
            <>
              {env?.enableIntercom && (
                <IntercomLauncher
                  isIntercomOpen={isIntercomOpen}
                  toggleIntercom={() => setIsIntercomOpen(!isIntercomOpen)}
                />
              )}
              <NotificationsOption isSelected={isSelected} setIsSelected={memoizedSetIsSelected} />
            </>
          )}
        </>
        <AvatarOption />
      </S.BottomContainer>
    </S.SidebarNavWrapper>
  );
};

export const SidebarNav = () => {
  const currentUser = useCurrentUser();
  return (
    <UserNotificationsProvider enabled={!!currentUser}>
      <SidebarNavContent />
    </UserNotificationsProvider>
  );
};
