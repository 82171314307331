import React, { useContext } from "react";

type ProjectMoreOptionsState = {
  onOpenDownloads?: () => void;
  onOpenCostOverview?: () => void;
  onOpenProjectSettings?: () => void;
  onChangeLayout?: (layout: string) => void;
  showExportLinks?: boolean;
  showCidOnSurveyView?: boolean;
  performSearchSync?: () => void;
  currentView: string;
};

export type ProjectMoreOptionsProviderProps = ProjectMoreOptionsState & {
  children: React.ReactNode;
};

const ProjectMoreOptionsContext = React.createContext<ProjectMoreOptionsState | null>(null);

export const ProjectMoreOptionsProvider = ({ children, ...props }: ProjectMoreOptionsProviderProps) => (
  <ProjectMoreOptionsContext.Provider value={props}>{children}</ProjectMoreOptionsContext.Provider>
);

export const useProjectMoreOptionsContext = () => {
  const context = useContext(ProjectMoreOptionsContext);

  if (!context) throw new Error("ProjectMoreOptionsContext should only be used within the ProjectMoreOptionsProvider");

  return context;
};
