import { useThemeTokens } from "@alphasights/alphadesign-components";

export const useKpcChartStyles = () => {
  const { spacing } = useThemeTokens();
  return {
    popoverContentWrapper: {
      maxH: "274px",
      w: "260px",
      overflow: "auto",
    },
    popoverItemWrapper: {
      display: "flex",
      justifyContent: "space-between",
      p: spacing.inner.base02,
      alignItems: "center",
    },
    iconGroup: {
      display: "flex",
      alignItems: "center",
      gap: spacing.inner.base02,
    },
  };
};
