import React from "react";
import CardStack from "pages/AlphaNowPage/components/CardStack";
import CompanyPageCard from "pages/AlphaNowPage/components/CompanyPageCard";

const CompanyPageCardStack = ({
  title,
  companies,
  onClick,
  selectedCompanyId,
}: {
  title: string;
  companies: { name: string; logo: string; cdsAlphaCompanyId: number }[];
  onClick: (cdsAlphaCompanyId: number) => void;
  selectedCompanyId: number;
}) => {
  return (
    <CardStack title={title}>
      {companies.map(({ name, logo, cdsAlphaCompanyId }) => {
        const isSelected = selectedCompanyId === cdsAlphaCompanyId;
        return (
          <CompanyPageCard
            key={name}
            companyName={name}
            companyLogo={logo}
            onClick={() => onClick(cdsAlphaCompanyId)}
            isSelected={isSelected}
          />
        );
      })}
    </CardStack>
  );
};

export { CompanyPageCardStack as default };
