import React from "react";
import { x } from "@xstyled/styled-components";
import { Pill, PillVariants, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import { useCustomerPrimersStore } from "pages/AlphaNowPage/primers/CustomerPrimer/state";
import { useGetCompanyProperties } from "pages/AlphaNowPage/primers/hooks";

type YAxisProps = {
  vendorsAndScores: { companyName: string; score: number }[];
  selectedVendor: string;
  onVendorClick: (companyName: string) => void;
};

const getScoreColor = (score: number): PillVariants => {
  if (score > 0) return "green";
  if (score < 0) return "red";
  return "light";
};

const YAxis = ({ selectedVendor, vendorsAndScores, onVendorClick }: YAxisProps) => {
  const { color, icons, spacing, typography } = useThemeTokens();
  const companiesV2 = useCustomerPrimersStore(({ companiesV2 }) => companiesV2);
  const { getCompanyProperties } = useGetCompanyProperties([], companiesV2);
  const nameMaxLenght = 15;

  return (
    <x.div flex="0 0 auto" paddingTop="30px" display="flex" flexDirection="column">
      <x.div
        {...typography.body.small}
        fontWeight="400"
        textAlign="right"
        h={spacing.inner.base08}
        color={color.text.strong._}
      >
        Score
      </x.div>
      {vendorsAndScores.map(({ companyName, score }) => (
        <x.div
          key={companyName}
          h="51px"
          w="100%"
          alignItems="center"
          display="flex"
          justifyContent="right"
          columnGap={spacing.inner.base02}
          {...typography.body.small}
          onClick={() => onVendorClick(companyName)}
          opacity={selectedVendor === companyName ? 1 : 0.2}
        >
          <PrimerCompanyLogo size={icons.size.lg} src={getCompanyProperties(companyName).logoLink} />
          {companyName.length > nameMaxLenght ? (
            <Tooltip title={companyName} variant="dark">
              {`${companyName.substring(0, nameMaxLenght)}...`}
            </Tooltip>
          ) : (
            <Typography variant="body-small" color={color.text.strong._}>
              {companyName}
            </Typography>
          )}
          <Pill size="small" variant={getScoreColor(score)}>
            {score}
          </Pill>
        </x.div>
      ))}
    </x.div>
  );
};

export default YAxis;
