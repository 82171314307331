import styled from "@xstyled/styled-components";
import { useThemeTokens } from "@alphasights/alphadesign-components";

const CardHeader = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: ${spacing.inner.base02};
  `;
});

const TextWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return `
    display: flex;
    flex-direction: column;
    gap: ${spacing.inner.base};
  `;
});

export { CardHeader, TextWrapper };
