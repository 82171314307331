import React from "react";
import { x } from "@xstyled/styled-components";
import { Alert, Icon, Loading } from "@alphasights/alphadesign-components";
import { QueryStatus as QueryStatusType } from "query-utils";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { GenericMessagePage } from "views/DeliverablesView/DeliverablesPage/GenericMessagePage";
import { useHideDeliverablesContent } from "views/DeliverablesView/DeliverablesPage/useHideDeliverablesContent";
import { ThirdPartyInteraction } from "types";
import {
  ERROR_GENERATING_QUESTION_VIEW_MESSAGE,
  ERROR_OCCURRED_MESSAGE,
  GENERATING_QUESTION_MESSAGE,
  GENERATING_QUESTION_TITLE,
  NO_QUESTIONS_MESSAGE,
  QuestionProcessionStatus,
} from "./consts";
import NewQuestion from "./NewQuestion";
import CompleteQuestion from "./CompleteQuestion";
import { DocumentQuestion } from "./types";
import { ErrorContainer } from "./CompleteQuestion/CompleteQuestion.styled";
import { QuestionContainer } from "./DocumentQuestionTab.styled";
import ProcessingQuestion from "./ProcessingQuestion";
import { QueryStatus } from "views/DeliverablesView/ThirdPartyDocumentPage/consts";

const DataTestIds = {
  questionContent: "questions-content",
  questionSection: "question-section",
};

const DocumentQuestionTab = ({
  questions,
  setSearchTargetEl,
  interaction,
  documentQuestionsStatus,
}: {
  questions: DocumentQuestion[];
  setSearchTargetEl: any;
  interaction: ThirdPartyInteraction;
  documentQuestionsStatus: QueryStatusType;
}) => {
  const { contentStyle, showContent } = useHideDeliverablesContent();
  const user = useCurrentUser();

  if (documentQuestionsStatus === QueryStatus.Loading) {
    return (
      <GenericMessagePage
        title={GENERATING_QUESTION_TITLE}
        message={GENERATING_QUESTION_MESSAGE}
        pre={
          <Icon>
            <Loading />
          </Icon>
        }
      />
    );
  }

  if (documentQuestionsStatus === QueryStatus.Error) {
    return (
      <ErrorContainer>
        <Alert variant="danger" w="100%">
          <b>{ERROR_OCCURRED_MESSAGE}</b> {ERROR_GENERATING_QUESTION_VIEW_MESSAGE}
        </Alert>
      </ErrorContainer>
    );
  }

  if (!questions.length) {
    return (
      <ErrorContainer>
        <Alert variant="info" w="100%">
          {NO_QUESTIONS_MESSAGE}
        </Alert>
      </ErrorContainer>
    );
  }

  return (
    <x.div {...contentStyle} data-testid={DataTestIds.questionContent}>
      {user?.enableAiInteractivity && showContent && questions.length > 1 && (
        <QuestionContainer>
          <NewQuestion documentId={interaction.id} />
        </QuestionContainer>
      )}
      <div ref={setSearchTargetEl} data-testid={DataTestIds.questionSection}>
        {questions.map((q) => (
          <QuestionContainer key={q.id}>
            {q.status === QuestionProcessionStatus.pending ? (
              <ProcessingQuestion question={q} />
            ) : (
              <CompleteQuestion question={q} interaction={interaction} />
            )}
          </QuestionContainer>
        ))}
      </div>
    </x.div>
  );
};

export { DocumentQuestionTab as default };
