import React from "react";
import { x } from "@xstyled/styled-components";
import { useNewMessagePanelStyles } from "./NewMessagePanel.styles";
import { IconButton, ToggleButton, ToggleButtonGroup, Typography } from "@alphasights/alphadesign-components";
import { Expert, MessageType } from "types";
import { PortalTooltip } from "components/Tooltip/Tooltip";
import { Info } from "@alphasights/alphadesign-icons";
import { ClarificationSection } from "./ClarificationSection/ClarificationSection";
import { CallGuideSection } from "./CallGuideSection/CallGuideSection";
import { WorkRequestSection } from "./WorkRequestSection";

interface NewMessagePanelProps {
  enableLegacyBCGClarifications: boolean;
  onChangeMessageType: (messageType: MessageType) => void;
  newMessageType?: MessageType;
  experts: Expert[];
  selectedExperts: Expert[];
  setSelectedExperts: (selectedExperts: Expert[]) => void;
  handleSubmit: (...args: any) => Promise<void>;
  switchToWorkRequest: () => void;
  messageText: string;
  setMessageText: (messageText: string) => void;
  isLoading: boolean;
  blinded: boolean;
  sendMessageButtonRef?: React.MutableRefObject<HTMLButtonElement | null>;
  showInfoBanner: boolean;
  showNonElegibleExperts: boolean;
  externalLinkLabel?: string;
  onClickExternalLink?: () => void;
  mobileVariant?: boolean;
  onMessageSent?: () => void;
  attachTextBoxToNavbar?: boolean;
  onMobileTextBoxHeightChange?: (newHeight: number) => void;
  maximumTaskDuration?: number;
  setMaximumTaskDuration?: (maximumTaskDuration: number) => void;
}

export const NewMessagePanel = ({
  enableLegacyBCGClarifications,
  onChangeMessageType,
  newMessageType,
  experts,
  selectedExperts,
  setSelectedExperts,
  handleSubmit,
  switchToWorkRequest,
  messageText,
  setMessageText,
  isLoading,
  blinded,
  sendMessageButtonRef,
  showInfoBanner,
  showNonElegibleExperts,
  externalLinkLabel,
  onClickExternalLink,
  mobileVariant,
  onMessageSent,
  attachTextBoxToNavbar,
  onMobileTextBoxHeightChange,
  maximumTaskDuration,
  setMaximumTaskDuration,
}: NewMessagePanelProps) => {
  const { tooltipContent, toggleMessageTypeContainer, tooltipDiv } = useNewMessagePanelStyles();

  const tooltip = (
    <x.div {...tooltipContent}>
      {!enableLegacyBCGClarifications && (
        <>
          <Typography>Call Guide: Provide context to the expert for the scheduled call.</Typography>
          <Typography>
            Work Request: Request written work from the expert: add attachments, add a deadline and duration.
          </Typography>
        </>
      )}
      <Typography>Clarification: Ask the expert a short follow up question.</Typography>
    </x.div>
  );

  return (
    <>
      {!mobileVariant && (
        <x.div data-testid="toggle-message-type" {...toggleMessageTypeContainer}>
          <ToggleMessageType
            onChangeMessageType={onChangeMessageType}
            newMessageType={newMessageType}
            enableLegacyBCGClarifications={enableLegacyBCGClarifications}
          />
          <PortalTooltip title={tooltip} position="bottom">
            <x.div {...tooltipDiv}>
              <IconButton size="small" variant="basic">
                <Info />
              </IconButton>
            </x.div>
          </PortalTooltip>
        </x.div>
      )}

      {newMessageType === MessageType.Clarification && (
        <x.div data-testid="clarification-panel">
          <ClarificationSection
            allExperts={experts}
            selectedExperts={selectedExperts}
            setSelectedExperts={setSelectedExperts}
            handleSubmit={handleSubmit}
            switchToWorkRequest={() => onChangeMessageType(MessageType.WorkRequest)}
            messageText={messageText}
            setMessageText={setMessageText}
            isLoading={isLoading}
            blinded={blinded}
            sendMessageButtonRef={sendMessageButtonRef}
            showInfoBanner={showInfoBanner}
            showNonElegibleExperts={showNonElegibleExperts}
            externalLinkLabel={externalLinkLabel}
            onClickExternalLink={onClickExternalLink}
            mobileVariant={mobileVariant}
            onMessageSent={onMessageSent}
            attachTextBoxToNavbar={attachTextBoxToNavbar}
            onMobileTextBoxHeightChange={onMobileTextBoxHeightChange}
          />
        </x.div>
      )}

      {newMessageType === MessageType.WorkRequest && !enableLegacyBCGClarifications && (
        <x.div data-testid="work-request-panel">
          <WorkRequestSection
            allExperts={experts}
            selectedExperts={selectedExperts}
            setSelectedExperts={setSelectedExperts}
            handleSubmit={handleSubmit}
            messageText={messageText}
            setMessageText={setMessageText}
            isLoading={isLoading}
            blinded={blinded}
            sendMessageButtonRef={sendMessageButtonRef}
            setMaximumTaskDuration={setMaximumTaskDuration}
            showInfoBanner={showInfoBanner}
            showNonElegibleExperts={showNonElegibleExperts}
            maximumTaskDuration={maximumTaskDuration}
            externalLinkLabel={externalLinkLabel}
            onClickExternalLink={onClickExternalLink}
            mobileVariant={mobileVariant}
            onMessageSent={onMessageSent}
            attachTextBoxToNavbar={attachTextBoxToNavbar}
            onMobileTextBoxHeightChange={onMobileTextBoxHeightChange}
          />
        </x.div>
      )}

      {newMessageType === MessageType.CallGuide && !enableLegacyBCGClarifications && (
        <x.div data-testid="call-guide-panel">
          <CallGuideSection
            allExperts={experts}
            selectedExperts={selectedExperts}
            setSelectedExperts={setSelectedExperts}
            handleSubmit={handleSubmit}
            switchToWorkRequest={switchToWorkRequest}
            messageText={messageText}
            setMessageText={setMessageText}
            isLoading={isLoading}
            blinded={blinded}
            sendMessageButtonRef={sendMessageButtonRef}
            externalLinkLabel={externalLinkLabel}
            onClickExternalLink={onClickExternalLink}
            mobileVariant={mobileVariant}
            onMessageSent={onMessageSent}
            attachTextBoxToNavbar={attachTextBoxToNavbar}
            onMobileTextBoxHeightChange={onMobileTextBoxHeightChange}
          />
        </x.div>
      )}
    </>
  );
};

const ToggleMessageType = ({
  newMessageType,
  onChangeMessageType,
  enableLegacyBCGClarifications = false,
}: {
  newMessageType: MessageType | undefined;
  onChangeMessageType: (value: any) => void;
  enableLegacyBCGClarifications?: boolean;
}) => {
  const { toggleMessageType } = useNewMessagePanelStyles();

  return (
    <x.div {...toggleMessageType}>
      <ToggleButtonGroup
        value={newMessageType ?? MessageType.WorkRequest}
        onChange={onChangeMessageType}
        allowUnselection={false}
      >
        <>
          {!enableLegacyBCGClarifications && (
            <>
              <ToggleButton value={MessageType.CallGuide} data-testid="call-guide-button">
                {MessageType.CallGuide}
              </ToggleButton>
              <ToggleButton value={MessageType.WorkRequest} data-testid="work-request-button">
                {MessageType.WorkRequest}
              </ToggleButton>
            </>
          )}
        </>
        <ToggleButton value={MessageType.Clarification} data-testid="clarification-button">
          {MessageType.Clarification}
        </ToggleButton>
      </ToggleButtonGroup>
    </x.div>
  );
};
